import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Header } from './TestStripDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { formatDateInCurrentLocale } from './utils';

const IISVaccineDetails = ({ iisVaccine }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const printDetails = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col>
          <div className="py-2">
            <h4>{iisVaccine.displayName}</h4>
          </div>
        </Col>
        <Col className="text-end" xs="auto">
          <Button
            variant="link"
            aria-label="Download vaccine details"
            onClick={printDetails}
          >
            {!isMobile && <div className="me-2">{t('login.download')}</div>}
            <FontAwesomeIcon icon={faArrowDownToLine} />
          </Button>
        </Col>
      </Row>
      <div className="medical_history__item test-card">
        <div className="ms-2">
          <div className="body-large-18-regular inter bold body">
            {formatDateInCurrentLocale(iisVaccine.display_time)}
          </div>
          <Row>
            <Col xs="auto">
              <div
                className="pt-2 body-large-16-medium"
                style={{ color: '#2D3648' }}
              >
                Vaccine name
              </div>
              {iisVaccine?.dose && (
                <div
                  className="pt-2 body-large-16-medium"
                  style={{ color: '#2D3648' }}
                >
                  Vaccine dose
                </div>
              )}
              <div className="pt-2 text-secondary">Source</div>
            </Col>
            <Col>
              <div className="pt-2 body-large-16-regular">{iisVaccine.displayName}</div>
              {iisVaccine?.dose && (
                <div className="pt-2 body-large-16-regular">{iisVaccine.dose} mL</div>
              )}
              <div className="pt-2 text-secondary">{iisVaccine.source.toUpperCase()}</div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const IISVaccineDisplay = ({
  iisVaccine,
  selectedIISVaccine,
  setselectedIISVaccine,
}) => {
  return (
    <React.Fragment>
      {!selectedIISVaccine && (
        <div
          className="medical_history__item test-card my-3"
          onClick={() => setselectedIISVaccine(iisVaccine)}
          key={iisVaccine.id}
        >
          <Header
            displayName={iisVaccine.displayName}
            icon={'syringe'}
            color={'#2862FA'}
            displayTime={iisVaccine.display_time}
            key={'o' + iisVaccine.id}
            source={iisVaccine.source}
          />
        </div>
      )}
      {selectedIISVaccine && selectedIISVaccine.id == iisVaccine.id && (
        <IISVaccineDetails
          key={'d' + selectedIISVaccine.id}
          iisVaccine={selectedIISVaccine}
          setselectedIISVaccine={setselectedIISVaccine}
        />
      )}
    </React.Fragment>
  );
};

export default IISVaccineDisplay;
