import React from 'react';
import { useTranslation } from 'react-i18next';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SearchBar = ({ label, setSearch, search }) => {
  const { t } = useTranslation();

  const searchLabel = (
    <React.Fragment>
      <FontAwesomeIcon className="me-2" icon={faMagnifyingGlass} />
      {label || t('enter_information.search')}
    </React.Fragment>
  );

  return (
    <FloatingLabelInput
      label={searchLabel}
      id="search"
      onChange={(e) => setSearch(e.target.value)}
      value={search}
      style={{
        backgroundColor: '#FBFBFB',
        border: 'none'
      }}
    />
  );
};

export default SearchBar;
