import React, { useState, useEffect } from "react";
import ResultsAccess from "./ResultsAccess";
import { Container } from "react-bootstrap";
import Header from '../../Registration/components/Header';
import MedicalHistory from "./MedicalHistory";
import { useMediaQuery } from "react-responsive";

const ResultsRouter = (props) => {
  const [ hasCredentials, toggleHasCredentials ] = useState(false);
  const [ data, setData ] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const switchFooter = () => {
    const defaultFooter = document.getElementById('default-footer');
    const otcFooter = document.getElementById('otc-footer');
  
    if (defaultFooter && otcFooter) {
      defaultFooter.classList.toggle('d-none', !isMobile);
      otcFooter.classList.toggle('d-none', isMobile);
    }
  };

  useEffect(() => {
    setData(props);
    if (props.appointment && props.user) {
      toggleHasCredentials(true);
    }
  }, [])

  useEffect(() => {
    switchFooter();
  }, [isMobile]);

  return (
    <div
      style={{ minHeight: 'calc(100vh - 85px)' }}
      className="responsive-background"
    >
      <Header testGroup={props.test_group} />
      {hasCredentials ? (
        <div
          className={isMobile ? '' : 'container'}
          style={{ maxWidth: isMobile ? '100%' : '1300px' }}
        >
          <MedicalHistory data={data} />
        </div>
      ) : (
        <Container style={{ maxWidth: '1300px' }}>
          <ResultsAccess
            {...{ toggleHasCredentials, setData, testGroup: props.test_group }}
          />
        </Container>
      )}
    </div>
  );
}

export default ResultsRouter;
