export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "ماه",
    "day": "روز",
    "year": "سال",
    "january": "جنوری",
    "february": "فبروری",
    "march": "مارچ",
    "april": "اپریل",
    "may": "می",
    "june": "جون",
    "july": "جولای",
    "august": "اگست",
    "september": "سپتمبر",
    "october": "اکتوبر",
    "november": "نومبر",
    "december": "دسمبر"
  },
  "user": {
    "send_appointment_confirmation_message": "قرار ملاقات شما برای {{name}} تأیید شد.",
    "landline_appointment_reminder_message": "سلام، {{full_name}}. این یک یادآوری برای قرار شما در {{name}} است. قرار شما در{{date}} در {{time}} در {{address}} است.",
    "send_at_home_visit_confirmation_message": "قرار ملاقات شما در {{date}} پس از {{time}} تأیید می‌شود.",
    "send_mail_order_confirmation_message": "سفارش پستی شما تایید شد",
    "send_waitlist_message": "شما برای {{name}} در لیست انتظار قرار گرفته اید. لطفاً تا زمانی که ملاقات شما تأیید نشده باشد حاضر نشوید.",
    "verify_contact": {
      "phone": "لطفاً شماره تلفن خود را با کلیک روی: {{confirmation_link}} تأیید کنید",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "ثبت نام برای قرار ملاقات را در اینجا ادامه دهید: {{url}}",
    "on_demand_confirmation_message": "شما با موفقیت برای {{name}} ثبت نام کردید.",
    "appointment_reminder": "لطفاً قبل از قرار ملاقات خود در {{start_time}}، روی پیوند کلیک کنید تا موارد فعال برجسته را تکمیل کرده و تأیید خود را مشاهده کنید. لطفاً بیش از 15 دقیقه قبل از قرار ملاقات نکنید.",
    "appointment_reminder_base": "{{text}} اینجا را کلیک کنید: {{link}}",
    "test_result_notifier": {
      "text_message": "پورتال بیمار برای {first_name_with_last_initial}% به روز رسانی شده است. به {url}% بروید و از کد: {access_code}% استفاده کنید",
      "email_subject": "به روز رسانی های جدید در پورتال بیمار شما",
      "email_intro": "سلام {first_name_with_last_initial}%",
      "email_p1": "پیام یا به‌ روزرسانی بیمار جدید برای شما قابل دسترس می باشد.",
      "email_p2": "برای مشاهده پورتال بیمار خود و استفاده از کد، روی دکمه زیر کلیک کرده یا از لینک استفاده کنید",
      "email_p3": "مشاهده پورتال بیمار در:'",
      "email_button": "مشاهده پورتال"
    },
    "landline_appointment_reminder_message_without_time": "سلام، {{full_name}}. این یک یادآوری برای قرار شما در {{name}} است. قرار شما در {{date}} در {{address}} است.",
    "appointment_reminder_on_demand": "لطفاً قبل از قرار ملاقات خود در {{date}} روی پیوند کلیک کنید تا موارد فعال برجسته را تکمیل کرده و تأیید خود را مشاهده کنید.",
    "new_test_result_notifier": {
      "email_intro": "پورتال بیمار شما به روز رسانی شده است.",
      "email_text_1": "برای مشاهده هرگونه سوابق جدید بیمار یا گزارش های آزمایشگاهی، از پورتال تاریخچه پزشکی خود در زیر دیدن کنید.",
      "email_button": "تاریخچه پزشکی",
      "email_text_2": "همچنین می توانید این URL را در مرورگر خود کپی و جایگذاری کنید:"
    },
    "mailers": {
      "email_contact_us": "سوالی دارید؟ تماس با ما"
    }
  },
  "errors": {
    "generic": {
      "message": "اشتباهی رخ داده است لطفا دوباره تلاش کنید"
    },
    "messages": {
      "blank": "نباید خالی باشد"
    },
    "incorrect_credentials": "اطلاعات اعتباری شما اشتباه بودند، لطفا دوباره امتحان کنید.",
    "error_activating_test_kit": "خطایی در فعال‌سازی کیت آزمایش رخ داد"
  },
  "type": "نوع",
  "payment": {
    "continue_label": "به پرداخت ادامه دهید",
    "policy_text": "در صورت لغو یا عدم حضور در قرار ملاقات، ما 80 درصد از مبلغ پرداختی شما را به شما 7 روز پس از تاریخ ملاقات شما پس می دهیم. شما می توانید [شرایط خدمات] کامل ({{link}}) را مشاهده کنید",
    "card_number": "شماره کارت",
    "expiry_date": "تاریخ انقضا",
    "pay_button": "پرداخت",
    "no_credit_card": "در محل پرداخت میکنم",
    "cash_payment_helper": "لطفاً اطمینان حاصل کنید که **{{payment}}** را برای قرار ملاقات خود بیاورید",
    "invoice": "صورتحساب",
    "pay_by_card": "پرداخت با کارت",
    "cost": "هزینه",
    "total": "مجموع",
    "pay_cash_at_appointment": "من در هنگام ملاقات خود مبلغ نقدی به {{appointment}} پرداخت می کنم",
    "view_invoice": "مشاهده/چاپ صورتحساب",
    "refund_policy": "اگر وقت خود را لغو کنید، {{amount}} از پرداختی تا ۷ روز پس از تاریخ وقت ملاقات به شما بازگردانده خواهد شد. می‌توانید شرایط کامل خدمات را در Terms of Service مشاهده کنید.",
    "refund_window_passed": "قرار ملاقات شما واجد شرایط بازپرداخت نیست زیرا زمان قرار سپری شده است. لطفاً برای هر گونه تصمیم گیری مستقیماً با هماهنگ کننده های سایت تماس بگیرید.",
    "amount_paid": "مبلغ پرداخت شده",
    "balance": "بیلانس"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "معافیت رضایت را بارگذاری کنید یا کادر امضا را در زیر امضا کنید",
    "stop": "برای تکمیل ثبت نام رضایت لازم است",
    "parent_or_guardian_required_html": "به منظور نهایی شدن ثبت نام، والدین، سرپرست یا تصمیم گیرنده مجاز شما باید رضایت خود را از فرم زیر ارائه کرده و نام آنها را امضا کنند. شما <b> نمی توانید </b> به تنهایی با این سرویس موافقت کنید.",
    "send_parent_or_guardian": "می توانید این پیوند را به والدین یا سرپرست خود ارسال کنید یا از آنها بخواهید که در زیر در این صفحه امضا کنند.",
    "consent_guardian_name_signature": "نام اصلی",
    "consent_for_minor": "از طرف خردسال رضایت نامه را امضا کنید",
    "guardian_signature": "امضای والدین، سرپرست یا تصمیم گیرنده پزشکی مجاز",
    "after_you_schedule": "شرکت کنندگان زیر سن {{age}} باید رضایت نامه ای را از طرف والدین یا سرپرست خود امضا کنند. لطفاً تا پایان ثبت نام ادامه دهید تا به سرپرست خود ارسال کنید یا برای وابستگان خود امضا کنید.",
    "consent_guardian_last_name_signature": "نام خانوادگی",
    "consent_first_and_last_name": "نام اصلی و خانوادگی",
    "phone_number": "شماره تلفن نگهبان",
    "email": "ایمیل نگهبان",
    "consent_registrar_with_guardian_name_signature": "نام سرپرست",
    "consent_registrar_with_guardian_last_name_signature": "نام خانوادګی سرپرست",
    "consent_registrar_first_name_signature": "نام ثبت کننده",
    "consent_registrar_last_name_signature": "نام خانوادګی ثبت کننده",
    "consent_on_behalf": "من به نمایندگی از {{first_name}} {{last_name}} امضا می‌کنم.",
    "consent_on_behalf_registrar": "من به صورت شفاهی توسط {{first_name}}  {{last_name}} مجاز شده‌ ام تا به نمایندگی از آنها امضا کنم.",
    "consent_on_behalf_registrar_with_guardian": "من به صورت شفاهی توسط سرپرست {{first_name}} {{last_name}} مجاز شده‌ ام تا به نمایندگی از آنها امضا کنم.",
    "registrar_phone": "شماره تلفن دفتر ثبت",
    "registrar_email": "ایمیل دفتر ثبت",
    "consented_by_parent_guardian": "والدین / سرپرست",
    "consented_by_decision_maker": "تصمیم گیرنده پزشکی مجاز",
    "consented_by_guardian_verbal": "ثبت با سرپرست (رضایت شفاهی اخذ شده)",
    "consented_by_registrar": "ثبت (رضایت شفاهی اخذ شده)",
    "full_name": "نام و نام خانوادگی",
    "print": "چاپ کنید"
  },
  "registration": {
    "contact_information": "نام و آدرس",
    "contact_information_additional": "اطلاعات تماس والدین یا سرپرست برای ارتباط و نتایج ملاقات",
    "personal_information": "اطلاعات دموگرافیک",
    "address_placeholder": "لطفا آدرس منزل خود را وارد کنید",
    "first_name_label": "نام اصلی",
    "last_name_label": "نام خانوادگی",
    "errors": {
      "state": "حالت باید یک کد دولتی معتبر 2 نویسه باشد",
      "phone_number_invalid": "شماره تلفن معتبر نیست",
      "required": "این مقدار الزامی است",
      "email_domain": "ورودی شما نامعتبر است؛ لطفا دوباره چک کنید. ثبت نام محدود به شرکت کنندگانی است که توسط سازمان حامی انتخاب شده اند. لطفاً از ایمیل محل کار یا انجمن خود استفاده کنید. اگر احساس می کنید واجد شرایط هستید و خطایی وجود دارد، لطفاً با پشتیبانی تماس بگیرید.",
      "age_requirement": "تاریخ تولد شرایط سن را برآورده نمی کند",
      "signature_required": "امضا لازم است",
      "regex_invalid": "به نظر می رسد این مقدار نامعتبر است.",
      "date_invalid": "این تاریخ وجود ندارد",
      "invalid_entry": "لطفا با ثبت نام ادامه ندهید. دستورالعمل های روی صفحه را دنبال کنید یا با نقطه تماس خود تماس بگیرید.",
      "city": "باید شهری معتبر باشد",
      "survey_unanswered": "لطفاً بررسی کنید که به تمام سوالات با علامت * مورد نیاز پاسخ داده شده است.",
      "postal_code": "باید یک کد پستی 5 رقمی معتبر باشد",
      "field_required": "{{field}} باید یک مقدار داشته باشد",
      "option_required": "شما باید یکی از گزینه ها را انتخاب کنید",
      "year_length_invalid": "(yyyy) تاریخ تولد را با سال چهاررقمی وارد کنید",
      "invalid_day": "روز باید بین 1 تا 31 باشد",
      "reached_max_chars": "شما به حداکثر تعداد نویسه ها رسیده اید.",
      "chars_max": "حداکثر نویسه ها",
      "minimum_length": "ورودی باید حداقل {{length}} کاراکتر داشته باشد."
    },
    "insurance_status": {
      "question": "آیا شما بیمه سلامت دارید؟",
      "have_health_insurance": "بله، من بیمه درمانی دارم",
      "do_not_have_health_insurance": "نه، من بیمه درمانی ندارم"
    },
    "insurance_policy_holder": {
      "question": "بیمه گذار کیست؟",
      "i_am": "من هستم",
      "my_spouse": "همسر یا شریک زندگی من",
      "my_parents": "والدین من",
      "someone_else": "شخص دیگری",
      "policy_first_name": "نام دارنده بیمه نامه",
      "policy_last_name": "نام خانوادگی دارنده بیمه نامه",
      "policy_dob": "تاریخ تولد دارنده بیمه نامه",
      "name": "نام دارنده بیمه "
    },
    "insurance_information": {
      "title": "اطلاعات بیمه",
      "company_name": "نام شرکت بیمه",
      "id_number": "شماره شناسایی عضو",
      "group_number": "شماره گروه اعضا",
      "secondary_insurance_label": "من بیمه ثانویه دارم",
      "take_photo": "لطفاً از کارت بیمه خود عکس بگیرید. گرفتن عکس برخی از اطلاعات را به‌صورت خودکار پر می‌کند.",
      "front_of_card": "قسمت جلوی کارت",
      "card_information": "اطلاعات کارت بیمه ",
      "back_of_card": "پشت کارت"
    },
    "employment_information": {
      "label": "استخدام",
      "address_1": "آدرس شرکت",
      "address_2": "مثال: سوئیت 200",
      "phone_number": "شماره تلفن شرکت",
      "company": "نام شرکت",
      "postal_code": "کد پستی کارفرما",
      "employed": "به کار گرفته شده",
      "sole_prorietor": "مالک انفرادی",
      "not_employed": "بیکار",
      "reporting_supervisor": "ناظر گزارش",
      "reporting_department": "بخش گزارش",
      "supervisor_name": "نام سرپرست",
      "supervisor_email": "ایمیل سرپرست"
    },
    "location_availability": "در حال حاضر آزمایش برای اعضای جامعه ساکن در این مناطق در اولویت است",
    "custom_survey": "پرسشنامه",
    "confirmation": "مرور",
    "waitlisted": "در لیست انتظار",
    "schedule_your_appointment": "برنامه قرار ملاقات",
    "information": "اطلاعات",
    "consent": "رضایت",
    "location": "محل",
    "symptoms": "علائم",
    "address_required": "لطفا آدرس خود را وارد کنید",
    "consent_required": "لطفاً برای ادامه رضایت دهید",
    "required_field": "دایره مورد نیاز را نشان می دهد",
    "phone_number": "شماره موبایل",
    "email": "پست الکترونیک",
    "date_of_birth": "تاریخ تولد",
    "minimum_age": "حداقل سن برای شرکت {{year}} سال است.",
    "no_minimum_age": "حداقل سن برای آزمایش وجود ندارد",
    "continue_button": "ادامه",
    "email_required": "این مقدار باید یک ایمیل معتبر باشد.",
    "done": "انجام شده",
    "signature": "امضا",
    "clear_button": "پاک کردن",
    "back_button": "بازگشت",
    "choose_location": "مکان را انتخاب کنید",
    "no_slots": "دیگر اسلات در {{location}} موجود نیست",
    "choose_appointment": "زمان ملاقات را در {{location}} انتخاب کنید",
    "appointment_required": "لطفاً زمان ملاقات را انتخاب کنید",
    "phone_number_required": "شماره تلفن معتبر نیست",
    "phone_number_label": "لطفاً برای نتایج سریعتر آزمایش، عددی را وارد کنید که بتواند پیام های متنی دریافت کند",
    "symptoms_experiencing": "لطفاً روی علائمی که در حال حاضر با آنها روبرو هستید کلیک کنید. اگر هیچ علائمی ندارید، لطفاً ادامه دهید.",
    "household": "اعضای خانواده",
    "household_p1": "در صورت تمایل، برای اعضای خانواده که نیاز به آزمایش دارند، قرار ملاقات بگذارید.",
    "add_dependent": "افزودن عضو خانواده",
    "remove_dependent": "حذف وابسته",
    "dependent_consents": "رضایت",
    "submit": "کامل",
    "add_waitlist": "افزودن به لیست انتظار",
    "address": "آدرس خانه",
    "address_1": "خط آدرس 1",
    "address_2": "آپارتمان/ رشته #",
    "address_city": "شهر",
    "address_state": "ایالت",
    "postal_code": "کد پستی",
    "race_ethnicity": "مسابقه",
    "gender": "هویت جنسیتی",
    "self_described_gender": "جنسیت خود توصیف شده",
    "interpreter": "به مترجم نیاز دارید؟ اگر بله، کدام زبان؟",
    "consent_to_terms": "من با این شرایط موافقت می کنم.",
    "reg_not_open": "ثبت نام ها باز نشده",
    "no_more_avail_spots": "محدوده زمانی انتخاب شده، دیگر در دسترس نمیباشد. لطفا دوباره تلاش کنید.",
    "consent_helper": "برای امضا، ماوس یا انگشت خود را روی کادر زیر کلیک کرده و بکشید",
    "phone_number_unreachable_label": "تلفن ثابت؟",
    "select": "انتخاب کنید",
    "test_surveys": "سوالات قرار ملاقات",
    "edit": "ویرایش",
    "continue_to_registration": "برای ثبت نام ادامه دهید",
    "accounts": {
      "already_have_an_account": "قبلاً حساب دارید؟",
      "log_in": "وارد شدن",
      "sign_up": "ثبت نام",
      "sign_up_description": "لطفاً اطلاعات خود را وارد کنید تا حساب خود را ایجاد کرده و در ثبت نام های بعدی در وقت خود صرفه جویی کنید.",
      "log_in_description": "لطفاً ایمیل و گذرواژه خود را وارد کنید، یا از طریق Google یا Outlook در زیر وارد شوید",
      "sign_in_with_label": "ورود با {{provider}}",
      "password_must_match": "رمزهای عبور باید مطابقت داشته باشند",
      "password_uppercase": "گذرواژه شما باید حداقل (%s) حرف بزرگ داشته باشد.",
      "password_lowercase": "گذرواژه شما باید حداقل (%s) حروف کوچک داشته باشد.",
      "password_number": "گذرواژه شما باید حداقل (%s) شماره داشته باشد.",
      "password_special": "گذرواژه شما باید حداقل (%s) نویسه ویژه داشته باشد."
    },
    "password": "رمز عبور",
    "password_confirmation": "تایید رمز عبور",
    "consent_for": "رضایت برای {{name}}",
    "book_one_time_appointment": "رزرو نوبت یکبار مصرف",
    "duplicate_users": {
      "exists": "شما قبلاً ثبت نام کرده اید",
      "overlapping_email_and_phone_p1": "ما به شماره تلفن و آدرس ایمیل شما پیام ارسال کردیم.",
      "p2": "با استفاده از پیام خود، می توانید قرار ملاقات خود را تغییر دهید یا نتایج آزمایش را وارد کنید.",
      "overlapping_phone_and_email_p3": "لطفاً ایمیل یا تلفن خود را برای جزئیات بیشتر بررسی کنید.",
      "overlapping_email_p3": "لطفاً ایمیل خود را برای جزئیات بررسی کنید.",
      "overlapping_phone_p3": "لطفاً تلفن خود را برای جزئیات بررسی کنید.",
      "overlapping_phone_p1": "ما به شماره تلفن شما پیام دادیم.",
      "overlapping_email_p1": "ما به آدرس ایمیل شما پیامی ارسال کردیم.",
      "p4": "اگر فکر می کنید این خطا است، لطفاً با ما در support@primary.health تماس بگیرید",
      "overlapping_email_and_phone_p3": "لطفاً ایمیل یا تلفن خود را برای جزئیات بیشتر بررسی کنید."
    },
    "duplicate_waitlist": {
      "exists": "شما در حال حاضر در لیست انتظار هستید",
      "overlapping_email_and_phone_p1": "ما تأیید دیگری را به شماره تلفن و آدرس ایمیل شما ارسال کردیم",
      "p2": "با استفاده از تأیید خود، می توانید اطلاعات اضافی ارائه دهید یا خود را از لیست انتظار حذف کنید.",
      "overlapping_phone_and_email_p3": "لطفاً ایمیل یا تلفن خود را برای جزئیات بیشتر بررسی کنید",
      "overlapping_email_p3": "لطفاً ایمیل خود را برای جزئیات بررسی کنید",
      "overlapping_phone_p3": "لطفاً تلفن خود را برای جزئیات بررسی کنید",
      "overlapping_email_p1": "ما تأیید دیگری را به ایمیل شما ارسال کردیم."
    },
    "insist_guardian_consent": "من از طرف نماینده رضایت می دهم",
    "confirmation_section": {
      "title": "بررسی و تأیید کنید",
      "p1": "لطفاً قبل از تکمیل ثبت نام اطلاعات خود را مرور کنید.",
      "edit_information": "برگردید و ویرایش کنید"
    },
    "iemodal": {
      "title": "مرورگر پشتیبانی نشده",
      "body": "در حال حاضر امکان ثبت نام در این مرورگرها وجود دارد"
    },
    "show_other_locations": "برای نمایش مکان های دیگر کلیک کنید",
    "non_us_address": "این آدرس بین المللی است",
    "test_group_user_survey": "غربالگری شرکت کنندگان",
    "self_consent": "من برای خودم رضایت می دهم",
    "address_country": "کشور",
    "appointment": "قرار ملاقات",
    "employee_id": "شناسه کارمند",
    "appointment_time": "زمان ملاقات",
    "appointment_location": "موقعیت",
    "phone_or_email": "شماره تلیفون یا ایمیل",
    "no_self_consent": "من رضایت خود را رد می کنم",
    "no_insist_guardian_consent": "من از طرف {{full_name}} رد می کنم",
    "minimum_age_with_months": "حداقل سن برای شرکت {{year}} سال و {{month}} ماه است.",
    "additional_consents_helpertext": "ممکن است بعداً رضایت بیشتری درخواست شود",
    "assistive_technology": "فناوری کمکی",
    "covid_vaccine_y_n": "آیا با این رزرو واکسن کووید-۱۹ می‌خواهید؟",
    "received_covid_vaccine_y_n": "آیا قبلاً واکسن کووید  ۱۹ دریافت کرده اید؟",
    "covid_vaccinations": "واکسیناسیون کووید    ۱۹",
    "select_vaccine_dose": "لطفاً دوز واکسنی را که می خواهید دریافت کنید انتخاب کنید",
    "demographic_info": "معلومات نفوس شماری",
    "additional_info": "معلومات اضافه",
    "self_described_race": "نژاد خود توصیف شده",
    "verify_contact_information": "تایید",
    "verify_contact": {
      "we_sent_you_a_code": "لطفاً کدی را که به {{contact}} فرستادیم وارد کنید",
      "confirmation_code": "کد تایید",
      "code_resent": {
        "email": "کد دیگری به ایمیل شما ارسال شد",
        "phone_number": "کد دیگری به گوشی شما ارسال شد"
      },
      "did_not_receive_code": "کد تایید را دریافت نکردید؟",
      "verify_by": {
        "email": "در عوض از طریق ایمیل تأیید کنید",
        "phone_number": "در عوض با شماره تلفن تأیید کنید"
      }
    },
    "preferred_method_of_communication": {
      "question": "روشی که ترجیح می دهید با شما از طریق ان تماس گرفته شود",
      "helper_text": "ما با به‌روزرسانی‌های مربوط به قرار مقالات با شما تماس خواهیم گرفت",
      "phone_number": "تلفن (تنها پیام‌رسانی از طریق پیامک و نرخ‌های دیتا ممکن است اعمال شود)",
      "landline": "در حال حاضر قادر به پشتیبانی از تلفن ثابت نیستیم. لطفاً یک ایمیل برای ارتباطات مهم",
      "verification_method": "چگونه می خواهید کد احراز هویت خود را دریافت کنید؟",
      "verification_helper_text": "قبل از ورود به سیستم کدی برای تایید هویت شما ارسال می شود."
    },
    "skip_for_now": "فعلا رد شوید",
    "default_information": {
      "title": "صحیح است، ما از این آدرس استفاده خواهیم کرد",
      "title_v2": "باشه، ما از این اطلاعات تماس استفاده خواهیم کرد"
    },
    "middle_name_label": "نام میانی",
    "confirm_appointment": "قرار ملاقات را تایید کنید",
    "dob": "تاریخ تولد ",
    "different_appointment": "قرار ملاقات متفاوت",
    "select_appointment_time": "زمان قرار ملاقات را انتخاب کنید",
    "decline_address": "من از ارائه آدرس خودداری می کنم. لطفا به جای آن از آدرس برنامه استفاده کنید.",
    "patient_information": "اطلاعات بیمار",
    "scan_license": "اسکن گواهینامه رانندگی",
    "how_to_contact": "چطور باید با شما ارتباط برقرار کنیم؟",
    "decline_email_or_phone": "از ارائه ایمیل یا شماره تلفن خودداری می کنم. من به مدیران برنامه اجازه می‌دهم نتایج من را دریافت کنند.",
    "medical_screening": "اسکرنینگ پزشکی",
    "show_service_at_this_location": "ارایه دهندگان خدمات در این مکان را نشان دهید  ",
    "verbal_consent_provided": "مطابق قوانین آمریکا افراد دارای معلولیت (ADA)، خودفرد یا تصمیم‌گیرنده قانونی آن رضایت شفاهی ارائه کرده است. رضایت کتبی باید ظرف ۲۴ ساعت ازفرد یا تصمیم‌گیرنده قانونی آن‌ها دریافت شود.",
    "unexpired_written_consent_present": "محل آزمایش دارای رضایت کتبی معتبر در پرونده است.",
    "select_to_proceed": "لطفاً یکی از گزینه‌های بالا را برای ادامه انتخاب کنید",
    "guardians_information": "اطلاعات سرپرست",
    "registrars_information": "اطلاعات ثبت کننده ",
    "optional": "اختیاری",
    "vfc_eligibility": "واجد شرایط برای واکسن کودکان (VFC)",
    "vfc_eligibility_subtext": "فرزند شما ممکن است بتواند واکسن‌های رایگان را از طریق برنامه واکسن‌های کودکان (VFC) مرکز کنترل و پیشگیری بیماری‌ها (CDC) دریافت کند. این واکسن‌ها در مطب‌های ارائه‌دهندگان خدمات بهداشتی، داروخانه‌ها و کلینیک‌های بهداشتی که در برنامه VFC ثبت‌نام شده اند، در دسترس اند."
  },
  "gender_key": {
    "male": "مذکر",
    "female": "مؤنث",
    "cisgender": "سیسجندر",
    "non_binary": "Genderqueer یا غیر دودویی",
    "other": "دیگر",
    "prefer_to_self_describe": "هویت ذکر نشده است",
    "prefer_not_to_disclose": "از پاسخ دادن سر باز زدن",
    "transgender_male": "مرد مذکر/ ترانسمن",
    "transgender_female": "مونثترنس/ زن ترنس",
    "unknown": "ناشناس",
    "non_binary_only": "شخص خارج از جنسیت مرد و زن",
    "intersex": "دوجنسه",
    "transgender": "تفیر جنسیت",
    "gender_fluid": "جنسیت سیال",
    "not_applicable": "غیرقابل اجرا"
  },
  "ethnicity": {
    "american_indian_alaska_native": "سرخ پوستان آمریکایی یا بومی آلاسکا",
    "american_indian_central_america": "سرخ پوست آمریکایی از آمریکای جنوبی یا مرکزی",
    "asian": "آسیایی",
    "black": "سیاه پوست یا آمریکایی آفریقایی تبار",
    "latinx": "لاتین، لاتینکس یا اسپانیایی",
    "middle_eastern_north_african": "خاورمیانه یا شمال آفریقا",
    "native_hawaiian_pacific_islander": "جزیره بومی هاوایی یا اقیانوس آرام",
    "white_european": "سفید یا قفقازی",
    "unknown": "ناشناس",
    "prefer_not_to_disclose": "ترجیح می دهید افشا نشود",
    "asian_indian": "هندی آسیایی",
    "filipino": "فیلیپینی",
    "japanese": "جاپانی",
    "korean": "کوریا یی",
    "vietnamese": "ویتنامی",
    "other_asian": "آسیایی دیگر",
    "native_hawaiian": "بومی هاوایی",
    "guamanian_or_chamorro": "گوآمانی یا چامورو",
    "samoan": "ساموآ",
    "other_pacific_islander": "دیگر جزیره اقیانوس آرام",
    "chinese": "چینی ها",
    "help": "نژاد به مجموعه ای از ویژگی های فیزیکی اشاره دارد که برای تمایز بین گروه های افراد استفاده می شود. نژاد ممکن است با ملیت یا کشوری که تابعیت آن را دارید مطابقت داشته باشد",
    "subtitle": "مقررات ایجاب می کند که ما تمام اطلاعات زیر را جمع آوری کنیم",
    "laotian": "لائوس",
    "cambodian": "کامبوج",
    "other": "دیگر",
    "bangladeshi": "بنگلادشی",
    "hmong": "همونگ",
    "indonesian": "اندونزیایی",
    "malaysian": "مالزیایی",
    "pakistani": "پاکستانی",
    "sri_lankan": "سریلانکایی",
    "thai": "تایلندی",
    "taiwanese": "تایوانی",
    "fijian": "فیجی",
    "guamanian": "گوامی",
    "tongan": "تونگایی"
  },
  "languages": {
    "en": "انگلیسی",
    "fr": "فرانسوی",
    "es": "اسپانیول",
    "so": "سومالی",
    "hmn": "همونگ",
    "asl": "زبان اشاره آمریکایی (ASL)",
    "kar": "کارن",
    "am": "آمهاری",
    "ru": "روسی",
    "om": "اورومو"
  },
  "symptoms": {
    "fever": "تب یا لرز",
    "cough": "سرفه کردن",
    "muscle_aches": "دردهای عضلانی",
    "severe_fatigue": "خستگی شدید (بیش از حد معمول)",
    "trouble_breathing": "مشکل در تنفس",
    "diarrhea": "اسهال",
    "loss_of_smell": "از دست دادن بویایی",
    "loss_of_taste": "از دست دادن طعم و مزه",
    "shortness_of_breath": "تنگی نفس یا مشکل در تنفس",
    "headache": "سردرد",
    "sore_throat": "گلو درد",
    "congestion": "احتقان یا آب ریزش بینی",
    "nausea": "تهوع یا استفراغ",
    "close_contact": "تماس نزدیک با فرد آلوده",
    "helper_text": {
      "close_contact": "آیا با کسی که COVID-19 را تایید کرده است، تماس نزدیک (در فاصله 6 پا حداقل 15 دقیقه) داشته اید"
    },
    "suspected_exposure": "مواجهه مشکوک",
    "none": "بدون علائم"
  },
  "instructions": {
    "title": "شما ثبت نام کرده اید اما قبل از رفتن...",
    "p1": "این صفحه شامل اطلاعاتی در مورد قرار ملاقات شما و همچنین بارکد قرار ملاقات شما است",
    "appointment_scheduled": "قرار شما برنامه ریزی شده است",
    "verify_info": {
      "title": "اطلاعات خود را تأیید کنید",
      "p1": "مهم است که اطلاعات تماس خود را تأیید کنید تا بتوانید به سرعت به درگاه بیمار خود دسترسی پیدا کنید.",
      "p2": "تأییدیه ای به ایمیل شما ارسال شده است. لطفا روی پیوند ارائه شده کلیک کنید.",
      "p3": "اگر نیاز به تغییر اطلاعات تماس خود دارید، لطفاً دکمه راهنما را فشار دهید."
    },
    "verified": "تأیید شد",
    "not_verified": "تأیید نشده",
    "resend_text": "پیوند را دریافت نکرده اید؟",
    "resend": "ارسال مجدد",
    "skip": "رد اعتبار سنجی و نمایش تأیید",
    "name": "نام",
    "appointment_details": "وقت ملاقات",
    "date_and_time": "تاریخ و زمان",
    "add_to_calendar": "افزودن به تقویم",
    "instructions": "دستورالعمل های عمومی",
    "successfully_flash": "قرار ملاقات با موفقیت ایجاد شد!",
    "success_flash_email": "ایمیل با موفقیت تأیید شد",
    "success_flash_phone": "شماره تلفن با موفقیت تأیید شد",
    "mail_order": "شما ظرف یک هفته کیت تست خود را به صورت پستی دریافت خواهید کرد.",
    "at_home": "در همین زمان یک ارائه دهنده پزشکی به خانه شما می آید",
    "at_home_instructions": "دستورالعمل در خانه",
    "mail_order_instructions": "دستورالعمل سفارش پستی",
    "request_additional_appointment": "رزرو نوبت بعدی",
    "book_additional_appointment": "یک قرار ملاقات دیگر رزرو کنید",
    "confirmation_code": "کد تایید",
    "completed": "تکمیل شد",
    "appointment_barcode": "بارکد قرار ملاقات",
    "dependent_links": "پیوندهای قرار ملاقات خانگی",
    "on_demand_title": "شما ثبت نام کرده اید اما قبل از رفتن...",
    "save_link": "این پیوند را ذخیره کنید",
    "verify_phone": "شماره تلفنتان را تائید کنید",
    "verify_phone_description": "مهم است که اطلاعات تماس خود را تأیید کنید تا بتوانید به سرعت به سوابق خود دسترسی پیدا کنید. یک پیام متنی تأیید به شماره شما ارسال شده است.",
    "verify_email_description": "مهم است که اطلاعات تماس خود را تأیید کنید تا بتوانید به سرعت به سوابق خود دسترسی پیدا کنید. یک ایمیل تأیید به آدرس ایمیل شما ارسال شده است.",
    "information": "اطلاعات ثبت نام",
    "follow_up_appointment": "انتصاب پیگیری",
    "get_directions": "دریافت جهات",
    "cancel": "لغو قرار ملاقات",
    "reschedule_appointment": "تجدید وقت ملاقات",
    "reschedule_linked_appointments": "برنامه ریزی مجدد قرار ملاقات ها",
    "no_slots": "بار دیگر در دسترس نیست",
    "check_results": "پورتال بیماران را بررسی کنید",
    "follow_up_modal_header": "لطفا دومین دوز خود را برنامه ریزی کنید",
    "are_you_sure_you_want_to_cancel": "آیا مطمئن هستید که می خواهید این قرار را لغو کنید؟",
    "please_choose_cancellation_reason": "لطفاً دلیل لغو را در زیر انتخاب کنید",
    "additional_details": "لطفاً هرگونه جزئیات اضافی را در زیر اضافه کنید",
    "errors": {
      "missing_cancellation_reason": "لطفاً دلیل لغو را انتخاب کنید"
    },
    "verify_email": "ایمیل خود را تایید کنید",
    "redcap_url": "برای تکمیل نظرسنجی خود کلیک کنید",
    "verify_contact_information": "اطلاعات تماس خود را تأیید کنید",
    "please_also": "لطفا همچنان",
    "new_title": "تأیید برای {{name}}",
    "contact_method": "روش تماس",
    "next_steps": {
      "title": "مراحل بعدی",
      "p1": "شما یک کیت آزمایش برای فعال کردن دارید. وقتی برای آزمایش آماده شدید، پیوند ارسال شده به ایمیل خود را دنبال کنید تا به این صفحه بازگردید و کیت خود را در زیر فعال کنید.",
      "administered": "زمانی که کیت شما به آزمایشگاه رسید و نتایج شما آماده شد، با شما تماس خواهیم گرفت. نتایج معمولاً 3-5 روز پس از رسیدن به آزمایشگاه طول می کشد. وقتی نتایج آماده شد می‌توانید از صفحه [تاریخ پزشکی]({{link}}) خود مشاهده کنید.",
      "resulted": "نتایج در صفحه [تاریخچه پزشکی]({{link}}) شما موجود است.",
      "shipping_information": "اطلاعات ارسال"
    },
    "save_this_page": {
      "title": "این صفحه را ذخیره کنید",
      "p1": "از این صفحه برای گزارش نتایج تست خودی یا مشاهده تاریخچه پزشکی استفاده کنید",
      "p2": "برای دسترسی به این صفحه در آینده بر روی لینک موجود در ایمیل تاییدیه یا متن پیامک خود کلیک کنید.",
      "p3": "می‌توانید این صفحه را با نشانک‌گذاری، افزودن آن به صفحه اصلی یا کپی کردن پیوند در مکانی امن ذخیره کنید."
    },
    "show_my_barcode": "بارکد من را نشان بده",
    "my_account": "حساب من",
    "register_another": "ثبت نام یک شرکت کننده دیگر",
    "update_vaccine": "به روز رسانی اطلاعات واکسن",
    "medical_history": "تاریخچه پزشکی",
    "upcoming_appointments": "وقت ملاقات های آینده",
    "reschedule": "تغییر وقت ملاقات ",
    "resend_confirmation": "ارسال مجدد تاییدیه ",
    "appointment_details_v2": "جزییات وقت ملاقات ",
    "confirm_cancellation": "تایید کنسلی ",
    "confirm_cancellation_question": "آیا اطمینان دارید که می خواهید این وقت را کنسل کنید؟",
    "select_new_appointment_time_below": "زمان جدیدی را برای قرار ملاقات خود از لیست زیر انتخاب کنید. اگر نیاز به تغییر خدمات یا مکان ملاقات دارید، لطفاً این وقت ملاقات را لغو کرده و یک وقت جدید بگیرید.",
    "no_take_me_back": "خیر، برگرد به عقب",
    "yes_cancel_appointment": "بله، وقت را کنسل کنید",
    "update_appointment": "وقت را تغییر دهید",
    "select_new_appointment_time": "وقت ملاقات جدید را انتخاب کنید",
    "clinic": "کلینیک",
    "services": "خدمات درمانی",
    "appointment_missed": "وقت را از دست داده اید",
    "appointment_canceled": "وقت کنسل شده است"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "کد دسترسی نامعتبر"
    },
    "landing": {
      "p1": "برای مشاهده نتایج، لطفاً موارد زیر را وارد کنید:",
      "access_code_label": "کد دسترسی",
      "submit_button": "ارسال",
      "recent_results": "نتایج اخیر شما"
    },
    "view_results": {
      "headline": "نتایج آزمایش برای {{name}}",
      "reregister": "دوباره ثبت نام کنید",
      "status": {
        "likely_positive": "نتایج نامشخص",
        "test_not_performed": "بازآزمایی مورد نیاز است",
        "results_ready": "نتایج آماده است",
        "processing": "در حال پردازش",
        "test_status": "وضعیت تست",
        "test_result": "نتیجه آزمایش",
        "administered": "تنظیم شده",
        "results_pending": "نتایج در انتظار",
        "test_results": "نتایج آزمایش"
      },
      "at": "در {{address}}"
    },
    "result_label": "نتیجه",
    "result": {
      "flu_a": {
        "result_label": "A نتیجه آنفولانزا"
      },
      "flu_b": {
        "result_label": "B نتیجه آنفولانزا"
      },
      "covid19": {
        "result_label": "نتیجه کووید -19"
      },
      "covid": {
        "result_label": "نتیجه کووید -19"
      },
      "sars": {
        "result_label": "نتیجه SARS"
      },
      "control": {
        "result_label": "کنترول"
      },
      "rsv": {
        "result_label": "نتیجه RSV"
      },
      "result": {
        "result_label": "نتیجه"
      },
      "hba1c": {
        "normal": "طبیعی",
        "warning": "پیش دیابت",
        "danger": "دیابت"
      },
      "lead_venous": {
        "danger": "غیرطبیعی",
        "normal": "طبیعی"
      },
      "tc": {
        "result_label": "نتیجه TC"
      },
      "hdl": {
        "result_label": "نتیجه HDL"
      },
      "trg": {
        "result_label": "نتیجه TRG"
      },
      "ldl": {
        "result_label": "نتیجه LDL"
      },
      "non_hdl": {
        "result_label": "نتیجه غیر HDL"
      },
      "tc_hdl_ratio": {
        "result_label": "نسبت TC/HDL"
      },
      "glu": {
        "result_label": "نتیجه GLU"
      },
      "alere_cholestech_ldx": {
        "danger": "در خطر",
        "warning": "غیرطبیعی",
        "normal": "طبیعی"
      },
      "lead": {
        "result_label": "رهبری"
      },
      "zinc": {
        "result_label": "زِنک"
      },
      "lead_standard_profile": {
        "danger": "غیرطبیعی",
        "normal": "طبیعی"
      },
      "creatinine": {
        "danger": "غیرطبیعی",
        "normal": "طبیعی"
      },
      "igg": {
        "result_label": "نتیجهIgG"
      },
      "igm": {
        "result_label": "نتیجه IgM"
      },
      "blood_glucose_fasted": {
        "normal": "طبیعی",
        "warning": "بالا",
        "danger": "بالا",
        "hypoglycemia": "قند خون بالا",
        "prediabetes": "پیش دیابت",
        "diabetes": "دیابت"
      },
      "total_cholesterol_fasted": {
        "normal": "طبیعی",
        "elevated": "بالا",
        "high": "بالا",
        "low": "پایین",
        "abnormal": "غیر عادی"
      },
      "total_cholesterol_unfasted": {
        "normal": "طبیعی",
        "elevated": "بالا",
        "high": "بالا"
      },
      "a1c_now": {
        "normal": "طبیعی",
        "warning": "پیش دیابت",
        "danger": "دیابت"
      },
      "blood_glucose": {
        "warning": "کم",
        "normal": "عادی",
        "prediabetes": "پیش دیابت",
        "danger": "دیابت",
        "hypoglycemia": "قند خون پایین",
        "medical_emergency": "وضعیت اضطراری پزشکی",
        "inconclusive_1": "نامشخص 1",
        "inconclusive_2": "نامشخص 2",
        "possible_diabetes": "احتمال دیابت"
      },
      "triglycerides": {
        "result_label": "تری گلیسیرید "
      },
      "blood_glucose_ucsf": {
        "low": "کم",
        "normal": "طبیعی",
        "high": "زیاد"
      },
      "syphilis": {
        "result_label": "نتیجه سیفلیس"
      },
      "hepatitis_c": {
        "result_label": "نتیجه هپاتیت C"
      },
      "hiv": {
        "result_label": "نتیجه اچ‌آی‌وی"
      }
    },
    "documents": "اسناد",
    "self_administered": "تست هایی که توسط خود افراد گرفته شده در {{location}} تسلیم گرفته میشود",
    "patient": "بیمار",
    "medical_history": "تاریخچه پزشکی",
    "overview_title": "برای مشاهده جزئیات بیشتر و هرگونه اقدام اضافی مورد نیاز، یک آزمایش یا سرویس را انتخاب کنید. توجه داشته باشید که برخی از نتایج آزمایش ممکن است هنوز در دسترس نباشند یا تنها پس از صحبت با ارائه دهنده در دسترس خواهند بود.",
    "insurance_information": "اطلاعات بیمه را پر کنید",
    "contact_support": "اگر به کمک نیازدارید، لطفا با قسمت ساپورت تماس بگیرید.",
    "show": "نمایش دهید",
    "hide": "پنهان کنید",
    "lab_report": "گزارش آزمایشگاه",
    "contact_provider": {
      "header": "نتایج مثبتی دارید",
      "description": "آیا می خواهید با یک ارائه دهنده صحبت کنید تا در مورد نتایج و درمان خود صحبت کنید؟",
      "yes_option_text": "بله، من می خواهم با یک ارائه دهنده صحبت کنم",
      "no_option_text": "نه، من نتایج خود را خوانده و درک کرده ام و نمی خواهم با ارائه دهنده صحبت کنم",
      "confirm_phone_number_header": "شماره تلفن خود را تأیید کنید",
      "confirm_phone_number_description": "لطفاً بهترین شماره تلفن را برای انجام مشاوره تان تأیید کنید.",
      "confirm_button_text": "تایید",
      "consultation_confirmed_header": "مشاوره تایید شد",
      "consultation_confirmed_description": "یک ارائه‌دهنده ظرف 2 تا 3 روز کاری با شماره {{phone_number}} با شما تماس خواهد گرفت.",
      "acknowledgement_option_helper_text": "چیزی که اهمیت درمان را توضیح می‌دهد و به کاربر امکان می‌دهد در صورت تغییر نظر خود بداند چگونه یک تماس را برنامه‌ریزی کند.",
      "acknowledgement_confirmed_header": "تصدیق تایید شد",
      "acknowledgement_confirmed_description": "چیزی در مورد اهمیت درمان با منابع مرتبط. یادآوری کنید که مشاوره کاملا رایگان است و ارائه‌دهنده می‌تواند برای رفع عفونت به او کمک کند یا هر چیز دیگری تجویز کند.",
      "acknowledgement_confirmed_change_mind_text": "اگر نظر خود را تغییر دادید، به سادگی روی \"من یک مشاوره می خواهم\" در زیر کلیک کنید.",
      "request_consultation_button_text": "من مشاوره می خواهم"
    },
    "phone": "تلفن",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "آدرس شما با شرایط واجد شرایط بودن سایتهای موجود در این برنامه مطابقت ندارد."
    }
  },
  "member": {
    "medical_history": "تاریخ",
    "view_instructions": "مشاهده تأیید",
    "next_appointment": "قرار بعدی",
    "over_18": "من تأیید می کنم که من 18 سال یا بیشتر دارم",
    "choose_test_configurations_title": "لطفاً سرویس (های) موردنظر خود را برای این قرار ملاقات انتخاب کنید",
    "member_taken_error": "کاربر قبلاً یک حساب کاربری دارد",
    "choose_test_configurations_subtitle": "سرویس (ها) برای همه اعضای خانواده اعمال می شود",
    "service": "سرویس (خدمت)",
    "group_or_location": "گروپ (گروه)/مکان",
    "details": "جزیات",
    "see_all": "همه را ببین",
    "no_history": "در حال حاضر هیچ سابقه ای در دسترس نیست.",
    "medical_history_title_with_name": "{{name}}' سابقه",
    "no_dashboard": "در حال حاضر چیزی در دسترس نیست",
    "product": "تولید",
    "price": "قیمت",
    "quantity": "تعداد",
    "total_services_selected": "مجموع خدمات انتخاب شده",
    "total_price": "قیمت کل"
  },
  "or": "یا",
  "account": {
    "errors": {
      "must_be_13": "برای ثبت نام در حساب باید 13 سال داشته باشید"
    }
  },
  "self_administration": {
    "title": "خود اداره",
    "self_administer_action": "خودگردان",
    "skip_to_link": "به بخش خودگردان بروید",
    "select_person": "شخصی را برای بررسی انتخاب کنید",
    "adult": "بالغ",
    "child": "کودک",
    "checked_out": "بررسی شد",
    "go_back": "به عقب برگردید",
    "switch_to_scanner": "تبدیل کردن به اسکنر",
    "enter_barcode": "وارد کردن بار کد",
    "scan_barcode": "اسکن کردن بار کد",
    "cancel": "لغو کردن",
    "barcode_for": "بار کد برای",
    "enter_barcode_manually": "وارد کردن بار کد بصورت دستی",
    "instructions": "بار کد واقع در تیوب آزمایش تان در ذیل را اسکن کنید یا وارد کنید.",
    "regex_prefix": "بار کد باید متشکل باشد از",
    "all_completed": "تکمیل شد: همه اعضای خانواده این مرحله را تکمیل کرده اند",
    "errors": {
      "no_appointment": "مشکلی در یافتن موقعیت ملاقات وجود دارد",
      "barcode_in_use": "این بار کد از قبل استفاده شده است. اگر از قبل با این جعبه خود را آزمایش نکرده اید، لطفا با کارمند ساحه که جعبه را به شما داد صحبت کنید و یک جعبه جدید درخواست کنید. در غیر آن، بالای دکمه کمک در ذیل کلیک کنید.",
      "test_error_general": "مشکلی در ایجاد آزمایش وجود داشت",
      "different_barcodes": "بار کد هایی را که وارد کرده اید با هم مطابقت ندارند."
    },
    "confirm_barcode_input": "برای تأیید، بار کد را دوباره وارد کنید",
    "click_to_self_test": "برای خود آزمایی اینجا کلیک کنید",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "لیست انتظار",
      "unavailable": "قرار ملاقات در دسترس نیست"
    },
    "labels": {
      "address": "نشانی",
      "next_appointment": "قرار بعدی موجود",
      "services": "خدمات موجود"
    }
  },
  "waiting_room": {
    "header": "شما در اتاق انتظار هستید؛ لطفاً منتظر بمانید تا ما شما را با سیستم زمان بندی متصل کنیم.",
    "p1": "لطفا صبور باشید. ما با هم به این همه گیری پایان می دهیم.",
    "signature": "خالصانه",
    "p2": "ما می دانیم که تجربه COVID-19 می تواند خسته کننده باشد. لطفاً در اینجا منتظر بمانید تا ما با حداکثر سرعت کار کنیم تا شما را برای رزرو نوبت خود وارد سایت کنیم."
  },
  "users": {
    "send_appointment_confirmation_message": "سلام {{full_name}}. این یک یادآوری برای قرار شما در {{name}} است. قرار شما در {{date}} در {{time}} در {{address}} است.",
    "send_registration_link": "سلام، {{full_name}}. این پیوند را دنبال کنید تا برای {{registration_link}} {{name}}  خود ثبت نام کنید"
  },
  "follow_up": {
    "first_dose": "دوز اول",
    "previous_appointment": "انتصاب گذشته",
    "booked_appointment": "قرار ملاقات شما",
    "second_dose": "دوز دوم",
    "choose_second_location": "مکان ملاقات دوم را انتخاب کنید"
  },
  "cancellation_types": {
    "cant_get_to_location": "من درگیری زمان داشتم",
    "timing_conflict": "من با کووید -19 مریض شدم",
    "became_ill_with_covid19": "من این سرویس را در جای دیگری دریافت کردم",
    "received_service_elsewhere": "دیگر",
    "other": "در عوض نام را تایپ کنید",
    "duplicate_appointment": "این یک وقت ملاقات بود"
  },
  "translation": {
    "consent": {
      "type_name_instead": "نام را تایپ کنید",
      "type_name": "به جای آن امضا را تایپ کنید"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "کد دسترسی شما می باشد",
      "please_confirm_your_email": "لطفاً ایمیل خود را با کلیک روی دکمه زیر تأیید کنید",
      "hello": "سلام",
      "click_to_reschedule": "برای نمایش دستورالعمل ها/برنامه ریزی مجدد کلیک کنید",
      "click_to_reschedule_on_demand": "برای نمایش دستورالعمل ها کلیک کنید"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "قرار ملاقات شما در {{time}} در {{location}} لغو شده است. اگر این یک اشتباه است، لطفا به support@primary.health ایمیل بزنید"
    }
  },
  "signature_lines": {
    "name": "نام شرکت کننده",
    "date": "تاریخ",
    "signature": "امضای شرکت کننده",
    "and_or": "و/ یا",
    "guardian_name": "نام والدین/ سرپرست",
    "guardian_signature": "امضای والدین/ سرپرست"
  },
  "employer_testing": {
    "hello_name": "سلام {{name}}",
    "results": {
      "negative": "منفی",
      "positive": "مثبت",
      "did_not_result": "ناشناس",
      "unknown": "ناشناخته",
      "invalid": "بی اعتبار",
      "pending": "در انتظار"
    },
    "code_reader": {
      "scan_your_code": "کد روی کارت تست خود را اسکن کنید",
      "find_in_top_right_hand_corner": "کد اسکن مختص آزمون شماست و در گوشه سمت راست بالای آزمون شما یافت می شود",
      "center_code": "کد را در اینجا قرار دهید",
      "already_used_error": "این بارکد آزمایشی قبلاً استفاده شده است. لطفاً برای پشتیبانی با ارائه دهنده کیت آزمایش خود تماس بگیرید.",
      "click_to_scan": "برای اسکن کد QR کلیک کنید",
      "scan_new_test_card": "برای اسکن کارت تست جدید اینجا را کلیک کنید.",
      "not_working": "کار نمیکند؟",
      "try_again": "جهت تلاش دوباره اینجا کلیک کنید."
    },
    "continue": "ادامه هید",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "لطفاً تاریخ تولد شرکت کننده را برای ادامه تأیید کنید",
      "contact_administrator": "اگر اطلاعات نمایش داده شده نادرست است ، لطفاً برای راهنمایی با سرپرست تماس بگیرید",
      "error": "تاریخ تولد وارد شده نادرست است - لطفاً دوباره امتحان کنید یا برای به روزرسانی آن با سرپرست سایت خود تماس بگیرید."
    },
    "result_entry": {
      "code_is_registered": "کد شما ثبت شده است",
      "take_the_test": "امتحان بده",
      "follow_the_instructions": "دستورالعمل های همراه با آزمایش خود را دنبال کنید و زمانی که سواب بینی را داخل ویال روی کارت آزمایش وارد کردید، تایمر 15 دقیقه ای خود را شروع کنید.",
      "cant_find_instructions": "نمی توانید دستورالعمل ها را پیدا کنید؟",
      "view_instructions": "دستورالعمل های Binaxnow را مشاهده کنید",
      "start_timer": "شروع 15 دقیقه زمان (اختیاری)",
      "submit_within_15": "نکته: نتایج خود را ظرف 15 دقیقه ارسال کنید",
      "enter_test_results": "نتایج آزمایش را وارد کنید",
      "choose_a_result": "گزینه ای را انتخاب کنید که نتیجه کارت تست شما را توصیف می کند",
      "submit": "ارسال",
      "must_log_result_and_photo": "برای ادامه باید یک نتیجه ثبت کنید و از آزمایش خود عکس بگیرید",
      "submit_within_15_v2": "نکته: نتایج خود را پس از 15 دقیقه اجرا شدن آزمون ارسال کنید",
      "click_to_take_photo": "برای گرفتن عکس کلیک کنید",
      "results_may_be_invalid": "نتایج آزمایش ممکن است نامعتبر باشد"
    },
    "summary": {
      "title": "خلاصه نتایج",
      "negative_subtitle": "شما COVID-19 ندارید",
      "negative_message": "ما یک اعلان به کارفرمای شما ارسال کرده ایم مبنی بر اینکه اجازه ندارید به محل کار خود برگردید.",
      "positive_subtitle": "متأسفیم، اما شما COVID-19 دارید",
      "positive_message": "ما به کارفرمای شما اعلانی مبنی بر ابتلا به COVID-19 ارسال کرده ایم.",
      "what_should_you_do": "چه کاری باید انجام دهید:",
      "employer_name_resources": "منابع نام کارفرما:",
      "follow_cdc_guidelines": "دستورالعمل های توصیه شده CDC را دنبال کنید"
    },
    "clarifying_results": {
      "title": "شفاف سازی نتایج شما",
      "which_did_your_result_most_look_like": "نتیجه شما بیشتر شبیه کدام بود؟",
      "no_lines": "خطی مشاهده نشد",
      "blue_control_line_only": "فقط خط کنترل آبی",
      "pink_sample_line_only": "فقط خط نمونه صورتی",
      "blue_control_line_and_pink_sample_line": "خط کنترل آبی و خط نمونه صورتی/بنفش",
      "still_not_sure": "هنوز مطمئن نیست",
      "submit_results": "ارسال نتایج",
      "thank_you": "متشکرم!",
      "clarification_received_message": "توضیحات نتیجه شما دریافت شده است.",
      "only_pink_line": "صرف خط صورتی",
      "no_lines_v2": "بدون خطوط"
    },
    "return_to_confirmation": "بازگشت به صفحه تأیید"
  },
  "preferred_language": {
    "title": "زبان ترجیحی",
    "subtitle": "کدام زبان را ترجیح می دهید؟"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "سری واکسن های اولیه",
    "one_dose": "برای یک دوز خاص یک قرار ملاقات رزرو کنید. به خصوص، این مال من است",
    "title": "انتخاب دوز",
    "first_dose": "دوز اول",
    "second_dose": "دوز دوم",
    "single_dose_title": "دوز تک",
    "additional": "اضافی",
    "booster": "تقویت کننده",
    "supplemental": "الحاقی",
    "third_dose": "فقط دوز سوم",
    "no_vaccine": "هیچ کدام از موارد بالا"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "باید دارای فرمت {{datetime_format}} باشد"
            },
            "results_at": {
              "invalid_datetime": "باید دارای فرمت {{datetime_format}} باشد"
            },
            "uid": {
              "unknown_test": "آزمایش یافت نشد"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "کاربر وجود دارد و اجازه به روز رسانی آن را ندارد"
            },
            "date_of_birth": {
              "invalid_date": "باید دارای فرمت {{date_format}} باشد"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "جنسیت وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{genders}}"
            },
            "phone_number": {
              "invalid": "یک عدد نامعتبر است"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "اگر جنسیت نباشد نمی تواند آن را پر کند \"ترجیح دهید خود را توصیف کنید\" \"\""
            },
            "sex": {
              "unknown_sex": "جنسیت وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "جهت_جنسی وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "وضعیت معتبر نیست، از مقادیر قابل قبول زیر انتخاب کنید: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{races}}",
                "other": "{{unknown_races}} وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "امتیازات کافی برای تعیین نقش"
            },
            "user_id": {
              "unknown_user": "کاربر پیدا نشد"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON نامعتبر است"
            },
            "user_id": {
              "unknown_user": "کاربر پیدا نشد"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "محل قرار ملاقات پیدا نشد"
            },
            "date_of_birth": {
              "invalid_date": "باید دارای فرمت {{date_format}} باشد"
            },
            "email": {
              "duplicated": "قبلاً استفاده می شود"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "جنسیت وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{genders}}"
            },
            "phone_number": {
              "invalid": "یک عدد نامعتبر است"
            },
            "population": {
              "unknown_population": "{{unknown_population}} وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "اگر جنسیت نباشد نمی تواند آن را پر کند \"ترجیح دهید خود را توصیف کنید\" \"\""
            },
            "sex": {
              "unknown_sex": "جنسیت وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{sexes}} "
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "جهت_جنسی وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{sexual_orientations}} "
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}}  وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{races}} ",
                "other": "{{unknown_races}} وجود ندارد، از مقادیر قابل قبول زیر انتخاب کنید: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "و آدرس در حال حاضر در مکان دیگری استفاده می شود"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "اسپانیایی یا لاتینی/ی",
    "not_hispanic": "نه اسپانیایی یا لاتینی/ی",
    "hispanic_expanded": {
      "other": "منشاء دیگر اسپانیایی، لاتین/اسپانیایی",
      "mexican": "مکزیکی، آمریکایی مکزیکی، Chicano/a",
      "puerto_rican": "پورتوریکویی",
      "cuban": "کوبا"
    },
    "subtitle": "مقررات ایجاب می کند که ما اطلاعات زیر را جمع آوری کنیم",
    "help": "قومیت به مجموعه ای از ویژگی های مشترک مانند زبان، آداب و رسوم فرهنگی، دین و سایر ویژگی هایی که برای تمایز گروه های مردم استفاده می شود اشاره دارد. ممکن است با هویت نژادی هم خوانی داشته باشد یا نداشته باشد.",
    "unknown": "ناشناس",
    "title": "قومیت"
  },
  "sex_at_birth": {
    "question": "جنسیت",
    "female": "مؤنث",
    "male": "مذکر",
    "help": "رابطه جنسی به چیزی است که در شناسنامه شما وجود دارد.",
    "non_binary": "غیر دوتایی",
    "subtitle": "مقررات ایجاب می کند که ما تمام اطلاعات زیر را جمع آوری کنیم.",
    "decline": "از پاسخ دادن سر باز زدن",
    "unknown": "ناشناس"
  },
  "gender": {
    "help": "هویت جنسیتی به نحوه شناسایی شخصی شما اشاره دارد. ممکن است با جنسیتی که در بدو تولد تعیین کرده اید مطابقت داشته باشد یا نداشته باشد.",
    "subtitle": "اگر می خواهید اطلاعات مربوط به هویت جنسیتی خود را اضافه کنید، لطفاً این کار را انجام دهید."
  },
  "sexual_orientation": {
    "title": "گرایش جنسی",
    "subtitle": "اگر می خواهید اطلاعاتی در مورد گرایش جنسی خود اضافه کنید، لطفاً این کار را انجام دهید.",
    "gay": "همجنسگرا، لزبین، یا همجنسگرا",
    "heterosexual": "دگرجنسگرا یا مستقیم",
    "bisexual": "دوجنسه",
    "questioning": "سوال کردن/ مطمئن نیستم/ نمی دانم",
    "prefer_not_to_disclose": "انتخاب کنید که افشا نشود",
    "unknown": "ناشناس",
    "orientation_not_listed": "گرایش ذکر نشده است",
    "not_applicable": "غیرقابل اجرا",
    "pansexual": "پان‌سکشوال",
    "queer": "کوییر"
  },
  "pronouns": {
    "title": "ضمایر ترجیحی",
    "he": "او/ او",
    "she": "او/ او",
    "they": "آنها/ آنها",
    "choose_not_to_disclose": "انتخاب کنید که افشا نشود",
    "prefer_to_self_describe": "ترجیح می دهید خود را توصیف کنید"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "گواهینامه رانندگی یا شماره شناسنامه دولت",
    "driver_license": "گواهینامه رانندگی",
    "use_ssn_instead": "در عوض از SSN استفاده کنید",
    "social_security_number": "شماره تأمین اجتماعی",
    "state": "دولت",
    "upload_front_of_driver_license": "جواز راننده گی یا شناسنامه ایالتی خود را آپلود کنید",
    "choose_file": "انتخاب فایل",
    "no_file_chosen": "هیچ فایلی انتخاب نشده",
    "no_identification": "من شناسنامه ندارم",
    "insurance_card_has_a_back": "کارت بیمه من پشت سر دارد",
    "upload_front_of_insurance_card": "جواز کارت بیمه خود را آپلود کنید",
    "front_of_insurance_card_uploaded": "جواز کارت بیمه شما آپلود شده است",
    "insurance_card": "کارت بیمه",
    "insurance_card_back": "برگشت کارت بیمه",
    "back_of_insurance_card_uploaded": "پشت سر کارت بیمه شما آپلود شده است"
  },
  "quidel": {
    "certify_text": "من تأیید می کنم که فقط یکبار نتایج این آزمون را ثبت می کنم.",
    "entering_results": "وارد کردن نتایج شما",
    "review_instructions_1": "دستورالعمل ها را با دقت مرور کنید",
    "review_instructions_2": "می توانید دستورالعمل های همراه با آزمون خود را مرور کنید، یک راهنمای گام به گام بخوانید یا یک فیلم آموزشی زیر را تماشا کنید:",
    "read_instructions": "دستورالعملها را بخوان",
    "watch_video": "فیلم را ببینید",
    "timer": "بعد از هم زدن سواب در لوله، تایمر را شروع کنید. تایمر بعد از 1 دقیقه متوقف می شود (برای برداشتن سواب از لوله). لطفاً توجه داشته باشید: باید تایمر را از سر بگیرید تا 10 دقیقه شروع شود تا نتیجه آزمون شما به نتیجه برسد.",
    "start_timer": "شروع زمان سنج (اختیاری)",
    "restart_timer": "توقف و راه اندازی مجدد",
    "resume": "از سرگیری",
    "one_minute_countdown": "1 دقیقه گذشت! سواب خود را از لوله خارج کرده و تایمر را از سر بگیرید.",
    "take_photo": "از نوار تست خود عکس بگیرید",
    "photo_warning": "ارائه عکس برای ادامه کار ضروری است.",
    "enter_results": "نتایج آزمایش را وارد کنید",
    "choose_result": "گزینه ای را انتخاب کنید که نوار تست شما را به بهترین شکل منعکس می کند. کمک خواستن؟ برای مشاهده نتایج نمونه اینجا را کلیک کنید.",
    "positive": "مثبت",
    "negative": "منفی",
    "unknown": "ناشناس",
    "clarify": "شفاف سازی نتایج شما",
    "strip_question": "نوار تست شما بیشتر شبیه کدام بود؟",
    "no_lines": "بدون خط",
    "pink_line": "فقط خط صورتی",
    "not_sure": "هنوز مطمئن نیستم"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "نتیجه آزمایش اولیه",
    "retake_photo": "عکس گرفتن مجدد",
    "capture_photo": "عکس گرفتن",
    "confirm_information_correct": "من تأیید می کنم که اطلاعات بالا درست است.",
    "submit": "ارسال",
    "title": "نتایج را برای {{name}} وارد کنید",
    "subtitle": "شما نیازی به دانلود یا استفاده از برنامه ای هیلت کوفید 19  ندارید.",
    "instruction_title": "دستورالعمل های ی هیلت را ببینید",
    "instruction_guide": "دستورالعملها را بخوانید",
    "video_guide": "فیلم را ببینید",
    "add_photo": " تصویر اضافه کنید",
    "confirm_result": "نتیجه تایید نماید",
    "result_warning": "برای ارسال نتیجه انتخاب کنید",
    "confirm_warning": "تایید نماید  برای ارسال نتیجه",
    "administered_at_label": "چه زمانی این آزمون را دادید؟",
    "instruction_subtitle": "دستورالعمل‌هایی را که همراه با آزمون شما ارائه شده است، ببینید یا دستورالعمل‌های اینجا را مرور کنید."
  },
  "public_test_groups": {
    "title": "کیت های آزمایش کووید-۱۹",
    "appointment_recoveries_title": "برمی گردید؟",
    "appointment_recoveries_button_text": "لینک من را پیدا کنید",
    "search_test_group_title": "اولین بار است؟",
    "search_test_group_button_text": "اکنون ثبت نام کنید",
    "title_2": "ثبت نام و گزارش دهی ساده",
    "step_one": "ثبت حساب",
    "step_two": "نمونه بزاق را برای فرستادن جمع آوری کنید",
    "step_three": "نتایج را به صورت آنلاین مشاهده کنید",
    "title_3": "برای فعال کردن کیت خود را ثبت نام کنید",
    "population_title": "ثبت نام",
    "population_button": "فعال کردن",
    "faq_subtitle": "بیشتر بیاموزید",
    "faq_title": "سوالات متداول",
    "faq_1": "چگونه کیت آزمایش خود را بازگردانم؟",
    "faq_1_footer": "دستورالعمل جمع آوری مستفیم بزاق توسط قیف (Salvia Direct Funnel Collection) را مشاهده نماید",
    "faq_2": "من قبلا ثبت نام کرده ام اما اکانت خود را فراموش کرده ام.",
    "faq_3": "چگونه بسته خود را فعال کنم؟",
    "faq_2_subtitle": "به عنوان یک کاربر بازگشت کننده، می توانید گزارش خود را با استفاده از دکمه **پیدا \"کردن لینک ** در بالا تایید کنید.",
    "faq_3_subtitle": "لطفاً با استفاده از گزینه‌های زیر فرآیند حساب بهداشت اولیه را طی کنید:",
    "footer_text_1": "این محصول توسط سازمان غذا و دارو(َِّFDA) تایید نشده است، اما برای استفاده اضطراری توسط سازمان غذا و دارو (َِّFDA) تحت مجوز استفاده اضطرای (EUA)مجاز شده است.",
    "footer_text_2": "این محصول فقط برای جمع آوری و نگهداری نمونه های بزاق به عنوان کمکی برای تشخیص اسید نوکلئیک از سندرم حاد تنفسی۲ (SARS-CoV-2) مجاز است، نه برای هیچ ویروس یا پاتوژن (pathogens)دیگری.",
    "footer_text_3": "استفاده اضطراری از این محصول فقط برای مدت زمانی مجاز است که شرایط اعلام شده اضطراری آنرا مطابق بخش (b)564(1) قانون فدرال غذا، دارو و آرایشی بهداشتی، 360bbb-3(b)(1) § 21 USC، توجیه می کند. ، مگر اینکه اعلامیه فسخ شود یا مجوز زودتر لغو شود.",
    "description": "برای راحتی شما می توانید کیت تست خود را با استفاده از دکمه های زیر فعال کنید:",
    "subtitle": "بار اول تان در Primary است؟",
    "subtitle_1": "درباره کیت های آزمایش کووید-۱۹ شما",
    "faq_1_1": "لوله بسته شده را که حاوی نمونه است در کسیه نمونه قرار دهید",
    "faq_1_2": "کیسه حاوی نمونه را در جعبه ایی که برچسب SalivaDirect دارد قرار دهید. جعبه با برچسب SalivaDirect را در پاکت FedEx UN 3373 قرار دهید.",
    "faq_1_3": "قسمت چسبنده پاکت FedEx UN 3373 را بردارید و محکم فشار دهید تا بسته شود.",
    "faq_1_4": "لطفاً نمونه خود را در همان روزی که جمع آوری کرده اید و قبل از پایان زمان تحویل گیری، تحویل بدهید. نمونه خود را در آخر هفته ها در جای دریافت نمونه، تحویل ندهید.",
    "faq_1_5": "از وب‌سایت FedEx در آدرس **[fedex.com/labreturns](https://www.fedex.com/labreturns)** دیدن کنید تا مکان‌ها و برنامه های زمانی تحویل گیری را مشاهده کنید.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "نتیجه را گزارش کنید",
    "report_result_subtitle": "اگرسازمان شما خود- آزمایی، ارایه میدهد و شما آماده گزارش نتیجه هستید، لطفا روی دکمه زیر کلیک کنید.",
    "need_help": "کمک خواستن؟",
    "assistance": "اگر به کمک نیاز دارید، یا برای تغیر اطلاعات تماس خود، لطفا با مرکز پشتیبانی تماس بگیرید",
    "contact_support": "با پشتیبانی تماس بگیرید",
    "save_link": "این  لینک را ذخیره کنید تا بعدا نتایج را گزارش کنید",
    "send_via_email": "ارسال لینک از طریق ایمیل",
    "send_via_text": "ارسال لینک از طریق پیامک (اس ام اس)",
    "copy_link": "لینک را کاپی کنید",
    "resend_email": "ارسال مجدد تایید ایمیل",
    "phone_verified": "تلیفون تایید شد",
    "add_vaccination_status": "افزودن/به روزرسانی وضعیت واکسیناسیون کووید -19",
    "terms_of_service": "شرایط استفاده از خدمات",
    "verify_contact_information": "اطلاعات تماس خود را تایید کنید تا بتوانید نتایج و ارتباطات را دریافت کنید. اگرنیاز به تغیر اطلاعات تماس خود دارید, لطفا با مرکز پشتیبانی ما تماس بگیرید",
    "resend_text": "ارسال مجدد پیامک تایید صحت",
    "loading": "بارگذاری",
    "add_to_account": "به حساب اضافه کنید",
    "hi": "سلام",
    "email_verified": "ایمیل تایید شده است",
    "go_to_my_account": "برو به حساب من",
    "activate_kit": "یک کیت تست را فعال کنید",
    "report_custom_result": "نتیجه را گزارش کنید: {{test}}",
    "activate_custom_test": "یک آزمایش را فعال کنید: {{test}}",
    "update_vaccination_status": "ابدیت وضعیت واکسیناسیون",
    "order_a_test_kit": "یک بسته تست را سفارش دهید"
  },
  "enter_information": {
    "contact_info": "اطلاعات تماس خود را وارد کنید",
    "label": "تلیفون یا ایمیل",
    "invalid": "این یک ایمیل یا شماره تلیفون معتبرنیست",
    "send": "ما یک کد به تلیفون یا ایمیل شما ارسال خواهیم کرد",
    "legal": "با وارد کردن شماره تلیفون یا ایمیل خود، با **[شرایط خدمات](https://primary.health/terms-of-service) ** و **[خط مشی رازداری](https://primary.health/privacy-policy)** موافقت میکنید.",
    "subtitle": "ما یک پیامک یا ایمیل برای شما ارسال خواهیم کرد تا به شما در یافتن سوابق خود یا پیوندی برای گزارش نتیجه آزمایش کمک کنیم!",
    "find_link": "آیا ثبت نام کرده اید؟ در لینک زیر چک کنید",
    "not_registered": "آیا ثبت نشده اید؟ سایت خود را جستجو کنید",
    "search_by_keyword": "جستجو بر اساس نام، واژه کلیدی، شهر، کد پستی، یا کد سازمان!",
    "search": "جستجو کردن",
    "register_here": "در اینجا ثبت نام کنید"
  },
  "contact_support": {
    "invalid": "معلومات نا معتبر",
    "invalid_explanation": "شما اخیرا یک پیوند ورود به سیستم Primary.Health درخواست کرده اید. با این حال,",
    "never_registered": "قبلا ثبت نام نکرده اید؟ لطفاً برای پیوند ثبت نام با ارائه دهنده آزمایش خود تماس بگیرید یا سایت خود را در زیر بیابید.",
    "get_help": "میتوانید برای تطبیق سابقه خود با معلومات تماس خود کمک دریافت کنید",
    "get_help_calling": "یا با تماس",
    "invalid_explanation_bold": ".معلومات که ارایه کردید نا معتبربود",
    "get_help_label": "کمک بگیرید",
    "mobile": {
      "invalid_explanation": "هیچ استفاده کننده ایی پیدا نشد تا در حساب شما اضافه شود",
      "get_help": "اگر سوالهای بیشتری دارید، لطفا به تماس شوید",
      "reason": "تمامی استفاده کننده گان اولیه از خدمات صحی واجد شرایط برای بهره مند شدن از ویژگی های جدید حساب ما نیستند. زمانی که شما واجد شدید، شما یک دعوت نامه از خدمات صحی اولیه یا مسئول مدیریت گروپتان دریافت خواهید کرد."
    },
    "zen_name": "نام تماس ",
    "ticket_type": "این در مورد چیست",
    "appointment_access_code": "کد تایید (در صورت شناخته شدن)",
    "zen_desc": "پیام",
    "contact_us": "با ما تماس بگیرید",
    "need_assistance": "اگر به کمک نیاز دارید یا برای تغییر اطلاعات تماس خود، لطفاً با پشتیبانی تماس بگیرید."
  },
  "show_appointments": {
    "welcome": "پروفایلتان را پیدا کنید",
    "welcome_subtitle_1": "نمایش نتایج زیر برای",
    "added_to_account": "به حساب شما اضافه شد",
    "error": "این شرکت کننده ممکن است از قبل یک حساب داشته باشد",
    "welcome_subtitle_2": "نام را در لیست زیر نمی‌بینید؟",
    "welcome_not_logged_in": "پیداکردن لینک وبسایت ",
    "welcome_logged_in": "گروهتان را انتخاب کنید",
    "welcome_subtitle_1_logged_in": "برای تکمیل افزودن یک عضو، کاربر و گروه را انتخاب کنید. به هرکدام از اعضا یک حساب کاربری اختصاص داده شده می شود.",
    "welcome_subtitle_2_logged_in": "اگر نام عضو خود را در لیست بالا نمی‌بینید، می‌توانید",
    "welcome_subtitle_1_not_logged_in": "انتخاب کنید چه کسی نیاز به پیدا کردن سایت خود دارد یا یک عضو جدید اضافه کنید. عضو پیدا نشد؟",
    "welcome_subtitle_3_logged_in": "روش تماس دیگری را امتحان کنید",
    "welcome_subtitle_4_logged_in": "یا با ما با این شماره ها تماس بگیرید ",
    "welcome_subtitle_2_not_logged_in": "جستجوی خود را به‌روز کنید.",
    "welcome_subtitle_3": "جستجوی خود را به‌روز کنید."
  },
  "enter_code": {
    "code": "کد چیست؟",
    "enter_code": "کد ارسال شده را وارد کنید",
    "incorrect_code": ".شما یک کد اشتباه وارد کرده اید",
    "verify": "تایید حساب",
    "verified": "تایید شده است",
    "verify_account_information": "حساب کاربری خود را تأیید کنید"
  },
  "general_self_checkout": {
    "choose_test": "شما کدام امتحان (آزمایش) را گرفتید؟",
    "photo_required": "عکس نتیجه مورد نیاز است",
    "find_your_test": "تست (امتحان) خود را پیدا کنید"
  },
  "login": {
    "create_account": "یک حساب کاربردی بسازید",
    "create_account_help": "ایجاد حساب مشابه با ثبت نام برای آزمایش یا واکسیناسیون نیست. وقتیکه یکبار ثبت نام نموده باشید.",
    "create_account_help_schedule": "وقت ملاقات ها را برنامه ریزی کنید",
    "create_account_help_record": "امتحان خانگی را- ثبت کنید",
    "create_account_help_match": ".سوابق واکسیناسیون و آزمایش را مدیریت کنید",
    "continue": "به عنوان مهمان ادامه دهید",
    "already": "از قبل حساب کاربردی دارید؟ کلیک",
    "login": ".برای داخل شدن به سیستم",
    "or_sign_in": ":یا با",
    "no_account": "حساب کاربردی ندارید؟ کلیک",
    "signup": "برای ثبت نام",
    "here": "اینجا",
    "email_address": ":آدرس ایمیل",
    "password": ":کلمه عبور",
    "complete_registration": "ثبت نام کامل",
    "last_covid_test": "آخرین نتیجه آزمایش کووید - 19",
    "no_record": "بدون سابقه",
    "viewing_information": "مشاهده {{first_name}}' معلومات",
    "download": "دانلود (گرفتن) پایین کردن",
    "not_registered": "معلوم میشود که درهیچ گروهی ثبت نام نکرده اید",
    "please_follow_link": "لطفا برای پیوند دعوت را از ایمیل خود برای برنامه ریزی و دسترسی به معلومات خود با Primary.Health دنبال کنید",
    "log_results": "نتایج آزمون من را ثبت کنید",
    "book_appointment": "ملاقات کنید",
    "no_new_appointments": "درحال حاضرملاقات های جدید را نمی پذیرد",
    "upload_vaccinations": "بارگذاری واکسین",
    "vaccination": "واکسیناسیون",
    "new_appointment": "ملاقات جدید",
    "log_otc_results": "OTC ثبت نتایج برای امتحان",
    "no_household_members": ".شما هنوز هیچ خانواده ندارید",
    "no_upcoming": "بدون ملاقات آینده",
    "update_vaccination": "واکسیناسیون را به روز کنید",
    "upload_vaccination": "بارگذاری واکسین",
    "title": "خوش آمدیدPrimary.Health به",
    "sub_title": "Before registering please create an account.",
    "p1": ":ساختن یک حساب کاربردی به شما این امکان را میدهد",
    "p2": "ملاقات آینده را برنامه ریزی کنید",
    "p3": "امتحان (تست) های کووید -19 را در خانه ضبط کنید",
    "p4": "سوابق واکسیناسیون و آزمایش افراد وابسته را مدیریت کنید",
    "p5": "من my.primary.healthداخل شدن ساده از",
    "no_vaccination_record": "هنوز واکسنی اضافه نشده است",
    "vaccination_title_card": "وضعیت واکسیناسیون کووید-19",
    "account_and_settings": "حساب و تنظیمات",
    "delete_account": "حذف حساب کاربری",
    "language": "زبان",
    "language_current": "زبان فعلی: {{language}}",
    "log_out": "خروج",
    "delete_account_title": "آیا مطمئن هستید که می خواهید اکانت خود را حذف کنید؟",
    "new_encounter": "دیدار جدید {{first_name}}",
    "new_encounter_subtitle": "این دیدار جدید برای کدام گروه است؟",
    "no_programs": "به نظر می رسد در هیچ برنامه ای ثبت نام نکرده اید",
    "edit_members": "ویرایش اعضا",
    "edit_members_subtitle": "انتخاب کنید به کدام داشبورد می خواهید بروید یا عضو دیگری را به حساب خود اضافه کنید",
    "add_members": "اضافه کردن عضو",
    "delete_member": "حساب کاربری {{full_name}} حذف شود؟",
    "delete_member_subtitle": "حذف کاربر تمام اطلاعات مرتبط با حساب را حذف می کند.",
    "select_member": "انتخاب اعضا",
    "edit_appointment": "ویرایش قرار ملاقات",
    "route": {
      "me": "داشبورد",
      "history": "تاریخ",
      "programs": "یک تست اضافه کنید",
      "contact_us": "کمک",
      "choose_member": "عضو",
      "settings": "تنظیمات",
      "help": "کمک"
    },
    "user_dashboard": "داشبورد {{first_name}}",
    "view_full_results": "مشاهده در پورتال نتایج",
    "required": "در جریان استفاده از برنامه اصلی ، ضروری میباشد.",
    "no_email": "من آدرس ایمیل ندارم"
  },
  "vaccination_status": {
    "not_vaccinated": "واکسین نشده",
    "fully_vaccinated": "کامل واکسین شده",
    "partially_vaccinated": "تاحدی واکسین شده است",
    "vaccination_records": "سوابق واکسیناسیون",
    "title": "وضعیت واکسین کووید-۱۹ شما چگونه است؟",
    "definition_title": "تعریف های وضعیت واکسیناسیون",
    "definition_cdc": "تعریف وضعیت واکسیناسیون CDC",
    "definition_osha": "تعریف وضعیت واکسیناسیون OSHA",
    "definition": {
      "fully_vaccinated": "۲ هفته پس از دوز نهایی واکسین ۲ دوزه فایزر-بایونتیک  یا مادیرنا  یا ۱ دوز واکسن جانسون و جانسون.",
      "partially_vaccinated": "فقط ۱ دوز از واکسین ۲ دوزه در مرحله ابتدایی دریافت نموده، یا کمتر از ۲ هفته بعد از تطبیق دوز نهایی در دوره ۲ دوزه یا ۱ دوز جانسون و جانسون دریافت نموده.",
      "not_vaccinated": "هیچ واکسن کووید-۱۹ تجویز نشده است.",
      "fully_vaccinated_with_one_booster": "دوز تقویت کننده واکسن های فایزر-بایونتیک  یا مادیرنا  بعد از دوره کامل یا ۱ دوز واکسن جانسون و جانسون دریافت نموده.",
      "fully_vaccinated_with_two_boosters": "دومین دوز تقویت‌کننده واکسن‌های فایزر-بایونتیک یا  مادیرنا را بعد از دوره کامل یا دوز واکسن جانسون و جانسون و دوز تقویت‌کننده دریافت نموده."
    },
    "label": {
      "fully_vaccinated": "کاملا واکسین شده",
      "partially_vaccinated": "تا حدی واکسین شده",
      "not_vaccinated": "واکسین نشده",
      "fully_vaccinated_with_one_booster": "کاملا واکسین شده + بوسترواکسین تقویت کننده",
      "fully_vaccinated_with_two_boosters": "کاملا واکسین شده + ۲ بوستر واکسین تقویت کننده",
      "prefer_not_to_answer": "ترجیح می دهم پاسخ ندهم"
    },
    "progress_bar_title": "تاریخچه واکسین کووید-۱۹",
    "upload_record_card": "کارت واکسیناسیون کووید-۱۹ خود را آپلود کنید",
    "show_example": "نشان دادن مثال",
    "hide_example": "مخفی کردن مثال",
    "take_photo": "برای گرفتن عکس کلیک کنید",
    "or": "یا",
    "upload_image_or_pdf_instead": "عکس را آپلود کنید به عوض پی دی اف /PDF",
    "select_doses_received": "تمام دوزهای واکسین کووید-۱۹ را که دریافت کرده اید انتخاب کنید.",
    "first_dose": "دوز اول",
    "second_dose": "دوز دوهم",
    "first_booster_dose": "دوز اول تقویتی",
    "second_booster_dose": "دوز دوهم تقویتی",
    "additional_dose": "دوز اضافی",
    "first_dose_manufacturer": "تولید کننده اولین دوز کووید-۱۹ شما چه کسی بود؟",
    "first_dose_date": "تاریخ تطبیق اولین دوز شما چه زمانی بود؟",
    "second_dose_manufacturer": "تولید کننده دومین دوز کووید-۱۹ شما چه کسی بود؟",
    "second_dose_date": "تاریخ تطبیق دومین دوز شما چه زمانی بود؟",
    "first_booster_dose_manufacturer": "تولید کننده اولین دوز تقویتی کووید-۱۹ شما چه کسی بود؟",
    "first_booster_dose_date": "تاریخ تطبیق اولین دوز تقویتی شما چه زمانی بود؟",
    "second_booster_dose_manufacturer": "تولید کننده دومین دوز تقویتی کووید-۱۹ شما چه کسی بود؟",
    "second_booster_dose_date": "تاریخ تطبیق دومین دوز تقویتی شما چه زمانی بود؟",
    "additional_dose_manufacturer": "تولید کننده دوز اضافی کووید-۱۹ شما چه کسی بود؟",
    "additional_dose_date": "تاریخ تطبیق دوز اضافی شما چه زمانی بود؟",
    "completed_one": "تکمیل شد",
    "completed_two": "برای به روز رسانی وضعیت واکسیناسیون از شما متشکریم!",
    "progress_bar_complete": "کامل",
    "upload_image": "آپلود تصویر یا بارگذاری عکس",
    "retake_photo": "عکس گرفتن مجدد",
    "other": "موارد دیګر",
    "remove_first_dose": "تاریخچه اولین دوز را حذف کنید",
    "remove_second_dose": "تاریخچه دومین دوز را حذف کنید",
    "remove_first_booster_dose": "تاریخچه اولین دوز تقویتی را حذف کنید",
    "remove_second_booster_dose": "تاریخچه دومین دوز تقویتی را حذف کنید",
    "remove_additional_dose": "تاریخچه دوز اضافی را حذف کنید",
    "exemption": "آیا شما مستثنی هستید؟",
    "exempt": "من معاف هستم",
    "not_exempt": "من معاف نیستم",
    "enter_exemption": "وارد معافیت",
    "upload_exemption_documentation": "برای معافیت خود اسناد را آپلود کنید",
    "remove_dose": "دوز را حذف کنید",
    "continue": "ادامه",
    "enter_credentials": "لطفا اطلاعات شناسایی خود را وارد کنید",
    "incorrect_credentials": "اطلاعات شناسایی نادرست است، لطفاً دوباره کوشش نمایید.",
    "add_photo": "عکس را اضافه نمایید"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "سلام {{name}} , ما متوجه شدیم که زمان‌بندی قرار {{group_name}} خود را با {{org_name}} تمام نکرده اید. برای حفظ قرار ملاقات خود، باید مرحله پرداخت را تکمیل کنید. لطفاً برای تکمیل فرآیند {{complete_url}} روی این پیوند کلیک کنید. اگر مایلید قرار ملاقات خود را لغو کنید، لطفاً روی این پیوند {{cancel_url}} کلیک کنید.متشکرم"
    }
  },
  "otc": {
    "record_results_for_abbott": "تایج خود را برای آزمون BinaxNOW آزمایش و ثبت کنید",
    "record_results": "نتایج خود را آزمایش و ثبت کنید",
    "activate_a_kit": "یک کیت را فعال کنید",
    "activate_a_kit_for_test": "یک کیت برای  {{test}} فعال کنید",
    "todays_test_process": "روند آزمون امروز:",
    "date_of_birth_question": "تاریخ تولد  {{first_name}}' چیست؟",
    "confirm_dob_to_continue": "لطفاً برای ادامه، تاریخ تولد شرکت‌کننده را تأیید کنید:",
    "incorrect_dob": "تاریخ تولد نادرست",
    "file_too_large": "لطفاً مطمئن شوید که اندازه فایل آپلود شده کوچکتر از {{size}}است.",
    "enter_valid_date_in_last_x_days": "تاریخ معتبری را در {{n}} روز گذشته وارد کنید",
    "barcode_format_not_valid": "فرمت بارکد معتبر نیست",
    "complete_button": "کامل",
    "next_button": "بعد",
    "back_button": "بازگشت",
    "report_a_test": "گزارش یک آزمایش:",
    "x_%_completed": "کامل کنید  {{n}}%",
    "answer_survey": "به سوالات نظرسنجی پاسخ دهید",
    "steps": {
      "answer_survey": "پاسخ نظرسنجی",
      "review_instructions": "دستورالعمل ها را مرور کنید",
      "scan_code": "اسکن کد",
      "enter_time": "زمان را وارد کنید",
      "enter_result": "نتیجه را وارد کنید",
      "take_photo": "عکس گرفتن",
      "confirm_result": "نتیجه را تایید کنید",
      "selest_test_kit": "نوع آزمون را انتخاب کنید",
      "enter_date": "تاریخ را وارد کنید",
      "enter_date_and_time": "تاریخ و زمان را وارد کنید"
    },
    "review_instructions": "دستورالعمل های آزمون را مرور کنید",
    "follow_instructions_or_below": "دستورالعمل‌هایی را که همراه با آزمایش ارائه شده است، دنبال کنید یا دستورالعمل‌های زیر را مرور کنید.",
    "watch_video": "تماشای ویدیو",
    "view_instructions": "مشاهده دستورالعمل ها",
    "follow_instructions": "دستورالعمل هایی را که همراه با تست شما ارائه شده است، دنبال کنید.",
    "scan_code": "کد QR آزمایشی را اسکن کنید",
    "qr_code_description": "کد QR برای تست شما منحصر به فرد است و در گوشه سمت راست بالای تست شما یافت می شود",
    "show_example": "نشان دادن مثال",
    "hide_example": "نمونه را پنهان کنید",
    "barcode_scanned": "بارکد با موفقیت اسکن شد",
    "enter_barcode": "بارکد را وارد کنید",
    "scan_qr_code": "کد QR را اسکن کنید",
    "which_test_did_you_take": "کدوم تست دادی",
    "when_did_you_take_test": "کی این تست رو دادی؟",
    "what_day_did_you_take_test": "چه روزی این تست رو دادی؟",
    "what_time_did_you_take_test": "چه ساعتی این تست رو دادی؟",
    "time": {
      "today": "امروز",
      "yesterday": "دیروز",
      "two_days_ago": "۲ روز قبل"
    },
    "enter_current_time": "زمان فعلی را وارد کنید",
    "enter_result": "نتیجه آزمون را وارد کنید",
    "choose_result_option": "گزینه ای را انتخاب کنید که نتیجه کارت تست شما را توضیح می دهد:",
    "clarify_results": "نتایج خود را روشن کنید",
    "take_result_photo": "از نتایج عکس بگیرید",
    "take_result_photo_description": "از کارت نتایج تکمیل شده عکس بگیرید تا نتایج خود را ثبت کنید.",
    "my_result": "نتیجه من",
    "switch_camera": "دوربین را تغییر دهید",
    "confirm_result": "نتیجه را تایید کنید",
    "name": "نام:",
    "date": "تاریخ:",
    "result": "نتیجه:",
    "results": "نتایج",
    "test_submitted": "متشکرم، {{first_name}}! با موفقیت ارسال شد  {{test}}",
    "dob": "تاریخ تولد:",
    "administered": "اداره می شود:",
    "or": "یا",
    "upload": "بارگذاری",
    "change_uploaded_file": "فایل آپلود شده را تغییر دهید",
    "take_photo": "عکس گرفتن",
    "confirm_information": "تایید اطلاعات",
    "barcode": "بارکد:",
    "scan_test_barcode": "بارکد تست را اسکن کنید",
    "barcode_description": "بارکد برای آزمون شما منحصر به فرد است و می تواند در آزمون شما پیدا شود",
    "enter_date_in_last_30_days": "تاریخ 30 روز گذشته و زمان تقریبی آزمون را وارد کنید",
    "add_image": "تصویر اضافه کن",
    "change_image": "تغییر تصویر",
    "skip_barcode_reader": "نمی توانید کد را اسکن کنید؟ به هر حال برای گزارش نتیجه آزمایش روی بعدی یا نکست کلیک کنید",
    "enter_date_and_time": "تاریخ و زمان آزمون را وارد کنید",
    "enter_date": "تاریخ آزمون را وارد کنید",
    "did_you_take_test_today": "آیا امروز تست را انجام داده اید؟",
    "record_results_for_generic": "نتایج خود را برای آزمایش {{name}} تست و ثبت کنید",
    "choose_result_option_custom": "گزینه‌ای را انتخاب کنید که نتیجه {{test_name}} شما را توصیف می‌کند:"
  },
  "yes": "آره",
  "no": "خیر",
  "event_token": {
    "title": "لطفاً کد زیر را در دستگاه فروش خودکار وارد کنید تا کیت آزمایشی خود را تحویل دهید",
    "loading_text": "در حال بارگیری، لطفاً یک دقیقه دیگر دوباره بررسی کنید"
  },
  "appointment_recovery": {
    "complete_title": "شما آماده اید!",
    "subtitle_1": "اگر از آیفون استفاده می‌کنید، لطفاً روی **انجام شده** در گوشه سمت چپ بالای این صفحه ضربه بزنید تا به داشبورد خود بازگردید.",
    "subtitle_2": "اگر از اندروید استفاده می کنید، روی **X** در گوشه سمت چپ بالای این صفحه ضربه بزنید.",
    "subtitle_0": "لطفاً برای ادامه از مرورگر خارج شوید."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "رابطه توافقی",
  "finish_registration": {
    "title": "ثبت نام خود را تکمیل کنید",
    "subtitle": "لطفا قبل از قرار ملاقات اطلاعات ذیل را تکمیل کنید:",
    "button": "ثبت نام را تمام کنید"
  },
  "arab_ethnicity": {
    "arab": "عرب",
    "non_arab": "غیر-عرب",
    "prefer_not_to_answer": "ترجیح می دهم پاسخ ندهم",
    "question": "قومیت عرب",
    "subtitle": "مقررات لازم میدارد که اطلاعات ذیل را جمع آوری کنیم.",
    "help": "قومیت به مجموعه‌ای از ویژگی‌های مشترک مانند زبان، آداب و رسوم فرهنگی، مذهب و سایر ویژگی‌های مورد استفاده برای متمایز کردن گروه‌های از مردم اشاره دارد.همچنان ممکن یا غیر ممکن با هویت نژادی نیز هماهنگ باشد."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "بسته به دستگاه خود، برای دانلود Primary Health Pass روی پیوند زیر کلیک کنید."
    }
  },
  "stepper": {
    "next": "مابعد",
    "back": "بازگشت",
    "complete": "کامل"
  },
  "registration_direcory": {
    "keyword_search_label": "کلمه کلیدی، شهر، کد پستی، نام سازمان",
    "keyword_search_label_mobile": "شهر، کد پستی، نام سازمان",
    "zero_results": {
      "title": "ما هیچ ارائه‌دهنده‌ خدمات درمانی با این عبارت جستجو پیدا نکردیم.",
      "sub_title": "به دنبال ارائه‌دهنده خدمات درمانی بگردید:",
      "p1": "کدپستی ",
      "p2": "نام شهر ",
      "p3": "نام کانتی",
      "p4": "نام سازمان یا ارایه دهنده خدمات"
    }
  },
  "registration_flyer": {
    "open_camera": "برنامه دوربین را در دستگاه خود باز کنید",
    "point_to_qr_code": "به کد QR اشاره کنید و روی پیوند ضربه بزنید",
    "complete_registration": "ثبت نام را تکمیل کنید",
    "need_help": "به کمک نیاز دارید؟ با ما تماس بگیرید در"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "گواهی اطلاعات واکسن",
    "select_checkbox": "لطفاً موارد زیر را مرور کنید و قبل از ادامه گزینه موردنظر را انتخاب کنید",
    "opportunity_to_read": "برگه معلوماتی اطلاعات واکسن (\"VIS\") یا مجوز استفاده اضطراری (\"EUA\") ارائه شده برای واکسن(های) که باید تزریق شود، قبلا امکان خواندن را داشته‌ام یا به من این امکان داده شده است، یا برای من خوانده شده است.",
    "vaccine_info_sheet": "برگه اطلاعات واکسن",
    "vaccine_fact_sheet": "برگه اطلاعات واکسن",
    "vaccine_info_statement": "بیانیه اطلاعات واکسن"
  },
  "exemption": {
    "dtap_tdap": "واکسن سه گانه دیفتری کزاز سیاه سرفه",
    "mmr": "سرخک سرخجه اوریون",
    "ipv_opv": "فلج اطفال ",
    "hib": "ایدز",
    "hep_b": "HEPB",
    "var": "آبله مرغان ",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "این کیت شامل آزمایشات کلامیدیا و سوزاک است.",
    "B": "این کیت شامل آزمایشات کلامیدیا و سوزاک است.",
    "C": "این کیت شامل تست های سیفلیس است.",
    "D": "این کیت شامل آزمایشات HIV، سیفلیس، کلامیدیا و سوزاک می باشد.",
    "E": "این کیت شامل آزمایش HIV و سیفلیس می باشد.",
    "box_type": "جعبه {{type}}",
    "administered_text": "زمانی که کیت شما به آزمایشگاه رسید و نتایج شما آماده شد، با شما تماس خواهیم گرفت.",
    "activate_kit": "کیت آزمایش من را فعال کنید",
    "register_another_test": "ثثبت یک آزمایش دیگر",
    "credentials": {
      "title": "شروع کنیم",
      "label": "تماس",
      "p1": "ما با اطلاعاتی در مورد شما شروع می کنیم.",
      "location_step": {
        "p1": "کیت آزمایش شما را چه کسی ارائه کرده است؟",
        "load": "بارگذاری بیشتر"
      }
    },
    "checklist": {
      "not_urinated": "در یک ساعت گذشته ادرار نکرده اید.",
      "bathroom": "برای جمع آوری ادرار به حمام دسترسی دارید.",
      "anal_swab": "شما به یک مکان خصوصی برای انجام نمونه‌برداری از مقعد دسترسی دارید.",
      "blood_extraction": "شما به یک محیط تمیز برای استخراج خون دسترسی دارید.",
      "washed_hands": "دست های خود را به مدت 30 ثانیه با آب گرم و صابون شسته اید.",
      "title": "آیا برای شروع جمع آوری نمونه آماده هستید؟",
      "no_alcohol": "در روز جمع آوری الکل مصرف نکنید.",
      "menstruation": "در طول دوره‌ی حیض یا در ۲۴ ساعت پس از رابطه جنسی، نمونه‌برداری از واژن انجام ندهید."
    },
    "confirm": {
      "title": "اطلاعات خود را تایید کنید",
      "p1": "آیا این اطلاعات صحیح است؟"
    },
    "display_name": {
      "A": "کلامیدیا و سوزاک (محدود به یک محل)",
      "B": "کلامیدیا و سوزاک (سه محل)",
      "C": "سیفلیس",
      "shortened": {
        "A": "کلامیدیا و سوزاک",
        "B": "کلامیدیا و سوزاک",
        "C": "سیفلیس"
      },
      "CC": "COVID-19",
      "CE": "سکرینینگ بیماری سلیاک",
      "CR": "تست کراتینین",
      "D": "تست 4 پانل - HIV، سیفلیس، کلامیدیا و سوزاک (محدود به یک محل)",
      "DD": "اسکرینینگ دیابت (HbA1c)",
      "E": "HIV و سیفلیس",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "هرپس سیمپلکس",
      "I": "HIV",
      "TT": "سطح تستوسترون",
      "VD": "ویتامین دی",
      "VS": "نظارت ویروسی",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV"
    },
    "rescan": "اسکن مجدد",
    "scanned": "شما جعبه {{type}} را اسکن کرده اید",
    "not_yet": "نه هنوز",
    "success_registration": "موفقیت موفق!",
    "no_box": "جعبه ندارید؟",
    "faq_link": "به سوالات متداول مراجعه کنید",
    "welcome": "خوش آمدید!",
    "scan": {
      "title": "شما یک قدم به سلامتی خود نزدیکتر هستید.",
      "p1": "**بارکد کیت** را از کنار جعبه خود اسکن یا وارد کنید."
    },
    "appointments": {
      "title": "بیمار و ارائه دهنده را انتخاب کنید",
      "p1": "ما بیش از یک پروفایل مرتبط با اطلاعات تماس شما پیدا کردیم. پروفایل صحیح را انتخاب کنید:",
      "new": "بیمار یا ارائه دهنده جدید"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "ما پرونده بیمار شما را پیدا کردیم."
    },
    "section_1": "محتویات کیت را تأیید کنید",
    "section_2": "جمع‌آوری نمونه آزمون",
    "section_3": "بسته بندی",
    "section_4": "ارسال",
    "box_a": "جعبه A",
    "box_b": "جعبه B",
    "box_c": "جعبه C",
    "kit_flow": {
      "button_1": "به بسته بندی ادامه دهید",
      "button_2": "من آماده ام",
      "button_3": "کیت من کامل و بسته بندی شده است",
      "button_4": "من تمام شده ام",
      "button_5": "تست بعدی",
      "button_6": "دستورالعمل‌ها را نادیده بگیرید"
    },
    "contents": {
      "header": "عالی! بیایید مطمئن شویم کیت شما کامل است",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "کارت جمع آوری خون",
      "blood_collection_card_subtitle": "( 1یا 2 مربوط به کیت آزمایش",
      "alchohol_pads": "2عدد پد الکلی",
      "lancets": "لنست های یکبار مصرف",
      "up_to_other": "(تا 4 بسته مربوط به کیت آزمایش",
      "adhesive_bandages": "پانسمان چسبنده",
      "pipette": "پیپت",
      "urine_tube": "لوله جمع آوری ادرار",
      "urine_cup": "لیوان جمع آوری ادرار",
      "oral_swab": "نمونه‌برداری دهانی",
      "oral_tube": "لوله جمع آوری دهان",
      "anal_swab": "نمونه برداری مقعدی",
      "anal_tube": "لوله جمع‌آوری نمونه از مقعد",
      "vaginal_tube": "یک عدد لوله جمع‌آوری نمونه از واژن",
      "vaginal_swab": "1نمونه ‌برداری واژنی",
      "biodegradable_paper": "کاغذ جمع‌آوری قابل تجزیه",
      "bottle_and_probe": "بطری نمونه برداری و پروب",
      "prepaid_envelope": "پاکت ارسال پیش پرداخت",
      "biohazard_subtitle": "(همراه با پد جاذب)",
      "biohazard_bag": "1 کیسه نمونه",
      "biohazard_bag_plural": "({{count}}) کیسه‌های بیوهازارد",
      "alcohol_pads_plural": "{{count}} پد الکلی",
      "sterile_gauze_pad": "1 پد گاز استریل",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "چک لیست بسته بندی",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "شما تمام محتویات کیت آزمایش را بسته بندی کرده اید",
      "step_2": "شما از تمام مواد جمع آوری موجود در کیت برای جمع آوری نمونه های مورد نیاز استفاده کرده اید",
      "step_3": "**تاریخ تولد** شما با فرمت MM/DD/YYYY روی تمام لوله های جمع آوری نمونه نوشته شده است.",
      "step_4": "**تاریخ جمع آوری** روی تمام لوله های جمع آوری نمونه با فرمت MM/DD/YYYY نوشته شده است.",
      "step_5": "لوله جمع‌آوری نمونه در کیسه بیوهازارد خود مهر شده است",
      "step_6": "تمام نمونه ها در جعبه قرار می گیرند",
      "step_7": "جعبه در پاکت حمل و نقل پیش‌پرداخت‌شده قرار می‌گیرد",
      "step_8": "پاکت حمل و نقل برگشتی کاملاً مهر شده است"
    },
    "packaging_box_b": {
      "step_1": "شما تمام محتویات کیت آزمایشی را بسته‌بندی کرده‌اید، حتی اگر از آزمون(ها) گذشته باشید",
      "step_2": "شما از تمام مواد جمع آوری موجود در کیت برای جمع آوری نمونه های مورد نیاز استفاده کرده اید",
      "step_3": "**تاریخ تولد** شما با فرمت MM/DD/YYYY روی تمام لوله های جمع آوری نمونه نوشته شده است.",
      "step_4": "**تاریخ جمع آوری** روی تمام لوله های جمع آوری نمونه با فرمت MM/DD/YYYY نوشته شده است.",
      "step_5": "هر لوله جمع‌آوری نمونه در کیسه بیوهازارد خودش مهر و موم شده است (فقط **یک** نمونه در هر کیسه)",
      "step_6": "تمام نمونه ها در جعبه قرار گرفته است",
      "step_7": "جعبه در پاکت حمل و نقل از پیش پرداخت شده قرار گرفته است",
      "step_8": "پاکت ارسال مجدد کاملاً مهر و موم شده است"
    },
    "packaging_box_c": {
      "step_1": "شما تمام محتویات کیت آزمایش، از جمله لانست های استفاده شده را بسته بندی کرده اید.",
      "step_2": "**تاریخ تولد** شما با فرمت MM/DD/YYYY روی کارت جمع آوری خون نوشته شده است.",
      "step_3": "**تاریخ جمع آوری** روی کارت جمع آوری خون با فرمت MM/DD/YYYY نوشته شده است",
      "step_4": "کارت جمع آوری خون و تمام لانست های استفاده شده در کیسه بیوهازارد قرار می گیرند",
      "step_5": "کیسه خطر زیستی در جعبه قرار گرفته است",
      "step_6": "جعبه در پاکت ارسال از پیش پرداخت شده قرار گرفته است",
      "step_7": "پاکت ارسال بازگشت کاملاً مهر و موم شده است"
    },
    "success": {
      "header_1": "عالی!",
      "text_1": "شما آماده جمع آوری اولین نمونه خود هستید.",
      "header_2": "شما انجامش دادید !",
      "text_2": "** مرحله بعدی: ** کیت خود را برای ارسال مجدد بسته بندی کنید.",
      "header_3": "کارتان عالی بود!",
      "text_3": "شما یک قدم به سلامتی بهتر نزدیکتر شده اید.",
      "header_4": ":کارتان را خوب انجام دادید!",
      "text_4": "فقط یک بار دیگر و کار شما تمام است!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "برای مشاهده دستورالعمل ها، یک آزمایش را انتخاب کنید:",
      "subtitle": "آزمایش",
      "button": "دستورالعمل ها",
      "pdf": "دستورالعمل ها PDF"
    },
    "instructions_box_a": {
      "title": "جمع آوری ادرار",
      "subtitle": "برای این تست به مواد زیر نیاز دارید:",
      "step_1": "حداقل یک ساعت قبل از جمع آوری ادرار نکنید. استفاده از ادرار اول صبح بهترین کار است.",
      "step_2": "مستقیماً در پی‌کنتر یا ظرف جمع‌آوری ادرار کرده و حجمی بین 1/3 تا 1/2 را پر کنید",
      "step_3": "از پی‌کنتر ریخته یا از پیپت برای انتقال ادرار از ظرف جمع‌آوری به لوله(های) جمع‌آوری استفاده کنید با برچسب <span style=\"color: #FF5000\">**\"URINE\"**</span> نارنجی استفاده کنید.",
      "step_4": "لوله(های) جمع آوری را پر کنید تا مایع ترکیب شده بین خطوط پرکننده تعیین شده قرار گیرد.",
      "step_4_sublabel": "لوله را فراتر از خط پرشدن حداکثر پر نکنید، در غیر اینصورت نمونه رد خواهد شد",
      "step_5": "درپوش را روی لوله جمع آوری قرار دهید و مطمئن شوید که به طور یکنواخت و محکم بسته شده است.",
      "step_6": "تاریخ تولد و تاریخ جمع آوری نمونه (تاریخ امروز) را با فرمت MM/DD/YYYY روی لوله در ناحیه تعیین شده بنویسید.",
      "step_7": "نمونه را با پد جاذب در یک کیسه بیوهازارد خالی قرار دهید و مطمئن شوید که مهر و موم کاملا بسته شده است."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "جمع آوری ادرار",
        "subtitle": "برای این تست به مواد زیر نیاز دارید:",
        "step_1": "حداقل یک ساعت قبل از جمع آوری ادرار نکنید. استفاده از ادرار اول صبح بهترین کار است.",
        "step_2": "مستقیماً در پی کانتر یا ظرف جمع آوری ادرار کنید و حجمی بین 1/3 تا 1/2 را پر کنید",
        "step_3": "از پی‌کنتر ریخته یا از پیپت برای انتقال ادرار از ظرف جمع‌آوری به لوله(های) جمع‌آوری استفاده کنید با برچسب <span style=\"color: #FF5000\">**\"URINE\"**</span> نارنجی استفاده کنید.",
        "step_4": "لوله(های) جمع آوری را پر کنید تا مایع ترکیب شده بین خطوط پرکننده تعیین شده قرار گیرد.",
        "step_4_subtitle": "لوله را فراتر از خط پرشدن حداکثر پر نکنید، در غیر اینصورت نمونه رد خواهد شد",
        "step_5": "درپوش را روی لوله جمع آوری قرار دهید و مطمئن شوید که به طور یکنواخت و محکم بسته شده است.",
        "step_6": "تاریخ تولد و تاریخ جمع آوری نمونه (تاریخ امروز) را با فرمت MM/DD/YYYY روی لوله در ناحیه تعیین شده بنویسید.",
        "step_7": "نمونه را با پد جاذب در یک کیسه بیوهازارد خالی قرار دهید و مطمئن شوید که مهر و موم کاملا بسته شده است."
      },
      "test_2": {
        "title": "نمونه‌برداری دهانی",
        "subtitle": "برای این تست به مواد زیر نیاز دارید:",
        "step_1": "سواب را بین خط کم واضح (در صورت وجود) و قسمتی که روی آن پنبه است نگه دارید.",
        "step_2": "سواب را داخل دهان خود قرار دهید و نوک سواب را به مدت 10 ثانیه به پشت گلو بمالید.",
        "step_3": "سواب را با دقت بیرون بکشید و در لوله جمع آوری که با برچسب آبی <span style=\"color: #001F70\">**\"ORAL\"**</span> مشخص شده است قرار دهید. سواب را از قسمت خط مشخص شده با خم کردن به سمت لوله جمع‌آوری بشکنید.",
        "step_4": "درپوش را روی لوله جمع آوری قرار دهید و مطمئن شوید که به طور یکنواخت و محکم بسته شده است.",
        "step_5": "تاریخ تولد و تاریخ جمع آوری نمونه (تاریخ امروز) را با فرمت MM/DD/YYYY روی لوله در ناحیه تعیین شده بنویسید.",
        "step_6": "نمونه را با پد جاذب در یک کیسه بیوهازارد خالی قرار دهید و مطمئن شوید که مهر و موم کاملا بسته شده است."
      },
      "test_3": {
        "title": "نمونه برداری مقعدی",
        "subtitle": "برای این تست به مواد زیر نیاز دارید:",
        "step_1": "سواب را بین خط مشخص کم واضح (در صورت وجود) و قسمتی که روی آن پنبه است نگه دارید.",
        "step_2": "سواب را 3 تا 5 سانتی متر (1 تا 2 اینچ) داخل کانال مقعد قرار دهید. به آرامی سواب را به مدت 5 تا 10 ثانیه بچرخانید تا آورکانیزم های بالقوه جمع آوری شود.",
        "step_3": "سواب را با دقت بیرون بکشید و در لوله جمع آوری که با برچسب سبز رنگ <span style=\"color: #00C08C\">**\"ANAL\"**</span> مشخص شده است قرار دهید. سواب را از قسمت خط مشخص شده با خم کردن به سمت لوله جمع‌آوری بشکنید.",
        "step_4": "درپوش را روی لوله جمع آوری قرار دهید و مطمئن شوید که به طور یکنواخت و محکم بسته شده است.",
        "step_5": "تاریخ تولد و تاریخ جمع آوری نمونه (تاریخ امروز) را با فرمت MM/DD/YYYY روی لوله در ناحیه تعیین شده بنویسید.",
        "step_6": "نمونه را با پد جاذب در یک کیسه بیوهازارد خالی قرار دهید و مطمئن شوید که مهر و موم کاملا بسته شده است."
      }
    },
    "instructions_box_c": {
      "title": "جمع آوری خون",
      "subtitle": "برای این تست به مواد زیر نیاز دارید:",
      "step_1": "نام، تاریخ تولد و تاریخ جمع آوری خود را در قسمت های مشخص شده بنویسید. از قالب MM/DD/YYYY استفاده کنید.",
      "step_2": "پرده کارت خون را باز کنید تا دایره‌های روی کاغذ جمع‌آوری خون آشکار شوند. به کاغذ جمع‌آوری خون دست نزنید.",
      "step_3": "دست‌ها را با آب گرم حداقل به مدت 30 ثانیه بشویید، سپس به مدت 15 ثانیه دست‌ها را به شدت تکان دهید تا جریان خون در انگشتان شما تقویت شود.",
      "step_4": "نوک انگشت را با پد الکلی تمیز کنید. بهتر است از انگشت وسط یا انگشت حلقه دست کم‌فعالتان استفاده کنید.",
      "step_5": "لانست را بردارید و درپوش را بچرخانید. نوک کوچک را محکم به نوک انگشت خود فشار دهید تا سوزن با یک صدای کلیک خارج شود. در حین جمع آوری دست خود را زیر قلب خود نگه دارید، انگشت خود را از پایه تا نوک ماساژ دهید تا جریان خون تقویت شود.",
      "step_5_subtitle": "لانست ها یک بار مصرف هستند. تمام لانست ها باید همراه با نمونه شما برای دفع به آزمایشگاه بازگردانده شوند.",
      "step_6": "شروع از مرکز، ۳-۶ قطره را برای پر کردن دایره و نفوذ به کاغذ جمع‌آوری اعمال کنید. به کاغذ با انگشت خود دست نزنید چرا که این کار جریان خون را محدود می‌کند. هنگامی که دایره پر شد، به دایره بعدی بروید. مجاز است که خون از خطوط خارج شود، اما اجازه ندهید لکه‌های خون به یکدیگر پخش شود.",
      "step_6_subtitle": "پس از تکمیل یا خشک شدن دایره، خون اضافی را به دایره اضافه نکنید. \"لایه بندی\" خون، مجموعه را باطل می کند.",
      "step_7": "بدون بستن کارت خون، آن را روی یک سطح صاف قرار دهید و اجازه دهید کاغذ خون گیری در دمای اتاق حداقل 30 دقیقه در هوا خشک شود. کاغذ جمع آوری خون را گرم نکنید، سشوار نکنید و در معرض نور مستقیم خورشید قرار ندهید. گرما به نمونه آسیب می رساند.",
      "step_7_subtitle": "سمت پشت کاغذ جمع‌آوری خون را بررسی کنید. خون باید به طور کامل نفوذ کرده و هر دایره از کاغذ جمع‌آوری خون را پر کند.",
      "step_8": "هنگامی که کاغذ جمع‌آوری خون خشک شد، کارت خون را با برگرداندن پرده ببندید. سپس کارت خون و لنست‌ها را در کیسه بیوهازارد همراه با بسته خشک‌کننده قرار دهید و اطمینان حاصل شود که کیسه بیوهازارد به درستی مهر شده است."
    },
    "test_tips": {
      "title": "نکاتی برای جمع آوری صحیح خون",
      "subtitle": "برای بهترین نتایج:",
      "step_1": "در حین انجام جمع آوری مطمئن شوید که دچار کم آبی نمی شوید. هیدراسیون باعث افزایش جریان خون می شود.",
      "step_2": "بلافاصله پس از سیگار کشیدن، جمع آوری را انجام ندهید.",
      "step_3": "شستن و گرم کردن دست ها در زیر آب گرم به افزایش جریان خون در دست ها کمک می کند.",
      "step_4": "دست ها را به شدت به سمت زمین تکان دهید تا جریان خون در انگشتان شما تقویت شود.",
      "step_5": "دستگاه جمع آوری و دست ها را در هنگام جمع آوری برای بهترین جریان خون زیر قلب خود نگه دارید.",
      "step_6": "ممکن است به بیش از یک نوک انگشت نیاز داشته باشید. این نکات را بین هر نوک انگشت تکرار کنید."
    },
    "shipping": {
      "header": "کیت شما آماده ارسال است!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "کیت آزمایش پیشنهادی خود را از کارکنان سایت تحویل بگیرید و در زیر ثبت نام کنید",
    "short_display_name": {
      "A": "کلامیدیا و سوزاک",
      "B": "کلامیدیا و سوزاک",
      "C": "سیفلیس",
      "CC": "سرطان روده بزرگ",
      "CE": "سلیاک",
      "CR": "کراتینین",
      "D": "HIV، سیفلیس، کلامیدیا و سوزاک",
      "DD": "دیابت",
      "E": "HIV و سیفلیس",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "هرپس سیمپلکس",
      "I": "HIV",
      "TT": "تستوسترون",
      "VD": "ویتامین دی",
      "VS": "نظارت ویروسی"
    },
    "test_kit": "کیت آزمایش",
    "view_results": "مشاهده نتایج",
    "recommended_kit_title": "کیت های آزمایش توصیه شده",
    "instructions_box_h": {
      "title": "جمع‌آوری نمونه‌برداری واژنی",
      "subtitle": "لوله های جمع آوری ممکن است حاوی مواد نگهدارنده مایع باشند. <span class=\"text-danger\">**مایع نگهدارنده را در صورت وجود از لوله های جمع آوری خالی نکنید.**</span>",
      "step_1": "سواب را بین خط کمتر واضح (در صورت وجود) و قسمت نوک پنبه سواب در یک دست نگه دارید و لابیاها (چین های پوست اطراف دهانه واژن) را جدا کنید.",
      "step_2": "سواب را به اندازه 5 سانتی متر (2 اینچ) در دهانه واژن قرار دهید. به آرامی سواب را به مدت 30 ثانیه بچرخانید در حالی که سواب را به دیواره های واژن می مالید.",
      "step_3": "سواب را با دقت بیرون بکشید و در لوله جمع آوری علامت گذاری شده با <span class=\"text-danger\">**برچسب قرمز \"واژینال\"**</span> قرار دهید. سواب را با خم کردن به سمت لوله جمع‌آوری بشکنید.",
      "step_4": "درپوش را روی لوله جمع آوری قرار دهید و مطمئن شوید که به طور یکنواخت و محکم بسته شده است.",
      "step_5": "تاریخ تولد خود را با فرمت MM/DD/YYYY روی لوله در ناحیه تعیین شده (DOB) بنویسید.",
      "step_6": "تاریخ جمع آوری نمونه (تاریخ امروز) را با فرمت MM/DD/YYYY روی لوله در ناحیه تعیین شده (تاریخ جمع آوری) بنویسید.",
      "step_7": "نمونه را با پد جاذب در کیسه خالی بیوهازاذد قرار دهید و از بسته شدن کامل مهر و موم اطمینان حاصل کنید."
    },
    "packaging_box_h": {
      "step_3": "**تاریخ تولد** شما با فرمت MM/DD/YYYY روی لوله جمع آوری نمونه نوشته شده است.",
      "step_4": "**تاریخ جمع آوری** شما روی لوله جمع آوری نمونه با فرمت MM/DD/YYYY نوشته شده است.",
      "step_6": "کیسه بیوهازارد را در جعبه قرار می گیرد"
    },
    "instructions_box_cc": {
      "title": "جمع آوری مدفوع",
      "step_1": "تاریخ تولد و تاریخ جمع آوری نمونه (تاریخ امروز) را با فرمت MM/DD/YYYY روی لوله در ناحیه تعیین شده بنویسید. درپوش سبز را با چرخاندن و بلند کردن باز کنید.",
      "step_2": "کاغذ جمع آوری ارائه شده را در کاسه توالت روی آب قرار دهید.",
      "step_3": "نمونه مدفوع را روی کاغذ جمع آوری قرار دهید.",
      "step_4": "نمونه را از مدفوع جمع‌آوری کنید قبل از اینکه کاغذ فرو رود و نمونه مدفوع به آب بخورد.",
      "step_5": "سطح نمونه مدفوع را با پروب نمونه خراش دهید.",
      "step_6": "قسمت شیاردار پروب را به طور کامل با نمونه مدفوع بپوشانید.",
      "step_7": "شیشه‌ی نمونه‌برداری را با وارد کردن نمونه‌بردار و بستن محکم درپوش سبز ببندید. دوباره باز نکنید.",
      "step_8": "تخلیه کنید. کاغذ جمع‌آوری تجزیه‌پذیر است و به سیستم‌های فاضلابی آسیبی نمی‌زند",
      "step_9": "بطری نمونه برداری را در پد جاذب بپیچید و در کیسه بیوهازارد قرار دهید."
    },
    "contact_support": {
      "title": "بیا برای این مشکل را حل کنیم",
      "text_1": "از شنیدن اینکه چیزی اشتباه است متاسفیم!",
      "text_2": "لطفاً با ما تماس بگیرید تا بدانیم چه مشکلی وجود دارد و ما به شما کمک خواهیم کرد تا کیت شما را تعویض کنیم."
    },
    "contact_support_success": {
      "title": "پیام شما را دریافت کردیم",
      "text_1": "با تشکر از تماس با ما.",
      "text_2": "یکی از اعضای تیم پشتیبانی ما به زودی با شما تماس خواهد گرفت.",
      "button_text": "به صفحه اصلی برگردید."
    },
    "kit_in_transit_to_patient": "سفارش شما در راه است! بیشتر سفارش‌ها در 2-5 روز کاری تحویل داده می‌شون. هنگامی که کیت خود را دریافت کردید، اطلاعات بیشتری در مورد نحوه فعال سازی و ارسال مجدد آن برای شما ارسال می کنیم. پیوند ردیابی: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}\"",
    "kit_received_at_lab": "آزمایشگاه کیت شما را دریافت کرد! هنگامی که نتایج آماده شد، اعلانی برای بررسی پورتال مربوط بیمار دریافت خواهید کرد",
    "registration_confirmation": "ثبت نام شما اکنون کامل شده است! برای فعال کردن کیت آزمایش خود به صفحه تایید ثبت نام خود مراجعه کنید:",
    "kit_ordered_online": "ما سفارش کیت شما را دریافت کرده‌ایم و پس از ارسال به‌روزرسانی آن را ارسال خواهیم کرد! سفارش شماره: {{order_number}} تاریخ سفارش: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "سفارش شما را دریافت کردیم!",
      "p1": "ما سفارش شما را دریافت کردیم! پس از ارسال سفارش شما، به‌روزرسانی دیگری برای شما ارسال می‌کنیم.",
      "order_number": "سفارش #",
      "order_date": "تاریخ سفارش"
    },
    "completed": "تکمیل شد",
    "I": "این کیت شامل آزمایشات HIV می باشد.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "این کیت شامل آزمایشاتی برای بیماری سلیاک است.",
    "CR": "این کیت شامل آزمایشات کراتینین است.",
    "DD": "این کیت شامل تست های دیابت می باشد.",
    "H": "این کیت شامل تست های HPV می باشد.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "این کیت شامل تست هایی برای HSV-2 می باشد.",
    "TT": "این کیت شامل تست تستوسترون است.",
    "VD": "این کیت شامل آزمایشات ویتامین D است.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B."
  },
  "copy_link": "لینک صفحه را کپی کنید",
  "copied_link": "لینک کپی شده!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "محافظت در برابر ویروس های رایج آنفولانزا",
      "vaccine_covid": "محافظت در برابر ویروس های COVID-19",
      "tdap": "واکسن Tdap می تواند از کزاز جلوگیری کند",
      "polio": "محافظت در برابر ویروس فلج اطفال. برای کودکان قبل از شروع مدرسه لازم است.",
      "varicella": "واکسنی که از آبله مرغان محافظت می کند",
      "shingles": "واکسنی که از زونا محافظت می کند. حداقل سن 18 سال است.",
      "human_papillomavirus": "واکسنی که از HPV محافظت می کند. برای کودکان 11 ساله توصیه می شود.",
      "meningococcal": "واکسن در برابر چهار نوع باکتری مننگوکوک محافظت می کند."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "یک کلینیک پیدا کنید",
    "select_pin": "برای مشاهده جزئیات، پین را انتخاب کنید",
    "miles_shorten": "{{miles}} mi",
    "next_available": "بعدی در دسترس",
    "appointment_type": "نوع قرار",
    "individual_appointment": "قرار انفرادی",
    "family_appointment": "قرار خانواده",
    "vaccines": "واکسن ها",
    "what_kind_appointment": "چه نوع قراری را می خواهید رزرو کنید؟",
    "select_family_or_individual": "اگر برای چند عضو خانواده به طور همزمان برنامه ریزی می کنید، قرار خانوادگی را انتخاب کنید.",
    "vaccines_selected": "واکسن های انتخاب شده",
    "where_care": "از کجا می خواهید مراقبت دریافت کنید؟",
    "select_vaccines_for_individual": "واکسن ها را برای قرار ملاقات خود انتخاب کنید (اختیاری).",
    "select_vaccines_for_family": "واکسن ها را برای قرار ملاقات خانوادگی خود انتخاب کنید (اختیاری).",
    "schedule_services": "زمان‌بندی خدمات",
    "add_family": "اعضای خانواده خود را اضافه و خدمات را در زیر انتخاب کنید.",
    "family_member": "عضو خانواده {{number}}",
    "morning": "صبح",
    "afternoon": "بعد از ظهر",
    "slot_available": "{{number}} موجود است",
    "within_x_miles": "در فاصله {{miles}} مایلی از",
    "any_distance": "هر فاصله ای از",
    "partial_results": "نتایج جزئی",
    "partial_matches": "کلینیک‌های زیر برخی از واکسن‌هایی را که به دنبال آن هستید، اما نه همه را، ارائه می‌کنند",
    "no_matches": "جستجوی شما نتایجی را که مطابقت داشته باشد پیدا نکرد. برای نتایج بهتر، فیلترهای خود را تغییر دهید.",
    "no_clinics_found": "هیچ کلینیکی وجود ندارد که با جستجوی شما مطابقت داشته باشد.",
    "broaden_filters": "برای نتایج بهتر، فیلترهای خود را گسترش دهید.",
    "unavailable_vaccines": "واکسن های که در دسترس نیست:",
    "available_vaccines": "واکسن های موجود :",
    "select_date": "تاریخ را انتخاب کنید",
    "available_appointments": "قرار ملاقات های در دسترس",
    "appointment_scheduling_info": "در مرحله بعدی می‌توانید زمان قرار را به اعضای خاصی از خانواده اختصاص دهید. در صورت تمایل می توانید قرار ملاقات های خود را در روزهای مختلف برنامه ریزی کنید.",
    "hold_selected_appointments": "ما قرارهای انتخابی شما را به مدت 15 دقیقه نگه می داریم.",
    "appointments_selected": "قرارهای ملاقات انتخاب شده",
    "no_appointments_selected": "قراری انتخاب نشده است",
    "vaccines_needed": "واکسن های مورد نیاز",
    "select_x_appointments": "{{number}} قرار ملاقات را انتخاب کنید.",
    "more": "بیشتر",
    "less": "کمتر",
    "register_next_family_member": "عضو بعدی خانواده را ثبت نام کنید",
    "successfully_registered_x_of_y_family_members": "شما با موفقیت {{x}} از {{y}} عضو خانواده را ثبت‌نام کرده‌اید.",
    "next_family_member": "عضو بعدی خانواده",
    "appointments_abbreviated": "وقت های ملاقات ",
    "register_for_clinic": "برای کلینیک ثبت نام کنید",
    "select_services": "خدمات درمانی را انتخاب کنید",
    "person": "فرد {{number}}",
    "add_person": "فرد بعدی",
    "registration_confirmation": "تایید ثبت نام "
  },
  "user_mailer": {
    "verify_email": "بیایید ایمیل شما را تأیید کنیم!",
    "hi_full_name": "سلام، {{full_name}}",
    "verify_email_button": "تأیید ایمیل",
    "please_verify_email": "لطفا از دکمه زیر برای تایید ایمیل خود استفاده کنید."
  },
  "services": {
    "dptap_dt": "واکسن سه گانه دیفتری کزاز سیاه سرفه",
    "hepatitis_a": "هپاتیت A",
    "hepatitis_b": "هپاتیت B",
    "hepatitis_a_b": "هپاتیت A و B",
    "hib": "Hib",
    "mmr": "سرخک، اوریون، سرخجه",
    "meningococcal": "مننگوکوک",
    "mpox": "Mpox",
    "pneumococcal": "پنوموکوک",
    "polio": "فلج اطفال",
    "rsv": "ویروس سین سیشال تنفسی",
    "rotovirus": "روتاویروس",
    "zoster": "زوستر (زونا)",
    "tetanus_diptheria": "کزاز و دیفتری",
    "tdap": "واکسن سه گانه دیفتری کزاز سیاه سرفه",
    "typhoid": "حصبه",
    "varicella": "واریسلا (آبله مرغان)",
    "covid-19": "کووید 19",
    "covid-19_adult": "COVID-19 (بزرگسالان)",
    "covid-19_adult_description": "برای افراد 12 سال یا بیشتر.",
    "covid-19_children": "COVID-19 (کودک)",
    "covid-19_children_description": "برای کودکان 4 تا 11 ساله.",
    "covid-19_infants": "COVID-19 (نوزادان)",
    "covid-19_infants_description": "برای کودکان 6 ماه - 3 سال.",
    "influenza": "آنفولانزا",
    "mmrv": "سرخک سرخجه اوریون"
  },
  "deep_archived": {
    "admin": "برای مقاصد امنیت داده، داده‌های گروه {{test_group_name}} ({{slug}}) بایگانی شده و دیگر از این صفحه قابل مشاهده یا تغییر نیست. لطفاً اگر به کمک نیاز دارید، با مدیر حساب خود یا support@primary.health تماس بگیرید.",
    "participant": "این صفحه دیگر در دسترس نیست. لطفاً با مسیولین کمپین بهداشت خود تماس بگیرید: {{test_group_name}}. اگر در مشاهده رکوردی قدیمی مشکل دارید، لطفاً با support@primary.health برای کمک تماس بگیرید."
  }
}