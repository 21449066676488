import React, { useState } from "react";
import { Button, Form, Modal, InputGroup, Spinner } from "react-bootstrap";
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BarcodeScanner from "../../../common/components/BarcodeScanner";
import { createTest } from "../ServicesSection";
import { validateCode } from "../CheckoutPage";


const BarcodeScannerModal = ({
  handleCreateTest,
  setShowScanner,
  setUnsavedUid,
  showScanner,
  testConfiguration,
  unsavedUid,
}) => {

  const isCodeValid = validateCode(testConfiguration, unsavedUid);

  return (
    <Modal
      onHide={() => setShowScanner(false)}
      show={showScanner}
    >
      <Modal.Header>
        <h5>
          <i
            className={`fa-regular fa-${testConfiguration.font_awesome_icon}`}
            style={{ color: testConfiguration.color_code }}
          ></i>{' '}
          <b>{testConfiguration.checkout_name}</b>
        </h5>
        <Button
          onClick={() => setShowScanner(false)}
          variant="link"
          className="p-0 text-dark"
        >
          X
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <InputGroup className="mb-4">
            <Form.Control
              autoFocus
              onChange={(e) => setUnsavedUid(e.target.value)}
              placeholder="Enter it manually"
              data-test-hook="barcode_manual_input"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && isCodeValid) {
                  e.preventDefault();
                  handleCreateTest(unsavedUid);
                  setUnsavedUid('');
                  setShowScanner(false);
                }
              }}
              value={unsavedUid}
            />
            <Button
              data-test-hook="barcode_manual_submit"
              disabled={!isCodeValid}
              variant="primary"
              onClick={() => {
                handleCreateTest(unsavedUid);
                setUnsavedUid('');
                setShowScanner(false);
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </InputGroup>
          <BarcodeScanner
            onScan={(unsavedUid) => {
              setUnsavedUid(unsavedUid);
              if (validateCode(testConfiguration, unsavedUid)) {
                handleCreateTest(unsavedUid);
                setUnsavedUid('');
                setShowScanner(false);
              }
            }}
          />
          {unsavedUid.length > 0 && !isCodeValid && (
            <label className="form-label mt-2" style={{ color: 'red' }}>
              Incorrect Barcode Format
            </label>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
};

const ScanOrGenerateCardBody = ({
  appointment,
  key,
  setTests,
  test,
  tests,
  testGroup,
}) => {

  const barcodeGenerationAvailable = test.test_configuration.generate_record_configuration != "scan_only";
  const [loading, setLoading] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [unsavedUid, setUnsavedUid] = useState('');

  const handleCreateTest = async (uid = null) => {
    if (loading) return;
    setLoading(true);
    const newTest = await createTest(testGroup.slug, uid, appointment.id, test.test_configuration.id);
    newTest["saved"] = false;
    // updates the current dummy test to an actual test created on the backend
    const newTests = tests.map((test) => {
      const replacalbeDummyTest = !test.id && test.test_configuration.id == newTest.test_configuration.id;
      return replacalbeDummyTest ? newTest : test;
    });
    setTests(newTests);
    setLoading(false);
  };

  const handleRemove = () => {
    setTests(tests.filter((t) => t.id !== test.id));
  };

  return (
    <div key={key}>
      <Form.Group>
        <Form.Label>Barcode</Form.Label>
        <div className="d-flex">
          <Button
            onClick={() => setShowScanner(true)}
            variant="outline-secondary"
          >
            Scan barcode
          </Button>
          {barcodeGenerationAvailable && (
            <Button
              className="ms-2"
              variant="outline-secondary"
              onClick={async () => handleCreateTest()}
            >
              {
                loading
                  ? <Spinner animation="border" size="sm" className="me-2" />
                  : "Generate barcode"
              }
            </Button>
          )}
        </div>
      </Form.Group>
      <div className="d-flex justify-content-end">
        <Button
          onClick={handleRemove}
          variant="link"
        >
          Remove service
        </Button>
        <Button disabled={true}>
          Save
        </Button>
      </div>
      <BarcodeScannerModal
        handleCreateTest={handleCreateTest}
        setShowScanner={setShowScanner}
        setUnsavedUid={setUnsavedUid}
        showScanner={showScanner}
        testConfiguration={test.test_configuration}
        unsavedUid={unsavedUid}
      />
    </div>
  );
};

export default ScanOrGenerateCardBody;