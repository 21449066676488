import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col, Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns-tz';
import { Header } from './TestStripDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { formatDateInCurrentLocale } from './utils';

const VaccineDetails = ({ vaccine }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t, i18n } = useTranslation();
  const vaccineInfoSheet =
    vaccine.vaccine_result.vaccine_route.vaccine_info_sheets_props
      ?.vaccine_info_sheet_versions[0];

  const printDetails = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col>
          <div className="py-2">
            <h4>{vaccine.test_configuration.display_name}</h4>
          </div>
        </Col>
        <Col className="text-end" xs="auto">
          <Button
            variant="link"
            aria-label="Download vaccine details"
            onClick={printDetails}
          >
            {!isMobile && <div className="me-2">{t('login.download')}</div>}
            <FontAwesomeIcon icon={faArrowDownToLine} />
          </Button>
        </Col>
      </Row>
      <div className="medical_history__item test-card">
        <Row>
          <Col>
            <div className="body-large-18-regular inter bold body">
              {formatDateInCurrentLocale(vaccine.display_time)}
            </div>
            <div className="text-secondary pt-2">{vaccine.location_name}</div>
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            {[
              'Administrator',
              'Vaccine name',
              'Vaccine dose',
              'Lot number',
            ].map((title) => (
              <div
                className="pt-2 body-large-16-medium"
                style={{ color: '#2D3648' }}
                key={title}
              >
                {title}
              </div>
            ))}
            {vaccineInfoSheet?.vaccine_info_sheet_version_file_url && (
              <div
                className="pt-2 body-large-16-medium"
                style={{ color: '#2D3648' }}
              >
                Vaccine information statement
              </div>
            )}
          </Col>
          <Col>
            <div className="pt-2 body-large-16-regular">
              {vaccine.vaccine_result.administrator_name}
            </div>
            <div className="pt-2 body-large-16-regular">
              {
                vaccine.vaccine_result.vaccine_route
                  .trade_name_with_manufacturer
              }
            </div>
            <div className="pt-2 body-large-16-regular">{vaccine.vaccine_result.dose} mL</div>
            <div className="pt-2 body-large-16-regular">{vaccine.vaccine_result.lot_number}</div>
            {vaccineInfoSheet?.vaccine_info_sheet_version_file_url && (
              <div className="pt-2 body-large-16-regular">
                <a
                  target="_blank"
                  href={vaccineInfoSheet.vaccine_info_sheet_version_file_url}
                  variant="primary"
                >
                  Version{' '}
                  {format(
                    new Date(
                      vaccineInfoSheet.vaccine_info_sheet_version.version_date,
                    ),
                    'mm-dd-yyyy',
                  )}
                </a>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const VaccineDisplay = ({ vaccine, selectedVaccine, setSelectedVaccine }) => {
  return (
    <React.Fragment>
      {!selectedVaccine && (
        <div
          className="medical_history__item test-card my-3"
          onClick={() => setSelectedVaccine(vaccine)}
          key={vaccine.id}
        >
          <Header
            displayName={vaccine.test_configuration.display_name}
            icon={vaccine.test_configuration.font_awesome_icon}
            color={vaccine.test_configuration.color_code}
            displayTime={vaccine.display_time}
            key={'o' + vaccine.id}
            source={'Primary.Health'}
          />
        </div>
      )}
      {selectedVaccine && selectedVaccine.id == vaccine.id && (
        <VaccineDetails
          key={'d' + selectedVaccine.id}
          vaccine={selectedVaccine}
        />
      )}
    </React.Fragment>
  );
};

export default VaccineDisplay;
