import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ContactSupport = ({ isMobile }) => {
  const { t } = useTranslation();

  return (
    <div
      className={isMobile ? '' : 'card border-0 my-3'}
      style={{ height: 'auto' }}
    >
      <div className={isMobile ? 'p-3' : 'card-body'}>
        <img
          className="navbar-brand logo d-block mb-2"
          src="/images/logo.png"
          alt="Primary logo"
          style={{ maxHeight: 16 }}
        ></img>
        <h5 className="font-size-16 inter semibold">
          {t('self_resulting.need_help')}
        </h5>
        <p className="inter regular">{t('result_page.contact_support')}</p>
        <Button
          variant="link"
          data-bs-toggle="modal"
          data-bs-target="#supportModal"
          className="p-0"
        >
          {t('self_resulting.contact_support')}
        </Button>
      </div>
    </div>
  );
};

export default ContactSupport;
