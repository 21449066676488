import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card } from 'react-bootstrap';
import { vaccineCode, manufacturerCode } from '../../common/utils/vaccine';
import { Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import IISReferences from './IisReferences';
import ImmunizationTable from './ImmunizationTableV2';
import ImmunizationCheck from './ImmunizationCheckV2';
import CheckoutSection from './CheckoutSection';
import MatchStatusHandler from './MatchStatusHandler';

const requestHistoryUpdate = ({ testGroupId, userId }) => {
  const url = `/test_groups/${testGroupId}/participants/${userId}/vaccine_history_updates`;
  return axios.post(url);
};

const lastCheckedLabel = reference => {
  return (reference
    ? `${format(new Date(reference.last_checked_at), 'yyyy-MMM-dd')} (${reference.source.toUpperCase()})`
    : "Never"
  );
};

const vaccinationSourcesLabel = (iisReferences) => {
  const sources = iisReferences.map((reference) =>
    reference.source.toUpperCase(),
  );

  if (sources.length >= 1) {
    return `List of vaccines from ${sources.join(', ')} and/or Primary.Health`;
  } else {
    return `List of vaccines from Primary.Health`;
  }
};

const uniqueKey = ({ vaccinated_on, vaccine, manufacturer }) => {
  return [
    vaccinated_on,
    vaccineCode(vaccine),
    manufacturerCode(manufacturer),
  ].join('|');
};

export const deduplicateHistories = (histories) => {
  const missing_manufacturers = histories.filter(history => history.manufacturer === null);
  const deduplicated = histories.reduce((uniqueHistories, history) => {
    const key = uniqueKey(history);
    if (uniqueHistories[key]) {
      const uniqueHistory = uniqueHistories[key];
      if (uniqueHistory.source == 'primary') {
        uniqueHistories[key] = history; // Give preference to non-Primary record
      }
      if (uniqueHistories[key].source && !uniqueHistories[key].source.includes(history.source)) {
        uniqueHistories[key].source += `, ${history.source}`;
      }
    } else {
      const missing_manufacturer = missing_manufacturers.find(missing => key.includes(uniqueKey(missing)) && key != uniqueKey(missing));

      if (missing_manufacturer && !missing_manufacturer.source.includes(history.source)) {
        uniqueHistories[key] = missing_manufacturer;
        uniqueHistories[key].source += `, ${history.source}`;
        var matchWarning = `Warning: Reduced confidence with ${history.source} immunization match. IIS missing manufacturer.`;
        if (uniqueHistories[key].notes) {
          uniqueHistories[key].notes += matchWarning;
        } else {
          uniqueHistories[key].notes = matchWarning;
        }
      } else if (!missing_manufacturer) {
        uniqueHistories[key] = history;
      }
    }
    return uniqueHistories;
  }, {});

  return Object.values(deduplicated);
};

const sortByDate = (histories) => {
  return histories.sort((a, b) => {
    return new Date(b.vaccinated_on) - new Date(a.vaccinated_on);
  });
};

const filterByThreeMonths = (histories) => {
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  return histories.filter(
    (history) => new Date(history.vaccinated_on) >= threeMonthsAgo,
  );
};

const ImmunizationHistory = (props) => {
  const {
    testGroupId,
    user,
    isHipaaTrained = false,
    histories: propHistories,
    iisReferences: propIISReferences,
  } = props;

  const updateImmunizations = ({ vaccineHistories, iisReferences }) => {
    setHistories(vaccineHistories);
    setIISReferences(iisReferences);
    setLatestReference(new IISReferences(iisReferences).mostRecent());
  };

  const [histories, setHistories] = useState(propHistories);
  const [iisReferences, setIISReferences] = useState(propIISReferences);
  const [latestReference, setLatestReference] = useState(new IISReferences(iisReferences).mostRecent())
  const allHistories = sortByDate(deduplicateHistories(histories));
  const recentHistories = filterByThreeMonths(allHistories);
  const [activeTab, setActiveTab] = useState('recent');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const checkNow = () => {
    return requestHistoryUpdate({ testGroupId, userId: user.id })
      .then((response) => {
        updateImmunizations({
          vaccineHistories: response.data.vaccine_histories,
          iisReferences: response.data.iis_references,
        });
        setError(false);
      })
      .catch((error) => {
        setError(true);
      });
  };

  const handleCheckNow = () => {
    setLoading(true);
    checkNow().then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    checkNow(); // check when loading
  }, []);

  return (
    <CheckoutSection
      hidePending={true}
      sectionKey="show_immunization_history"
      title={'Vaccine history'}
      topRightContent={
        <div className="d-flex my-auto">
          <div className="text-muted my-auto me-2">
            Updated: {lastCheckedLabel(latestReference)}
          </div>
          <div>
            <Button onClick={handleCheckNow} variant="link">
              Check for updates
              {loading &&
                <Spinner animation="border" size="sm" className="ms-3" role="status" />
              }
            </Button>
          </div>
        </div>
      }
    >
      <div>
        <ImmunizationCheck
          checkNow={checkNow}
          error={error}
          iisReferences={iisReferences}
          latestReference={latestReference}
          setError={setError}
          setLoading={setLoading}
          testGroupId={testGroupId}
          user={user}
          setLatestReference={setLatestReference}
        />

        <p>{vaccinationSourcesLabel(iisReferences)}</p>
        <div className="d-flex justify-content-between my-3 border-bottom border-muted">
          <div className="d-flex">
            <div
              className="px-3 py-2"
              onClick={() => setActiveTab('recent')}
              style={{
                cursor: 'pointer',
                borderBottom: activeTab === 'recent' ? '1px solid black' : 'none',
                fontWeight: activeTab === 'recent' ? 'bold' : 'normal',
              }}
            >
              Last 3 months
            </div>
            <div
              className="px-3 py-2"
              onClick={() => setActiveTab('history')}
              style={{
                cursor: 'pointer',
                borderBottom: activeTab === 'history' ? '1px solid black' : 'none',
                fontWeight: activeTab === 'history' ? 'bold' : 'normal',
              }}
            >
              History
            </div>
          </div>
        </div>

        <div className="my-3">
          {activeTab === 'recent' && (
            isHipaaTrained ? (
              <ImmunizationTable
                histories={recentHistories}
                message='No vaccines in the last 3 months found. For a full history, view history tab.'
                showAlert={allHistories.length > 0 && recentHistories.length === 0}
              />
            ) : (
              <Card body bg="warning">
                You do not have access to the patient’s recent history. Please
                contact your site lead for detailed vaccination history.
              </Card>
            )
          )}
          {activeTab === 'history' && (
            isHipaaTrained ? (
              <ImmunizationTable histories={allHistories} showAlert={allHistories.length === 0}/>
            ) : (
              <Card body bg="warning">
                You do not have access to the patient’s full history. Please
                contact your site lead for detailed vaccination history.
              </Card>
            )
          )}
        </div>
        <MatchStatusHandler
          latestReference={latestReference}
          updateImmunizations={updateImmunizations}
          testGroupId={testGroupId}
          user={user}
          onMatchAccepted={checkNow}
        />
      </div>
    </CheckoutSection>
  );
};

export default ImmunizationHistory;
