export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Ji'a",
    "day": "Guyyaa",
    "year": "waggaa",
    "january": "Amajjii",
    "february": "Guraandhala",
    "march": "Bitootessa",
    "april": "Ebla",
    "may": "Caamsaa",
    "june": "Waxabajjii",
    "july": "Adoolessa",
    "august": "Hagayya",
    "september": "Fulbaana",
    "october": "Onkololeessa",
    "november": "Sadaasa",
    "december": "Muddee"
  },
  "user": {
    "send_appointment_confirmation_message": "Beellamni keessan mirkanaa'eera {{name}}.",
    "landline_appointment_reminder_message": "Akkam, {{full_name}}. Kuni guyyaa bellama keessan isin yaadachisudhaafi {{name}}. Beellamni kee {{date}} Irratti{{time}} Irratti{{address}}.",
    "send_at_home_visit_confirmation_message": "Beellamni keessan yeroo armaan gaditti mirkanaa'eera {{date}} sa'ati {{time}}booda.",
    "send_mail_order_confirmation_message": "Ajajni poostaan ajajjan keessan mirkanaa'eera",
    "send_waitlist_message": "Eeggatotaa keessa seentaniittu {{name}}. yeroo bellama kee osoo nuti siif hin markaneessin akka hin dhufnee.",
    "verify_contact": {
      "phone": "laakkofsa bilbilaa keeessan ta'uusaa nuf mirkaneessudhaaf liinkii kana xuqa: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "yeroo bellaama keessanif galmaa'u itti fufudhaaf asitti : {{url}}",
    "on_demand_confirmation_message": "Galmooftani xumurtaniittu {{name}}.",
    "appointment_reminder": "Dursa beellama keessani {{start_time}}, Liinki kana xuquudhan waanta hafee jiru xumuruufi akka isinii milkaa'e ilaaludhaaf . Beellama keessan daqiiqa 15 caalaa dursitanii akka hin argamnee..",
    "appointment_reminder_base": "{{text}} kana xuqaa: {{link}}",
    "test_result_notifier": {
      "text_message": "Portaali dhukkubsataa kan  {{first_name_with_last_initial}} haaromfamee jira gara {{url}} deemudhan akkasumas koodi kana {{access_code}} fayyadama",
      "email_subject": "Ergaa haaraa poortala dhukkubsataa keessan irra jira",
      "email_intro": "Ashamaa  {{first_name_with_last_initial}}",
      "email_p1": "Ergaan haaraan ni qabdu haala dhukkubni keessan irra jiru kan ibsu.",
      "email_p2": "Qabduu armaan gadii cuqaasaa ykn linkii  fayyadamuun portalii haala dhukkuba keessanii koodi itti galchaati ilaalaa.",
      "email_p3": "Poortaala dhukkubsataa ilaalaa:'",
      "email_button": "Portalii yookan dashboordi keessan  ilaala"
    },
    "landline_appointment_reminder_message_without_time": "Akkam, {{full_name}}. Kuni guyyaa bellama keessan isin yaadachisudhaafi  {{name}}. Yeroon bellama keessan {{date}} irratti{{address}}.",
    "appointment_reminder_on_demand": "Dursa beellama keessani {{start_time}}, Liinki kana xuquudhan waanta hafee jiru xumuruufi akka isinii milkaa'e ilaaludhaaf.",
    "new_test_result_notifier": {
      "email_intro": "Portaaliin dhukkubsataa keessanii haaromfameera.",
      "email_text_1": "Galmee dhukkubsataa haaraa ykn gabaasa mana yaalaa kamiyyuu ilaaluuf poortala Seenaa Yaalaa keessan armaan gadii daawwadhaa.",
      "email_button": "Galmee yookan Seenaa Fayyaa",
      "email_text_2": "Liinki webisayiti kana gara brawuseri keessanitti Koppii  fi peesti gochuu ni dandeessu"
    },
    "mailers": {
      "email_contact_us": "Gaaffii qabdu? Nu qunnamaa"
    }
  },
  "errors": {
    "generic": {
      "message": "wanti tokko dogoggora irra deebi'aa yaala"
    },
    "messages": {
      "blank": "Bakki kun duwwaa ta'uu hin danda'u"
    },
    "incorrect_credentials": "Mirkaneessi kee sirrii hin turre, irra deebi'ii yaali.",
    "error_activating_test_kit": " Meeshaa qorannoo yookan kit kessan  hojiirra oolchuu irratti dogoggorri mul'ateera."
  },
  "type": "barressi",
  "payment": {
    "continue_label": "kaffalti itti fufaa",
    "policy_text": "yeroo beellama keessani yoo haftaan yookan guyya beellama keessan sana irratti yoo argamuu baattan, guyyaa 7 boodde qarshii kaffaltan irra 80% isiniif deebifna, asirra ilaalu dandeessu [Terms of Service]({{link}})",
    "card_number": "Lakkofsa kaardii",
    "expiry_date": "Guyyaa itti dhumu",
    "pay_button": "Kaffalli",
    "no_credit_card": "Qaamaniin kaffala",
    "cash_payment_helper": "Fiddanii dhufuu akka hin irranfanne **{{payment}}** yeroo bellama keessaniitti",
    "invoice": "nagaa'ee kaffaltii",
    "pay_by_card": "kaardiidhan kaffala",
    "cost": "Gatii",
    "total": "walii gala",
    "pay_cash_at_appointment": "Guyya beellamakootti qarshdhan kaffala {{appointment}}",
    "view_invoice": "ilaali/nagayee kuti",
    "refund_policy": "Yoo bellama keessan haqxan, kaffaltii keessan keessa {{amount}}, guyyaa beellama keessan guyyaa 7 booda isiniif deebi'aa. Guutumaa  [Terms of Service]({{link}}) isaa dubbisuuf.",
    "refund_window_passed": "Bellamni keessan akka maallaqni isiniif deebi'uu isin hin dandeessisu sababa guyyaan beellama keessan darbeef. Gaffii keessan kamiifu namoota bakkicha jiran qunnama.",
    "amount_paid": "Maallaqa Kaffalame",
    "balance": "Qaarshi yookin baalansi qabdan"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "eyyama kana addan kutukee wanta mul'isu asirrati galchii(apiloodi) godhii yookan ammo kan gajjala jiru kana iratti mallatessi",
    "stop": "Galmee xumuruudhaf walii galte ni barbaachisa",
    "parent_or_guardian_required_html": "Galmee kee xumuruudhaf maatinke,guuddisenkee yookan ammo qaamn seerawan murtessu danda'u waali galte kana maalattesse dhiyeessu qaba. ati <b>CANNOT</b> waali galte tajaajila kana.",
    "send_parent_or_guardian": "Akka isaan irratti mallateessanif liinki Kana maaati keedhaf yookan ammo guddistukeedhaf erguu dandeessa  ",
    "consent_guardian_name_signature": "Maqaa jalqabaa",
    "consent_for_minor": "mallatoo walii galtee baka bu'aa daa'imaa",
    "guardian_signature": "mallatoo maatii,guddisee yookan qaama searawa walii galtee fayyaa mallateessu danda'u ",
    "after_you_schedule": "Hirmaattonni umurii kana gadii  {{age}}walii galtee kana isaan bakka bu`ee kan mallatessu qabuu maatii yookan guddisee dha. itti fufaati galmee kana xumuraa akka maatii yookan guddiseen isiniif malatessuu danda`uuf",
    "consent_guardian_last_name_signature": "Maqaa xumuraa",
    "consent_first_and_last_name": "Maqaa jalqabaa fi Maqaa xumuraa ",
    "phone_number": "Lakkoofsa bilbilaa guddiftu",
    "email": "Imeeyilii guddistu",
    "consent_registrar_with_guardian_name_signature": "Maqaa warraa",
    "consent_registrar_with_guardian_last_name_signature": "Maqaa lammataa warraa",
    "consent_registrar_first_name_signature": "Maqaa galmeessaa",
    "consent_registrar_last_name_signature": "maqaa lammataa galmeessaa",
    "consent_on_behalf": "Bakka bu'ee mallatteessaan jira  {{first_name}} {{last_name}}",
    "consent_on_behalf_registrar": "Bakka bu'ee mallatteessuufiif aangoon naa kennameera {{first_name}} {{last_name}}",
    "consent_on_behalf_registrar_with_guardian": "Bakka bu'ee mallatteessuufiif aangoon warra isaaniin afaaniin naa kennameera {{first_name}} {{last_name}}",
    "registrar_phone": "Lakkoofsa bilbilaa dhabbaticha",
    "registrar_email": "Imeelii dhaabbaticha",
    "consented_by_parent_guardian": "Maati yookan Guddiftuu",
    "consented_by_decision_maker": "Murtee Fayyaa Irratti muto kennuf angoon kan kennameefi",
    "consented_by_guardian_verbal": "Galmeessaa Maati yookan Guddiftuu waliin (heyyama afaaniin kennameera) .",
    "consented_by_registrar": "Galmeessaa (heyyama afaaniin dhugoomeera yookan kennamera) .",
    "full_name": "Maqaa guutuu",
    "print": "Maxxansi yookan printi godhi"
  },
  "registration": {
    "contact_information": "Maqaa fi Teessoo",
    "contact_information_additional": "Bellama fi bu`aa isinitti himuuf Maatii yookan guddisee karaa itti qunnamnu",
    "personal_information": "Odeeffannoo naannicha",
    "address_placeholder": "bakka jireenyaa kessanii guutaa",
    "first_name_label": "Maqaa jalqabaa",
    "last_name_label": "Maqaa xumuraa",
    "errors": {
      "state": "Koodii nannoo mallattoo lama sirrii ta`e ",
      "phone_number_invalid": "lakkofsi bilbilaa dogongora",
      "required": "gaatiin kun hin barbachisa",
      "email_domain": "Wanti isin guttan dogongora,irraa deebi`aa ilaala. galmeen hirmaattota kan qaama gargaru qaban qofaafi.imelii kan hoojii yookan kan hawasaa fayyadama.yoo ulaaga nan guuta jettanii dogongora argitan qaama isin gargaaru qunnama. ",
      "age_requirement": "Guyyaan dhaloota keessan ulagaa barbadamu hin guutne",
      "signature_required": "Mallattoon hin barbachisa",
      "regex_invalid": "gaatiin kun siirrii hin fakkatu",
      "date_invalid": "Guyyaan kun hin jiru",
      "invalid_entry": "Maloo,Galmee kana itti hin fufin , ajaja jiru hordofi, nama si gargaaruf ka`ame qunnami",
      "city": "Magalaa sirrii ta`uu qaba",
      "survey_unanswered": "Gaffilee barbachisan mallattoo * barrefaman guutamuu qabu",
      "postal_code": "Lakkofsa sanduqa postaa sirrii koodii 5 ta`uu qaba",
      "field_required": "{{field}} Fayiida qabachuudha qaba",
      "option_required": "Filannowan armaan gadi keessa tokko filachuun dirqamadha ",
      "year_length_invalid": "Bara dhalotaa kee galchi(yyyy)",
      "invalid_day": "Guyyaan 1 - 31 gidduu ta`uu qaba.",
      "reached_max_chars": "lakkoofsi qubee eyyamamu guuteera",
      "chars_max": "Baayyinni qubee guutuu dha",
      "minimum_length": "Odeefanno isin guuttan yoo xiqqaate {{length}} dheerachuu qaba."
    },
    "insurance_status": {
      "question": "Inshuraansi Fayya ni qabdaa?",
      "have_health_insurance": "Eyyee, Inshuraansi Fayya nin qaba",
      "do_not_have_health_insurance": "Lakki ,Inshuraansi Fayya hin qabu"
    },
    "insurance_policy_holder": {
      "question": "Abbaan polisii enyuu",
      "i_am": "Anaa dha",
      "my_spouse": "Abba warraayookan hidhata",
      "my_parents": "Maatii koo",
      "someone_else": "Nama kan biraa",
      "policy_first_name": "Maqaa jalqabaa nama polisii harkaa qabuu",
      "policy_last_name": "Maqaa dhumaa nama polisii harkaa qabuu",
      "policy_dob": "Bara dhaloota nama polisii harkaa qabuu",
      "name": "Maqaa imaammaticha"
    },
    "insurance_information": {
      "title": "Odefannoo inshuransii",
      "company_name": "Maqaa Dhabbataa inshuransii",
      "id_number": "Lakkofsa eyyummessaa misensaa",
      "group_number": "Lakkofsa gartuu misensaa",
      "secondary_insurance_label": "Inshuransii sadarkaa lammaffan qaba",
      "take_photo": "Mee suuraa kaardii inshuraansii keessanii kaasa. Suuraa kaasuun odeeffannoo tokko tokko ofumaan guuta.",
      "front_of_card": "Fuuldura kaardii",
      "card_information": "Odeeffannoo kaardii inshuraansii",
      "back_of_card": "Duuba kaardii"
    },
    "employment_information": {
      "label": "Haala qacaarrii",
      "address_1": "Odeffannoo dhabbaataa",
      "address_2": "Fakkenyaaf: suite 200",
      "phone_number": "Lakkofsa bilbilaa dhabbataa",
      "company": "Maqaa Dhabbataa ",
      "postal_code": "Lakkofsa postaa dhabbata qaacaree",
      "employed": "Qacaaramera",
      "sole_prorietor": "Abbaa qabiyyee",
      "not_employed": "Hin qacaramne ykn hoojii dhabaa",
      "reporting_supervisor": "To'aata gabaasii",
      "reporting_department": "Dipartimantii gabaasi",
      "supervisor_name": "Maqaa Supervisora",
      "supervisor_email": "Emeelii supervisora"
    },
    "location_availability": "Qorannon ammaf namoota miseensa nannoo kana ta`aniif dursi kennamera",
    "custom_survey": "Gaaffilee",
    "confirmation": "ilaali",
    "waitlisted": "eggataa",
    "schedule_your_appointment": "Wayitii bellamaa",
    "information": "Odeffannoo",
    "consent": "Walii Galtee",
    "location": "Bakka",
    "symptoms": "Mallatolee",
    "address_required": "Odeffannoo bakka jiraatanii guutaa",
    "consent_required": "Itti fufuudhaaf walii galaa",
    "required_field": "Bakka barbachisu argisisaa",
    "phone_number": "Lakkofsa bilbilaa harka kessaanii ykn mobayilii",
    "email": "Emeelii ",
    "date_of_birth": "Guyyaa dhaloota",
    "minimum_age": "Umurii hirmannaa dhaaf eyyamamu {{year}} .",
    "no_minimum_age": "qorannoo dhaaf umuriin hin dangefamuu",
    "continue_button": "Itti fufi",
    "email_required": "Emeliin kun sirrii tauu qaba",
    "done": "Xumurameera",
    "signature": "Mallattoo",
    "clear_button": "Balleessi",
    "back_button": "Boodatti deebi`i",
    "choose_location": "Bakka filaadhu",
    "no_slots": " Bakka kanatti {{location}} iddoo ga`aan hin jiru",
    "choose_appointment": "Sa`aatii bellamaa filaadhu {{location}}",
    "appointment_required": "Sa`aatii bellamaa filaadhu {{location}}",
    "phone_number_required": "Lakkaofsi bilbilaa guutame dogongora",
    "phone_number_label": "Lakkofsa bilbilaa ergaa barrefamaa dafee fudhachuu danda`u guutaa ",
    "symptoms_experiencing": "Mallatolee  amma isinitti dhaga`ama jiruu irraatti mallattoo godhaa,yoo mallatoleen isinitti dhaga`ama hin jirre, itti fufaa",
    "household": "Miseensota maatii",
    "household_p1": "Filannoodhaaf, miseensa maatii dhaaf bellama qabaa,kan qorannoo geggefachuu barbaadu yoo jiraate",
    "add_dependent": "Miseensa maatii itti dabaluf",
    "remove_dependent": "miseensa gargarsa barbaadu keessa haquuf",
    "dependent_consents": "Walii galtee",
    "submit": "Xumuuri",
    "add_waitlist": "Galmee eggatotaa tti dabali",
    "address": "Bakka mana jireenyaa",
    "address_1": "Kallattii mana jirenyaa 1",
    "address_2": "lakkofsa bakka bellama",
    "address_city": "Magalaa",
    "address_state": "Nannoo",
    "postal_code": "laakofsa Postaa",
    "race_ethnicity": "Qomoo",
    "gender": "Salaa",
    "self_described_gender": "Salaa ittin of ibsitu",
    "interpreter": "Nama afaan hiikuu hin barbaadda? eyyee yoo jettee,Afaan kam?",
    "consent_to_terms": "seera kanatti waaliif galeera",
    "reg_not_open": "Galmeessuu hin eegallee",
    "no_more_avail_spots": "Sa`atii isin filattan hin jiru, irraa deebi`aa yaala",
    "consent_helper": "cuuqasaa , maa`usii dhaan harkisaa sanduqaa keessatti mallatessuu dhaaf",
    "phone_number_unreachable_label": "lakkofsa bilbilaa kan manaa",
    "select": "Filaadhu",
    "test_surveys": "Gaffilee  bellamaa",
    "edit": "Gulaala",
    "continue_to_registration": "Galmee itti fufi ",
    "accounts": {
      "already_have_an_account": "Siluma iyyu abbummaa nan qaba",
      "log_in": "Seeni",
      "sign_up": "Haaraa naaf bani",
      "sign_up_description": "Abbummaa haaraa banachuuf odefannoo kee guuti, galmee itti aanuuf yeroo kee qusaachuf",
      "log_in_description": "Imeelii keef icitii darbinsaa guuti yookan karaa gugilii ykn outlook jala jiruun",
      "sign_in_with_label": "Kana waliin seeni {{provider}}",
      "password_must_match": "Galmeen iciitii keetii wal fakkachuu qaba",
      "password_uppercase": " Galmeen iciitii keetii yoo xiqqate mallatoolee irraa  (%s) kana qabachuu qabu",
      "password_lowercase": "Galmeen iciitii keetii yoo xiqqatee  mallatoolee jalaa (%s) qabachuu qabu",
      "password_number": "Galmeen iciitii keetii lakkofsa(%s) qabachuu qaba",
      "password_special": "Galmeen iciitii keetii mallatolee (%s) addaa qabachuu qabu"
    },
    "password": "Galmee iciitii ( paswoordii)",
    "password_confirmation": "Galmee iccitii mirkanessaa",
    "consent_for": "Walii galteen kun {{name}}",
    "book_one_time_appointment": "Bellama si`a tokko qabadhu",
    "duplicate_users": {
      "exists": "Dura iyyuu galmooftee jirta",
      "overlapping_email_and_phone_p1": "Ergaa lakkofsa bilbilaa keetif imeelii kee irraatti erginee jirra",
      "p2": "Ergaa kee fayyadamtee, bellama kee jijjiruu fi bu`aa qorannoo kee guutuu dandessaa",
      "overlapping_phone_and_email_p3": "Hubannoo dabalataf lakkofsa bilbilaa yookan imeelii keessan ilalaa",
      "overlapping_email_p3": "Hubannooo dabalataf imeelii kessan ilaala",
      "overlapping_phone_p3": "Hubannoo dabalataf  bilbilaa kessaan ilaala",
      "overlapping_phone_p1": "Ergaa bilbila keessan irraatti erginerraa",
      "overlapping_email_p1": "Ergaa imeelii kessan irraatti erginerra",
      "p4": "Yoo dogongora jettee yaddee, aisiin nu quunnami support@primary.health",
      "overlapping_email_and_phone_p3": "Hubannoo bal`aa dhaaf bilbila kee yookan imeelii kee ilaali"
    },
    "duplicate_waitlist": {
      "exists": "Galmee eggatotaa tti dabalamtee jirta",
      "overlapping_email_and_phone_p1": "lakkofsa mirkaanessaa bilbilaa kee fi imeelii kee irratti erginee jirraa",
      "p2": "Lakkofsa mirkanessaa kee fayyadami,odefannolee dabalataa yookan galmee eggatotaa kessaa of ballessi",
      "overlapping_phone_and_email_p3": "Hubannoo dabalataf bilbila kee yookan imeelii kee ilaali ",
      "overlapping_email_p3": "Hubannoo dabalataf imeelii kee ilaali",
      "overlapping_phone_p3": "Hubannoo dabalataf bilbila kee ilaali",
      "overlapping_email_p1": "Mirkanessaa kan biraa imeelii irraatti erginerraa"
    },
    "insist_guardian_consent": "Walii galtee kana, kanan bakka bu`ee dhiyessaa jiruu ",
    "confirmation_section": {
      "title": "ilaali mirkaanessi ",
      "p1": "Odefannolee kee sirritti  ilaalii utuu galmee kee hin xumurin dura",
      "edit_information": "Boddaatti deebi`i, guulali"
    },
    "iemodal": {
      "title": "Iyyafatni kun hin hoojetu",
      "body": "Galmeen amma iyyafta kana irratti hin hoojjeta"
    },
    "show_other_locations": "iddoo biraa argisisuuf itti bu`i",
    "non_us_address": "Kun tessoo addunyaawa dha.",
    "test_group_user_survey": "gulaallii hirmaatotaa",
    "self_consent": "Ani ofii koon walii galaa jira",
    "address_country": "Biyya",
    "appointment": "Beellama",
    "employee_id": "Lakkofasa enyumma hojjeta ",
    "appointment_time": "Yeroo Beellama",
    "appointment_location": "Iddoo",
    "phone_or_email": "Lakkofsa bilbilaa yookin Emelii",
    "no_self_consent": "Eyyama ofiikoof nan haqa",
    "no_insist_guardian_consent": "Eyyama bakka {{full_name}} bu'ee nan haqa",
    "additional_consents_helpertext": "Eyyamoota adda bira booda gaafatamu ni danda'u",
    "minimum_age_with_months": "Da'da ugu yar ee ka qaybqaadashadu waa {{year}} sano iyo {{month}} bilood.",
    "assistive_technology": "Teekinoolojiiwwan gargaaran",
    "covid_vaccine_y_n": "Beellama kana waliin talaalii Korona ni barbaadu?",
    "received_covid_vaccine_y_n": "Kana dura talalii korona fudhateeta?",
    "covid_vaccinations": "Taliila Korona",
    "select_vaccine_dose": "Talaalii hamma fudhachu barbaadan filadha",
    "demographic_info": "Odeffannoo demoografii",
    "additional_info": "Odeffannoo dabalataa",
    "self_described_race": "Sanyii ittiin of-ibsatan",
    "verify_contact_information": "Mirkaneessuu",
    "verify_contact": {
      "we_sent_you_a_code": "Mee koodii gara {{contact}} tti erginee galchi.",
      "confirmation_code": "Koodii mirkaneessaa",
      "code_resent": {
        "email": "Koodiin biraa gara email keessaniitti ergameera",
        "phone_number": "Koodiin biraa bilbila keessanitti ergameera"
      },
      "did_not_receive_code": "Koodii mirkaneessaa hin arganne?",
      "verify_by": {
        "email": "Bakka isaa imeelin mirkaneessi",
        "phone_number": "Bakka isaa lakkoofsa bilbilaatiin mirkaneessi"
      }
    },
    "preferred_method_of_communication": {
      "question": "Mala walqunnamtii filatattan",
      "helper_text": "Odeeffannoo beellama keessani isinitti himuuf isin qunnamna",
      "phone_number": "Bilbila (Ergaa ergaa gabaabaa qofaafi tilmaamni daataa hojiirra ooluu danda’a)",
      "landline": "Yeroo kanatti bilbilaan isin qunnamu hin dandeenyu. Yeroo isin barbaanutti akka isin qunnamnuuf Imeeli keessan nuuf kenna",
      "verification_method": "Koodii mirkaneessuu keessan akkamitti argachuu barbaadda?",
      "verification_helper_text": "Osoo hin seenin dura eenyummaa kee mirkaneessuuf koodii siif ergama."
    },
    "skip_for_now": "Ammaaf darbi",
    "default_information": {
      "title": "Tole, teessoo kana fayyadamna",
      "title_v2": "Toofta, odeeffannoo quunnamtii kana ni fayyadama"
    },
    "middle_name_label": "Maqaa giddu",
    "confirm_appointment": "Beellama ke mirkaneessi",
    "dob": "guyyaa dhalootaa",
    "different_appointment": "Beellama adda",
    "select_appointment_time": "Yeroo beellama filadhu",
    "decline_address": "Teessoo kiyya kennuu nan barbaadu. Bakka teessoo dhaabaticha fayyadamaa.",
    "patient_information": "Odeeffannoo nama wal'aansa barbaadu",
    "scan_license": "Hayyama konkolaachisummaa iskaani godhi",
    "how_to_contact": "Akkamitti isin qunnamuu dandeenya?",
    "decline_email_or_phone": "Imeelii ykn lakkoofsa bilbilaa kiyya kennuu hin barbaadu. Bulchitoonni dhaabbaticha bu'aa wal'aaansako akka naaf fudhaan heyyama nan kennafi.",
    "medical_screening": "Sakatta’iinsa yaalaa",
    "show_service_at_this_location": "Bakka kanatti tajaajila agarsiisi",
    "verbal_consent_provided": "Akkaataa seera qaama miidhamtoota Ameerikaatiin, hirmaataan ykn murteessaan isaanii seeraan hayyamame hayyama afaaniin kenneera. Hayyamni barreeffamaa hirmaataa ykn murteessaa isaanii seeraan hayyamame irraa sa’aatii 24 keessatti ni argama.",
    "unexpired_written_consent_present": "Saayitiin qorannoo hayyama barreeffamaa yeroon isaa hin xumuramne faayilii irratti qaba.",
    "select_to_proceed": "Mee itti fufuuf filannoo armaan olii filadhu.",
    "guardians_information": "odeeffannoo maati yaakan eegdu",
    "registrars_information": "Odeeffannoo Galmeessaa",
    "optional": "Filanno yookan drqama jan hin taane",
    "vfc_eligibility": "Talaallii Daa’immaniif (VFC) ulaagaa guutuu",
    "vfc_eligibility_subtext": "Mucaan keessan talaallii baasii malee karaa Sagantaa Talaallii Daa’immaniif (VFC) CDC’n waajjiraalee dhiyeessitoota eegumsa fayyaa, manneen qorichaa fi kilinika fayyaa Sagantaa VFC keessatti galmaa’an keessatti argachuu danda’a."
  },
  "gender_key": {
    "male": "Dhiira",
    "female": "Dhalaa",
    "cisgender": "saala dhalaten adda od baasu",
    "non_binary": "saala dhalateen nama adda of hin basne",
    "other": "kan biraa",
    "prefer_to_self_describe": "kana kessaa hin jiru",
    "prefer_not_to_disclose": "deebii keennu hin barbaadu",
    "transgender_male": "Gara dhiraatti kan jijjirame",
    "transgender_female": "Gara dhalaatti kan jjiramte",
    "unknown": "Kan hin beekamne",
    "non_binary_only": "Saal-malee",
    "intersex": "Saal-lamee",
    "transgender": "Saal-faallee",
    "gender_fluid": "Sanyii jijjiiramaa",
    "not_applicable": "Hin malleeffatu"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Hindii dhalataa Ameerikaayookan dhalataa Alaskaa",
    "american_indian_central_america": "Hindii dhalataa ameerikaa kibbaa irraa yookan giddu galessaa ameerikaa",
    "asian": "Eshiyaa",
    "black": "Gurrachaa yookan gurracha ameerikaa",
    "latinx": "Latinoo yookan spaanishii",
    "middle_eastern_north_african": "Giddu galessa bahaa yookan kaaba afriikaa",
    "native_hawaiian_pacific_islander": "Dhalataa Hawaii yookan islandii pasiifikii",
    "white_european": "Adii yookan caucasianii",
    "unknown": "Kan hin beekamnne",
    "prefer_not_to_disclose": "Himuu dhisuun filadha",
    "asian_indian": "Hindii eshiyaa",
    "filipino": "Fiilipinsi",
    "japanese": "Japanisii",
    "korean": "Koriyaani",
    "vietnamese": "Vetnamisii",
    "other_asian": "Eshiyaa kan biroo",
    "native_hawaiian": "Dhalataa Hawaiianii",
    "guamanian_or_chamorro": "Guamnianii yokaan chamorro",
    "samoan": "Samoanii",
    "other_pacific_islander": "Odola pasifikii irra kan jiraatu",
    "chinese": "chayinisi",
    "help": "Qomoon kan gargaaruf adda addumma qamota adda adda gidduu jiru adda baasufi, qomoon lammummaa biyyaa wajjin tokko ta`uu dhisuu danda`a.",
    "subtitle": "Odefannolee kana gadii hunda akka walitti qabnuf seera tu nurra barbada",
    "laotian": "Laotioni",
    "cambodian": "Canbodiani",
    "other": "Kan biraa",
    "bangladeshi": "Baangilaadishi",
    "hmong": "Himongi",
    "indonesian": "Indoneezhiyaan",
    "malaysian": "Maleeziyaan",
    "pakistani": "Pakistaani",
    "sri_lankan": "Srilankaan",
    "thai": "Taayilaandi",
    "taiwanese": "Taayiwaani",
    "fijian": "Fiijii",
    "guamanian": "Guwaam",
    "tongan": "Toongaan"
  },
  "languages": {
    "en": "Ingiliffaa",
    "fr": "Faransayiffaa",
    "es": "Spanishii",
    "so": "somaaliffa",
    "hmn": "Hamongiffaa",
    "asl": "Afaan mallattoo Ameerikaa",
    "kar": "Kareeniffa",
    "am": "Amaariffa",
    "ru": "This is not Oromo language",
    "om": "Oromiffaa"
  },
  "symptoms": {
    "fever": "Ho`aa qaama yookan qorraa ",
    "cough": "Qufaa",
    "muscle_aches": "Dhukkubbii maashaalee",
    "severe_fatigue": "Akka malee dadhabbiin sitti dhaga`amuu",
    "trouble_breathing": "Hargansuu dadhabuu",
    "diarrhea": "Garaa kaasaa",
    "loss_of_smell": "waa suufuu dadhabuu",
    "loss_of_taste": "Waa dhandhamuu dadhabuu",
    "shortness_of_breath": "Hargansuu dadhabuu yookan rakkachuu",
    "headache": "Mataa dhukkuubbii",
    "sore_throat": "Utalloo dhaan qonqoo cuufame",
    "congestion": "Fuurrii sitti bayi`suu",
    "nausea": "Balaqamsiisuu",
    "close_contact": "Nama dhukkuban qabame wajjin wal xuqtee bektaa?",
    "helper_text": {
      "close_contact": "Nama dhukkuba covidii dhaan qabamee wajjin wal xuqtee bektaa? "
    },
    "suspected_exposure": "Mallatolee saaxilamuu",
    "none": "Mallatolee hin qabu"
  },
  "instructions": {
    "title": "Galmoofteetta gaaruu osoo demiin dura ...",
    "p1": "Fuulli kun odeffannolee  bellamaa kee  akkasumas lakkofsa Barkoodii kee qaba",
    "appointment_scheduled": "Bellamni kee qabameera",
    "verify_info": {
      "title": "Odeffannolle kee mirkaanessi",
      "p1": "Karaa itti si qunnamnu mirkanessuu qabda, sensaa nama dhukkubsataa daftee argachuu akka dandessuf",
      "p2": "Mirkanefannon imeelii kee irraatti ergamera.Hidhaa jiruu kana xuqaa",
      "p3": "Karaa itti si qunnamnu jijjiruu yoo barbadde, Qabduu kana xuqaa"
    },
    "verified": "Mirkaana`eera",
    "not_verified": "Hin mirkaanofnne ",
    "resend_text": "Hidhaa yookan geessituu hin argannee?",
    "resend": "irraa deebiin ergi",
    "skip": "Ragaasisa irraa darbi fi mirkaanessaa natti argisisi",
    "name": "Maqaa",
    "appointment_details": "Beellama",
    "date_and_time": "Guyyaa fi sa`aatii",
    "add_to_calendar": "Kalandarii tti dabali",
    "instructions": "Ajaja walii galaa",
    "successfully_flash": "bellamni qabattee milkaa`era",
    "success_flash_email": "Imeeliin Milkaa`ee ragaasifamera",
    "success_flash_phone": "Lakkofsi bilbilaa milkaa`ee ragaasifamera",
    "mail_order": "Meshaa qorannoo yeroo torbaan tokko kessaatti siif ergina",
    "at_home": "Ogeessi yaala  kan keenu mana kee hin dhufa, nannoo sa`atii kanaa",
    "at_home_instructions": "Ajaja Manaatti",
    "mail_order_instructions": "Ergaa dabaree ajaja",
    "request_additional_appointment": "bellama hordoffii qabadhu",
    "book_additional_appointment": "Bellama biraa qabadhu",
    "confirmation_code": "koodii mirkaanessaa",
    "completed": "Xumurameera",
    "appointment_barcode": "Barkoodii Bellamaa",
    "dependent_links": "Gessituu bellamaa maatii",
    "on_demand_title": "Galmooftetta gaaruu utuu deemin dura",
    "save_link": "gessituu kana olkaa`i",
    "verify_phone": "Lakkofsa bilbilaa kee mirkaneessi",
    "verify_phone_description": "Karaa itti si qunnamnu raggaasuu qabda ,odefannoo kees salphatti argatta. ergaa gababan mirkanessu lakkofsa bilbilaa kee irraatti ergama",
    "verify_email_description": "Karaa itti si qunnamnu raggaasuu qabda ,odefannoo kees salphatti argatta. ergaa gababan mirkanessu Imeelii kee irraatti ergama",
    "information": "Odeefannoo galmee",
    "follow_up_appointment": "hordoffii bellamaa",
    "get_directions": "Kallaattii argadhu ",
    "cancel": "Bellama haqi",
    "reschedule_appointment": "Bellama irra deebin qabadhu",
    "reschedule_linked_appointments": "Bellama irra deebin qabadhu",
    "no_slots": "Sa`atii biraan hin jiru",
    "check_results": "Portaalii dhukkubsataa ilali",
    "follow_up_modal_header": "Maaloo doozii lammaffa dhaaf bellama qabadhaa",
    "are_you_sure_you_want_to_cancel": "Dhuguma bellama kana haquu barbaadda?",
    "please_choose_cancellation_reason": "Maaloo sababa bellama haqaa jirtaniif waan asii gadi jiruu kana kessa filaadhaa",
    "additional_details": " gadi fagenyaan waan dabaltan asitti guuta",
    "errors": {
      "missing_cancellation_reason": "Maaloo sababa haqaa jirtanif filaadha"
    },
    "verify_email": "Imeelii kessaan mirkanessaa",
    "redcap_url": "marsuu kessan xumuruuf kana xuqaa",
    "verify_contact_information": "odeeffannoo qunnamtii kessan  mirkaanessa",
    "please_also": "Maaloo isaas",
    "new_title": "Mirkaneessi {{name}} tiif.",
    "contact_method": "Akkamiti isin qunnamu dandeenya",
    "next_steps": {
      "title": "Tarkaanfiiwwan itti aanan",
      "p1": "Meeshaa talaalli qorannoo hojiirra oolchuuf qabdu qabda. Yeroo qoranno wal'aansa keessani fudhachuuf qophooftan, gara fuula kanaatti deebi’uuf liinkii imeeli keesatti ergame hordofuun kitii yookan meesha qoranno keessan armaan gadii akka hojechu jalqabu godhaa.",
      "administered": "Yeroo kittiin keessan mana yaalaa gahu fi yeroo bu'aan qoranno keessani qophaa'u isin qunnamna. Bu’aan isaa yeroo baay’ee erga mana yaalaa ga’ee booda guyyoota 3-5 fudhata. Bu'aa fuula [Medical history]({{link}}) kee irraa yeroo qophaa'u ilaaluu dandeessa.",
      "resulted": "Bu'aan [Galmee yookan Seenaa Fayyaa keessan]({{link}}) kee irratti argama.",
      "shipping_information": "Odeeffannoo ergaa"
    },
    "save_this_page": {
      "title": "Fuula kana save godhaa",
      "p1": "Fuula kana fayyadamuun of qorachuu gabaasuu ykn bu'aa qoranno seenaa yaalaa keessani ilaaluu ni dandeessu.",
      "p2": "Gara fuulduratti fuula kana argachuuf linkii email mirkaneessaa ykn barreeffama SMS keessan keessa jiru cuqaasaa.",
      "p3": "Fuula kana mallattoo itti gochuudhaan, iskiriinii jalqabaa irratti dabaluudhaan ykn hidhaa sana gara bakka nageenya qabutti waraabuudhaan qusachuu dandeessa."
    },
    "show_my_barcode": "Barkoodii koo agarsiisi",
    "my_account": "Akkaawuntii koo",
    "register_another": "Hirmaataa biraa galmeessi",
    "update_vaccine": "Odeeffannoo talaallii haaromsi",
    "medical_history": "Galmee yookan Seenaa Fayyaa",
    "upcoming_appointments": "Muudama dhufu",
    "reschedule": "Irra deebiin saganteessi",
    "resend_confirmation": "Mirkaneessa irra deebi'ii ergi",
    "appointment_details_v2": "wa'ee bellama keessani gadi fageenyan",
    "confirm_cancellation": "Beellamake haquu mirkaneessi",
    "confirm_cancellation_question": "Beellama kana haquu akka barbaaddu mirkaneeffatteettaa?",
    "select_new_appointment_time_below": "Yeroo beellama haaraa armaan gadii filadhu. Tajaajila ykn bakka beellama haara yoo barbaadde, beellama kana haqiitii beellama haaraa beellamadhu.",
    "no_take_me_back": "Lakki duddubatti na deebisi",
    "yes_cancel_appointment": "Eeyyee, beellama haqi",
    "update_appointment": "Beellama haaromsi",
    "select_new_appointment_time": "Yeroo beellama haaraa filadhu",
    "clinic": "Kilinika",
    "services": "Tajaajila",
    "appointment_missed": "yeroo beellamni irrati hin argamne",
    "appointment_canceled": "Beellamni haqi"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "koodii Fashalaa yookan kan hin hojjennee"
    },
    "landing": {
      "p1": "Bu`aa kee ilaaluuf,galchi:",
      "access_code_label": "Koodii gahiinsa",
      "submit_button": "Galchi",
      "recent_results": "Bu`aa kee yeroo dhihootti"
    },
    "view_results": {
      "headline": "Bu`aa qorannoo {{name}}",
      "reregister": "Irraa debiin galmaa`i",
      "status": {
        "likely_positive": "Bu`aan hin beekamne",
        "test_not_performed": "Irraa deebiin qorannoo geggesssu barbaachisa",
        "results_ready": "Bu`aan qorannoo baa`era",
        "processing": "Adeemsa",
        "test_status": "Sadarkaa qorannoo",
        "test_result": "Bu`aa qorannoo",
        "administered": "Geggefamera/ godhamera",
        "results_pending": "Bu'aan qoranno kessani eega jira",
        "test_results": "Bu'aa qoranno"
      },
      "at": "{{address}}"
    },
    "result_label": "Bu`aa",
    "result": {
      "flu_a": {
        "result_label": "qufaa A bu`aa qorannoo"
      },
      "flu_b": {
        "result_label": "qufaa B bu`aa qorannoo"
      },
      "covid19": {
        "result_label": "Bu`aa Qorannoo Covidii 19"
      },
      "covid": {
        "result_label": "Bu`aa Qorannoo Covidii 19"
      },
      "sars": {
        "result_label": "Bu`aa qorannoo SARS"
      },
      "control": {
        "result_label": "Too`annaa"
      },
      "rsv": {
        "result_label": "Bu'aa RSV"
      },
      "result": {
        "result_label": "Bu'aa"
      },
      "hba1c": {
        "normal": "Normalii dha yookan ammo dhibbe irra bilisa",
        "warning": "Dhukkuba sukkaaraa duraa",
        "danger": "Dhukkuba sukkaaraa"
      },
      "lead_venous": {
        "danger": "Kan hin baramne",
        "normal": "Normalii dha yookan ammo dhibbe irra bilisa"
      },
      "tc": {
        "result_label": "Bu'aa TC"
      },
      "hdl": {
        "result_label": "Bu'aa HDL"
      },
      "trg": {
        "result_label": "Bu'aa TRG"
      },
      "ldl": {
        "result_label": "Bu'aa LDL"
      },
      "non_hdl": {
        "result_label": "Bu'aa HDL Hin Taane"
      },
      "tc_hdl_ratio": {
        "result_label": "Reeshiyoo TC/HDL"
      },
      "glu": {
        "result_label": "Bu'aa GLU"
      },
      "alere_cholestech_ldx": {
        "danger": "Riiski Irra Jira",
        "warning": "Kan hin baramne",
        "normal": "Normalii dha yookan ammo dhibbe irra bilisa"
      },
      "lead": {
        "result_label": "Lead yookan ammo rakkoo onnee fi ujummoolee dhiigaa fi miidhaa tiruu."
      },
      "zinc": {
        "result_label": "Ziinkii"
      },
      "lead_standard_profile": {
        "danger": "Kan hin baramne",
        "normal": "Normalii dha yookan ammo dhibbe irra bilisa"
      },
      "creatinine": {
        "danger": "Kan hin baramne",
        "normal": "Normalii dha yookan ammo dhibbe irra bilisa"
      },
      "igg": {
        "result_label": "Bu’aa IgG"
      },
      "igm": {
        "result_label": "Bu’aa IgM"
      },
      "blood_glucose_fasted": {
        "normal": "Normaali",
        "warning": "Damee",
        "danger": "Damee",
        "hypoglycemia": "Dhibee sukkaaraa gadi bu’aa",
        "prediabetes": "Durra sukkaaraa",
        "diabetes": "Dhukkuba sukkaaraa"
      },
      "total_cholesterol_fasted": {
        "normal": "Normaali",
        "elevated": "Damee",
        "high": "Damee",
        "low": "Hidhaa",
        "abnormal": "Almakkaam"
      },
      "total_cholesterol_unfasted": {
        "normal": "Normaali",
        "elevated": "Damee",
        "high": "Damee"
      },
      "a1c_now": {
        "normal": "Normaali",
        "warning": "Prediidaabeetis",
        "danger": "Daabeetis"
      },
      "blood_glucose": {
        "warning": "Gad",
        "normal": "Dhaabbataa",
        "prediabetes": "Duratti Dhukkuba Sukkaaraa",
        "danger": "Dhukkuba Sukkaaraa",
        "hypoglycemia": "Dhibee sukkaaraa gadi bu’aa",
        "medical_emergency": "Rakkoo fayyaa",
        "inconclusive_1": "Murtii hin dhaabbanne 1",
        "inconclusive_2": "Murtii hin dhaabbanne 2",
        "possible_diabetes": "Dhukkuba sukkaaraa kan ta’uu malu"
      },
      "triglycerides": {
        "result_label": "Tiraayigiliisaayidii"
      },
      "blood_glucose_ucsf": {
        "low": "Xiqqaa",
        "normal": "Sirrii",
        "high": "Olka’aa"
      },
      "syphilis": {
        "result_label": "Bu'aa Sifilisis"
      },
      "hepatitis_c": {
        "result_label": "Bu'aa Hepatitis C"
      },
      "hiv": {
        "result_label": "Bu'aa HIV"
      }
    },
    "documents": "Ragaa , Galmee",
    "self_administered": "Ofii koo of sakkatta`uf deeme furadhe {{location}}",
    "patient": "Dhukkubsataa",
    "medical_history": "Galmee yookan Seenaa Fayyaa",
    "overview_title": "Bal'ina dabalataa fi wantoota gocha dabalataa barbaachisan kamiyyuu ilaaluuf talaalli ykn tajaajila kan jedhu filadhu. Bu’aan qorannoo fayya tokko tokko ammallee argamuu dhiisuu danda’a yookan ammo erga qaama dhimmisa ilaalatu wajjin erga maree gootanin booda ni mul'ata",
    "insurance_information": "Odeeffannoo inshuraansii guuti",
    "contact_support": "Yoo gargaarsa barbaaddan qaama dhimmisa ilaalatu qunnamaa.",
    "show": "Agarsiisi",
    "hide": "Dhoksi",
    "lab_report": "Gabaasa laabraatoorii",
    "contact_provider": {
      "header": "Bu'aan qoranno keessani poozetividha",
      "description": "Bu’aa fi yaala kee irratti mari’achuuf ogeessa fayyaa waliin haasa’uu ni barbaaddaa?",
      "yes_option_text": "Eeyyee, ogeessa fayya waliin haasa'uu na barbaada",
      "no_option_text": "Lakki, bu’aa qorannokoo dubbisee hubadheera, ogeessa fayya waliin hassa'u hin barbaadu",
      "confirm_phone_number_header": "Lakkoofsa bilbila keessanii mirkaneessi",
      "confirm_phone_number_description": "Lakkoofsa bilbilaa marii keessaniif gaarii ta'e mirkaneessaa.",
      "confirm_button_text": "MIrkaneessi",
      "consultation_confirmed_header": "Mariin taasisuuf mirkanaa'eera",
      "consultation_confirmed_description": "Dhiyeessaan tyookan hoggensi fayya guyyoota hojii 2-3 keessatti {{phone_number}} irratti siif bilbila.",
      "acknowledgement_option_helper_text": "wanta nuti ifa isiniif gochuu barbannu yoo yaada kessan jijjirtani tajaajila wal'aansa argachu barbaadanitti akkamitti akka bilbilatii beellama qabachudandeessanidha ",
      "acknowledgement_confirmed_header": "Akka walii galtan mirkaneesitanittu",
      "acknowledgement_confirmed_description": "yeroo baay'ee dhabbata fayye gaari ta'etti waal'aan argachuun fayyidale inni qabu kessa inni tokko  yerro marii yookan ammo consultation kaffalti maale argattu akkasumas dhibee kessanif wal'aan barbaachisa ni argattu",
      "acknowledgement_confirmed_change_mind_text": "Yoo yaada kee jijjiirte, “Marii nan barbaada” kan jedhu cuqaasu ni dandeessa",
      "request_consultation_button_text": "Marii nan barbaada"
    },
    "phone": "Bilbila",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Tessoon kee seera qabessummaa dhaaf ulagaa barbachisuu wajjin wal hin fudhatu sagantaa kana kessatti hirmaachuf"
    }
  },
  "member": {
    "medical_history": "Seenaa",
    "view_instructions": "dhugoomsa arguuf",
    "next_appointment": "Bellama itti aanu",
    "over_18": "umurii koo 18 yookan isa  ol akka ta`e nan mirkanessa",
    "choose_test_configurations_title": "Maloo bellama kana irraatti  tajajila barbaddan filadhaa ",
    "member_taken_error": "Namni fayyadama jiru kun abbummaa qaba",
    "choose_test_configurations_subtitle": "Tajaajjilli kun missensa maatii hunddaf kan ta`uudha",
    "service": "Tajaajilaa",
    "group_or_location": "Gaaree/iddoo itti argamaa",
    "details": "Bal`inaan",
    "see_all": "hundaa arguuf",
    "no_history": "Yeroo kanatti seenaan galmee'e hin jiru.",
    "medical_history_title_with_name": "{{name}} seenaa",
    "no_dashboard": "Yeroo ammaa kana wanti argamu hin jiru",
    "product": "Firii",
    "price": "Gatii",
    "quantity": "Baayyina",
    "total_services_selected": "Tajaajila waliigalaa",
    "total_price": "Gatii waliigalaa"
  },
  "or": "yookan",
  "account": {
    "errors": {
      "must_be_13": "Abbumma banachuu dhaaf umuriin 13 ol ta`uu qaba"
    }
  },
  "self_administration": {
    "title": "Ofiin of bulchuu",
    "self_administer_action": "Ofiin of bulchuu",
    "skip_to_link": "Gara ofiin of bulchutti darbi",
    "select_person": "sakkata`insaaf nama tokko filadhaa",
    "adult": "Dargageessa",
    "child": "Mucaa",
    "checked_out": "sakkata`insaa",
    "go_back": "Duddubatti deebi'i",
    "switch_to_scanner": "Gara meesha iskanii godhutti nannessi",
    "enter_barcode": "Baarkoddi itti galchi",
    "scan_barcode": "Baarkoddi Iskaaani godhi",
    "cancel": "Ballessi",
    "barcode_for": "Baarkoddi...dhaaf",
    "enter_barcode_manually": "Baarkodhicha barrefaam galchi ",
    "instructions": "Qoranno  tiyubi gajjala kana jiru irrati barkodi gaalchi yookan ammo iskaani godhi",
    "regex_prefix": "Baarkoddin dirqama wanta inni qabaachuudha qabu",
    "all_completed": "xumurteetta: matiin hundi tajaajila kanaaf galmaa'ani jiran sadarkalle xumuramuudha qabu hunda xumuraniruu.",
    "errors": {
      "no_appointment": "Rakkoo beellama qabachuu dhabuu",
      "barcode_in_use": "Baarkoddiin kun kanaan dura fayiida irra oolera. kanaan dura abba meeshaka kana you hin ture ta'e adara nama wanta kana \r\nilaallatu wajiin hasa'i isaan wanta barbaachisu kan bira sif kennu danda'u yookan ammo kan gajjala jiru gargaarsa kan jedhu xuqi",
      "test_error_general": "Qoranno rakkina kana fiduu danda'e",
      "different_barcodes": "Baarkoddi ati galchite sirri miti"
    },
    "confirm_barcode_input": "Mirkaneessudhaf baarkoddi irra deebi'iti yaali",
    "click_to_self_test": "Qoranno matakke yoo godhachuu barbaadde kana tuqi ",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "galmee eggatotta",
      "unavailable": "Bellamni hin jiru"
    },
    "labels": {
      "address": "Tessoo",
      "next_appointment": "Bellama itti aanu",
      "services": "Tajaajila jiru"
    }
  },
  "waiting_room": {
    "header": "Kutaa tessee eggattu kessa jirta, haga nuti si qunnamsisnutti obsaan eeggadhaa",
    "p1": "Maaloo obsaa ,dhukkuba dadarbaa kana waliin balleessina",
    "signature": "Kabajaan",
    "p2": "Bekna muxannon dhukkuba koovidi -19 nama arsa.maaloo nu eegi  haga dandenyu ariitin gara bellama bakka qabattutti si gessuf hojjechaa jirra"
  },
  "users": {
    "send_appointment_confirmation_message": "Akkam {{full_name}}.kun bellama kee si yadachisuuf,bakka {{name}}. bellamni kee guyyaa {{date}} sa`atii {{time}}bakka {{address}}.",
    "send_registration_link": "Akkam {{full_name}}. hidhaa kana hordoofi galmaa`i {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "Doosii jalqabaa",
    "previous_appointment": "Bellama darbe",
    "booked_appointment": "Bellama kee",
    "second_dose": "Doosii lammaffaa",
    "choose_second_location": "bakka bellama kee lamaffaa filadhu"
  },
  "cancellation_types": {
    "cant_get_to_location": "Yeroodhan argamuu hin danda`u",
    "timing_conflict": "Koovidii -19 dhukkubeen jira",
    "became_ill_with_covid19": "Tajajila kana iddoo biraatti argadhera",
    "received_service_elsewhere": "Kan biraa",
    "other": "Iddosaa maqaa barreessi",
    "duplicate_appointment": "bellamni kun kanan duraa qabameraa"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Maqaaa barressii",
      "type_name": "Bakka isaa mallattoo mallatessi"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Lakkaddaa gahiinsa keetii",
      "please_confirm_your_email": "Maaloo imeelii kessaan mirkaanessuf geessituu kana xuqaa",
      "hello": "Akkam",
      "click_to_reschedule": "Qajeelfamoota arguuf kana xuqaa / bellama irra deebi`uf",
      "click_to_reschedule_on_demand": "Qajeelfamoota arguuf kana xuqaa"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Beellamni kee {{yeroo}} {{bakka}} irratti haqameera. Yoo kun dogoggora ta'e, email nuqunnamu dandeessu support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Maqaa hirmaatotaa",
    "date": "Guyyaa",
    "signature": "Mallattoo Hirmaatotaa",
    "and_or": "Fi/ yookan",
    "guardian_name": "Maqaa maatii/ guddisee",
    "guardian_signature": "Mallattoo maatii/ guddisee"
  },
  "employer_testing": {
    "hello_name": "Akkam {{name}}",
    "results": {
      "negative": "Negaativii",
      "positive": "Pozativii",
      "did_not_result": "Hin beekamne",
      "unknown": "Hin beekamu ",
      "invalid": "Fashala",
      "pending": "kan hin xumuramne"
    },
    "code_reader": {
      "scan_your_code": "kardii qorannoo kee irraa koodii jiru iskaanii godhi",
      "find_in_top_right_hand_corner": "Koodiin iskaanii ta`u kan kee qofadha,kan inni argamu gara olii harka mirgaa qorannoo kee irrattiidha",
      "center_code": "walakkaa koodii kunooti",
      "already_used_error": "Barkoodiin kun kanan dura fayyadamamera,nama meshaa qorannoo kana kennu gargarsaaf gafadha",
      "click_to_scan": "as xuuqaa QR koodii iskaanii gochuuf",
      "scan_new_test_card": "kardii qorannoo iskaanii gochuuf asitti bu`i",
      "not_working": "Hojjeechaa hin jiruu?",
      "try_again": "Irraa deebi`itanii yaaluf as xuqaa"
    },
    "continue": "Itti fufi",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Itti fufuuf Bara dhaloota nama hirmaatuu mirkaanessa",
      "contact_administrator": "Odeffannon isinif dhufe kun dogongora yoo ta`e ,bulchinsa sysytemii qunnama",
      "error": "Barri dhaloota galchitan dogongora- irraa deebi`aa yaalaa yookan Bulchinsa sysytema dhihenyatti jiru qunnama"
    },
    "result_entry": {
      "code_is_registered": "Koodiin keessan galmaa`era.",
      "take_the_test": "Qorannoo furaadha.",
      "follow_the_instructions": "Qajeelfamoota meeshaa qorannoo keessanii wajjin dhufu hordofaa, erga waan qoratamu dhangala`aa funyaanii meshaa sana irratti naqtanii booda daqiqaa kudha shan lakka`aa",
      "cant_find_instructions": "Qajeelfamoota sana hin dhabdanii?",
      "view_instructions": "Binaaxii  ilaala qajelfamootas",
      "start_timer": "daqiqaa 15 lakka`uu jalqabaa",
      "submit_within_15": "Dabalataf: Bu`aa qorannoo kessaan daqiqaa kudha shan kessaati ergaa",
      "enter_test_results": "Bu`aa qurannoo galchi",
      "choose_a_result": "meeshaa qoranno kee itti bahinsa isaa filaadhu",
      "submit": "Galchi",
      "must_log_result_and_photo": "Bu`aa qorannoo kee suuraa kaasi itti fuufi",
      "submit_within_15_v2": "Dabalataf: Bu`aa qorannoo kessaan daqiqaa kudha shan erga qorannoo geggessitanii booda ergaa",
      "click_to_take_photo": "suuraa kaasuf asitti bu`aa",
      "results_may_be_invalid": " Bahiin Qorannoo kun sirrii ta`uu dhisuu mala"
    },
    "summary": {
      "title": "Cunfaa bahii",
      "negative_subtitle": "Kovidii-19 hin qabdu",
      "negative_message": "Dhabbata isin qacare tti beeksisa erginee jirra akka isin qulqulluu tatan hojitti deebi`uu akka dandessaan",
      "positive_subtitle": "Hin gaddina Kovidii-19 qabdu",
      "positive_message": "Akka isin dhukkuba kovidii-19 qabdan dhabbata hoojii kessanitti ergaa erginerraa",
      "what_should_you_do": "Wanti isin gochuu qabdan:",
      "employer_name_resources": "Maqaa qabeenya dhabbataa:",
      "follow_cdc_guidelines": "Qajeelfamoota CDC akka hordoftan isin gorsina"
    },
    "clarifying_results": {
      "title": "Bu`aa qorannoo ibsaa",
      "which_did_your_result_most_look_like": "bu`aan qorannoo keessan kamiin fakkata?",
      "no_lines": "Sararri hin argamu",
      "blue_control_line_only": "Sararri  too`annaa cuquliisa qofa",
      "pink_sample_line_only": "Fakkenya sararri diimine  qofa",
      "blue_control_line_and_pink_sample_line": "Sararri too`annaa cuquliisa fi Fakkenya sararri diimine  ",
      "still_not_sure": "haga amma sirritti hin beeku",
      "submit_results": "Bu`aa galchi",
      "thank_you": "Galatooma!",
      "clarification_received_message": "Ibsi bu`aa qorannoo keetii nu ga`eera.",
      "only_pink_line": "Hallu Bildiima qofa",
      "no_lines_v2": "sarara duwwa"
    },
    "return_to_confirmation": "Fuula mirkanessuutti deebi`i"
  },
  "preferred_language": {
    "title": "Afaan fayyadamu barbaddan",
    "subtitle": "Afaan kam filatta?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Toora tajaajila vaaksiini",
    "one_dose": "Bellama doozii addaa qabadhu",
    "title": "doozii filaachuu",
    "first_dose": "Doozii jalqabaa",
    "second_dose": "Doozii lammaffa",
    "single_dose_title": "Doozii tokko",
    "additional": "dabalata",
    "booster": "Faayida yookan humna dabalataatiif wanta tajaajilu ",
    "supplemental": "Dabalataan",
    "third_dose": "Marsaa sadaffaa qofa",
    "no_vaccine": "Kanneen armaan olii keessaa tokkollee hin jiru"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "Formaatii kana qabachu qaba {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "Formaatii kana qabachu qaba {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "Qoraanon hin jiru"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "Fayyadaman hin jira , Jijjiruun hin danda`amu"
            },
            "date_of_birth": {
              "invalid_date": "Formaatii kana qabachu qaba {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} as kessa hin jiru, warra as keessa jiran kessaa filadhu {{ethnicities}}",
                "other": "{{unknown_ethnicities}} as kessa hin jiru, wantota jiran kessaa filadhu {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "Salli kun hin jiru ,warra jiran kana kessaa filadhu{{genders}}"
            },
            "phone_number": {
              "invalid": "Lakkofsi kun sirrii miti"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} as kessaa hin jiru,warra as kessa jiran kessaa tokko filadhu{{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "Yoo salli ati ittin of ibsitu hin jiranne itti hin guutin"
            },
            "sex": {
              "unknown_sex": "Salli kun hin jiru ,warra jiran kana kessaa filadhu{{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "Eyyumessaa saala kun as kessa hin jiru,warra jiran kessaa tokko filadhu:{{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "Eyyumessaa saala kun as kessa hin jiru,warra jiran kessaa tokko filadhu: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} qomoon kun hin jiru,warra jiran kessaa filadhu:{{races}}",
                "other": "{{unknown_races}} qomoon kun hin jiru,warra jiran kessaa filadhu:{{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "Mirga ga`aa hin qabdan kana godhuuf"
            },
            "user_id": {
              "unknown_user": "Fayyadamaan kun hin jiru"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON kun sirrii miti"
            },
            "user_id": {
              "unknown_user": "Fayyadaman kun hin jiru"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "Bakki bellama kun hin jiru ykn hin argamu"
            },
            "date_of_birth": {
              "invalid_date": "Formaatii kana qabachuu qaba {{date_format}}"
            },
            "email": {
              "duplicated": "Itti fayyadamamera"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} kun hin jiru,warra jiraan kessaa tokko filaadha{{ethnicities}}",
                "other": "{{unknown_ethnicities}} kun hin jiru,warra jiraan kessaa tokko filaadha{{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "Salli isin filatan hin jiru,warraa jiran kessaa filaadhu:{{genders}}"
            },
            "phone_number": {
              "invalid": "Lakkofsi kun sirrii miti"
            },
            "population": {
              "unknown_population": "{{unknown_population}}kun hin jiru, warra jiran kessaa filaadhu:{{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "saala kee ati ittin of ibsitu hin jiru tanan hin guutin"
            },
            "sex": {
              "unknown_sex": "saalli ati filaatte hin jiru,warra jiran kessaa tokko filadhu:{{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "Salli ittin of ibsitan hin jiru, warra jiran kessaa filadhu:{{sexes}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} qomoon kun hin jiru,warra jiran kessaa filadhu:{{races}}",
                "other": "{{unknown_races}} qomoon kun hin jiru,warra jiran kessaa filadhu:{{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "Tessoon kun kanan dura fayyadamameera"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Spaanishii yookan Latinii",
    "not_hispanic": "Spaanishii miti yookan Latinii",
    "hispanic_expanded": {
      "other": "Spanishii kan biraa,Latinii yookan Spanishii ",
      "mexican": "Dhalataa meksikoo,Meksikoo Ameerikaa,chicano/a",
      "puerto_rican": "Dhalata puerto rican",
      "cuban": "Dhalata kuubaa"
    },
    "subtitle": "Oddefannoo kana akka walitti qabnuf seeratu nu ajaja",
    "help": "Qomoo yookan sanyii tokkoo jechuun: affan tokko dubbachuu,aadaa tokko qabachuu,Amantii fi amaloota warra kan irraa adda baasu ummata qabuu jechuudha.",
    "unknown": "Hin beekamu",
    "title": "Gosaa"
  },
  "sex_at_birth": {
    "question": "saala",
    "female": "Dhalaa",
    "male": "Dhiira",
    "help": "Salli kee wanta waraqaa ragaa dhaloota kee irraatti barrefameedha",
    "non_binary": "Lamanuu kan hin taane",
    "subtitle": "Oddefannoo kana akka walitti qabnuf seeratu nu ajaja",
    "decline": "Deebii kennuu hin barbaadu",
    "unknown": "Hin beekamu"
  },
  "gender": {
    "help": "Enyumessa saala jechuun akkata namni itti of adda baasu dhiraa ykn dhala jechuudha.kan ittin dhalattan irraa adda bau`uus hin danda`a",
    "subtitle": "oddefannoo dabalata waa`ee enyumessaa salaa kee kennu yoo barbaade.barressi"
  },
  "sexual_orientation": {
    "title": "Enyumessaa saala",
    "subtitle": "oddefannoo dabalata waa`ee enyumessaa salaa kee kennu yoo barbaade.barressi",
    "gay": "saadoomii yookan luuxii ",
    "heterosexual": "Saala fallaa isaa wajjin kan walqunnamtii godhu",
    "bisexual": "Saala lama warra qaban",
    "questioning": "Hin beeku/gafachaan jira/",
    "prefer_not_to_disclose": "Himachuu hin barbaadu",
    "unknown": "Hin beekamu",
    "orientation_not_listed": "salli na ibsu as kessa hin jiru",
    "not_applicable": "Hin malleeffatu",
    "pansexual": "Panseksuwaal",
    "queer": "Kuweer"
  },
  "pronouns": {
    "title": "Isa moo ishee jedhamu filatta?",
    "he": "Isa / inni",
    "she": "Ishee/isii",
    "they": "Isaan/isaani",
    "choose_not_to_disclose": "Himaachuu hin barbaadu",
    "prefer_to_self_describe": "Of ibsuun barbaada"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Eyyama konkolachisummaa/ lakkofsa waraqaa enyummaa ",
    "driver_license": "Eyyama konkolachisummaa",
    "use_ssn_instead": "Iddosaa SSN fayyadami",
    "social_security_number": "Lakkofsa nageenyumma hawasaa",
    "state": "Nannoo",
    "upload_front_of_driver_license": "fuulaa eyyama konkolachistummaa yookin waraqaa enyummaa ol fe`i",
    "choose_file": "faa`ila  kessaa filadhu",
    "no_file_chosen": "faa`ila hin filannee",
    "no_identification": "Waraqaa enyummaa hin qabu",
    "insurance_card_has_a_back": " kardii inshuransii Dubee qaba ",
    "upload_front_of_insurance_card": "Kardiin inshuransii kee ol fee`i",
    "front_of_insurance_card_uploaded": "kardiin inshuransii keetii ol fee`amera",
    "insurance_card": "Kardii inshuransii",
    "insurance_card_back": "Duubeen kardii inshuransii",
    "back_of_insurance_card_uploaded": "Duubeen kardii inshuransii keetii olfe`amera"
  },
  "quidel": {
    "certify_text": "Bu`aa qorannoo galchaa jiru kana nan mirkaanessa",
    "entering_results": "Bu`aa qorannoo galchaan jira",
    "review_instructions_1": "Ajaja jiru of eggannoo dhhan ilaali",
    "review_instructions_2": "ajaja jiru ilaluu dandessaa kan qorannoo kee waliin dhufee jechuudha. tokko laman erga dubbistee hordofte booda, ajaja vidiyoo asii gadii kana ilali",
    "read_instructions": "ajaja kana dubbisi",
    "watch_video": "Vidiyoo kana ilaali",
    "timer": "erga dhanga`alaa sana meshaa qorannootti naqtee booda,kan siif lakka`u daqiqaa tokko booda dhabbata( dhanga`alaa sana gadi baasufi meesha kee kessaa).hubadhu:daqiqaa kan siif lakkoftu jalqabsisi ti daqiqaa 10 eegi bu`aa qorannoo keetif",
    "start_timer": "lakkoftuu daqiqaa kee jalqabsisi",
    "restart_timer": "Dhabi fi irra deebiin jalqabi",
    "resume": "Itti fufi",
    "one_minute_countdown": "Daqiqaan 1 darbeera! meeshaa qorannoo kee kessaa dhanga`alaa jitu gadi naqi, lakkoftuu daqiqaa kee itti fufi",
    "take_photo": "suuraa tamsasa qorannoo kee kaasi",
    "photo_warning": "suuraa barbachisu dhiyeessun itti fufufi",
    "enter_results": "Bu`aa qorannoo kee galchi",
    "choose_result": "tamsasa qorannoo kee akka gaariitti ibsuu kan danda`u as kessa filaadhu,gargarsa barbaadda?iddo kana xuqi fakkaenya bu`aa qorannoo jiran ilaali",
    "positive": "Pozativii",
    "negative": "Negaativii",
    "unknown": "Hin beekamu",
    "clarify": "Bu`aa qorannoo kee ibsi",
    "strip_question": "Isa kamtu Tamsasa qorannoo kee caala fakkata?",
    "no_lines": "Sarara hin qabu",
    "pink_line": "sarara diminee duwwaa",
    "not_sure": "Haga ammatti hin beekne"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Deebi agatte suura isaa ",
    "retake_photo": "suura irra deebi'i kaasi",
    "capture_photo": "Suura kaasi",
    "confirm_information_correct": "Odeeffannoo armaan gubba jiru kun sirri ta'uusa nan mirkanessa",
    "submit": "Mirkanessi",
    "title": "Bu'aa yooakn deebi galchiif {{name}}",
    "subtitle": "Appilikeshinii  iHealth korona variyasi fayyadamun yookan ammo\n dawunloodi gochuun sif hin barbaachisu ",
    "instruction_title": "Akkata fayyadama iHealth ilaali",
    "instruction_guide": "Akkata fayyadama isaa dubbisi",
    "video_guide": "Vidiyoo kana ilaali",
    "add_photo": "Suura irratti galchi",
    "confirm_result": "Bu'aa argatte sirri ta'usa mirkanessi",
    "result_warning": "Bu'aa argatte mirkanessuken dura bu'a argame xuqi ",
    "confirm_warning": "bu'aa argatte mirkaneesuken duraa sirri ta'uusa ilali",
    "administered_at_label": "Qoormata kana yoom fudhatte",
    "instruction_subtitle": "Akkatan fayyadama isaa qoormata wajjin sidhufe jira kanafuu akkata fayyadama isaa ilaali"
  },
  "public_test_groups": {
    "title": "Meesha korana variyasi qabamuuf qabamu dhiisu ittin ilaalan",
    "appointment_recoveries_title": "Deebisu",
    "appointment_recoveries_button_text": "Liinkin as jira kuno",
    "search_test_group_title": "Yeroo jalqabaa",
    "search_test_group_button_text": "Amma galmaa'i",
    "title_2": "Galmee salphaafi deebi argame",
    "step_one": "Akkawunti banachuu",
    "step_two": "Deebistee ergudhaaf sampili gorora qopheesi",
    "step_three": "Bu'aa argame oolaini ilaali",
    "title_3": "Meesha qo'anno kessan aktivi gochuuf asirratti galmaa\"a",
    "population_title": "galmee",
    "population_button": "Aktivi godhi",
    "faq_subtitle": "Sirriti hubadhuu",
    "faq_title": "Gaafille yeroo heddu gafataman",
    "faq_1": "Meesha qoranno akkamittan deebisu danda'a",
    "faq_1_footer": "Akkata ittin saampili gorora fuudhan ilaali hubadhu",
    "faq_2": "Galmee akkawunti banadheera garuu nan irranfadhe",
    "faq_3": "Meesha qorannoko akkamittan aktiivi gochu danda'a",
    "faq_2_subtitle": "Akka nama deebi'e fayyadama jiruutti bu'aa argattan '**Liinkin as jira kuno**' kan jidhu kan gubba jiru cuuqasudhan ilalu ni dandessu",
    "faq_3_subtitle": "Filaanowwan kana gaditti jiran fayyadamudhan akkata fayyadama tajaalila fayya sadarka jalqaba ilaalu ni dandessa",
    "footer_text_1": "Tajaajilli kin FDA dhan hin mirkanoofne hata'uu malee wanta atatama yokaan ammo imerjensidhaaf yoo barbaadde FDA karaa EUA fayyadamu ni dandessa",
    "footer_text_2": "Tajaalili kun nuklic asidi SARS-CoV-2 irra adda basuuf kantajajiru qoranno gororaa sasabufi tajaaludhaf malee dhukkuba gara gara kan namattti fidu miti",
    "footer_text_3": "Tajaajila kana yeroo rakko qaqabutti akka hojirra ooluf kan eyyamu tajaajila nyaat federalaa,qorichaa fi kan midhaniiga seera 564(b)(1)  seera kana fayyadamudhan yerro rakkini muddama qababutti qofa tajajilarra akka oolu kan eyyamudha. USC § 360bbb-3(b)(1)፣ labsiin yookan eyyamni tajajila yoo addan cite irran kan hafe.",
    "description": "Akka isiniif tolutti meesha qoranno kessan akka hojii jalqabuuf kan gajjala jiru kana xuqudhaan aktivi gochu ni dandessu",
    "subtitle": "Yeroo jalqabaa lammata irratti",
    "subtitle_1": "Wa'ee meesha bu'aa qoranno korona vayirasii",
    "faq_1_1": "Namuuna yookan ammo bu'a qorannotif kan qopha'e meesha borsa boyoo kessa galchi",
    "faq_1_2": "Meshaa yookan borsaa bayoo dayirektara gorora kan jehame keessa galchi.dayirektara gorora kan jedhame kana gara saaxini FedEx UN 3373 Pak jedhamutti galchi.",
    "faq_1_3": "Kan FedEx UN 3373 Pak jedhamu qadadisaa irra baasudhan sirritti cuufudhaf gadii dhiibi",
    "faq_1_4": "Namuuna keessan fudhachaaf guyyan tokko yoogga hafuu akkasumas guyya fudhattan sanatti gadi buusa.sanbatafi dilbata yookan ammo qiidame fi sandaba namuuna keessan adara gara saaxinitti hin butina nanno sanarra fagessa",
    "faq_1_5": "Websayiti FedEx jedhu sirriti ilali  **[fedex.com/labreturns](https://www.fedex.com/labreturns)**  akkasumas sagantakefi iddo iti drop boksin argamule asidha",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "Bu`aa Qorannoo ",
    "report_result_subtitle": "Yoo dhabbatni kee Akka of sakkata`atu siif eyyame , bu`aa qorannoo keetii beeksisuu yoo barbaddee mallattoo kana gadi jirutti xuqi",
    "need_help": "Gargarsa barbaaddaa?",
    "assistance": "Deggersa yoo barbaddee, yookin karaa itti si qunnamnu jijjiruu yoo barbadde garee deggersa qunnami",
    "contact_support": "garee deggersa kennu qunnamuu",
    "save_link": "Hidhaa kana ol naaf kaa`i booda ittin bu`aa qorannoo koo gabasuuf",
    "send_via_email": "Hidhaa kana karaa email ergi",
    "send_via_text": "Hidhaa kana karaa ergaa gababa ergi",
    "copy_link": "hidhaa kana garagalchi",
    "resend_email": "irraa deebiin email mirkanessituu ergi",
    "phone_verified": "Bilbilli kun mirkanefameera",
    "add_vaccination_status": "  Kittibatii covidii 19 Dabali/ harressi ",
    "terms_of_service": "seerota Tajaajilaa",
    "verify_contact_information": "akka bu`aa qorannos tae nuu wajjin dubbachuu dandessuf Karaa itti si qunnamnu nuuf mirkaanessi, yoo jijjiruu barbaddef immo garee deggersaa kenyaa qunnami",
    "resend_text": "ergaa gababaa mirkanessituu irra deebin ergi",
    "loading": "Fe`aa jira",
    "add_to_account": "Eenyummessaa tti dabali",
    "hi": "Akkam",
    "email_verified": "Emeeliin mirkana`eera",
    "go_to_my_account": "Eenyummessaa kootti na geessi ",
    "activate_kit": "Meesha qorana yaliif eegalsiisa",
    "report_custom_result": "Deebii isaa gabaasa: {{test}}",
    "activate_custom_test": "Yaalii eegalsiisa {{test}}",
    "update_vaccination_status": "Bu`aa Talallii kee",
    "order_a_test_kit": "Baallee qorannoo ajaji"
  },
  "enter_information": {
    "contact_info": "Lakkofsa bilbilaa keessan galchaa",
    "label": "Bilbila yookin Imeelii",
    "invalid": "Imeelin kun sirrii miti yokin bilbilli kun ",
    "send": "Koodii karaa bilbilaa yookin Imeelii  ergina",
    "legal": "Lakkofsa bilbilaa kee yookin imeelii kee galchuudhaan seeraa Tajajilaa jalqabaa Fayyaa kenyatti malii galuu kee mirkanessita **[Terms of Service](https://primary.health/terms-of-service/)** and **[Privacy Policy](https://primary.health/privacy-policy/)**",
    "legal_and": "and",
    "terms_of_service": "Terms of Service",
    "privacy_policy": "Privacy Policy",
    "subtitle": "Galmee keessan yookaan bu'aa gabaasuuf wal'aansa kessan barreeffama yookaan Imeelin isiniif ergina!",
    "find_link": "Galmoofteetta? Link kees asitti argama",
    "not_registered": "Hin Galmoofne",
    "search_by_keyword": "Jechaan, magaalaan, teessoo yookin koodii dhaabbataan barbaadi",
    "search": "Barbaadi",
    "register_here": "Asirratti galmaa'aa"
  },
  "contact_support": {
    "invalid": "Odeeffannon kun dogongora",
    "invalid_explanation": "Dhihenya kana hidhaa tajajila fayya jalqaba kenya seenuf gafattee turte. gaaruu,",
    "never_registered": "Galmoodtee hin beektuu? Liinkii galmee argachuuf kennaa tajaajila qorannoo kee dubbisi, yookaan ammoo daandii armaan gadii hordofi",
    "get_help": "Deggersa argachuu dandessaa kuusaa keetif tessoo qunnamtii kee waliin",
    "get_help_calling": "Yookin bilbili",
    "invalid_explanation_bold": "Odeeffannon kun dogongora",
    "get_help_label": "Gargaarsa argadhu",
    "mobile": {
      "invalid_explanation": "Fayyadamaa kan biraan Eyyumessaa keetti dabalamu hin jiru",
      "get_help": "Gaffii dabaltaa yoo qabattee , nu qunnami",
      "reason": "hunduu dursa miti:  hordoffii fayyaa kan hordofan eyyummessa banachuu danda`u ,si`a tokko mirga isa argannan,nu biraa xalayaa afferraa Primary health biraa si ga`a."
    },
    "zen_name": "maqaa keessan",
    "ticket_type": "Kun waa'ee maaliiti",
    "appointment_access_code": "Koodii Mirkaneessaa (yoo beekame) .",
    "zen_desc": "Ergaa gababa",
    "contact_us": "Nu qunnama",
    "need_assistance": "Yoo gargaarsa barbaaddan, yookan lakkofsa bilbla keessan jijjiruu yoo barbaadan nu qunnamu dandeessu."
  },
  "show_appointments": {
    "welcome": "Profaayila kee barbaadi",
    "welcome_subtitle_1": "Bu'aa armaan gadiitti agarga jirta",
    "added_to_account": "Abbumma Enyumessaa itti dabali",
    "error": "Namni Hirmataa jiru kun dursee iyyuu Enyummessaa qaba.",
    "welcome_subtitle_2": "Armaan gaddi maqaake hin argine?",
    "welcome_not_logged_in": "Liinki gaga websayitichati si geessu ",
    "welcome_logged_in": "Garee kee filadhu",
    "welcome_subtitle_1_logged_in": "Miseensa dabalte  xumuruuf, fayyadamaa fi garee fili. Miseensonni garee tokko  akkaawuntii tokkotti ramadamu.",
    "welcome_subtitle_2_logged_in": "Yoo miseensi kee armaan olitti hin tarreeffame ta'e, ni dandeessa",
    "welcome_subtitle_1_not_logged_in": "Eenyu akka gareeketti ramadamu filadhu yookan ammo miseensa haaraa itti dabali. Miseensa hin arganne?",
    "welcome_subtitle_3_logged_in": "mala qunnamtii adda ta’e yaali",
    "welcome_subtitle_4_logged_in": "Yookan ammo kanaan nuqunnamu dandeessa",
    "welcome_subtitle_2_not_logged_in": "Barbaacha haaromsi.",
    "welcome_subtitle_3": "Barbaacha kee haaromsi"
  },
  "enter_code": {
    "code": "Koodiin Maali?",
    "enter_code": "Koodii sana galchi",
    "incorrect_code": "Koodii dogongoraa Galchite",
    "verify": "Eenyummessaa kana mirkanessi",
    "verified": "Mirkanaa`era",
    "verify_account_information": "Akkaawuntii kee mirkaneessi"
  },
  "general_self_checkout": {
    "choose_test": "Qorannoo kamiin godhatte?",
    "photo_required": "Suuraa Bu`aa qorannoo barbachisa",
    "find_your_test": "Qorannoo kee barbaadi"
  },
  "login": {
    "create_account": "Eenyumessaa Tolchi",
    "create_account_help": "Akkaawuntii banachuun galmaa'uu yookin talaallii fudhachuu wajjin tokko miti. Erga galmooftee booda",
    "create_account_help_schedule": "Bellama Qabadhu",
    "create_account_help_record": "manatti isa  qoratamtii galmessi",
    "create_account_help_match": "Galmee qorannoo fi talaalli  taaligi",
    "continue": "Keessummaa dhumaan itti fufi",
    "already": "Eenyummessaa duraan Qabdaa? cuuqaasi",
    "login": "seenuuf",
    "or_sign_in": "Yookin seenuuf kana wajjin",
    "no_account": "Eenyumessaa hin qabduu? cuuqasi",
    "signup": "Banachuuf",
    "here": "Asuma",
    "email_address": "email kee",
    "password": "Iggitaa",
    "complete_registration": "Galmee Xuumuri",
    "last_covid_test": "Bu`aa Qorannoo Covidii 19 kan yeroo dhumaaf",
    "no_record": "Galmen hin jiru",
    "viewing_information": "Ilaali {{first_name}} odeefannoo",
    "download": "Buusi",
    "not_registered": "Gaaree kamiin kessatti iyyuu waan galmoofte miti",
    "please_follow_link": "maaloo hidhaa  imeelii keessan irraa kana hordofuudhaan bellamaaf oddefannoo fayyaa keessani wajjin",
    "log_results": "dilba bu`aa qorannoo koo",
    "book_appointment": "Bellama Qabadhu",
    "no_new_appointments": "Dhihenya kana Bellama haaraa fudhachaa hin jirru",
    "upload_vaccinations": "Bu`aa Talaallii ol fee`i",
    "vaccination": "Talaallii",
    "new_appointment": "Bellama haaraa",
    "log_otc_results": "Bu`aa qorannoo OTC seeni ilali",
    "no_household_members": "Missensaa maatii ammaf hin qabdu",
    "no_upcoming": "Bellama hin qabdu",
    "update_vaccination": "Talaallii haareessi",
    "upload_vaccination": "Talaalli ol fe`i",
    "title": "Baga nagaan gara Primary.Health dhuftee",
    "sub_title": "Before registering please create an account.",
    "p1": "Enenyumessaa banachuun sidandeesa:",
    "p2": "Bellama gara fuula duratti qabadhu",
    "p3": "Galmee bu`aa qorannoo covidii 19 manatti godhamee",
    "p4": "Galmee Taalallii fi Qorannoo miseensota maatii hoggani",
    "p5": "my.primary.health  seenuuf",
    "no_vaccination_record": "Talaalliin vaaksiini hanga ammaatti hin dabalamne",
    "vaccination_title_card": "Haala vaaksini korona vayiirasi fudhatanittumo hin fudhanne",
    "account_and_settings": "Akkawuntifi seetiingi",
    "delete_account": "Akkawunti ballessi",
    "language": "Afaan",
    "language_current": "Afaan amma fayyadama jirtu : {{language}}",
    "log_out": "Keessa Ba'i",
    "delete_account_title": "Akkaawuntii keessan haquu akka barbaaddu mirkaneeffatteettaa?",
    "new_encounter": "{{first_name}}'s  Qunnamtii haara",
    "new_encounter_subtitle": "Qunnamtiin haaraan kun garee kamiif?",
    "no_programs": "Sagantaa kamiyyuu irratti waan hin galmoofne fakkaata",
    "edit_members": "Miseensoota gulaali yookan ammo eediiti godhi",
    "edit_members_subtitle": "Daashboordii kam keessa deemuu akka barbaaddu filadhu ykn miseensa biraa akkaawuntii kee irratti dabaluu barbaaddu",
    "add_members": "Miseensa dabali",
    "delete_member": "Ballessi {{full_name}}'s nama akkawunti kana fayyadamu?",
    "delete_member_subtitle": "Akkawunti kana haquun odeffanoo akkawunti kana waliin walqabatan hunda ni haqa.",
    "select_member": "Filanno miseensa",
    "edit_appointment": "Yeroo belammake Filadhu",
    "route": {
      "me": "Daashboordii",
      "history": "Seena",
      "programs": "Qoranno dabali",
      "contact_us": "Gargaarsa",
      "choose_member": "Miseensa",
      "settings": "Seetiingi",
      "help": "Gargaarsa"
    },
    "user_dashboard": "{{first_name}}'s Daashboordii",
    "view_full_results": "Bu'aa qorannoke poortali irra deemi ilaali",
    "required": "Appii jalqabaa fayyadamta taanaan barbaachisaa dha",
    "no_email": "Iimeelii hin qabu"
  },
  "vaccination_status": {
    "not_vaccinated": "Hin taalalamne",
    "fully_vaccinated": "guutuutti Talaalamera",
    "partially_vaccinated": "walakkaa Talaalamera",
    "vaccination_records": "Ragaa Talallii",
    "title": "Talaallii COVID-19 fudhatteettaa",
    "definition_title": "Ibsa haala talaallii",
    "definition_cdc": "Ibsa haala talaallii CDC",
    "definition_osha": "Ibsa haala talaallii OSHA",
    "definition": {
      "fully_vaccinated": "Ergan Pfizer-BioNTech yookin Moderna lama fudhadhee yookin talaallii Johnson and Johnson Janssen jedhamu tokko fudhadhee torbee lama booda",
      "partially_vaccinated": "Talaallii lama keessaa tokko qofan fudhadhe, yookn immoo talaallii lammataa ergan fudhadhee torbee lama hin guunne, yookin immoo talaallii jalqabaa Janssen kan Johnson and Johnson ergan fudhadhee torbee lama hin guunne",
      "not_vaccinated": "Talaallii COVID-19 homaa hin fudhanne",
      "fully_vaccinated_with_one_booster": "Ergan Pfizer-BioNTech yookin Moderna lama fudhadhee yookin talaallii Johnson and Johnson Janssen jedhamu tokko fudhadhee booda talaallii cimsituu fudhadheera",
      "fully_vaccinated_with_two_boosters": "Ergan Pfizer-BioNTech yookin Moderna lama fudhadhee yookin talaallii Johnson and Johnson Janssen jedhamu tokko fudhadhee booda talaallii cimsituu lammaffaa fudhadheera"
    },
    "label": {
      "fully_vaccinated": "Talaallii guutuu",
      "partially_vaccinated": "Talaallii walakkaa",
      "not_vaccinated": "Hin talaalamne",
      "fully_vaccinated_with_one_booster": "Talaallii guutuu + cimsituu",
      "fully_vaccinated_with_two_boosters": "Talaallii guutuu + cimsituu lama",
      "prefer_not_to_answer": "Deebisuu hin fedhu"
    },
    "progress_bar_title": "Seenaa talaallii COVID-19",
    "upload_record_card": "Kaardii talaallii COVID-19 kee ol-kaa'i",
    "show_example": "Fakkeenya agarsiisi",
    "hide_example": "Fakkeenya dhoksi",
    "take_photo": "Suuraa kaasi",
    "or": "Yookin",
    "upload_image_or_pdf_instead": "Fakkii/PDF isaa ol-kaa'i",
    "select_doses_received": "Talaallii COVID-19 fudhatte hunda tarreessi",
    "first_dose": "Talaallii jalqabaa",
    "second_dose": "Talaallii lammataa",
    "first_booster_dose": "Cimsituu jalqabaa",
    "second_booster_dose": "Cimsituu lammataa",
    "additional_dose": "Talaallii dabalataa",
    "first_dose_manufacturer": "Oomishaan tallaallii jalqabaa COVID-19 kee eenyu ture?",
    "first_dose_date": "Guyyaan talaallii jalqabaa kee yoom ture?",
    "second_dose_manufacturer": "Oomishaan tallaallii lammataa COVID-19 kee eenyu ture?",
    "second_dose_date": "Guyyaan talaallii lammataa kee yoom ture?",
    "first_booster_dose_manufacturer": "Oomishaan tallaallii cimsituu COVID-19 kee isa duraa eenyu?",
    "first_booster_dose_date": "Guyyaan talaallii cimsituu tokkoffaa kee yoom ture?",
    "second_booster_dose_manufacturer": "Oomishaan tallaallii cimsituu COVID-19 kee isa lammataa eenyu?",
    "second_booster_dose_date": "Guyyaan talaallii cimsituu lammataa kee yoom ture?",
    "additional_dose_manufacturer": "Oomishaan tallaallii dabalataa COVID-19 kee eenyu?",
    "additional_dose_date": "Guyyaan talaallii dabalataa kee yoom ture?",
    "completed_one": "Xumure",
    "completed_two": "Haala talaallii kee addeessuu keef galatoomi",
    "progress_bar_complete": "Xumura",
    "upload_image": "Suuraa olkaa'i",
    "retake_photo": "Suuraa biraa kaasi",
    "other": "Kan biraa",
    "remove_first_dose": "Seenaa talaallii tokkoffaa haqi",
    "remove_second_dose": "Seenaa talaallii lammataa haqi",
    "remove_first_booster_dose": "Seenaa talaallii cimsituu tokkoffaa haqi",
    "remove_second_booster_dose": "Seenaa talaallii cimsituu lammataa haqi",
    "remove_additional_dose": "Seenaa talaallii dabalataa haqi",
    "exemption": "Adda-baastuu qabdaa?",
    "exempt": "Adda-baastuu qaba",
    "not_exempt": "Adda-baastuu hin qabu",
    "enter_exemption": "Adda baastuu galchi",
    "upload_exemption_documentation": "Dookumantii adda-baastuu kee galchi",
    "remove_dose": "Talaallii dabalataa haqi",
    "continue": "Itti fufi",
    "enter_credentials": "Odeeffannoo kee galchi",
    "incorrect_credentials": "Odeeffannoo dogoggoraati, irra deebi'ii yaali maaloo",
    "add_photo": "Suuraa galchi"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Haloo {{name}}, akka isin bellama {{group_name}} keessan {{org_name}} waliin hin xumure argine ture. Bellama keessan tursifachuf, sadarkaa kaffaltii kana xumuruu qabdu. Kaffalticha xumuruuf liinkii kana irratti cuqaasa {{complete_url}}. Yoo beellama keessan haquu feetan, linkii kana irratti cuqaasa {{cancel_url}}. Galatooma"
    }
  },
  "otc": {
    "record_results_for_abbott": "Qabxii qorumsa BinaxNOW kee qoradhu galmeeffadhus",
    "record_results": "Qabxiikee qoradhu galmeeffadhus",
    "activate_a_kit": "Kan ittiin qoratan eegalsiisa",
    "activate_a_kit_for_test": "Kan ittiin qoratan eegalsiisa {{test}}",
    "todays_test_process": "Akkaataa qorumsaa har'a:",
    "date_of_birth_question": "Guyyaan dhaloota {{first_name}} yoomi?",
    "confirm_dob_to_continue": "Itti fufuuf guyyaa dhaloota nama hirmatu galchi:",
    "incorrect_dob": "Guyyaa dhaloota dogoggoora",
    "file_too_large": "Faayilii fe'aamu akka inni {{size}} gadii ta'uu mirkaneeffadha",
    "enter_valid_date_in_last_x_days": "Guyyaa sirrii ta'ee guyyoota {{n}} keessa galchi",
    "barcode_format_not_valid": "Akkaataan baarkodicha sirrii miti",
    "complete_button": "Xumuri",
    "next_button": "Itti aanee",
    "back_button": "Deebi'ii",
    "report_a_test": "Qorumsa gabaasii:",
    "x_%_completed": "{{n}}% guutuu",
    "answer_survey": "Gaffiiwwan qorannaf deebii kenna",
    "steps": {
      "answer_survey": "Deebii gaffii qoranna",
      "review_instructions": "Qajeelfamoota ilaali",
      "scan_code": "Koodii iskaanii godhi",
      "enter_time": "Sa'aatii galchi",
      "enter_result": "Deebii galchi",
      "take_photo": "Suuraa kaasi",
      "confirm_result": "Deebii mirkanneessi",
      "selest_test_kit": "Qorumsa filadhu",
      "enter_date": "Guyyaa galchi",
      "enter_date_and_time": "Guyyaa fi sa'aatii galchi"
    },
    "review_instructions": "Qajeelfamoota qorumsa ilaali",
    "follow_instructions_or_below": "Qajeelfamoota qorumsa kee waliin dhufan hordofii yookiin qajeelfamaa kana gadii irra deebiin ilaali.",
    "watch_video": "Vidiiyoo ilaali",
    "view_instructions": "Qajeelfamaa ilaali",
    "follow_instructions": "Qajeelfamoota qorumsa kee waliin dhufan hordofii.",
    "scan_code": "Koodii QR qorumsa iskaanii godhi",
    "qr_code_description": "QR koodiin qorumsicha kankee adda kan inni argamus gubbaa harka mirga qorumsakee irratti",
    "show_example": "Fakkeenya agarsiisi",
    "hide_example": "Fakkeenya dhoksi",
    "barcode_scanned": "Baarkodichi sirri iskanii ta'eera",
    "enter_barcode": "Baarkodii galchi",
    "scan_qr_code": "QR koodii iskaanii godhi",
    "which_test_did_you_take": "Qorannoo kam fudhatani?",
    "when_did_you_take_test": "Yoomi kan qoranicha fudhatan?",
    "what_day_did_you_take_test": "Guyyaa gaafa meeqa fudhatan qorumsa kana?",
    "what_time_did_you_take_test": "Sa'aatii meeqatti fudhatan qorumsicha?",
    "time": {
      "today": "Har'a",
      "yesterday": "Kaleessa",
      "two_days_ago": "Guyyaa 2 dura"
    },
    "enter_current_time": "Sa'aatii amma galchi",
    "enter_result": "Deebii qorumsa galchi",
    "choose_result_option": "Filannoowwan jiran keessa kan kaardii deebii keeti ibsu filadhu:",
    "clarify_results": "Deebii kee ibsadhu",
    "take_result_photo": "Suuraa qabxiiwwani kaasi",
    "take_result_photo_description": "Qabxiiwwan keessan galmeeffachuf deebiiwwan xumuraman suuraa kaasa",
    "my_result": "Qabxii koo",
    "switch_camera": "Kaameera jijjirri",
    "confirm_result": "Deebii mirkanneessi",
    "name": "Maqaa:",
    "date": "Guyyaa:",
    "result": "Qabxii:",
    "results": "Qabxiiwwan keessan galmeeffachuf deebiiwwan xumuraman suuraa kaasa",
    "test_submitted": "Galatoomi, {{first_name}}! {{test}} keessan sirritti ergameera.",
    "dob": "Guyyaa dhaloota:",
    "administered": "Kan ittin buluu:",
    "or": "YOOKIIN.",
    "upload": "Itti fe'ii",
    "change_uploaded_file": "Faayilaa feete jijjirrii",
    "take_photo": "Suuraa kaasi",
    "confirm_information": "Odeeffannoo mirkanneessi",
    "barcode": "Baarkodii:",
    "scan_test_barcode": "Baarkoodii qorumsa iskaanii godhi",
    "barcode_description": "Baarkoodiin qorumsicha kankee adda kan inni argamus qorumsakee irratti",
    "enter_date_in_last_30_days": "Guyyoota 30 darban keessatti guyyoota fi tilmaaman ammo sa'aattii itti qorumsa kana fudhate galchi",
    "add_image": "Suuraa galcha",
    "change_image": "Suuraa jijjirri",
    "skip_barcode_reader": "Koodiicha iskaanii gochu hin dandeenye? Itti aanuu kan jedhu cuqaasun deebii qorumsa gabaasa",
    "enter_date_and_time": "Guyyaa fi sa'aatii qorumsa galchi",
    "enter_date": "Guyyaa qorumsa galchi",
    "did_you_take_test_today": "Har`a qorannoo geggefattee?",
    "record_results_for_generic": "wal'aansafi  bu'aa wan'aansake  {{name}}  ilaali & galmeessi",
    "choose_result_option_custom": "Bu'aa qoranno wal'aansakeeti kan ibsu {{test_name}} filadhu"
  },
  "yes": "Eyyee",
  "no": "Lakkii",
  "event_token": {
    "title": "Mashinii kana gadii irratti koodii keessan galcha meesha qoranaa fudhachuuf",
    "loading_text": "Fe'aati jira, daqiiqa booda ilaala"
  },
  "appointment_recovery": {
    "complete_title": "Xumurtaniittu!",
    "subtitle_1": "Yoo iPhone fayyadamaa jirtan ta'e, golee harka bitaa gubbaa screen kanaa irratti **Done** tuquun gara daashboard keessanitti deebi'aa.",
    "subtitle_2": "Yoo Android fayyadamaa jirtan **X** kan golee harka bitaa gubbaa screen kanaa jiru tuqaa.",
    "subtitle_0": "Itti fufuudhaf kan amma fayyadama jirtu cufiiti ba'ii"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Michooma fedhaa",
  "finish_registration": {
    "title": "Galmee xumuri",
    "subtitle": "Beellama kee dura oddeffannoo armaan gadii guuti",
    "button": "Galmee xumuri"
  },
  "arab_ethnicity": {
    "arab": "Araba miti",
    "non_arab": "Araba miti",
    "prefer_not_to_answer": "Deebisuu hin fedhu",
    "question": "Sanyii Arabaa",
    "subtitle": "Odeeffannoo armaan gadii akka funaannu seerri nu dirqisiisa",
    "help": "Sanyii jechuun wantoota hawaasa tokko wal-fakkeessan, kanneen akka afaan, aadaa, amantaa fi kanneen kana fakkaatan kan ittiin garee namootaa adda baasan ibsa. Gosaa wajjin wal-fakkaachuus wal-fakkaachuu dhiisuus ni danda'a."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Meeshaa keessan irratti hundaa'uun, Liinkii armaan gadii cuqaasuun Primary Health Pass buufachuu dandeessu."
    }
  },
  "stepper": {
    "next": "Fuulduratti",
    "back": "Boodatti",
    "complete": "Xumura"
  },
  "registration_direcory": {
    "keyword_search_label": "Jecha ijoo, magaalaa, koodii ziip, maqaa dhaabbataa",
    "keyword_search_label_mobile": "Magaalaa, koodii ziip, maqaa dhaabbataa",
    "zero_results": {
      "title": "Dhiyeessitoota Jecha barbaacha sana qaban hin arganne.",
      "sub_title": "Dhiyeessaa kee barbaaduuf yaali:",
      "p1": "Ziip koodii",
      "p2": "Maqaa magaalaa ",
      "p3": "Maqaa Biyya ",
      "p4": "Maqaa dhiyeessaa ykn dhaabbataa"
    }
  },
  "registration_flyer": {
    "open_camera": "Meeshaa keessan irra appilikeeshini kaameraa banaa",
    "point_to_qr_code": "Gara QR code akeekiiti liinkii sana tuqi",
    "complete_registration": "Galmee keessan Xumura",
    "need_help": "Gargaarsa barbaadduu? kanaan Nu qunnamuu dandeessu"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Ragaa odeeffannoo talaallii",
    "select_checkbox": "Sanduuqa filannoo cuqaasun dura kanneen armaan gadii siritti ilaaliitii filadhu.",
    "opportunity_to_read": "Ibsawwan Odeeffannoo Talaallii (“VIS”) ykn Hayyama Itti Fayyadama Yeroo Muddamaa (“EUA”) waraqaa dhugaa talaalliinwwan akka kennamuuf kenname dubbisuuf carraan naaf kennameera ",
    "vaccine_info_sheet": "waraqaa odeeffannoo talaallii",
    "vaccine_fact_sheet": "odeefanno waraqaa talaallii",
    "vaccine_info_statement": "ibsa odeeffannoo talaallii"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap jedhamuun beekama",
    "mmr": "MMR",
    "ipv_opv": "Pooliyoo",
    "hib": "Infuleenzaa haemophilus gosa b (HIB)",
    "hep_b": "Dhukkuba tiruu B (HEPB)",
    "var": "Vaariseelaa",
    "hep_a": "Dhukkuba tiruu A (HEPA)",
    "pcv": "ulfaatina seelii kuufame (PCV) .",
    "mcv": " Ulfaatina korpuskulaarii (MCV)"
  },
  "test_strip": {
    "A": "this kit includes tests for chlamydia and gonorrhea.",
    "B": "this kit includes tests for chlamydia and gonorrhea.",
    "C": "this kit includes tests for syphilis.",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "this kit includes tests for HIV and syphilis.",
    "box_type": "Box {{type}}",
    "administered_text": "We will contact you when your kit reaches the lab and when your results are ready.",
    "activate_kit": "Activate my test kit",
    "register_another_test": "Register another test",
    "credentials": {
      "title": "Let's get started",
      "label": "Contact",
      "p1": "We'll start with some info about you.",
      "location_step": {
        "p1": "Who provided your test kit?",
        "load": "Load more"
      }
    },
    "checklist": {
      "not_urinated": "You have not urinated in the last hour.",
      "bathroom": "You have access to a bathroom for urine collection.",
      "anal_swab": "You have access to a private place to perform an anal swab.",
      "blood_extraction": "You have access to a clean environment for blood extraction.",
      "washed_hands": "You have washed your hands with soap and warm water for 30 seconds.",
      "title": "Are you ready to begin sample collection?",
      "no_alcohol": "Do not drink alcohol on the day of collection.",
      "menstruation": "Do not collect vaginal swab specimen during menstruation or within 24 hours of intercourse."
    },
    "confirm": {
      "title": "Confirm your information",
      "p1": "Is this information correct?"
    },
    "display_name": {
      "A": "Chlamydia & Gonorrhea (Single Site)",
      "B": "Chlamydia & Gonorrhea (3 Site)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia & Gonorrhea",
        "B": "Chlamydia & Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "COVID-19",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV"
    },
    "rescan": "Rescan",
    "scanned": "You've scanned Box {{type}}",
    "not_yet": "Not Yet",
    "success_registration": "Registration success!",
    "no_box": "Don't have a box?",
    "faq_link": "Visit FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "You're one step closer to a healthier you.",
      "p1": "Scan or enter the **kit bar code** on the side of your box."
    },
    "appointments": {
      "title": "Select the patient & provider",
      "p1": "We found more than one profile associated with your contact information. Select the correct profile:",
      "new": "New patient or provider"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "We found your patient record."
    },
    "section_1": "Confirm kit contents",
    "section_2": "Test collection",
    "section_3": "Packaging",
    "section_4": "Shipping",
    "box_a": "Box A",
    "box_b": "Box B",
    "box_c": "Box C",
    "kit_flow": {
      "button_1": "Continue to packaging",
      "button_2": "I'm ready",
      "button_3": "My kit is complete and packed",
      "button_4": "I'm finished",
      "button_5": "Next test",
      "button_6": "Skip instructions"
    },
    "contents": {
      "header": "Great! Let's make sure your kit is complete",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Blood collection card",
      "blood_collection_card_subtitle": "(1 or 2 depending on test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Single use lancets",
      "up_to_other": "(Up to 4 depending on test kit)",
      "adhesive_bandages": "Adhesive bandages",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Biodegradable collection paper",
      "bottle_and_probe": "Sampling bottle and probe",
      "prepaid_envelope": "Pre-paid shipping envelope",
      "biohazard_subtitle": "(with absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Biohazard bags",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 sterile gauze pad",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "Packaging checklist",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "The specimen collection tube is sealed in its biohazard bag",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_b": {
      "step_1": "You have packed all test kit contents, even if you skipped the test(s)",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "Each specimen collection tube is sealed in its own biohazard bag (only **one** specimen per bag)",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_c": {
      "step_1": "You have packed all test kit contents, including used lancets.",
      "step_2": "Your **date-of-birth** is written on the blood collection card using MM/DD/YYYY format",
      "step_3": "The **collection date** is written on the blood collection card using MM/DD/YYYY format",
      "step_4": "The blood collection card and all used lancets are placed in the biohazard bag",
      "step_5": "The biohazard bag is placed into the box",
      "step_6": "The box is placed into the pre-paid shipping envelope",
      "step_7": "The return shipping envelope is completely sealed"
    },
    "success": {
      "header_1": "Great!",
      "text_1": "You're ready to collect your first sample.",
      "header_2": "You did it!",
      "text_2": "**Next up:** package your kit to send back.",
      "header_3": "Great job!",
      "text_3": "You’re one step closer to better health.",
      "header_4": "Nice job!",
      "text_4": "Just one more and you’re done!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "Select a test to view instructions:",
      "subtitle": "Test",
      "button": "Instructions",
      "pdf": "PDF instructions"
    },
    "instructions_box_a": {
      "title": "Urine collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
      "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
      "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
      "step_4_sublabel": "Do not fill tube past the maximum fill line or specimen will be rejected.",
      "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
      "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Urine collection",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
        "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
        "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
        "step_4_subtitle": "Do not fill tube past the maximum fill line or specimen will be rejected.",
        "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab into your mouth and rub the swab tip against the back of your throat for 10 seconds.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a blue <span style=\"color: #001F70\">**\"ORAL\"**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab 3 - 5 cm (1 - 2”) into the anal canal. Gently turn the swab for 5 - 10 seconds to collect any potential organisms.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a green <span style=\"color: #00C08C\">**“ANAL”**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      }
    },
    "instructions_box_c": {
      "title": "Blood collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your name, date of birth, and the date of collection in the designated fields. Use MM/DD/YYYY format.",
      "step_2": "Open blood card flap to expose the circles on the blood collection paper. Do not touch the blood collection paper.",
      "step_3": "Wash hands with warm water for at least 30 seconds, then shake hands vigorously for 15 seconds to encourage blood flow to your fingers.",
      "step_4": "Clean fingertip with alcohol pad. It is best to use the middle or ring finger of your non-dominant hand.",
      "step_5": "Take the lancet and twist off the cap. Press the small tip firmly into the tip of your finger, until the needle ejects with a click. Keeping your hand below your heart during collection, massage finger from base to tip to encourage blood flow.",
      "step_5_subtitle": "Lancets are single use. All lancets need to be returned with your sample to the laboratory for disposal.",
      "step_6": "Starting at the center, apply 3-6 drops to fill the circle and soak through the collection paper. Do not touch the paper with your finger as this will restrict blood flow. Once circle is full, move on to next circle. It is okay for blood to extend beyond lines, but do not let blood spots spread into each other.",
      "step_6_subtitle": "Do not add additional blood to a circle once completed or dry. The “layering” of blood will invalidate the collection.",
      "step_7": "Without closing the blood card, lay it on a flat surface and allow the blood collection paper to air dry at room temperature, for at least 30 minutes. Do not heat, blow dry, or expose the blood collection paper to direct sunlight. Heat will damage the specimen.",
      "step_7_subtitle": "Check the back side of blood collection paper. Blood should saturate all the way through and fill each circle of the collection paper.",
      "step_8": "When blood collection paper is dry, close blood card by tucking flap. Place the blood card and lancets into biohazard bag with the desiccant pack. Ensure biohazard bag is properly sealed."
    },
    "test_tips": {
      "title": "Tips for Proper Blood Collection",
      "subtitle": "For best results:",
      "step_1": "Be sure you are not dehydrated while performing collection. Hydration promotes blood flow.",
      "step_2": "Do not perform collection immediately after smoking.",
      "step_3": "Washing and warming your hands under warm water will help promote blood flow in your hands.",
      "step_4": "Shake hands vigorously towards the floor to encourage blood flow to your fingers.",
      "step_5": "Keep collection device and hands below your heart during collection for best blood flow.",
      "step_6": "You may need more than one finger prick. Repeat these tips between each finger prick."
    },
    "shipping": {
      "header": "Your kit is ready to ship!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "Pick up your recommended test kit from the site staff and register below",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "View results",
    "recommended_kit_title": "Recommended Test Kits",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection",
      "subtitle": "Collection tubes may contain a liquid preservative. <span class=\"text-danger\">**Do not empty liquid preservative from the collection tubes if present.**</span>",
      "step_1": "Hold the swab between the faint score line (if present) and the cotton-tipped portion of the swab in one hand and separate the labia (folds of skin around the vaginal opening).",
      "step_2": "Insert the swab 5cm (2in) into the vaginal opening. Gently turn the swab for 30 seconds while rubbing the swab against the walls of the vagina.",
      "step_3": "Carefully withdraw the swab and place into the collection tube marked with a <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Break the swab by bending against the collection tube.",
      "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_5": "Write your date of birth in MM/DD/YYYY format onto the tube in the designated area (DOB).",
      "step_6": "Write the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area (Coll. Date).",
      "step_7": "Place specimen into the empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "packaging_box_h": {
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "Your **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_6": "The biohazard bag is placed into the box"
    },
    "instructions_box_cc": {
      "title": "Stool collection",
      "step_1": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area. Open green cap by twisting and lifting.",
      "step_2": "Place supplied collection paper into toilet bowl on top of water.",
      "step_3": "Deposit stool sample on top of collection paper.",
      "step_4": "Collect sample from stool before paper sinks and stool sample touches water.",
      "step_5": "Scrape the surface of the fecal sample with the sample probe.",
      "step_6": "Cover the grooved portion of the sample probe completely with stool sample.",
      "step_7": "Close sampling bottle by inserting sample probe and snapping green cap on tightly. Do not reopen.",
      "step_8": "Flush. Collection paper is biodegradable and will not harm septic systems.",
      "step_9": "Wrap sampling bottle in absorbent pad and insert in to  the biohazard bag."
    },
    "contact_support": {
      "title": "Let's fix this",
      "text_1": "We are sorry to hear that something is wrong!",
      "text_2": "Please contact us to let us know what’s wrong and we will help to replace your kit."
    },
    "contact_support_success": {
      "title": "We received your message",
      "text_1": "Thanks for contacting us.",
      "text_2": "A member of our support team will be in touch soon.",
      "button_text": "Return home"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B."
  },
  "copy_link": "Liinki fuula kana koppi godhi ",
  "copied_link": "linki koppi gootetta",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Vaayirasii infuleenzaa beekamaa irraa of eeegu",
      "vaccine_covid": "Korona Vaayirasi irra of. eegu",
      "tdap": "Talaalliin Tdap dhukkuba teetanus ittisuu ni danda'a",
      "polio": " Ijoollee barnoota osoo hin jalqabin dura talaalli yookan ammo kitibaati poliyu fudhachudha qabu.",
      "varicella": "Talaallii yookan kittibaati dhukkuba busaa irraa nama eegu",
      "shingles": "Talaallii shingles irraa nama eegu. Umriin yoo xiqatte wagga 18 ta'udha qaba.",
      "human_papillomavirus": "Talaallii HPV irraa nama eegu. Daa'imman umuriin isaani waggaa 11f isaa ol ta'eef akka fudhatan ni jajjabeefama.",
      "meningococcal": "Talaalliin baakteeriyaa meningococcal gosa afur irraa nama eega."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Kilinika barbaadi",
    "select_pin": "Bal'inaan ilaaluuf piini kan jettu cuqaasi",
    "miles_shorten": "{{maayilii}} mi",
    "next_available": "Guyya bellama itti anuu",
    "appointment_type": "Gosa beellama",
    "individual_appointment": "Beellama dhuunfa",
    "family_appointment": "Beellama Maati",
    "vaccines": "Talaalli yookan ammo kittibaati",
    "what_kind_appointment": "Beellama akkami akka isiniif qabnu barbaadu",
    "select_family_or_individual": "Maatike waliin yookan ammo maatike kessa nama tokko wajjin dhufta taanan beela maati isa jedhu filadhu",
    "vaccines_selected": "Talaalli yookan kittibata filatetta",
    "where_care": "Tajaajila fayya eessatti argachuu barbaadda?",
    "select_vaccines_for_individual": "Beellama keessaniif talaallii filadhaa (dirqama miti).",
    "select_vaccines_for_family": "Beellama maatii keessaniif talaallii filadhaa (dirqama miti).",
    "schedule_services": "Tajaalilaaf bellama qabadhu",
    "add_family": "Miseensota maatii itti dabaluudhaan tajaajiloota armaan gadii filadhau.",
    "family_member": "Miseensa maatii {{number}}.",
    "morning": "Ganama",
    "afternoon": "Waaree booda",
    "slot_available": "{{number}} ni argama",
    "within_x_miles": "Fageenya  {{miles}} irratti argama",
    "any_distance": "Fageenya kamiyyuu irraa",
    "partial_results": "Bu'aa qoranno walakka",
    "partial_matches": "Kilinikoonni armaan gadii talaallii isin barbaaddan keessaa muraasa malee hunda hin dhiyeessan",
    "no_matches": "Barbaacha sun walsimsiisaa hin arganne. Bu'aa fooyya'aa argachuuf filter kee jijjiiruuf yaali.",
    "no_clinics_found": "Kilinikoonni issin barbaaddan filanno keessa hin jiran.",
    "broaden_filters": "Bu'aa fooyya'aa argachuuf filter kee bal'isuu yaali.",
    "unavailable_vaccines": "Talaali yookan kittibati hin jirre",
    "available_vaccines": "Talaali yookan kittibati jiru",
    "select_date": "Guyya filadhu",
    "available_appointments": "Guyya beellama duwwa ta'e",
    "appointment_scheduling_info": "kanatti aanse miseensota maatii murtaa’aniif yeroo beellama ramaduu ni dandeessa. Yoo barbaadde guyyoota adda addaatti beellama qabachuu ni dandeessa.",
    "hold_selected_appointments": "Beellama fyerro ilattan daqiiqaa 15n kessatti belamicha akka qabattan mirkanessudha qabdu",
    "appointments_selected": "Beellama filattaniittu",
    "no_appointments_selected": "Beellama hin filanne",
    "vaccines_needed": "Talallin yookan ammo kittibaatin ni barbaachisa",
    "select_x_appointments": "Bellama {{number}} filadhu",
    "more": "Dabalata",
    "less": "Gad bu'aa",
    "register_next_family_member": "Maati itti aanu galmessi",
    "successfully_registered_x_of_y_family_members": "Miseensota maatii kan ta'an {{y}} {{x}} galmeessiteetta",
    "next_family_member": "Maati itti aanu",
    "appointments_abbreviated": "yeroo gartokkee thromboplastin (appts)",
    "register_for_clinic": "Kilinikaaf galmaa'i",
    "select_services": "Tajaajila filadhu",
    "person": "Lakofsa dhuunfa {{number}}",
    "add_person": "Nama gara bira itti dabali",
    "registration_confirmation": "Taajalilaaf akka galmooftan mirkanessu"
  },
  "user_mailer": {
    "verify_email": "Mee email keessan haa mirkaneessinu!",
    "hi_full_name": "Akkam jirtu  {{full_name}},",
    "verify_email_button": "Imeeli mirkaneessi",
    "please_verify_email": "Mee email keessan mirkaneessuuf malatto gara gadiitti argamu fayyadamaa."
  },
  "services": {
    "dptap_dt": "Dhukkuba garaachaa, Teetaanosii, Dhukkuba garaachaa(DTaP/Tdap/Td)",
    "hepatitis_a": "Dhukkuba tiruu A (HEPA)",
    "hepatitis_b": "Dhukkuba tiruu B (HEPB)",
    "hepatitis_a_b": "Dhukkuba tiruu A fi B",
    "hib": "Infuleenzaa haemophilus gosa b (HIB)",
    "mmr": "Dhukkuba Busaa, Mumps, Rubella",
    "meningococcal": "Meningococcal",
    "mpox": "Monkiypoks (Mpox)",
    "pneumococcal": "Pneumococcal",
    "polio": "Pooliyoo",
    "rsv": "Vaayirasii sinsitii sirna hargansuu",
    "rotovirus": "Vayirasii rota",
    "zoster": "Zoster (Shingles) jedhamu.",
    "tetanus_diptheria": "Teetaanosii fi Dhibee Diphtheria",
    "tdap": "Tetanus, Diphtheria, Dhukkuba garaachaa (TDAP)",
    "typhoid": "Taayifooyidii",
    "varicella": "Varicella (Dhukkuba Busaa)",
    "covid-19": "Korona Vaayirasi",
    "covid-19_adult": "Korona Vaayirasi  (Ga'eessota)",
    "covid-19_adult_description": "Namoota waggaa 12 fi isaa ol ta’aniif",
    "covid-19_children": "Korona Vaayirasi  (Daa'iman)",
    "covid-19_children_description": "Daa'imman umuriin isaanii waggaa 4 - 11 ta'aniif.",
    "covid-19_infants": "Korona Vaayirasi  (ijolle xiqqo reefu dhalataniif)",
    "covid-19_infants_description": "Daa'imman ji'a 6 hanga wagga 3 gidduutt ta'aniif.",
    "influenza": "Infuleenzaa",
    "mmrv": "Dhukkuba Busaa, Mumps, Rubella fi Varicella"
  },
  "deep_archived": {
    "admin": "Nageenya dokumentii keessaniif jecha, dokumentiiin garee {{test_group_name}} ({{slug}}) kana keessa kuufamee jiru, kanaan booda fuula kana irraa hin mul'atu  akkasumas hin jijjiiramu. gargaarsa yoo barbaaddan qaama dhimmisa iaalatu support@primary.health qunnamaa.",
    "participant": "Fuulli kun kana booda hin argamu. Yoo gaaffii qabaaattan garee dhabbata faayye kessan: {{test_group_name}} qunnamu dandeessu. Yoo galmee keessan durii ilaaluu  hin dandeenye gargaarsaaf support@primary.health qunnama"
  }
}