export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Mwa",
    "day": "Jou",
    "year": "Ane",
    "january": "Janvye",
    "february": "Fevriye",
    "march": "Mas",
    "april": "Avril",
    "may": "Me",
    "june": "Jen",
    "july": "Jiyè",
    "august": "Out",
    "september": "Septanm",
    "october": "Oktòb",
    "november": "Novanm",
    "december": "Desanm"
  },
  "user": {
    "send_appointment_confirmation_message": "Nou konfime yon randevou pou ou nan non {{name}}.",
    "landline_appointment_reminder_message": "Alo, {{full_name}}. Sa se yon rapèl pou randevou ou an a {{name}}. Randevou ou an se nan dat {{date}} a {{time}} nan {{address}}.",
    "send_at_home_visit_confirmation_message": "Nou konfime yon randevou pou ou nan {{date}} apre {{time}}.",
    "send_mail_order_confirmation_message": "Nou konfime kòmand ou a",
    "send_waitlist_message": "Yo mete w nan lis datant pou {{name}}. Tanpri pa vini jiskaske nou konfime yon lè pou randevou a.",
    "verify_contact": {
      "phone": "Tanpri  klike sou: {{confirmation_link}} pou konfime nimewo telefòn ou",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Kontinye enskri pou yon randevou isit la: {{url}}",
    "on_demand_confirmation_message": "Ou enskri avèk siksè pou {{name}}.",
    "appointment_reminder": "Anvan randevou w lan a {{start_time}}, tanpii klike sou lyen an pou w konplete atik ou pat fini yo epi gade konfimasyon w. Tanpri pa vini plis pase 15 minit anvan randevou w lan.",
    "appointment_reminder_base": "{{text}} KLIKE ISIT LA: {{link}}",
    "test_result_notifier": {
      "text_message": "Paj pou pasyan pou {{prenon_ak_non_fanmi}} aktyalize kounye a./Al nan {{url}} epi itilize kòd: {{access_code}}",
      "email_subject": "Nou bezwen mizajou nan paj prensipal pasyan ou a",
      "email_intro": "Bonjou/bonswa %{prenon_ak_dènye_lèt}",
      "email_p1": "Ou genyen yon nouvo mesaj oswa yon nouvo mizajou ki disponib sou paj prensipal la ",
      "email_p2": "Klike bouton ki anba a oswa itilize lyen an pou ou gade paj prensipal pou pasyan ou an epi itilize kòd la",
      "email_p3": "Gade paj prensipal pasyan an nan:'",
      "email_button": "Gade paj prensipal"
    },
    "landline_appointment_reminder_message_without_time": "Alo, {{full_name}}. Sa a se yon rapèl pou randevou w lan  {{name}}. Randevou w lan se {{date}} nan {{address}}.",
    "appointment_reminder_on_demand": "Anvan randevou w lan a {{start_time}}, tanpri klike sou lyen an pou w konplete atik ou pat fini yo epi gade konfimasyon w.",
    "new_test_result_notifier": {
      "email_intro": "Paj prensipal pasyan ou an ajou",
      "email_text_1": "Gade paj prensipal istoryèl medikal anba a pou ou wè nenpòt dosye pasyan oswa dosye laboratwa.",
      "email_button": "Antesedan medikal",
      "email_text_2": "Epitou, ou ka kopye epi kole lyen sa a nan navigatè ou a:"
    },
    "mailers": {
      "email_contact_us": "Ou gen kesyon? Kontakte nou nan"
    }
  },
  "errors": {
    "generic": {
      "message": "Gen yon bagay ki pa mache, tanpri eseye ankò"
    },
    "messages": {
      "blank": "paka vid"
    },
    "incorrect_credentials": "Pyès idantifikasyon ou yo pa korèk, tanpri eseye ankò.",
    "error_activating_test_kit": "Te gen yon erè nan aktivasyon kit tès la."
  },
  "type": "Tip",
  "payment": {
    "continue_label": "Kontinye pou Pèman",
    "policy_text": "Si ou anile oswa pa parèt pou randevou w lan, n ap remèt ou 80% pèman w lan, 7 jou aprè dat randevou w lan. Ou kapab wè tout [Terms of Service]({{link}})",
    "card_number": "Nimewo kat la",
    "expiry_date": "Dat ekspirasyon",
    "pay_button": "Peye",
    "no_credit_card": "M ap peye sou plas",
    "cash_payment_helper": "Tanpri rasire w pou w pote **{{payment}}** nan randevou w lan",
    "invoice": "Fakti",
    "pay_by_card": "Peye ak kat",
    "cost": "Pri",
    "total": "Total",
    "pay_cash_at_appointment": "M ap peye kòb kach pandan randevou mwen an nan {{appointment}}",
    "view_invoice": "Wè/Enprime Fakti a",
    "refund_policy": "Si ou anile randevou w la, n ap remèt ou {{amount}} peman ou a, 7 jou apre dat randevou w la. Ou kapab gade tout [Kondisyon Sèvis yo]({{link}})",
    "refund_window_passed": "Randevou w la pa kalifye pou yon ranbousman pase lè randevou a pase. Tanpri kontakte kowòdonatè sit yo dirèkteman pou jwenn yon solisyon.",
    "amount_paid": "Kantite ki peye a",
    "balance": "Balans"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Telechaje yon renonsyasyon konsantman oswa siyen nan bwat ki anba a",
    "stop": "Konsantman an Obligatwa pou Konplete Enskripsyon an",
    "parent_or_guardian_required_html": "Pou w kapab fini anrejistreman w lan, paran w, gadyen legal ou, oswa moun ki otorize pou pran desizyon an dwe bay konsantman li sou fòmilè a epi siyen non yo. Ou <b>PA KAPAB</b> bay konsantman w pou sèvis sa a poukont ou.",
    "send_parent_or_guardian": "Ou kapab voye lyen sa a bay paran w oswa gadyen legal ou oswa fè yo siyen anba nan paj sa a.",
    "consent_guardian_name_signature": "Premye Non w",
    "consent_for_minor": "Siyen Konsantman an sou non minè a",
    "guardian_signature": "Siyati paran, gadyen legal, oswa moun ki otorize pran desizyon medikal pou ou",
    "after_you_schedule": "Patisipan ki gen mwens pase {{age}} dwe gen konsantman an siyen pou yo pa yon paran oswa gadyen legal. Tanpri kontinye jiska fen enskripsyon an pou w ka voye li bay gadyen legal ou oswa siyen pou moun ki depann de ou a.",
    "consent_guardian_last_name_signature": "Siyati",
    "consent_first_and_last_name": "Non & Siyati",
    "phone_number": "Nimewo telefòn moun ki responsab la",
    "email": "Imèl moun ki responsab la",
    "consent_registrar_with_guardian_name_signature": "Non gadyen",
    "consent_registrar_with_guardian_last_name_signature": "Siyati gadyen",
    "consent_registrar_first_name_signature": "Non moun k ap enskri",
    "consent_registrar_last_name_signature": "Siyati moun k ap enskri",
    "consent_on_behalf": "M ap siyen pou {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "{{first_name}} {{last_name}} ban m otorizasyon pou m siyen pou li.",
    "consent_on_behalf_registrar_with_guardian": "Gadyen {{first_name}} {{last_name}} ban m otorizasyon pou m siyen pou li.",
    "registrar_phone": "Nimewo telefòn achivis la",
    "registrar_email": "Imèl achivis la",
    "consented_by_parent_guardian": "Paran/Gadyen",
    "consented_by_decision_maker": "Moun ki otorize pou pran desizyon medikal",
    "consented_by_guardian_verbal": "Achivis avek gadyen (yo jwenn konsantman vèbal)",
    "consented_by_registrar": "Achivis (yo jwenn konsantman vèbal)",
    "full_name": "Non konplè",
    "print": "Enprime"
  },
  "registration": {
    "contact_information": "Non ak adrès",
    "contact_information_additional": "Enfòmasyon pou Kontakte Paran oswa Gadyen Legal pou Kominikasyon sou randevou & Rezilta",
    "personal_information": "Enfòmasyon demografik",
    "address_placeholder": "Tanpri mete adrès lakay ou",
    "first_name_label": "Premye Non",
    "last_name_label": "Siyati",
    "errors": {
      "state": "Eta a dwe gen kòd eta ak 2 lèt la",
      "phone_number_invalid": "Nimewo telefòn nan pa valab",
      "required": "Valè sa a obligatwa",
      "email_domain": "Sa w ekri a pa valid; tanpri verifye ankò. Enskripsyon yo sèlman ouvè pou patisipan òganizasyon k ap finanse a chwazi. Tanpri eseye itilize imèl travay ou oswa kominote w la. Si w santi w elijib epi gen yon erè, tanpri kontakte ekip sipò a.",
      "age_requirement": "Dat nesans lan pa satisfè egzijans pou laj la",
      "signature_required": "Siyati a obligatwa",
      "regex_invalid": "Sanble valè sa a pa valab",
      "date_invalid": "Dat sa a pa egziste",
      "invalid_entry": "Tanpri pa kontinye ak enskripsyon an. Swiv enstriksyon yo sou ekran an oswa jwenn ak moun ki te ba w kontak la.",
      "city": "Vil la dwe valab",
      "survey_unanswered": "Tanpri verifye ou reponn tout kesyon obligatwa ki make ak *",
      "postal_code": "Kòd postal la dwe gen 5 chif valab",
      "year_length_invalid": "Antre dat ou fèt avèk kat chif ane a (aaaa)",
      "invalid_day": "Dat la dwe ant 1 - 31",
      "reached_max_chars": "Ou gentan rive nan kantite maksimòm karaktè.",
      "chars_max": "Kantite maksimòm karaktè",
      "minimum_length": "Sa w ap mete a dwe genyen {{length}} karaktè."
    },
    "insurance_status": {
      "question": "Eske w gen asirans sante?",
      "have_health_insurance": "Wi, mwen gen asirans sante",
      "do_not_have_health_insurance": "Non, mwen pa gen asirans sante"
    },
    "insurance_policy_holder": {
      "question": "Kiyès ki gen asirans lan?",
      "i_am": "Mwen menm",
      "my_spouse": "Konjwen m oswa moun mwen avèk li an",
      "my_parents": "Paran m",
      "someone_else": "Yon lòt moun",
      "policy_first_name": "Non moun ki gen Asirans la",
      "policy_last_name": "Siyati moun ki gen Asirans lan",
      "policy_dob": "Dat nesans moun ki gen Asirans lan",
      "name": "Non moun ki mèt asirans lan"
    },
    "insurance_information": {
      "title": "Enfòmasyon sou Asirans lan",
      "company_name": "Non Konpayi Asirans lan",
      "id_number": "Nimewo Idantifikasyon Manb lan",
      "group_number": "Nimewo Manb Gwoup lan",
      "secondary_insurance_label": "Mwen gen yon dezyèm asirans",
      "take_photo": "Tanpri fè yon foto kat asirans ou a. Lè w fè foto sa ap pèmèt kèk nan enfòmasyon yo ranpli otomatikman.",
      "front_of_card": "Devan kat la",
      "card_information": "Enfòmasyon kat asirans ou a",
      "back_of_card": "Dèyè kat la"
    },
    "employment_information": {
      "label": "Non Travay ou",
      "address_1": "Adrès Antrepriz la",
      "address_2": "Eg: Suite 200",
      "phone_number": "Nimewo Telefòn Antrepriz la",
      "company": "Non Antrepriz la",
      "postal_code": "Kòd Postal Patwon an",
      "employed": "Ap Travay",
      "sole_prorietor": "Pwopriyetè Inik",
      "not_employed": "P ap Travay",
      "reporting_supervisor": "Sipèvizè rapò a",
      "reporting_department": "Depatman ki bay rapò a",
      "supervisor_name": "Non sipèvizè a",
      "supervisor_email": "Nimewo sipèvizè a"
    },
    "location_availability": "Yo bay manb kominote yo k ap viv na zòn sa yo priyorite pou tès la",
    "custom_survey": "Kesyonè",
    "confirmation": "Revizyon",
    "waitlisted": "Nan lis datant",
    "schedule_your_appointment": "Pran yon randevou",
    "information": "Enfòmasyon",
    "consent": "Konsantman",
    "location": "Lokal",
    "symptoms": "Sentòm",
    "address_required": "Tanpri mete adrès lakay ou",
    "consent_required": "Tanpri bay konsantman w pou kontinye",
    "required_field": "montre domèn ki obligatwa a",
    "phone_number": "Nimewo Telefòn Mobil",
    "email": "Imèl",
    "date_of_birth": "Dat Nesans",
    "minimum_age": "Laj minimòm pou patisipe se {{year}}",
    "no_minimum_age": "Pa gen laj minimòm pou fè tès la",
    "continue_button": "Kontinye",
    "email_required": "Valè sa a ta sipoze yon imèl ki valab",
    "done": "Fini",
    "signature": "Siyati",
    "clear_button": "Efase",
    "back_button": "Tounen",
    "choose_location": "Chwazi yon lokal",
    "no_slots": "Pa gen espas disponib ankò pou {{location}}",
    "choose_appointment": "Chwazi yon lè pou randevou a nan {{location}}",
    "appointment_required": "Tanpri chwazi yon lè pou randevou a",
    "phone_number_required": "Nimewo telefòn nan pa valab",
    "phone_number_label": "Tanpri mete yon nimewo ki ka resevwa mesaj tèks pou w ka gen rezilta pi rapid",
    "symptoms_experiencing": "Tanpri klike sou sentòm ou deja santi yo. Si w pa gen sentom, tanpri kontinye",
    "household": "Manb Fwaye a",
    "household_p1": "Ou gen opsyon pran yon randevou pou manb fanmi w ki bezwen fè tès lan tou",
    "add_dependent": "Ajoute yon Manb Fwaye a",
    "remove_dependent": "Retire yon moun ki depann de ou",
    "dependent_consents": "Konsantman yo",
    "submit": "Konplete",
    "add_waitlist": "Ajoute nan lis datant lan",
    "address": "Adrès Lakay ou",
    "address_1": "Liy Adrès 1",
    "address_2": "# Apatman/Suite",
    "address_city": "Vil",
    "address_state": "Eta",
    "postal_code": "Kòd Postal",
    "race_ethnicity": "Ras",
    "gender": "Idantite Seksyèl",
    "self_described_gender": "Idantite Seksyèl Oto-Deklare",
    "interpreter": "Eske w bezwen entèprèt? Si wi, ki langaj?",
    "consent_to_terms": "Mwen bay konsantman m pou kondisyon sa yo",
    "reg_not_open": "Enskripsyon yo pa ouvè",
    "no_more_avail_spots": "Espas tan ou chwazi a pa disponib ankò. Tanpri eseye ankò.",
    "consent_helper": "Klike epi glise souri w lan oswa dwèt ou atravè bwat anba a pou w siyen",
    "phone_number_unreachable_label": "Telefòn ak fil?",
    "select": "Chwazi",
    "test_surveys": "Kesyon pou Randevou",
    "edit": "modifye",
    "continue_to_registration": "Kontinye rive nan Enskripsyon",
    "accounts": {
      "already_have_an_account": "Ou deja gen yon kont?",
      "log_in": "Ouvri yon sesyon",
      "sign_up": "Enskri",
      "sign_up_description": "Tanpri rantre enfòmasyon w pou w kreye yon kont epi pou w pa pèdi tan lè ou ap fè enskripsyon a lavni.",
      "log_in_description": "Tanpri mete imèl ou ak modpas ou, oswa enskri atravè Google oswa Outlook anba a",
      "sign_in_with_label": "Enskri ak {{provider}}",
      "password_must_match": "Modpas yo dwe konbine",
      "password_uppercase": "Modpas ou a dwe genyen omwen (%s) lèt majiskil",
      "password_lowercase": "Modpas ou a dwe genyen omwen (%s) lèt miniskil",
      "password_number": "Modpas ou a dwe genyen omwen (%s) chif",
      "password_special": "Modpas ou a dwe genyen omwen (%s) karaktè espesyal"
    },
    "password": "Modpas",
    "password_confirmation": "Konfimasyon Modpas",
    "consent_for": "Konsantman pou {{name}}",
    "book_one_time_appointment": "Pran yon Randevou pou yon sèl fwa",
    "duplicate_users": {
      "exists": "Ou deja enskri",
      "overlapping_email_and_phone_p1": "Nou voye yon mesaj sou nimewo telefòn ou ak sou imèl ou.",
      "p2": "Pandan n ap itilize mesaj ou a, ou ka chanje randevou w lan oswa mete rezilta tès ou yo.",
      "overlapping_phone_and_email_p3": "Tanpri verifye imèl ou ak nimewo telefòn ou  pou detay yo.",
      "overlapping_email_p3": "Tanpri verifye imèl ou pou detay yo.",
      "overlapping_phone_p3": "Tanpri verifye telefòn ou pou detay yo.",
      "overlapping_phone_p1": "Nou voye yon mesaj sou nimewo telefòn ou.",
      "overlapping_email_p1": "Nou voye yon mesaj sou imèl ou.",
      "p4": "Si w panse sa a se yon erè, tanpri kontakte nou nan support@primary.health",
      "overlapping_email_and_phone_p3": "Tanpri tcheke imèl ou ak telefòn ou pou detay yo."
    },
    "duplicate_waitlist": {
      "exists": "Ou deja sou lis datant",
      "overlapping_email_and_phone_p1": "Nou voye yon lòt konfimasyon nan nimewo telefòn ou an ak nan imèl ou",
      "p2": "Itilize konfimasyon w lan, ou kapab founi enfòmasyon siplemantè oswa retire tèt ou nan lis datant lan.",
      "overlapping_phone_and_email_p3": "Tanpri tcheke imèl ou ak telefòn ou pou detay yo.",
      "overlapping_email_p3": "Tanpri verifye imèl ou pou detay yo.",
      "overlapping_phone_p3": "Tanpri verifye telefòn ou pou detay yo.",
      "overlapping_email_p1": "Nou voye yon konfimasyon sou imèl ou an."
    },
    "insist_guardian_consent": "M ap bay konsantman sou non",
    "confirmation_section": {
      "title": "Revize epi konfime",
      "p1": "Tanpri revize enfòmasyon w anvan ou konplete enskripsyon an.",
      "edit_information": "Tounen pou w modifye"
    },
    "iemodal": {
      "title": "Pa Sipòte Navigatè a",
      "body": "Enskripsyon an posib sou navigatè sa yo"
    },
    "show_other_locations": "Klike pou montre lòt lokal yo",
    "non_us_address": "Sa a se yon adrès entènasyonal",
    "test_group_user_survey": "Depistaj Patisipan",
    "self_consent": "M ap bay konsantman pou tèt mwen",
    "address_country": "Peyi",
    "appointment": "Randevou",
    "employee_id": "Idantifikasyon Anplaye",
    "appointment_time": "Lè randevou",
    "appointment_location": "Kote",
    "phone_or_email": "Nimewo telefòn oswa adrès imèl",
    "no_self_consent": "Mwen refize konsantman pou tèt mwen",
    "no_insist_guardian_consent": "Mwen refize konsantman pou {{full_name}}",
    "minimum_age_with_months": "Laj minimòm pou patisipe se {{year}} zan ak {{month}} mwa.",
    "additional_consents_helpertext": "Ou kapab bezwen konsantman adisyonèl apre",
    "assistive_technology": "Teknoloji pou assistans",
    "covid_vaccine_y_n": "Eske ou ta renmen pran yon vaksen Covid-19 nan randevou sa a?",
    "received_covid_vaccine_y_n": "Èske ou deja pran vaksen COVID-19 la?",
    "covid_vaccinations": "Vaksen Covid-19",
    "select_vaccine_dose": "Tanpri chwazi dòz vaksen ou t a renmen resevwa a",
    "demographic_info": "Enfòmasyon demografik",
    "additional_info": "Enfòmasyon siplemantè",
    "self_described_race": "Ras ou, dapre w",
    "verify_contact_information": "Verifikasyon",
    "verify_contact": {
      "we_sent_you_a_code": "Tanpri mete kòd nou te voye bay {{contact}}",
      "confirmation_code": "Kòd konfimasyon",
      "code_resent": {
        "email": "Nou te voye yon lòt kòd sou imèl ou",
        "phone_number": "Nou te voye yon lòt kòd sou telefòn ou"
      },
      "did_not_receive_code": "Ou pa t resevwa kòd konfimasyon an?",
      "verify_by": {
        "email": "Verifye atravè imèl pito",
        "phone_number": "Verifye atravè nimewo telefòn pito"
      }
    },
    "preferred_method_of_communication": {
      "question": "Metòd kominikasyon ou prefere",
      "helper_text": "N ap kontakte w pou nenpòt mizajou ki ta fèt nan randevou ou a",
      "phone_number": "Telefòn (SMS sèlman, yo ka aplike frè pou voye mesaj la)",
      "landline": "Nou poko gen liy fiks nan moman an. Tanpri ba nou yon imèl pou voye kominikasyon enpòtan yo.",
      "verification_method": "Kijan ou vle resevwa kòd verikasyon ou a?",
      "verification_helper_text": "Yo pral voye yon kòd ba ou pou verifye idantite ou anvan ou konekte."
    },
    "skip_for_now": "Sote pati sa a pou kounya",
    "default_information": {
      "title": "Oke, n ap itilize adrès sa",
      "title_v2": "Dakò, n ap itilize enfòmasyon kontak sa a"
    },
    "middle_name_label": "Dezyèm non",
    "confirm_appointment": "Konfime randevou a",
    "dob": "Dat nesans",
    "different_appointment": "Yon lòt randevou",
    "select_appointment_time": "Chwazi lè randevou a",
    "decline_address": "Mwen refize bay yon adrès. Tanpri itilize adrès pwogram nan pito.",
    "patient_information": "Enfòmasyon sou pasyan an",
    "scan_license": "Eskane lisans kondui w",
    "how_to_contact": "Kijan nou ta dwe kontakte w?",
    "decline_email_or_phone": "Mwen refize bay yon imèl oswa nimewo telefòn. Mwen otorize pou administratè pwogram yo resevwa rezilta mwen yo.",
    "medical_screening": "Egzamen medikal",
    "show_service_at_this_location": "Afiche sèvis ki gen nan kote sa",
    "verbal_consent_provided": "Daprè Lwa Etazini sou Andikap (ADA), patisipan an oswa moun yo otorize legalman pou pran desizyon te bay konsantman vèbal. Nou ta dwe jwenn konsantman ekri nan men patisipan an oswa moun ki otorize legalman pou pran desizyon nan 24 èdtan.",
    "unexpired_written_consent_present": "Sit tès la gen yon konsantman ekri ki poko ekspire nan dosye a.",
    "select_to_proceed": "Tanpri chwazi youn nan opsyon pi wo yo pou kontinye.",
    "guardians_information": "Enfòmasyon gadyen an",
    "registrars_information": "Enfòmasyon achivis la",
    "optional": "opsyonèl",
    "vfc_eligibility": "Kondisyon pou jwenn aksè nan Pwogram Vaksen pou Timoun (VFC)",
    "vfc_eligibility_subtext": "Pitit ou ka jwenn vaksen gratis nan kad Pwogram Vaksen pou Timoun (VFC) CDC nan biwo founisè swen sante, famasi ak klinik sante ki anrejistre nan Pwogram Vaksen pou Timoun (VFC)."
  },
  "gender_key": {
    "male": "Gason",
    "female": "Fi",
    "cisgender": "Sisjan",
    "non_binary": "Omoseksyèl oswa Ki pa Binè",
    "other": "Lòt",
    "prefer_to_self_describe": "Idantite a pa nan lis la",
    "prefer_not_to_disclose": "Pa vle reponn",
    "transgender_male": "Gason Trans/Transgason",
    "transgender_female": "Fanm Trans/Transfanm",
    "unknown": "Enkoni",
    "non_binary_only": "Ni fi ni gason (miks)",
    "intersex": "Entèseksye (miks)",
    "transgender": "Trans (miks)",
    "gender_fluid": "Sèks likid",
    "not_applicable": "Pa aplikab"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Endyen Ameriken oswa Natif Alaska",
    "american_indian_central_america": "Endyen Ameriken ki soti nan Sid oswa Amerik Santral",
    "asian": "Azyatik",
    "black": "Nwa oswa Afriken Ameriken",
    "latinx": "Latino, oswa Latinx oswa Panyòl",
    "middle_eastern_north_african": "Mwayen Oryan oswa Afriken nan Nò",
    "native_hawaiian_pacific_islander": "Natif Awayi oswa Zile Pasifik",
    "white_european": "Blan oswa Kakwazyen",
    "unknown": "Enkoni",
    "prefer_not_to_disclose": "Prefere pa Divilge",
    "asian_indian": "Endyen Azyatik",
    "filipino": "Filipino",
    "japanese": "Japonè",
    "korean": "Koreyen",
    "vietnamese": "Vyetnamyen",
    "other_asian": "Lòt Azyatik",
    "native_hawaiian": "Natif Alaska",
    "guamanian_or_chamorro": "Gwamanyen oswa Chamowo",
    "samoan": "Samowen",
    "other_pacific_islander": "Lòt Moun Zile Pasifik",
    "chinese": "Chinwa",
    "help": "Ras la gen pou wè ak yon seri karakteristik fizik yo itilize pou fè diferans ant gwoup moun. Ras kapab oswa pa kapab gen pou wè ak nasyonalite, oswa peyi kote w gen sitwayènte.",
    "subtitle": "Règleman yo egzije pou nou rasanble tout enfòmasyon ki pral swiv yo.",
    "laotian": "Lawotyen",
    "cambodian": "Kambodyen",
    "other": "Lòt",
    "bangladeshi": "Bangladèch",
    "hmong": "Mong",
    "indonesian": "Endonezyen",
    "malaysian": "Malezyen",
    "pakistani": "Pakistani",
    "sri_lankan": "Sri Lanken",
    "thai": "Tay",
    "taiwanese": "Taywane",
    "fijian": "Fidjyen",
    "guamanian": "Guamyan",
    "tongan": "Tongyen"
  },
  "languages": {
    "en": "Anglè",
    "fr": "Fransè",
    "es": "Panyòl",
    "so": "Somali",
    "hmn": "Hmong",
    "asl": "Langaj Siy",
    "kar": "Karen",
    "am": "Amarik",
    "ru": "русский",
    "om": "Oromo"
  },
  "symptoms": {
    "fever": "Lafyèv oswa frison",
    "cough": "Tous",
    "muscle_aches": "Doulè nan miskilati",
    "severe_fatigue": "Fatig ki grav (ki plis pase nòmal)",
    "trouble_breathing": "Difikilte pou respire",
    "diarrhea": "Dyare",
    "loss_of_smell": "Pèt sant",
    "loss_of_taste": "Pèt gou",
    "shortness_of_breath": "Souf kout oswa difikilte pou respire",
    "headache": "Tèt fè mal",
    "sore_throat": "Gòj fè mal",
    "congestion": "Nen bouche oswa nen k ap koule",
    "nausea": "Kè plen oswa vomi",
    "close_contact": "Kontak sere ak yon moun ki enfekte*",
    "helper_text": {
      "close_contact": "*Eske w te gen kontak sere (sou yon distans 6 pye oswa pandan omwen 15 minit) ak yon moun ki gen yon ka COVID-19 ki konfime?"
    },
    "suspected_exposure": "Ekspozisyon sispèk",
    "none": "San Sentòm"
  },
  "instructions": {
    "title": "Ou anrejistre men anvan ou ale...",
    "p1": "Paj sa a gen enfòmasyon konsènan randevou w lan ansanm ak kòd dijital randevou w lan.",
    "appointment_scheduled": "Randevou w lan pwograme",
    "verify_info": {
      "title": "Verifye Enfòmasyon w lan",
      "p1": "Li enpòtan pou w verifye enfòmasyon pou yo kontakte w pou ou kapab gen aksè ak pòtay pasyan w lan rapid.",
      "p2": "Yo voye yon konfimasyon nan imèl ou a. Tanpri klike sou lyen yo voye a.",
      "p3": "Si w bezwen chanje enfòmasyon pou yo kontakte w, tanpri peze bouton pou èd la."
    },
    "verified": "Verifye",
    "not_verified": "Ki Pa Verifye",
    "resend_text": "Ou pa resevwa lyen an?",
    "resend": "Revoye",
    "skip": "Pase etap validasyon an & Montre Konfimasyon an",
    "name": "Non",
    "appointment_details": "Randevou",
    "date_and_time": "Dat ak Lè",
    "add_to_calendar": "Mete nan Kalandriye a",
    "instructions": "Enstriksyon Jeneral",
    "successfully_flash": "Ou kreye randevou an ak siksè",
    "success_flash_email": "Ou verifye imèl ou ak siksè",
    "success_flash_phone": "Ou verifye nimewo telefòn ou ak siksè",
    "mail_order": "Ou pral resevwa kit tès ou a nan kourye anvan yon semèn",
    "at_home": "Yon founisè medikal prale lakay ou a nan lè sa yo",
    "at_home_instructions": "Enstriksyon pou Lakay",
    "mail_order_instructions": "Enstriksyon pa Lapòs",
    "request_additional_appointment": "Pran randevou pou swivi",
    "book_additional_appointment": "Pran yon lòt randevou",
    "confirmation_code": "Kòd konfimasyon",
    "completed": "Konplè",
    "appointment_barcode": "Kòd Dijital Randevou an",
    "dependent_links": "Lyen yo pou Randevou nan Fwaye a",
    "on_demand_title": "Ou enskri men anvan ou ale...",
    "save_link": "Anrejistre lyen sa a",
    "verify_phone": "Verifye Nimewo telefòn ou an",
    "verify_phone_description": "Li enpòtan pou w verifye enfòmasyon pou yo kontakte w pou ou kapab gen aksè ak pòtay pasyan w lan rapid. Yo voye yon mesaj tèks sou nimewo w lan.",
    "verify_email_description": "Li enpòtan pou w verifye enfòmasyon pou yo kontakte w pou ou kapab gen aksè ak pòtay pasyan w lan rapid. Yo voye yon imèl sou adrès imèl ou an.",
    "information": "Enfòmasyon Enskripsyon",
    "follow_up_appointment": "Randevou pou Swivi",
    "get_directions": "Resevwa Èd",
    "cancel": "Anile Radevou a",
    "reschedule_appointment": "Repwograme Randevou a",
    "reschedule_linked_appointments": "Repwograme Randevou a",
    "no_slots": "Pa gen lè ki disponib",
    "check_results": "Verifye pòtay Pasyan an",
    "follow_up_modal_header": "Tanpri Pwograme 2zyèm Doz ou a",
    "are_you_sure_you_want_to_cancel": "Eske ou sèten ou vle anile randevou sa a?",
    "please_choose_cancellation_reason": "Tanpri chwazi yon rezon pou anilasyon an pi ba a",
    "additional_details": "Tanpri mete nenpòt ki detay siplemantè anba a",
    "errors": {
      "missing_cancellation_reason": "Tanpri chwazi yon rezon pou anilasyon an"
    },
    "verify_email": "Verifye imèl ou",
    "redcap_url": "Klike pou w konplete sondaj la",
    "verify_contact_information": "Verifye Enfòmasyon pou kontakte w",
    "please_also": "Tanpri fè sa a tou",
    "new_title": "Konfimasyon pou {{name}}",
    "contact_method": "Metòd kontak",
    "next_steps": {
      "title": "Pwochen etap",
      "p1": "Ou gen yon kit tès pou w aktive. Lè w pare pou pran tès ou a, swiv lyen yo voye nan imèl ou a pou retounen sou paj sa a epi aktive kit ou a anba a.",
      "administered": "N ap kontakte w lè kit ou a rive nan laboratwa a ak lè rezilta ou yo pare. Rezilta yo anjeneral pran 3-5 jou apre yo rive nan laboratwa a. Ou ka wè rezilta yo nan paj [Medical history]({{link}}) ou a lè yo pare.",
      "resulted": "Rezilta yo disponib sou paj [Medical history]({{link}}) ou a.",
      "shipping_information": "Enfòmasyon sou livrezon"
    },
    "save_this_page": {
      "title": "Anrejistre paj sa a",
      "p1": "Sèvi ak paj sa a pou rapòte yon tès ou te fè pou kont ou oswa gade rezilta yo atravè antesedan medikal la.",
      "p2": "Pou jwenn aksè ak paj sa a annaprè, klike sou lyen ki nan imèl oswa SMS ou te resevwa pou konfimasyon an.",
      "p3": "Si w vle konsève paj sa, ou kapab mete l nan favori, ajoute l sou ekran dakèy ou a, oswa kopye lyen an nan yon kote ki an sekirite."
    },
    "show_my_barcode": "Montre kòd ba mwen",
    "my_account": "Kont mwen",
    "register_another": "Enskri yon lòt patisipan",
    "update_vaccine": "Aktyalize enfòmasyon vaksen",
    "medical_history": "Antesedan medikal ",
    "upcoming_appointments": "Pwochen randevou",
    "reschedule": "Repwograme",
    "resend_confirmation": "Revoye konfimasyon",
    "appointment_details_v2": "Detay sou randevou a",
    "confirm_cancellation": "Konfime anilasyon an",
    "confirm_cancellation_question": "Èske ou sèten ou vle anile randevou sila a?",
    "select_new_appointment_time_below": "Chwazi yon lot lè pou randevou a pi ba. Si ou ta vle modifye yon sèvis oswa kote w ap gen randevou a, tanpri anile randevou sa a epi pwograme yon lòt.",
    "no_take_me_back": "Non, mwen vle tounen",
    "yes_cancel_appointment": "Wi, anile randevou",
    "update_appointment": "Aktyalize randevou",
    "select_new_appointment_time": "Chwazi yon nouvo lè randevou",
    "clinic": "Klinik",
    "services": "Sèvis",
    "appointment_missed": "Ou rate randevou a",
    "appointment_canceled": "Ou anile randevou a"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Kòd aksè a pa valab"
    },
    "landing": {
      "p1": "Pou w ka wè rezilta w yo, tanpri ekri:",
      "access_code_label": "Kòd Aksè",
      "submit_button": "Soumèt",
      "recent_results": "Rezilta resan w yo"
    },
    "view_results": {
      "headline": "Rezilta Tès pou {{name}}",
      "reregister": "Re-Anrejistre",
      "status": {
        "likely_positive": "Rezilta yo pa Detèmine",
        "test_not_performed": "Gen bezwen pou refè tès la",
        "results_ready": "Rezilta yo pare",
        "processing": "An Tretman",
        "test_status": "Estati Tès la",
        "test_result": "Rezilta Tès la",
        "administered": "Adminstre",
        "results_pending": "Rezilta yo poko pare",
        "test_results": "Rezilta egzamen yo"
      },
      "at": "nan {{address}}"
    },
    "result_label": "Rezilta",
    "result": {
      "flu_a": {
        "result_label": "Rezilta Grip A"
      },
      "flu_b": {
        "result_label": "Rezilta Grip B"
      },
      "covid19": {
        "result_label": "Rezilta COVID-19"
      },
      "covid": {
        "result_label": "Rezilta COVID-19"
      },
      "sars": {
        "result_label": "Rezilta SARS"
      },
      "control": {
        "result_label": "Kontwòl"
      },
      "rsv": {
        "result_label": "Rezilta RSV"
      },
      "result": {
        "result_label": "Rezilta"
      },
      "hba1c": {
        "normal": "Nòmal",
        "warning": "Prediabèt",
        "danger": "Dyabèt"
      },
      "lead_venous": {
        "danger": "Pa Nòmal",
        "normal": "Nòmal"
      },
      "tc": {
        "result_label": "Rezilta TC"
      },
      "hdl": {
        "result_label": "Rezilta HDL"
      },
      "trg": {
        "result_label": "Rezilta TRG"
      },
      "ldl": {
        "result_label": "Rezilta LDL"
      },
      "non_hdl": {
        "result_label": "Rezilta Non-HDL "
      },
      "tc_hdl_ratio": {
        "result_label": "Rapò TC/HDL"
      },
      "glu": {
        "result_label": "Rezilta GLU"
      },
      "alere_cholestech_ldx": {
        "danger": "Anba ris",
        "warning": "Pa Nòmal",
        "normal": "Nòmal"
      },
      "lead": {
        "result_label": "Plon"
      },
      "zinc": {
        "result_label": "Zenk"
      },
      "lead_standard_profile": {
        "danger": "Anormal",
        "normal": "Nòmal"
      },
      "creatinine": {
        "danger": "Anormal",
        "normal": "Nòmal"
      },
      "igg": {
        "result_label": "Rezilta IgG"
      },
      "igm": {
        "result_label": "Rezilta IgM"
      },
      "blood_glucose_fasted": {
        "normal": "Nòmal",
        "warning": "Wo",
        "danger": "Wo",
        "hypoglycemia": "Ipoglisemi",
        "prediabetes": "Predyabèt",
        "diabetes": "Dyabèt"
      },
      "total_cholesterol_fasted": {
        "normal": "Nòmal",
        "elevated": "Wo",
        "high": "Wo",
        "low": "Ba",
        "abnormal": "Anòmal"
      },
      "total_cholesterol_unfasted": {
        "normal": "Nòmal",
        "elevated": "Wo",
        "high": "Wo"
      },
      "a1c_now": {
        "normal": "Nòmal",
        "warning": "Prediyabète",
        "danger": "Diyabète"
      },
      "blood_glucose": {
        "warning": "Ba",
        "normal": "Nòmal",
        "prediabetes": "Predyabèt",
        "danger": "Dyabèt",
        "hypoglycemia": "Ipoglisemi",
        "medical_emergency": "Ijans medikal",
        "inconclusive_1": "Enkonklizyon 1",
        "inconclusive_2": "Enkonklizyon 2",
        "possible_diabetes": "Posib dyabèt"
      },
      "triglycerides": {
        "result_label": "Trigliserid"
      },
      "blood_glucose_ucsf": {
        "low": "Ba",
        "normal": "Nòmal",
        "high": "Wo"
      },
      "syphilis": {
        "result_label": "Rezilta sifilis"
      },
      "hepatitis_c": {
        "result_label": "Rezilta epatit C"
      },
      "hiv": {
        "result_label": "Rezilta VIH"
      }
    },
    "documents": "Dokiman",
    "self_administered": "Mwen administre tès la poukont mwen, mwen pran tès la nan {{location}}",
    "patient": "Pasyan",
    "medical_history": "Antesedan medikal",
    "overview_title": "Chwazi yon tès oswa yon sèvis pou wè plis detay ak nenpòt lòt aksyon ki nesesè. Sonje, kèk rezilta tès ka pa disponib ankò oswa yo pral disponib sèlman aprè w fin pale ak yon founisè.",
    "insurance_information": "Ranpli enfòmasyon sou asirans",
    "contact_support": "Si ou bezwen asistans, tanpri kontakte ekip sipò a.",
    "show": "Montre",
    "hide": "Kache",
    "lab_report": "Rapò laboratwa",
    "contact_provider": {
      "header": "Ou gen rezilta pozitif",
      "description": "Èske ou vle pale ak yon founisè pou diskite sou rezilta ak trètman ou yo?",
      "yes_option_text": "Wi, mwen vle pale ak yon founisè ",
      "no_option_text": "Non, mwen li ak konprann rezilta mwen yo, epi mwen pa vle pale ak yon founisè",
      "confirm_phone_number_header": "Konfime nimewo telefòn ou",
      "confirm_phone_number_description": "Tanpri konfime pi bon nimewo telefòn ou ta vle yo itilize pou konsiltasyon ou a.",
      "confirm_button_text": "Konfime",
      "consultation_confirmed_header": "Nou konfime konsiltasyon ou a",
      "consultation_confirmed_description": "Yon founisè ap rele ou nan {{phone_number}} nan espas 2-3 jou ouvrab.",
      "acknowledgement_option_helper_text": "Yon bagay ki eksplike enpòtans trètman an epi ki fè itilizatè a konnen kijan pou pwograme yon apèl si li chanje lide.",
      "acknowledgement_confirmed_header": "Ou konfime ou konprann",
      "acknowledgement_confirmed_description": "Yon bagay sou enpòtans pou jwenn trètman ki lye avèk resous yo. Sonje konsiltasyon an gratis epi founisè a ka preskri oswa fè nenpòt lòt bagay pou ede yo rezoud enfeksyon an.",
      "acknowledgement_confirmed_change_mind_text": "Si ou chanje lide, annik klike sou “Mwen ta renmen jwenn yon konsiltasyon” anba a.\"",
      "request_consultation_button_text": "Mwen ta renmen jwenn yon konsiltasyon"
    },
    "phone": "Telefòn",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Adrès ou an pa mache ak kondisyon pou elijib yo pou sit yo nan pwogram sa a."
    }
  },
  "member": {
    "medical_history": "Istorik",
    "view_instructions": "Konfimasyon Vizyalizasyon",
    "next_appointment": "Pwochenn Randevou",
    "over_18": "Mwen sètifye mwen gen plis pase 18 lane oswa plis",
    "choose_test_configurations_title": "Tanpri chwazi sèvis (yo) ou ta renmen pou randevou sa a",
    "member_taken_error": "Itilizatè a deja gen yon kont",
    "choose_test_configurations_subtitle": "N ap aplike sèvis (yo) bay tout manb fwaye a",
    "service": "Sèvis",
    "group_or_location": "Gwoup/Lokal",
    "details": "Detay",
    "see_all": "Wè tout",
    "no_history": "Pa gen okenn istorik disponib pou kounye a",
    "medical_history_title_with_name": "Istorik {{name}}",
    "no_dashboard": "Pa gen anyen ki disponib pou kounye a",
    "product": "Pwodui",
    "price": "Pri",
    "quantity": "Kantite",
    "total_services_selected": "Tout sèvis ou chwazi",
    "total_price": "Pri total"
  },
  "or": "oswa",
  "account": {
    "errors": {
      "must_be_13": "Dwe genyen 13 lane pou enskri pou yon kont"
    }
  },
  "self_administration": {
    "title": "Bay tèt ou li",
    "self_administer_action": "Bay tèt",
    "skip_to_link": "Ale nan Bay tèt ou li",
    "select_person": "Chwazi yon moun pou w retire",
    "adult": "granmoun",
    "child": "timoun",
    "checked_out": "RETIRE",
    "go_back": "Retounen",
    "switch_to_scanner": "Chanje nan eskanè",
    "enter_barcode": "Rantre nimewo bakòd",
    "scan_barcode": "Eskane nimewo bakòd",
    "cancel": "Anile",
    "barcode_for": "Nimewo bakòd pou",
    "enter_barcode_manually": "Rantre nimewo bakòd la alamen",
    "instructions": "Eskane oswa rantre nimewo bakòd ki ekri sou tib tès ou a anba la a",
    "regex_prefix": "Nimewo bakòd la dwe genyen",
    "all_completed": "Konplè: Tout moun ki rete nan kay la konplete etap sa a",
    "errors": {
      "no_appointment": "Pwoblèm pou jwenn randevou",
      "barcode_in_use": "Bakòd sa a deja itilize. Si ou poko janm itilize kit sa a poukont ou tanpri pale ak yon manm ekip la sou sit ki ba ou kit la epi mande yon nouvo kit. Sinon klike sou bouton èd la anba la a. ",
      "test_error_general": "Pwoblèm pou kreye tès la",
      "different_barcodes": "Nimewo bakòd ou rantre yo pa koresponn youn ak lòt"
    },
    "confirm_barcode_input": "Reantre nimewo bakòd la ankò pou konfime",
    "click_to_self_test": "Klike la a pou fè tès la pou tèt ou",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Lis datant",
      "unavailable": "Pa gen randevou ki disponib"
    },
    "labels": {
      "address": "Adrès",
      "next_appointment": "Randevou ki disponib aprè sa",
      "services": "Sèvis ki disponib"
    }
  },
  "waiting_room": {
    "header": "Ou nan sal datant la; tanpri tann pandan n ap konekte w ak sistèm pwogramasyon an.",
    "p1": "Tanpri pran pasyans. N ap fini ak pandemi sa a ansanm.",
    "signature": "Sensèman",
    "p2": "Nou konnen eksperyans COVID-19 la kapab bay fristrasyon. Tanpri tann isit la pandan n ap travay pi vit posib pou nou ka fè w rantre sou sit lan pou w pran randevou."
  },
  "users": {
    "send_appointment_confirmation_message": "Alo {{full_name}}. Sa a se yon rapèl pou randevou w lan pou {{name}}. Randevou w lan nan dat {{date}} a {{time}} nan {{address}}.",
    "send_registration_link": "Alo, {{full_name}}. Swiv lyen sa a pou {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "Premye doz",
    "previous_appointment": "Ansyen Randevou",
    "booked_appointment": "Randevou w lan",
    "second_dose": "Dezyèm doz",
    "choose_second_location": "Chwazi yon Dezyèm Lokal Randevou"
  },
  "cancellation_types": {
    "cant_get_to_location": "Mwen te gen yon konfli tan",
    "timing_conflict": "Mwen te vinn malad akòz COVID-19 la",
    "became_ill_with_covid19": "Mwen resevwa sèvis sa a lòt kote",
    "received_service_elsewhere": "Lòt",
    "other": "Tape non w pito",
    "duplicate_appointment": "Randevou sa a fèt deja"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Tape Non w",
      "type_name": "Tape siyati pito"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Kòd daksè w lan se",
      "please_confirm_your_email": "Tanpri konfime imèl ou an, klike sou bouton anba a",
      "hello": "Alo",
      "click_to_reschedule": "Klike pou w Ka Wè Direktiv/Repwograme",
      "click_to_reschedule_on_demand": "Klike pou w Ka Wè Direktiv"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Yo te anile randevou ou a a {{time}} nan {{location}}. Si sa se yon erè, tanpri voye yon imèl bay support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Non Patisipan an",
    "date": "Dat",
    "signature": "Siyati Patisipan an",
    "and_or": "AVÈK/OSWA",
    "guardian_name": "Non Paran an/Gadyen an",
    "guardian_signature": "Siyati Paran an/Gadyen an"
  },
  "employer_testing": {
    "hello_name": "Alo {{name}}",
    "results": {
      "negative": "Negatif",
      "positive": "Pozitif",
      "did_not_result": "Enkoni",
      "unknown": "Pa rekonèt enfòmasyon sa a",
      "invalid": "Pa valid",
      "pending": "An atant"
    },
    "code_reader": {
      "scan_your_code": "Eskane kòd ou an sou kat tès ou a",
      "find_in_top_right_hand_corner": "Kòd pou eskane a inik pou tès ou an epi ou ka jwenn li nan kwen anwo a dwat tès ou an",
      "center_code": "Santre kòd lan isit la.",
      "already_used_error": "Yo deja itilize Bakòd pou tès sa a. Tanpri kontakte founisè kit tès ou an pou sipò.",
      "click_to_scan": "Klike pou w eskane kòd QR ou an",
      "scan_new_test_card": "Klike isit lan pou w eskane yon nouvo kat tès.",
      "not_working": "Li pa mache?",
      "try_again": "Klike isit la pou w eseye ankò."
    },
    "continue": "Kontinye",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Tanpri konfime dat nesans patisipan an pou w kontinye",
      "contact_administrator": "Si enfòmasyon an pa kòrèk tanpri kontakte yon administratè pou asistans",
      "error": "Dat nesans ou mete a pa kòrèk — tanpri eseye ankò oswa kontakte administratè sit ou an pou li kapab aktyalize li."
    },
    "result_entry": {
      "code_is_registered": "Yo anrejistre kòd ou an.",
      "take_the_test": "Pran tès la.",
      "follow_the_instructions": "Swiv enstriksyon ki te vini ak tès ou an epi kòmanse òlòj 15 minit ou an yon fwa ou mete aplikatè pou nen an nan flakon kat tès la.",
      "cant_find_instructions": "Ou paka jwenn direktiv yo?",
      "view_instructions": "Vizyalize direktiv Binax Now yo",
      "start_timer": "KÒMANSE YON ÒLÒJ 15 MINIT (OPSYONÈL)",
      "submit_within_15": "Konsèy: Soumèt rezilta w yo sou 15 minit",
      "enter_test_results": "Rantre Rezilta Tès yo",
      "choose_a_result": "Chwazi opsyon ki dekri rezilta kat tès ou a",
      "submit": "Soumèt",
      "must_log_result_and_photo": "Ou dwe rantre yon rezilta epi pran foto tès ou an pou w ka kontinye",
      "submit_within_15_v2": "Konsèy: Soumèt rezilta w yo lè tès la fin pase 15 minit",
      "click_to_take_photo": "Klike pou w ka pran yon foto",
      "results_may_be_invalid": "Rezilta tès yo kapab pa valab"
    },
    "summary": {
      "title": "Rezime Rezilta yo",
      "negative_subtitle": "Ou pa gen COVID-19",
      "negative_message": "Nou voye yon avi bay patwon w lan pou di ke w lib pou retounen travay nan lokal la.",
      "positive_subtitle": "Nou dezole, men ou gen COVID-19",
      "positive_message": "Nou voye yon avi bay patwon w lan pou di l ou gen COVID-19.",
      "what_should_you_do": "Kisa w ta dwe fè:",
      "employer_name_resources": "Resous pou Non Patwon an:",
      "follow_cdc_guidelines": "Swiv Direktiv CDC Rekòmande yo"
    },
    "clarifying_results": {
      "title": "N ap Klarifye Rezilta w yo",
      "which_did_your_result_most_look_like": "Ak kisa rezilta w yo plis sanble?",
      "no_lines": "Nou pa wè okenn liy",
      "blue_control_line_only": "Liy pou kontwòl ble sèlman",
      "pink_sample_line_only": "Liy echantiyon wòz la sèlman",
      "blue_control_line_and_pink_sample_line": "Liy pou kontwòl ble an AK liy echantiyon wòz/mov",
      "still_not_sure": "Mwen Toujou pa Sèten",
      "submit_results": "Soumèt Rezilta",
      "thank_you": "Mèsi!",
      "clarification_received_message": "Nou resevwa klarifikasyon rezilta yo.",
      "only_pink_line": "Sèlman liy wòz la",
      "no_lines_v2": "Pa gen liy"
    },
    "return_to_confirmation": "Tounen nan paj konfimasyon an"
  },
  "preferred_language": {
    "title": "Langaj Prefere",
    "subtitle": "Ki lang ou pito?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Seri vaksen inisyèl",
    "one_dose": "Pran randevou pou yon doz espesifik. An patikilye, sa a se",
    "insist_first_dose_only": "Premye doz",
    "insist_second_dose_only": "Dezyèm doz",
    "single_dose_title": "Sèl doz",
    "title": "Chwazi doz",
    "additional": "Adisyonèl",
    "booster": "Piki rapèl",
    "supplemental": "Siplemantè",
    "third_dose": "Twazyèm dòz sèlman",
    "no_vaccine": "Okenn nan chwa sa yo"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "li ta dwe gen fòma {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "li ta dwe gen fòma {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "nou pa jwenn tès la"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "itilizatè a egziste men yo pa otorize aktyalize li"
            },
            "date_of_birth": {
              "invalid_date": "li ta dwe gen fòm {{datetime_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "idantite seksyèl lan pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{genders}}"
            },
            "phone_number": {
              "invalid": "se yon chif pa valab"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "paka ranpli li si idantite seksyèl lan  pa \"pito_bay_deskripsyon_an\""
            },
            "sex": {
              "unknown_sex": "sèks la pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "oryantasyon_seksyèl lan pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "estati a pa valab, chwazi nan valè akseptab ki pral swiv yo: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{races}}",
                "other": "{{unknown_races}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "pa gen ase privilèj pou defini wòl la"
            },
            "user_id": {
              "unknown_user": "nou pa jwenn itilizatè a"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON pa valab"
            },
            "user_id": {
              "unknown_user": "nou pa jwenn itilizatè a"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "nou pa jwenn lokal randevou a"
            },
            "date_of_birth": {
              "invalid_date": "ta dwe gen fòma {{date_format}}"
            },
            "email": {
              "duplicated": "nou itilize li deja"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "idantite seksyèl lan pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{genders}}"
            },
            "phone_number": {
              "invalid": "nimewo an pa valid"
            },
            "population": {
              "unknown_population": "{{unknown_population}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "paka ranpli li si idantite seksyèl lan  pa \"pito_bay_deskripsyon_an\""
            },
            "sex": {
              "unknown_sex": "sèks la pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "oryantasyon_seksyèl lan pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{races}}",
                "other": "{{unknown_races}} pa egziste, chwazi nan valè akseptab ki pral swiv yo: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "epi deja ap itilize adrès lan nan yon lòt lokal"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Panyòl oswa Latino/a",
    "not_hispanic": "Pa Panyòl oswa Latino/a",
    "hispanic_expanded": {
      "other": "Lòt Panyòl oswa Latino/a oswa gen pwovnans Espanyòl",
      "mexican": "Meksiken, Meksiken Ameriken, Chicano/a",
      "puerto_rican": "Pòtoriken",
      "cuban": "Kiben"
    },
    "subtitle": "Règleman yo egzije pou nou rekeyi enfòmasyon ki pral swiv yo",
    "help": "Gwoup etnik vle di yon seri tras ki sanble tankou langaj, koutim kiltirèl, relijyon, ak lòt karakteristik nou itilize pou fè diferans  ant gwoup moun yo. Li kapab oswa pa aliyen ak idantite ras moun yo tou.",
    "unknown": "Enkoni",
    "title": "Gwoup etnik"
  },
  "sex_at_birth": {
    "question": "Sèks",
    "female": "Fanm",
    "male": "Gason",
    "help": "Sèks la se sa ki sou ak nesans ou.",
    "non_binary": "Ki pa Binè",
    "subtitle": "Règleman yo egzije pou nou rekeyi enfòmasyon ki pral swiv yo",
    "decline": "Refize reponn",
    "unknown": "Enkoni"
  },
  "gender": {
    "help": "Idantite Seksyèl gen pou wè ak idantite pèsonèl. Li kapab oswa ka pa aliyen ak sèks yo te ba w lè w te fèt.",
    "subtitle": "Si ou ta renmen ajoute enfòmasyon konsènan idantite seksyèl ou tanpri fè sa."
  },
  "sexual_orientation": {
    "title": "Oryantasyon Seksyèl",
    "subtitle": "Si ou ta renmen ajoute enfòmasyon sou oryantasyon seksyèl, tanpri fè sa.",
    "gay": "Homoseksyèl, Lesbyèn (fanm ki renmen fanm), oswa Gason ki renmen gason",
    "heterosexual": "Hetewoseksyèl oswa renmen moun sèks opoze a",
    "bisexual": "Biseksyèl",
    "questioning": "M ap poze tèt mwen kesyon/mwen pa sèten/mwen pa konnen",
    "prefer_not_to_disclose": "Chwazi pa divilge",
    "unknown": "Enkoni",
    "orientation_not_listed": "Oryantasyon m nan pa nan lis la",
    "not_applicable": "Pa aplikab",
    "pansexual": "Pansèksyèl",
    "queer": "Kwi"
  },
  "pronouns": {
    "title": "Pwonon ou pi pito",
    "he": "Li menm/Misye",
    "she": "Li menm/Manzèl",
    "they": "Yo/Yo menm",
    "choose_not_to_disclose": "Chwazi pa divilge",
    "prefer_to_self_describe": "M pito bay deskripsyon an"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Pèmi pou Kondwi oswa Nimewo Idantifikasyon Eta a",
    "driver_license": "Pèmi pou Kondwi",
    "use_ssn_instead": "Itilize SSN (Nimewo Sekirite Sosyal) pito",
    "social_security_number": "Nimewo Sekirite Sosyal",
    "state": "Eta",
    "upload_front_of_driver_license": "Telechaje foto devan lisans ou oswa nimewo idantifikasyon ou",
    "choose_file": "Chwazi dosye",
    "no_file_chosen": "Ankenn dosye pa seleksyone",
    "no_identification": "Mwen pa gen pyès idantite",
    "insurance_card_has_a_back": "Kat asirans mwen an gen enfòmasyon dèyè li",
    "upload_front_of_insurance_card": "Telechaje foto devan kat asirans ou",
    "front_of_insurance_card_uploaded": "Foto devan kat asisrans ou a telechaje",
    "insurance_card": "Kat asirans",
    "insurance_card_back": "Foto dèyè kat asirans ",
    "back_of_insurance_card_uploaded": "Foto dèyè kat asirans ou a telechaje"
  },
  "quidel": {
    "certify_text": "Mwen sètifye  ap anrejistre rezilta yo pou tès la yon sèl fwa",
    "entering_results": "N ap Mete Rezilta w yo",
    "review_instructions_1": "Revize Enstriksyon yo ak Prekosyon",
    "review_instructions_2": "Ou kapab revize enstriksyon ki vini ak tès ou a, li gid la etap pa etap, oswa gade videyo ki bay enstriksyon yo anba a:",
    "read_instructions": "Li Enstriksyon yo",
    "watch_video": "Gade Videyo a",
    "timer": "Demare òlòj ou an lè ou finn ajite aplikatè a nan tib la. Òlòj la ap kanpe aprè 1 minit (pou ou kapab retire aplikatè a nan tib la). Tanpri sonje: Fòk ou relanse òlòj la pou ou kòmanse 10 minit yo pou tès ou a konplete.",
    "start_timer": "Demare òlòj la (Se yon Opsyon)",
    "restart_timer": "Kanpe epi Redemare",
    "resume": "Kontinye",
    "one_minute_countdown": "1 minit pase! Retire aplikatè a nan tib la epi rebalanse òlòj la.",
    "take_photo": "Pran yon foto bann tès ou a",
    "photo_warning": "Li nesesè pou w founi yon foto pou w ka kontinye.",
    "enter_results": "Mete Rezilta Tès ou a",
    "choose_result": "Chwazi opsyon ki pi sanble ak bann tès ou a. Ou bezwen èd? Klike isit la pou w ka wè egzanp kèk rezilta.",
    "positive": "Pozitif",
    "negative": "Negatif",
    "unknown": "Enkoni (Pa konnen)",
    "clarify": "N ap Klarifye Rezilta w yo",
    "strip_question": "Ak kisa bann tès ou a plis sanble?",
    "no_lines": "San liy",
    "pink_line": "Sèlman liy roz",
    "not_sure": "Mwen pako senten"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Foto rezilta tès",
    "retake_photo": "Repran Foto",
    "capture_photo": "Pran Foto",
    "confirm_information_correct": "Mwen konfime ke enfòmasyon pi wo yo kòrèk",
    "submit": "Soumèt",
    "title": "Rantre rezilta pou {{name}}",
    "subtitle": "Ou pa bezwen telechaje oswa itilize aplikasyon KOVID-19 iHealth la.",
    "instruction_title": "Gade enstriksyon iHealth yo",
    "instruction_guide": "Li enstriksyon",
    "video_guide": "Gade videyo",
    "add_photo": "Ajoute rezilta",
    "confirm_result": "Konfime rezilta",
    "result_warning": "Chwazi rezilta pou kapab soumèt",
    "confirm_warning": "Konfime rezilte pou kapab soumèt",
    "administered_at_label": "Kilè ou te pran tès sa a?",
    "instruction_subtitle": "Wè enstriksyon ki vini ak tès ou a oswa revize enstriksyon yo la a"
  },
  "public_test_groups": {
    "title": "Kit tès COVID-19",
    "appointment_recoveries_title": "Retounen?",
    "appointment_recoveries_button_text": "Jwenn lyen mwen",
    "search_test_group_title": "First time on Primary?",
    "search_test_group_button_text": "Register here",
    "title_2": "Anrejistreman senp ak rapò",
    "step_one": "Anrejistreman kont",
    "step_two": "Pran echantiyon krache pou voye ale a ",
    "step_three": "Wè rezilta yo sou entènèt",
    "title_3": "Anrejistre pou aktive kit ou a",
    "population_title": "Anrejistreman",
    "population_button": "Aktive",
    "faq_subtitle": "Konnen plis",
    "faq_title": "Kesyon yo poze souvan",
    "faq_1": "How do I return my test kit",
    "faq_1_footer": "Wè enstriksyon Tib pou krache a",
    "faq_2": "Mwen anrejistre men mwen bliye nimewo kont mwen an",
    "faq_3": "Kijan pou mwen aktive kit mwen an?",
    "faq_2_subtitle": "As a returning user you can confirm your report by using the '**Find my link**' button above.",
    "faq_3_subtitle": "Tanpri kontinye pwosesis kont Primary.Health la avèk opsyon sa yo: ",
    "footer_text_1": "This product has not been FDA cleared or approved but has been authorized for emergency use by FDA under an EUA;",
    "footer_text_2": "This product has been authorized only for the collection and maintenance of saliva specimens as an aid in the detection of nucleic acid from SARS-CoV-2 not for any other viruses or pathogens;",
    "footer_text_3": "The emergency use of this product is only authorized for the duration of the declaration that circumstances exist justifying the authorization of emergency use of medical devices under Section 564(b)(1) of the Federal Food Drug and Cos- metic Act 21 U.S.C. § 360bbb-3(b)(1) unless the declaration is terminated or authorization is revoked sooner.",
    "description": "For your convenience you can activate your test kit using the buttons below.",
    "subtitle": "Premye fwa sou Primary?",
    "subtitle_1": "Enfòmasyon sou kit tès COVID-19 yo",
    "faq_1_1": "Mete tib fèmen echantiyon an nan sak byospesimen la ",
    "faq_1_2": "Mete sak byospesimen la nan bwat SalivaDirect li te vini an. Mete bwat SalivaDirect la nan pake FedEx UN 3373 la",
    "faq_1_3": "Retire papye sou liy ki gen lakòl la sou pake FedEx UN 3373 la epi peze li byen pou fèmen li.",
    "faq_1_4": "Tanpri depoze echantiyon w lan menm jou ou pran li avan dènye lè yo ranmase pake yo. Piga a mete echantiyon w lan nan yon \"drop box\" nan jou wikenn.",
    "faq_1_5": "Vizite sit entènèt FedEx la nan **[fedex.com/labreturns](https://www.fedex.com/labreturns)** pou wè ki kote \"drop box\" yo ye epi to view drop box yo ye epi tou a kilè yo ranmase yo.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "Bay yon rezilta",
    "report_result_subtitle": "Si òganizasyon w lan ofri tès pou fè poukont ou , epi ou pare pou bay yon rezilta, tanpri peze bouton ki anba a.",
    "need_help": "Bezwen èd? ",
    "assistance": "Si ou bezwen asistans, oswa ou bezwen chanje enfòmasyon pou yo kontakte ou, tanpri kontakte Sant Sipò a.",
    "contact_support": "Kontakte Sipò",
    "save_link": "Kenbe lyen sa a pou bay rezilta yo yon lòt lè.",
    "send_via_email": "Voye lyen an pa imèl",
    "send_via_text": "Voye lyen an pa tèks SMS",
    "copy_link": "Kopye lyen an",
    "resend_email": "Revoye imèl konfimasyon an",
    "phone_verified": "Nimewo telefòn verifye",
    "add_vaccination_status": "Ajoute/Korije estati vaksinasyon COVID-19",
    "terms_of_service": "Kondisyon pou sèvis",
    "verify_contact_information": "Verifye enfòmasyon ou pou w kapab resevwa rezilta ansanm ak lòt kominikasyon. Si w bezwen chanje enfòmasyon ou, tanpri kontakte Sant Sipò nou an.",
    "resend_text": "Revoye imèl konfimasyon an",
    "loading": "Telechajman ap fèt",
    "add_to_account": "Ajoute sou kont",
    "hi": "Bonjou/Bonswa",
    "email_verified": "Imèl la verifye",
    "go_to_my_account": "Ale sou kont mwen",
    "activate_kit": "Aktive yon kit tès",
    "report_custom_result": "Bay yon rezilta: {{test}}",
    "activate_custom_test": "Aktive yon tès: {{test}}",
    "update_vaccination_status": "Mizajou estati vaksinasyon",
    "order_a_test_kit": "Kòmande yon seri tès"
  },
  "enter_information": {
    "contact_info": "Antre enfòmasyon pou kontakte ou",
    "label": "Nimewo telefòn oswa adrès imèl",
    "invalid": "Nimewo telefò sa a oswa imèl la pa bon",
    "send": "N ap voye yon kòd sou telefòn ou oswa adrès imèl ou.",
    "legal": "Lè ou antre nimewo telefòn ou oswa adrès imèl ou, ou dakò ansanm avèk kondisyon sèvis Primary Health yo **[Terms of Service](https://primary.health/terms-of-service/)** ansanm avèk **[Privacy Policy](https://primary.health/privacy-policy/)**.",
    "subtitle": "Nou pral voye yon mesaj tèks oswa imèl ba ou pou ede w jwenn dosye ou yo oswa lyen pou bay rezilta yon tès!",
    "find_link": "Ou enskri? Jwenn lyen ou an",
    "not_registered": "Pa enskri? Chèche sit ou",
    "search_by_keyword": "Chèche atravè non, mo kle, vil, kòd postal, oswa kòd òganizasyon!",
    "search": "Chèche",
    "register_here": "Enskri la"
  },
  "contact_support": {
    "invalid": "Enfòmasyon an pa bon",
    "invalid_explanation": "Ou pa gen lontan ou mande yon lyen anrejistreman pou Primary.Health. Toutfwa,",
    "never_registered": "Pa janm enskri? Tanpri kontakte moun ki ba w tès la pou yon lyen enskripsyon, oswa jwenn sit ou a pi ba a.",
    "get_help": "Ou kapab jwenn èd pou dosye w la kapab koresponn ak enfòmasyon w avèk",
    "get_help_calling": "Oswa si ou rele",
    "invalid_explanation_bold": "Enfòmasyon ou bay la pa bon",
    "get_help_label": "Mande èd",
    "mobile": {
      "invalid_explanation": "Nou pa jwenn okenn itilizatè pou ajoute sou kont ou.",
      "get_help": "Si ou genyen plis kesyon, tanpri kontakte",
      "reason": "Se pa tout itilizatè Primary.Health ki elijib pou opsyon nouvo Kont lan. Depi ou elijib, w ap resevwa yon envitasyon nan men Primary.Health oswa nan men administratè gwoup ou a."
    },
    "zen_name": "Non kontak la",
    "ticket_type": "Kisa sa konsène",
    "appointment_access_code": "Kòd konfimasyon (si ou konnen l)",
    "zen_desc": "Mesaj",
    "contact_us": "Kontakte nou",
    "need_assistance": "Si w bezwen asistans oswa pou chanje enfòmasyon kontak ou, tanpri kontakte ekip sipò a."
  },
  "show_appointments": {
    "welcome": "Jwenn pwofil ou",
    "welcome_subtitle_1": "Afiche rezilta ki anba a pou",
    "added_to_account": "Ajoute sou kont ou",
    "error": "Patisipan sa a kapab gen yon kont deja",
    "welcome_subtitle_2": "Ou pa wè non ki anba a?",
    "welcome_not_logged_in": "Jwenn lyen yon sit",
    "welcome_logged_in": "Chwazi gwoup ou",
    "welcome_subtitle_1_logged_in": "Pou w fin ajoute yon manm, chwazi itilizatè a ak gwoup la. Y ap asiyen manm yo nan yon sèl kont.",
    "welcome_subtitle_2_logged_in": "Si ou pa wè manm ou a nan lis ki anwo a, ou ka",
    "welcome_subtitle_1_not_logged_in": "Chwazi kiyès ki bezwen jwenn sit yo a oswa ajoute yon nouvo manm.Ou pa jwenn manm nan?",
    "welcome_subtitle_3_logged_in": "eseye yon lòt metòd kontak",
    "welcome_subtitle_4_logged_in": "oswa kontakte nou nan ",
    "welcome_subtitle_2_not_logged_in": "Aktyalize rechèch la.",
    "welcome_subtitle_3": "Aktyalize rechèch ou a"
  },
  "enter_code": {
    "code": "Kisa ki kòd la?",
    "enter_code": "Antre kòd yo voye nan",
    "incorrect_code": "Ou antre move kòd la",
    "verify": "Verifye kont la",
    "verified": "Verifye",
    "verify_account_information": "Verifye kont ou a"
  },
  "general_self_checkout": {
    "choose_test": "Ki tès ou te pran?",
    "photo_required": "Foto rezilta obligatwa",
    "find_your_test": "Jwenn tès ou a"
  },
  "login": {
    "create_account": "Kreye yon kont",
    "create_account_help": "Kreye yon kont se pa menm bagay ak enskri pou fè tès la oswa pou resevwa yon vaksen. Lè w fin enskri",
    "create_account_help_schedule": "Pran yon randevou",
    "create_account_help_record": "Bay rezilta tès ou fè lakay ou ",
    "create_account_help_match": "Jere rapò vaksinasyon ak tès",
    "continue": "Kontinye tankou yon envite",
    "already": "Ou deja gen yon kont? Peze",
    "login": "pou gen aksè",
    "or_sign_in": "Oswa anrejistre ak:",
    "no_account": "Ou pa gen kont? Peze",
    "signup": "pou anrejistre.",
    "here": "la a",
    "email_address": "Adrès imèl:",
    "password": "Mo sekrè",
    "complete_registration": "Finn anrejistre",
    "last_covid_test": "Dènye rezilta tès COVID-19",
    "no_record": "Pa gen dosye",
    "viewing_information": "Gade enfòmasyon {{first_name}}",
    "download": "Telechaje",
    "not_registered": "Sanble ou pa anrejistre nan ankenn gwoup",
    "please_follow_link": "Tanpri, swiv lyen envitasyon nan imèl ou a pou pran randevou ak gen aksè a enfòmasyon ou nan Primary Health. ",
    "log_results": "Anrejistre rezilta tès mwen ",
    "book_appointment": "Pran yon randevou",
    "no_new_appointments": "Nou pa asepte nouvo randevou pou kounyea",
    "upload_vaccinations": "Telechaje vaksinasyon yo",
    "vaccination": "vaksinayon",
    "new_appointment": "Nouvo randevou",
    "log_otc_results": "Telechaje rezilta pou yon tès san preskripsyon",
    "no_household_members": "Ou poko gen moun ki rete nan menm kay la",
    "no_upcoming": "Ou pa gen randevou",
    "update_vaccination": "Mizajou vaksinasyon",
    "upload_vaccination": "Telechaje vaksinasyon ",
    "title": "Byenvini nan Primary Health",
    "sub_title": "Before registering please create an account.",
    "p1": "Kreye yon kont pèmèt ou:",
    "p2": "Pran randevou pou yon pwochen fwa",
    "p3": "Anrejistre tès COVID-19 ou fè lakay ou",
    "p4": "Jere rapò vaksinasyon ak tès moun ki depandan ou nan menm kay la",
    "p5": "Pwosesis aksè ak kont ou ki senp nan my.primary.health",
    "no_vaccination_record": "Poko gen vaksen ki ajoute",
    "vaccination_title_card": "Estati vaksen Covid-19",
    "account_and_settings": "Kont ak paramèt",
    "delete_account": "Efase kont",
    "language": "Lang",
    "language_current": "Lang chwazi: {{language}}",
    "log_out": "Dekonekte",
    "delete_account_title": "Èske ou si ou vle efase kont ou a?",
    "new_encounter": "Rankont {{first_name}}",
    "new_encounter_subtitle": "Pou ki gwoup nouvou rankont sa a ye?",
    "no_programs": "Sanble ou pa anrejistre nan okenn pwogram",
    "edit_members": "Fè chanjman nan manm yo",
    "edit_members_subtitle": "Chwazi ki meni ou ta renmen ale ladan l oswa ajoute yon lòt manm sou kont ou a",
    "add_members": "Ajoute manm",
    "delete_member": "Efase kont manm {{full_name}} la?",
    "delete_member_subtitle": "Si ou retire itilizatè a, tout enfòmasyon ki asosye ak kont sa a ap efase",
    "select_member": "Seleksyon manm",
    "edit_appointment": "Fè chanjman nan randevou a",
    "route": {
      "me": "Meni",
      "history": "Istorik",
      "programs": "Ajoute tès",
      "contact_us": "Èd",
      "choose_member": "Manm",
      "settings": "Paramèt",
      "help": "Èd"
    },
    "user_dashboard": "dosye {{first_name}}",
    "view_full_results": "Gade rezilta yo nan dosye vityèl ou a",
    "required": "Obligatwa si w ap itilize Primary App la.",
    "no_email": "M pa gen imèl"
  },
  "vaccination_status": {
    "not_vaccinated": "Pa vaksinen",
    "fully_vaccinated": "Pran tout vaksen",
    "partially_vaccinated": "Pran kèk vaksen men poko pran tout",
    "vaccination_records": "Dosye vaksen",
    "title": "Ki estati vaksinasyon w kont COVID-19?",
    "definition_title": "Definisyon estati vaksinasyon",
    "definition_cdc": "Definisyon estati vaksinasyon daprè CDC (Sant pou Kontwòl Maladi)",
    "definition_osha": "Definisyon estati vaksinasyon daprè OSHA",
    "definition": {
      "fully_vaccinated": "2 semèn aprè yon dènye dòz nan seri 2 dòz Pfizer-BioNTech oswa Moderna, oswa 1 dòz vaksen Janssen Johnson & Johnson.",
      "partially_vaccinated": "Pran sèlman 1 nan 2 dòz nan yon seri primè, oswa mwens pase 2 semèn aprè w pran dènye dòz nan yon seri 2 dòz oswa 1 dòz vaksen Janssen Johnson & Johnson.",
      "not_vaccinated": "Yo pa janm bay okenn vaksen COVID-19.",
      "fully_vaccinated_with_one_booster": "Pran yon piki rapèl vaksen Pfizer-BioNTech oswa Moderna aprè yon seri konplè oswa 1 dòz vaksen Janssen Johnson & Johnson.",
      "fully_vaccinated_with_two_boosters": "Pran yon dezyèm piki rapèl vaksen Pfizer-BioNTech oswa Moderna aprè yon seri konplè oswa dòz vaksen Janssen Johnson & Johnson ak yon piki rapèl."
    },
    "label": {
      "fully_vaccinated": "Vaksinen nèt",
      "partially_vaccinated": "Vaksinen an pati",
      "not_vaccinated": "Pa vaksinen",
      "fully_vaccinated_with_one_booster": "Vaksinen nèt + yon piki rapèl",
      "fully_vaccinated_with_two_boosters": "Vaksinen nèt + 2 piki rapèl",
      "prefer_not_to_answer": "M pito pa reponn"
    },
    "progress_bar_title": "Istorik vaksinasyon COVID-19",
    "upload_record_card": "Telechaje kat vaksinasyon COVID-19",
    "show_example": "Montre egzanp",
    "hide_example": "Kache egzanp",
    "take_photo": "Klike pou fè yon foto",
    "or": "OSWA",
    "upload_image_or_pdf_instead": "Telechaje imaj/PDF pito",
    "select_doses_received": "Chwazi tout dòz vaksen COVID-19 ou pran deja.",
    "first_dose": "Premye dòz",
    "second_dose": "Dezyèm dòz",
    "first_booster_dose": "Premye piki rapèl",
    "second_booster_dose": "Dezyèm piki rapèl",
    "additional_dose": "Dòz siplemantè",
    "first_dose_manufacturer": "Ki mak fabrik (ki konpayi ki fè) premye dòz vaksen ou te pran an?",
    "first_dose_date": "Ki dat ou te pran premye dòz la?",
    "second_dose_manufacturer": "Ki mak fabrik (ki konpayi ki fè) dezyèm dòz vaksen ou te pran an?",
    "second_dose_date": "Ki dat ou te pran dezyèm dòz la?",
    "first_booster_dose_manufacturer": "Ki mak fabrik (ki konpayi ki fè) premye piki rapèl ou te pran an?",
    "first_booster_dose_date": "Ki dat ou te pran premye piki rapèl la?",
    "second_booster_dose_manufacturer": "Ki mak fabrik (ki konpayi ki fè) dezyèm piki rapèl ou te pran an?",
    "second_booster_dose_date": "Ki dat ou te pran dezyèm piki rapèl la?",
    "additional_dose_manufacturer": "Ki mak fabrik (ki konpayi ki fè) dòz siplemantè a?",
    "additional_dose_date": "Ki dat ou te pran dòz siplemantè a?",
    "completed_one": "Fini",
    "completed_two": "Mèsi dèske w mete estati vaksinasyon w ajou!",
    "progress_bar_complete": "Konplè",
    "upload_image": "Telechaje imaj",
    "retake_photo": "Pran foto a ankò",
    "other": "Lòt",
    "remove_first_dose": "Efase istorik premye dòz la",
    "remove_second_dose": "Efase istorik dezyèm dòz la",
    "remove_first_booster_dose": "Efase istorik premye piki rapèl la",
    "remove_second_booster_dose": "Efase istorik dezyèm piki rapèl la",
    "remove_additional_dose": "Efase istorik dòz siplemantè a",
    "exemption": "Èske w gen yon egzansyon?",
    "exempt": "Mwen egzan",
    "not_exempt": "Mwen pa egzan",
    "enter_exemption": "Mete egzansyon an",
    "upload_exemption_documentation": "Telechaje dokiman pou egzansyon w lan",
    "remove_dose": "Efase dòz",
    "continue": "Kontinye",
    "enter_credentials": "Tanpri mete enfòmasyon w",
    "incorrect_credentials": "Enfòmasyon an pa korèk, eseye ankò tanpri.",
    "add_photo": "Ajoute foto"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Bonjou {{name}}, Nou remake ou poko finn pran randevou pou {{group_name}} avèk {{org_name}}. Pou kenbe randevou w la, w ap bezwen konplete etap peman an. Tanpri klike sou lyen sa a pou konplete pwosesis {{complete_url}}. Si ou t a renmen anile randevou w la, tanpri klike sou lyen sa a {{cancel_url}}. Mèsi"
    }
  },
  "otc": {
    "record_results_for_abbott": "Teste ak anrejistre rezilta w yo pou tès BinaxNOW la",
    "record_results": "Teste ak anrejistre rezilta w yo",
    "activate_a_kit": "Aktive yon kit",
    "activate_a_kit_for_test": "Aktive yon kit pou {{test}}",
    "todays_test_process": "pwosesis tès jodi a:",
    "date_of_birth_question": "Kisa ki se dat fèt {{first_name}}?",
    "confirm_dob_to_continue": "Tanpri konfime dat fèt pasyan an pou kontinye:",
    "incorrect_dob": "Dat fèt la pa bon",
    "file_too_large": "Tanpri asire w ke gwosè fichye ou telechaje a pi piti pase {{size}}",
    "enter_valid_date_in_last_x_days": "Mete yon dat valid nan 30 {{n}} jou yo",
    "barcode_format_not_valid": "Fòma bakòd la pa bon",
    "complete_button": "Konplete",
    "next_button": "Swivan",
    "back_button": "Retounen",
    "report_a_test": "Bay rapò tès",
    "x_%_completed": "{{n}}% Konplè",
    "answer_survey": "Reponn kesyon sondaj",
    "steps": {
      "answer_survey": "Reponn sondaj",
      "review_instructions": "Gade enstriksyon",
      "scan_code": "Eskane kòd",
      "enter_time": "Mete lè",
      "enter_result": "Mete rezilta",
      "take_photo": "Pran foto",
      "confirm_result": "Konfime rezilta",
      "selest_test_kit": "Chwazi tip tès la",
      "enter_date": "Mete dat",
      "enter_date_and_time": "Mete dat ak lè"
    },
    "review_instructions": "Gade enstriksyon pou tès",
    "follow_instructions_or_below": "Swiv enstriksyon ki vini ak tès la oswa gade enstriksyon ki swiv yo",
    "watch_video": "Gade videyo a",
    "view_instructions": "Gade enstriksyon yo",
    "follow_instructions": "Swiv enstriksyon ki vini ak tès la",
    "scan_code": "Eskane kòd QR tès la",
    "qr_code_description": "Kòd QR ou a inik a tès ou a sèlman e ou kapab jwenn li nan tèt tès ou a a dwat",
    "show_example": "Montre egzanp",
    "hide_example": "Kache egzanp",
    "barcode_scanned": "Bakòd eskane",
    "enter_barcode": "Mete bakòd la",
    "scan_qr_code": "Eskane  kòd QR la",
    "which_test_did_you_take": "Ki tès ou pran?",
    "when_did_you_take_test": "Kilè ou pran tès sa a?",
    "what_day_did_you_take_test": "Ki jou ou te pran tès sa a?",
    "what_time_did_you_take_test": "A kilè ou pran tès sa a?",
    "time": {
      "today": "Jodia",
      "yesterday": "Yè",
      "two_days_ago": "Sa gen 2 jou"
    },
    "enter_current_time": "Mete lè li ye kounyea",
    "enter_result": "Mete rezilta tès la",
    "choose_result_option": "Chwazi opsyon ki dekri rezilta tès ou a:",
    "clarify_results": "Eksplike rezilta ou yo pi byen",
    "take_result_photo": "Pran foto rezilta yo",
    "take_result_photo_description": "Pran yon foto kat rezilta konplè a pou anrejistre rezilta ou yo",
    "my_result": "Rezilta m",
    "switch_camera": "Chanje kamera",
    "confirm_result": "konfime rezilta",
    "name": "Non:",
    "date": "Dat:",
    "result": "Rezilta:",
    "results": "Rezilta",
    "test_submitted": "Mèsi, {{first_name}}!  {{test}} ou a byen anrejistre.",
    "dob": "Dat ou fèt:",
    "administered": "Bay:",
    "or": "OSWA",
    "upload": "Telechaje",
    "change_uploaded_file": "Chanje fichye ki telechaje a",
    "take_photo": "Pran yon foto",
    "confirm_information": "Konfime enfòmasyon",
    "barcode": "Bakòd:",
    "scan_test_barcode": "Eskane bakòd tès la",
    "barcode_description": "Bakòd ou a inik tès ou a sèlman, e ou kapab jwenn li nan tès ou a",
    "enter_date_in_last_30_days": "Mete yon dat aproksimatif nan 30 dènye jou yo ak yon lè ou panse ou te pran tès la",
    "add_image": "Ajoute imaj",
    "change_image": "Chanje imaj",
    "skip_barcode_reader": "Ou pa kapab eskane tès la? Klike la a pou w anrejistre rezilta tès la kanmèm",
    "enter_date_and_time": "Mete dat ak lè tès la fèt",
    "enter_date": "Mete dat tès la",
    "did_you_take_test_today": "Èske ou pran tès la jodia?",
    "record_results_for_generic": "Teste ak anrejistre rezilta w yo pou tès {{name}} yo",
    "choose_result_option_custom": "Chwazi opsyon ki dekri rezilta {{test_name}} ou a:"
  },
  "yes": "Wi",
  "no": "Non",
  "event_token": {
    "title": "Tanpri mete nimewo kòd ki anba a nan aparèy la pou ou jwenn kit tès ou a",
    "loading_text": "Telechajman ankou, retounen nan yon minit"
  },
  "appointment_recovery": {
    "complete_title": "Tout bagay anfòm!",
    "subtitle_1": "Si w ap itilize yon iPhone, peze **Done** nan kwen anlè a gòch ekran sa a pou w retounen nan meni dosye pa w la.",
    "subtitle_2": "Si w ap itilize yon Android, peze **X**  nan kwen anlè a gòch ekran sa a.",
    "subtitle_0": "Tanpri fèmen paj sa a pou kontinye."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Relasyon ki gen konsantman",
  "finish_registration": {
    "title": "Fini ak enskripsyon w",
    "subtitle": "Tanpri fin mete enfòmasyon swivan sa a anvan randevou w:",
    "button": "Fini enskripsyon an"
  },
  "arab_ethnicity": {
    "arab": "Arab",
    "non_arab": "Pa arab",
    "prefer_not_to_answer": "M pito pa reponn",
    "question": "Etnisite arab",
    "subtitle": "Règleman yo egzije pou nou rekeyi enfòmasyon swivan.",
    "help": "Etnisite vle di yon seri tras moun pataje tankou lang, koutim kiltirèl, relijyon, ak lòt karakteristik yo itilize pou fè diferans ant gwoup moun. Li gendwa mache oswa pa mache ak idantite rasyal yon moun."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Dapre aparèy w ap itilize a, klike sou lyen ki anba a pou telechaje Primary Health Pass la."
    }
  },
  "stepper": {
    "next": "Pwochen",
    "back": "Fè bak",
    "complete": "Konplè"
  },
  "registration_direcory": {
    "keyword_search_label": "Mo kle, vil, kòd postal, non òganizasyon",
    "keyword_search_label_mobile": "Vil, kòd postal, non òganizasyon",
    "zero_results": {
      "title": "Nou pa jwenn okenn founisè ak tèm rechèch sa a.",
      "sub_title": "Eseye chèche founisè ou a ak:",
      "p1": "Kòd postal",
      "p2": "Non vil",
      "p3": "Non konte",
      "p4": "Non founisè oswa òganizasyon"
    }
  },
  "registration_flyer": {
    "open_camera": "Louvri aplikasyon kamera sou aparèy ou",
    "point_to_qr_code": "Vize kamera a sou kòd QR la epi klike sou lyen an",
    "complete_registration": "Fini enskripsyon",
    "need_help": "Èske w bezwen èd? Tanpri kontakte nou nan"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Atestasyon enfòmasyon vaksen",
    "select_checkbox": "Tanpri revize enfòmasyon ki anba a epi chwazi kaz ki kòrèk la anvan ou kontinye.",
    "opportunity_to_read": "Mwen genyen oswa yo ban m opòtinite pou m li, oswa yo te li pou mwen, Fèy Enfòmasyon sou Vaksen (“VIS”) oswa fèy enfòmasyon sou Otorizasyon pou Itilizasyon Ijan (“EUA”) yo te bay pou vaksen yo pral ban mwen an(yo):",
    "vaccine_info_sheet": "fèy enfòmasyon sou vaksen",
    "vaccine_fact_sheet": "fèy enfòmasyon sou vaksen",
    "vaccine_info_statement": "Deklarasyon enfòmasyon sou vaksen"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "Polyo",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "Varisèl",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "this kit includes tests for chlamydia and gonorrhea.",
    "B": "this kit includes tests for chlamydia and gonorrhea.",
    "C": "this kit includes tests for syphilis.",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "this kit includes tests for HIV and syphilis.",
    "box_type": "Box {{type}}",
    "administered_text": "We will contact you when your kit reaches the lab and when your results are ready.",
    "activate_kit": "Activate my test kit",
    "register_another_test": "Register another test",
    "credentials": {
      "title": "Let's get started",
      "label": "Contact",
      "p1": "We'll start with some info about you.",
      "location_step": {
        "p1": "Who provided your test kit?",
        "load": "Load more"
      }
    },
    "checklist": {
      "not_urinated": "You have not urinated in the last hour.",
      "bathroom": "You have access to a bathroom for urine collection.",
      "anal_swab": "You have access to a private place to perform an anal swab.",
      "blood_extraction": "You have access to a clean environment for blood extraction.",
      "washed_hands": "You have washed your hands with soap and warm water for 30 seconds.",
      "title": "Are you ready to begin sample collection?",
      "no_alcohol": "Do not drink alcohol on the day of collection.",
      "menstruation": "Do not collect vaginal swab specimen during menstruation or within 24 hours of intercourse."
    },
    "confirm": {
      "title": "Confirm your information",
      "p1": "Is this information correct?"
    },
    "display_name": {
      "A": "Chlamydia & Gonorrhea (Single Site)",
      "B": "Chlamydia & Gonorrhea (3 Site)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia & Gonorrhea",
        "B": "Chlamydia & Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "COVID-19",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV"
    },
    "rescan": "Rescan",
    "scanned": "You've scanned Box {{type}}",
    "not_yet": "Not Yet",
    "success_registration": "Registration success!",
    "no_box": "Don't have a box?",
    "faq_link": "Visit FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "You're one step closer to a healthier you.",
      "p1": "Scan or enter the **kit bar code** on the side of your box."
    },
    "appointments": {
      "title": "Select the patient & provider",
      "p1": "We found more than one profile associated with your contact information. Select the correct profile:",
      "new": "New patient or provider"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "We found your patient record."
    },
    "section_1": "Confirm kit contents",
    "section_2": "Test collection",
    "section_3": "Packaging",
    "section_4": "Shipping",
    "box_a": "Box A",
    "box_b": "Box B",
    "box_c": "Box C",
    "kit_flow": {
      "button_1": "Continue to packaging",
      "button_2": "I'm ready",
      "button_3": "My kit is complete and packed",
      "button_4": "I'm finished",
      "button_5": "Next test",
      "button_6": "Skip instructions"
    },
    "contents": {
      "header": "Great! Let's make sure your kit is complete",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Blood collection card",
      "blood_collection_card_subtitle": "(1 or 2 depending on test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Single use lancets",
      "up_to_other": "(Up to 4 depending on test kit)",
      "adhesive_bandages": "Adhesive bandages",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Biodegradable collection paper",
      "bottle_and_probe": "Sampling bottle and probe",
      "prepaid_envelope": "Pre-paid shipping envelope",
      "biohazard_subtitle": "(with absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Biohazard bags",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 sterile gauze pad",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "Packaging checklist",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "The specimen collection tube is sealed in its biohazard bag",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_b": {
      "step_1": "You have packed all test kit contents, even if you skipped the test(s)",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "Each specimen collection tube is sealed in its own biohazard bag (only **one** specimen per bag)",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_c": {
      "step_1": "You have packed all test kit contents, including used lancets.",
      "step_2": "Your **date-of-birth** is written on the blood collection card using MM/DD/YYYY format",
      "step_3": "The **collection date** is written on the blood collection card using MM/DD/YYYY format",
      "step_4": "The blood collection card and all used lancets are placed in the biohazard bag",
      "step_5": "The biohazard bag is placed into the box",
      "step_6": "The box is placed into the pre-paid shipping envelope",
      "step_7": "The return shipping envelope is completely sealed"
    },
    "success": {
      "header_1": "Great!",
      "text_1": "You're ready to collect your first sample.",
      "header_2": "You did it!",
      "text_2": "**Next up:** package your kit to send back.",
      "header_3": "Great job!",
      "text_3": "You’re one step closer to better health.",
      "header_4": "Nice job!",
      "text_4": "Just one more and you’re done!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "Select a test to view instructions:",
      "subtitle": "Test",
      "button": "Instructions",
      "pdf": "PDF instructions"
    },
    "instructions_box_a": {
      "title": "Urine collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
      "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
      "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
      "step_4_sublabel": "Do not fill tube past the maximum fill line or specimen will be rejected.",
      "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
      "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Urine collection",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
        "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
        "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
        "step_4_subtitle": "Do not fill tube past the maximum fill line or specimen will be rejected.",
        "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab into your mouth and rub the swab tip against the back of your throat for 10 seconds.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a blue <span style=\"color: #001F70\">**\"ORAL\"**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab 3 - 5 cm (1 - 2”) into the anal canal. Gently turn the swab for 5 - 10 seconds to collect any potential organisms.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a green <span style=\"color: #00C08C\">**“ANAL”**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      }
    },
    "instructions_box_c": {
      "title": "Blood collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your name, date of birth, and the date of collection in the designated fields. Use MM/DD/YYYY format.",
      "step_2": "Open blood card flap to expose the circles on the blood collection paper. Do not touch the blood collection paper.",
      "step_3": "Wash hands with warm water for at least 30 seconds, then shake hands vigorously for 15 seconds to encourage blood flow to your fingers.",
      "step_4": "Clean fingertip with alcohol pad. It is best to use the middle or ring finger of your non-dominant hand.",
      "step_5": "Take the lancet and twist off the cap. Press the small tip firmly into the tip of your finger, until the needle ejects with a click. Keeping your hand below your heart during collection, massage finger from base to tip to encourage blood flow.",
      "step_5_subtitle": "Lancets are single use. All lancets need to be returned with your sample to the laboratory for disposal.",
      "step_6": "Starting at the center, apply 3-6 drops to fill the circle and soak through the collection paper. Do not touch the paper with your finger as this will restrict blood flow. Once circle is full, move on to next circle. It is okay for blood to extend beyond lines, but do not let blood spots spread into each other.",
      "step_6_subtitle": "Do not add additional blood to a circle once completed or dry. The “layering” of blood will invalidate the collection.",
      "step_7": "Without closing the blood card, lay it on a flat surface and allow the blood collection paper to air dry at room temperature, for at least 30 minutes. Do not heat, blow dry, or expose the blood collection paper to direct sunlight. Heat will damage the specimen.",
      "step_7_subtitle": "Check the back side of blood collection paper. Blood should saturate all the way through and fill each circle of the collection paper.",
      "step_8": "When blood collection paper is dry, close blood card by tucking flap. Place the blood card and lancets into biohazard bag with the desiccant pack. Ensure biohazard bag is properly sealed."
    },
    "test_tips": {
      "title": "Tips for Proper Blood Collection",
      "subtitle": "For best results:",
      "step_1": "Be sure you are not dehydrated while performing collection. Hydration promotes blood flow.",
      "step_2": "Do not perform collection immediately after smoking.",
      "step_3": "Washing and warming your hands under warm water will help promote blood flow in your hands.",
      "step_4": "Shake hands vigorously towards the floor to encourage blood flow to your fingers.",
      "step_5": "Keep collection device and hands below your heart during collection for best blood flow.",
      "step_6": "You may need more than one finger prick. Repeat these tips between each finger prick."
    },
    "shipping": {
      "header": "Your kit is ready to ship!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "Pick up your recommended test kit from the site staff and register below",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "View results",
    "recommended_kit_title": "Recommended Test Kits",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection",
      "subtitle": "Collection tubes may contain a liquid preservative. <span class=\"text-danger\">**Do not empty liquid preservative from the collection tubes if present.**</span>",
      "step_1": "Hold the swab between the faint score line (if present) and the cotton-tipped portion of the swab in one hand and separate the labia (folds of skin around the vaginal opening).",
      "step_2": "Insert the swab 5cm (2in) into the vaginal opening. Gently turn the swab for 30 seconds while rubbing the swab against the walls of the vagina.",
      "step_3": "Carefully withdraw the swab and place into the collection tube marked with a <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Break the swab by bending against the collection tube.",
      "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_5": "Write your date of birth in MM/DD/YYYY format onto the tube in the designated area (DOB).",
      "step_6": "Write the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area (Coll. Date).",
      "step_7": "Place specimen into the empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "packaging_box_h": {
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "Your **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_6": "The biohazard bag is placed into the box"
    },
    "instructions_box_cc": {
      "title": "Stool collection",
      "step_1": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area. Open green cap by twisting and lifting.",
      "step_2": "Place supplied collection paper into toilet bowl on top of water.",
      "step_3": "Deposit stool sample on top of collection paper.",
      "step_4": "Collect sample from stool before paper sinks and stool sample touches water.",
      "step_5": "Scrape the surface of the fecal sample with the sample probe.",
      "step_6": "Cover the grooved portion of the sample probe completely with stool sample.",
      "step_7": "Close sampling bottle by inserting sample probe and snapping green cap on tightly. Do not reopen.",
      "step_8": "Flush. Collection paper is biodegradable and will not harm septic systems.",
      "step_9": "Wrap sampling bottle in absorbent pad and insert in to  the biohazard bag."
    },
    "contact_support": {
      "title": "Let's fix this",
      "text_1": "We are sorry to hear that something is wrong!",
      "text_2": "Please contact us to let us know what’s wrong and we will help to replace your kit."
    },
    "contact_support_success": {
      "title": "We received your message",
      "text_1": "Thanks for contacting us.",
      "text_2": "A member of our support team will be in touch soon.",
      "button_text": "Return home"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B."
  },
  "copy_link": "Kopye lyen paj la",
  "copied_link": "Ou kopye lyen an!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Pwoteksyon kont viris grip ki pi komen yo",
      "vaccine_covid": "Pwoteksyon kont viris COVID-19",
      "tdap": "Vaksen Tdap ka anpeche tetanòs",
      "polio": "Pwoteksyon kont viris Polyo. Obligatwa pou timoun anvan yo kòmanse lekòl.",
      "varicella": "Vaksen ki pwoteje kont varisèl",
      "shingles": "Vaksen ki pwoteje kont zona. Laj minimòm se 18 lane.",
      "human_papillomavirus": "Vaksen ki pwoteje kont HPV ( Viris Papiloma). Rekòmande pou timoun ki gen 11 lane.",
      "meningococcal": "Vaksen ki pwoteje kont kat (4) kalite bakteri menengosik yo."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Jwenn yon klinik",
    "select_pin": "Chwazi yon pin pou wè detay yo",
    "miles_shorten": "{{miles}} mil(distans)",
    "next_available": "Pwochen ki disponib",
    "appointment_type": "Tip randevou",
    "individual_appointment": "Randevou endividyèl",
    "family_appointment": "Randevou pou fanmi",
    "vaccines": "Vaksen",
    "what_kind_appointment": "Ki kalite randevou ou vle pran?",
    "select_family_or_individual": "Chwazi randevou familyal si w ap fè randevou pou plizyè manm fanmi an menm tan.",
    "vaccines_selected": "Vaksen ou chwazi yo",
    "where_care": "Ki kote ou vle resevwa swen?",
    "select_vaccines_for_individual": "Chwazi vaksen pou randevou ou a (opsyonèl).",
    "select_vaccines_for_family": "Chwazi vaksen pou randevou familyal ou a (opsyonèl).",
    "schedule_services": "Pwograme sèvis yo",
    "add_family": "Ajoute manm fanmi yo epi chwazi sèvis yo anba a.",
    "family_member": "Manm fanmi {{number}}",
    "morning": "Maten ",
    "afternoon": "Aprèmidi",
    "slot_available": "{{number}} disponib",
    "within_x_miles": "Nan yon distans {{miles}} mil ",
    "any_distance": "Nenpòt distans apati",
    "partial_results": "Rezilta pasyèl",
    "partial_matches": "Klinik sa yo ofri kèk nan vaksen w ap chèche yo, men li pa ofri tout",
    "no_matches": "Nou pa jwenn okenn rezilta nan rechèch sa a. Eseye chanje filtè w yo pou jwenn pi bon rezilta.",
    "no_clinics_found": "Pa gen okenn klinik ki matche ak rechèch ou a.",
    "broaden_filters": "Eseye elaji filtè w yo pou jwenn pi bon rezilta.",
    "unavailable_vaccines": "Vaksen ki pa disponib:",
    "available_vaccines": "Vaksen ki disponib:",
    "select_date": "Chwazi yon dat",
    "available_appointments": "RANDEVOU KI DISPONIB",
    "appointment_scheduling_info": "\r\n\r\nOu pral kapab chwazi lè randevou pou manm fanmi espesifik nan pwochen etap la. Ou ka pwograme randevou yo nan jou diferan si w vle.",
    "hold_selected_appointments": "N ap kenbe randevou ou te chwazi yo pou 15 minit.",
    "appointments_selected": "RANDEVOU OU TE CHWAZI YO",
    "no_appointments_selected": "Ou pa chwazi okenn randevou",
    "vaccines_needed": "Vaksen ki nesesè",
    "select_x_appointments": "Chwazi {{number}} randevou",
    "more": "Plis",
    "less": "Mwens",
    "register_next_family_member": "Enskri pwochen manm fanmi an",
    "successfully_registered_x_of_y_family_members": "Ou anrejistre avèk siksè {{x}} nan {{y}} manm fanmi",
    "next_family_member": "Pwochen manm fanmi an",
    "appointments_abbreviated": "Randevou",
    "register_for_clinic": "Enskri pou klinik",
    "select_services": "Chwazi sèvis",
    "person": "Moun {{number}}",
    "add_person": "Ajoute yon lòt moun",
    "registration_confirmation": "Konfimasyon enskripsyon"
  },
  "user_mailer": {
    "verify_email": "Ann verifye imèl ou!",
    "hi_full_name": "Bonjou {{full_name}},",
    "verify_email_button": "Verifye imèl",
    "please_verify_email": "Tanpri itilize bouton ki anba a pou verifye imèl ou."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "Epatit A",
    "hepatitis_b": "Epatit B",
    "hepatitis_a_b": "Epatit A ak B",
    "hib": "Hib (Haemophilus influenzae tip b)",
    "mmr": "Saranpyon, Mal mouton, Ribeyòl",
    "meningococcal": "Menengokòk",
    "mpox": "Varyòl Senj",
    "pneumococcal": "Nemokòk",
    "polio": "Polyo",
    "rsv": "Viris respiratwa sensityal",
    "rotovirus": "Wotaviris",
    "zoster": "Zoster (zona)",
    "tetanus_diptheria": "Tetanos ak Difteri",
    "tdap": "Tdap",
    "typhoid": "Tifoyid",
    "varicella": "Varisèl",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (granmoun)",
    "covid-19_adult_description": "Pou mou ki gen 12 lane oswa plis.",
    "covid-19_children": "COVID-19 (Timoun)",
    "covid-19_children_description": "Pou timoun ki gen 4 - 11 lane.",
    "covid-19_infants": "COVID-19 (Ti bebe)",
    "covid-19_infants_description": "Pou timoun ki gen ant 6 mwa ak 3 lane.",
    "influenza": "Grip",
    "mmrv": "Saranpyon, Mal mouton, Ribeyòl, Varisèl"
  },
  "deep_archived": {
    "admin": "Pou rezon sekirite done, nou achive done ki nan gwoup {{test_group_name}} ({{slug}}) yo epi ou pa ka wè yo oswa modifye yo ankò sou paj sa a. Tanpri kontakte responsab kont ou oswa support@primary.health si ou bezwen asistans.",
    "participant": "Paj sa a pa disponib ankò. Tanpri kontakte kontak ou yo nan kanpay sante a: {{test_group_name}}. Si w gen pwoblèm pou wè yon ansyen dosye, tanpri kontakte support@primary.health pou asistans."
  }
}