import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ChosenServices from "./ChosenServices";
import ServiceCard from "./ServiceCard";
import ServicesModal from "./ServicesModal";

const STATUS_COLORS = {
  ready: "green",
  pending: "orange",
}

export const createTest = async (slug, uid, appointmentId, testConfigurationId) => {
  try {
    const response = await axios.post(`/test_groups/${slug}/appointments/${appointmentId}/checkout`, {
      test_configuration_id: testConfigurationId,
      uid,
    });
    return response.data;
  } catch (error) {
    toastr.error("Something went wrong");
    console.log(error);
    return null;
  }
};

export const StatusDot = ({
  className,
  id=null,
  status
}) => {
  if (status === "ready") {
    return (
      <FontAwesomeIcon
        className={className}
        color={STATUS_COLORS["ready"]}
        icon={faCheckCircle}
        {...(id && { id })}
      />
    );
  }

  return (
    <span
      className={`${className} ${status}`}
      {...(id && { id })}
      style={{
        display: "inline-block",
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: STATUS_COLORS[status],
      }}
    >
    </span>
  );
};

const ServicesSection = ({
  appointment,
  displayChosenServices = false,
  setTests,
  submitCheckoutAttempted,
  testConfigurations,
  testGroup,
  tests,
  ...props
}) => {

  const addedVaccineTestConfigurationIds = tests
    .filter(test => test.service_type == "immunization")
    .map(test => test.test_configuration.id);
  const availableTestConfigurations = testConfigurations
    .filter(tc => !addedVaccineTestConfigurationIds.includes(tc.id));
  const [edit, setEdit] = useState(false);
  const [hasUnsavedCardTag, setHasUnsavedCardTag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showServicesModal, setShowServicesModal] = useState(false);
  const status = !tests.length || hasUnsavedCardTag ? "pending" : "ready"
  const errored = submitCheckoutAttempted && status == "pending";

  const addTests = async (testConfigurationIds) => {
    setLoading(true);
    const newTests = [];
    for (const testConfigurationId of testConfigurationIds) {
      const testConfiguration = availableTestConfigurations.find(tc => tc.id == testConfigurationId);
      const shouldCreateDummyTest = testConfiguration.service_type !== "immunization" &&
        ["no_configuration", "scan_only"].includes(testConfiguration.generate_record_configuration);
      if (shouldCreateDummyTest) {
        // creates a dummy test with no ID or UID using the test_configuration data
        // dummy tests cards on the ui have "Scan barcode" and "Generate barcode" buttons where user has to pick how to assign barcode
        newTests.push(
          {
            service_kind_label: testConfiguration.service_kind_label,
            service_type: testConfiguration.service_type,
            test_configuration: testConfiguration,
            uid: "",
          }
        )
      } else {
        // creates a test with randomly generated uid
        // TODO: discover options to save these tests in batches
        const newTest = await createTest(testGroup.slug, null, appointment.id, testConfiguration.id);
        if (newTest) {
          newTests.push(newTest);
        } else {
          toastr.error("Something went wrong");
        }
      }
    };
    setTests([...tests, ...newTests]);
    setShowServicesModal(false);
    setLoading(false);
  };

  useEffect(() => {
    const atLeastOneServiceIsUnsaved = document.querySelectorAll('.unsaved-card-tag').length > 0;
    setHasUnsavedCardTag(atLeastOneServiceIsUnsaved);

    const servicesSideBarStatusIndicator = document.getElementById(`services_sidebar_status_indicator`);
    if (!!servicesSideBarStatusIndicator) {
      atLeastOneServiceIsUnsaved
        ? servicesSideBarStatusIndicator.classList.remove('d-none')
        : servicesSideBarStatusIndicator.classList.add('d-none');
    }
  }, [tests]);

  return (
    <div
      className="p-3 checkout-section"
      id="services_section_container"
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "16px",
      }}
    >
      {displayChosenServices && (
        <ChosenServices
          chosenServices={appointment.chosen_test_configurations}
        />
      )}
      <div className="my-3 d-flex justify-content-between">
        <div className="d-flex">
          <span className="h4-20-semi-bold poppins semibold my-auto">
            Services
          </span>
          {errored ? (
            <FontAwesomeIcon
              color="#CB2531"
              className="ms-2 my-auto"
              icon={faCircleExclamation}
            />
          ) : (
            <StatusDot
              className="ms-2 my-auto"
              status={status}
            />
          )}
        </div>
        <div>
          <Button
            onClick={() => setShowServicesModal(!showServicesModal)}
            variant="link"
          >
            Add services
          </Button>
        </div>
      </div>
      {tests.map(test => {
        return (
          <ServiceCard
            appointment={appointment}
            color={test.test_configuration.color_code}
            containerClassName="my-3"
            edit={edit}
            isGroupOnDemandOnly={testGroup.on_demand_only}
            key={test.id}
            serviceSpecification={test.test_configuration.service_specification}
            serviceType={test.test_configuration.service_type}
            setEdit={setEdit}
            setTests={setTests}
            test={test}
            testGroup={testGroup}
            tests={tests}
            title={test.test_configuration.display_name}
            {...props}
          />
        )
      })}
      <ServicesModal
        addTests={addTests}
        loading={loading}
        showServicesModal={showServicesModal}
        setShowServicesModal={setShowServicesModal}
        testConfigurations={availableTestConfigurations}
      />
    </div>
  );
};

export default ServicesSection;