import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faUser, faEnvelope } from '@fortawesome/pro-solid-svg-icons';

const GuardianInformation = ({ appointment }) => {
  if (!appointment['has_guardian_information?']) return <span></span>
  const [
    consentedGuardianName,
    consentedGuardianPhoneNumber,
    consentedGuardianEmail,
  ] = [
    appointment.consented_guardian_name,
    appointment.consented_guardian_phone_number,
    appointment.consented_guardian_email,
  ];
  const [isGuardianDetailsVisible, setIsGuardianDetailsVisible] = useState(false);
  return (
    <div>
      <div
        onClick={() => setIsGuardianDetailsVisible(!isGuardianDetailsVisible)}
        style={{
          color: 'black',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        {isGuardianDetailsVisible ? 'Hide guardian details' : 'Show guardian details'}
      </div>

      {isGuardianDetailsVisible && (
        <>
          {consentedGuardianName && (
            <div className="mt-3">
              <FontAwesomeIcon className="me-3" color="#CBD2E0" icon={faUser} />
              <span>{consentedGuardianName}</span>
            </div>
          )}
          {consentedGuardianPhoneNumber && (
            <div className="mt-3">
              <FontAwesomeIcon className="me-3" color="#CBD2E0" icon={faPhone} />
              <span>{consentedGuardianPhoneNumber}</span>
            </div>
          )}
          {consentedGuardianEmail && (
            <div className="mt-3">
              <FontAwesomeIcon className="me-3" color="#CBD2E0" icon={faEnvelope} />
              <span>{consentedGuardianEmail}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GuardianInformation;