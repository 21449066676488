import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FixInsuranceModal from './FixInsuranceModal';
import { format } from 'date-fns';

const ConditionalButtons = ({
  test_group,
  user,
  accessCodeRegistrationUrl,
  setShowInsuranceModal,
  showInsuranceModal,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {test_group.request_additional_appointment && (
        <>
          <a href={accessCodeRegistrationUrl} className="btn btn-primary mt-3">
            {t('login.new_appointment')}
          </a>
          <br />
        </>
      )}
      {!user['belongs_to_account?'] && (
        <>
          <Button
            variant="primary"
            className="mt-3"
            href={`/members/sign_up?invite_code=${user.invite_code}`}
          >
            {t('self_resulting.add_to_account')}
          </Button>
          <br />
        </>
      )}
      {user.admin_requests_change_on_insurance && (
        <React.Fragment>
          <Button
            onClick={() => setShowInsuranceModal(true)}
            variant="primary"
            className="mt-3"
          >
            Complete insurance
          </Button>
          {showInsuranceModal && (
            <FixInsuranceModal
              test_group={test_group}
              user={user}
              setShow={setShowInsuranceModal}
            />
          )}
        </React.Fragment>
      )}
    </>
  );
};

export const BackButton = ({ onBackClick }) => {
  return (
    <div className="mb-3">
      <div
        className="d-flex justify-content-center align-items-center my-auto"
        style={{
          cursor: 'pointer',
          borderStyle: 'solid',
          borderWidth: 2,
          borderColor: '#CBD2E0',
          height: 44,
          width: 44,
          borderRadius: '50%',
          marginLeft: '0',
        }}
        onClick={onBackClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    </div>
  );
};

const UserDetailsSection = ({
  user,
  test_group,
  accessCodeRegistrationUrl,
  setShowInsuranceModal,
  showInsuranceModal,
  noTestSelected,
  onBackClick,
  isMobile,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={isMobile ? 'card-bottom-border' : 'card border-0'}
      style={{ height: 'auto' }}
    >
      <div className={isMobile ? 'p-3' : 'card-body'}>
        {!noTestSelected && <BackButton onBackClick={onBackClick} />}
        <div className="row">
          <div className="text-secondary-200 body-medium-bold col-md-12 col-auto my-auto pe-0">
            {t('result_page.patient').toUpperCase()}
          </div>
          <div className="inter body-large-16-regular medium my-auto col-auto">
            {user.full_name}
          </div>
        </div>
        {user.date_of_birth && (
          <div className="row mt-3">
            <div className="text-secondary-200 body-medium-bold col-md-12 col-auto my-auto pe-0">
              {t('registration.dob')}
            </div>
            <div className="inter body-large-16-regular medium my-auto col-auto">
              {user.date_of_birth}
            </div>
          </div>
        )}
        {!isMobile && (
          <>
            {user.phone_formatted && (
              <>
                <div className="text-secondary-200 body-medium-bold mt-3">
                  {t('result_page.phone').toUpperCase()}
                </div>
                <div className="inter body-large-16-regular medium">
                  {user.phone_formatted}
                </div>
              </>
            )}
            {user.email && (
              <>
                <div className="text-secondary-200 body-medium-bold mt-3">
                  {t('registration.email').toUpperCase()}
                </div>
                <div className="inter body-large-16-regular medium">
                  {user.email.length > 21
                    ? user.email.substring(0, 21) + '...'
                    : user.email}
                </div>
              </>
            )}
            {user.city && user.postal_code && user.state && (
              <>
                <div className="text-secondary-200 body-medium-bold mt-3">
                  {t('location_details.labels.address').toUpperCase()}
                </div>
                <div className="inter body-large-16-regular medium">
                  {user.city}, {user.state}
                </div>
                <div className="inter body-large-16-regular medium">
                  {user.postal_code}
                </div>
              </>
            )}
          </>
        )}
        <ConditionalButtons
          test_group={test_group}
          user={user}
          accessCodeRegistrationUrl={accessCodeRegistrationUrl}
          setShowInsuranceModal={setShowInsuranceModal}
          showInsuranceModal={showInsuranceModal}
        />
      </div>
    </div>
  );
};

export default UserDetailsSection;
