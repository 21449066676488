export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "월",
    "day": "일",
    "year": "년",
    "january": "1월",
    "february": "2월",
    "march": "3월",
    "april": "4월",
    "may": "5월",
    "june": "6월",
    "july": "7월",
    "august": "8월",
    "september": "9월",
    "october": "10월",
    "november": "11월",
    "december": "12월"
  },
  "user": {
    "send_appointment_confirmation_message": "% {name}님과의 예약이 확정되었습니다",
    "landline_appointment_reminder_message": "안녕하세요, {{full_name}} 님. {{name}} 님의 예약 알림입니다. 귀하의 예약 일시는 {{date}} {{time}}이고 예약 장소는 {{address}}입니다.",
    "send_at_home_visit_confirmation_message": "귀하의 예약이 %{날짜} %{시간} 이후로 확정되었습니다.",
    "send_mail_order_confirmation_message": "귀하의 우편 주문이 확인되었습니다",
    "send_waitlist_message": "{{name}} 님은 예약 대기자에 추가되었습니다. 예약이 확정되기 전까지는 방문을 삼가 주시길 바랍니다.",
    "verify_contact": {
      "phone": "링크를 클릭하여 전화번호 인증을 부탁드립니다: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "옆 링크를 통해 예약 등록를 계속해 주시길 바랍니다: {{url}}",
    "on_demand_confirmation_message": "%(성함)을 성공적으로 등록하셨습니다.",
    "appointment_reminder": "예약 시간 {{start_time}} 전에 다음 링크를 통해 모든 필요한 항목들을 완성하고 본인의 예약 확정 상황을 확인하여 주시길 바랍니다. 또한 15분보다 일찍 예약 장소에 방문하는 것을 삼가 주시길 바랍니다.",
    "appointment_reminder_base": "{{text}} 여기를 클릭하세요: {{link}}",
    "test_result_notifier": {
      "text_message": "{{first_name_with_last_initial}}의 환자 포털이 업데이트되었습니다. {{url}}로 이동하여 코드: {{access_code}}'를 사용하세요.",
      "email_subject": "환자 포털의 새로운 업데이트",
      "email_intro": "안녕하세요 {{first_name_with_last_initial}}",
      "email_p1": "새로운 메시지나 환자 업데이트를 사용할 수 있습니다.",
      "email_p2": "아래 버튼을 클릭하거나 링크를 사용하여 환자 포털을 보고 코드를 사용하세요.",
      "email_p3": "환자 포털 보기:'",
      "email_button": "포털 보기"
    },
    "landline_appointment_reminder_message_without_time": "안녕하세요, {{full_name}} 님. {{name}}으로 예약된 귀하의 예약 알림입니다. 귀하의 예약 일시는 {{date}}이고 예약 장소는 {{address}}입니다.",
    "appointment_reminder_on_demand": "{{date}} 예약일 전에 다음 링크를 통해 모든 필요한 항목들을 완성하고 본인의 예약 확정 상황을 확인하여 주시길 바랍니다.",
    "new_test_result_notifier": {
      "email_intro": "귀하의 환자 포털이 업데이트되었습니다.",
      "email_text_1": "새로운 환자 기록이나 실험실 보고서를 보려면 아래 의료 기록 포털을 방문하십시오.",
      "email_button": "병력",
      "email_text_2": "다음 URL을 복사하여 브라우저에 붙여넣을 수도 있습니다."
    },
    "mailers": {
      "email_contact_us": "질문이 있으신가요? 여기로 저희에게 연락 주세요"
    }
  },
  "errors": {
    "generic": {
      "message": "오류가 발생하였습니다. 다시 시도 바랍니다."
    },
    "messages": {
      "blank": "이 부분은 꼭 입력해야 합니다."
    },
    "incorrect_credentials": "자격 증명이 올바르지 않습니다. 다시 시도해 주세요.",
    "error_activating_test_kit": "테스트 키트를 활성화하는 중에 오류가 발생했습니다."
  },
  "type": "입력",
  "payment": {
    "continue_label": "결제 진행",
    "policy_text": "예약을 취소하시거나 예약 장소에 나타나지 않으실 경우 예약 일시로부터 7일 후에 지불액의 80%를 환불 받으실 수 있습니다. 다음 링크를 통해 전체 [서비스 약관]({{link}}) 확인 가능합니다. ",
    "card_number": "카드 번호",
    "expiry_date": "만료일",
    "pay_button": "지불",
    "no_credit_card": "현장결제 하겠습니다",
    "cash_payment_helper": "예약 장소에 **{{payment}}**를 지참하여 주시길 바랍니다.",
    "invoice": "영수증",
    "pay_by_card": "카드로 지불",
    "cost": "금액",
    "total": "전체 금액",
    "pay_cash_at_appointment": "저는 예약 장소 {{appointment}}에서 현금으로 지불하겠습니다.",
    "view_invoice": "보기/인쇄 영수증",
    "refund_policy": "예약을 취소하시면, 지불하신 금액의 {{amount}}를 예약 날짜로부터 7일 후에 환불받으실 수 있으십니다. 자세한 사항은 [서비스 조건]({{link}})을 참고하세요.",
    "refund_window_passed": "예약 시간이 이미 지났으므로 환불이 불가능합니다. 문의사항은 현장 진행 담당자에게 문의하십시오.",
    "amount_paid": "지불한 금액",
    "balance": "잔액"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "동의 면제를 업로드하거나 아래 칸에 서명을 해 주시길 바랍니다.",
    "stop": "등록 완료를 위한 동의 필요",
    "parent_or_guardian_required_html": "등록을 마치기 위해서 귀하의 부모, 후견인 혹은 공인된 의사 결정자가 아래 양식에 동의를 하고 서명해야 합니다. 본인이 직접 동의를 <b>하실 수 없습니다</b>",
    "send_parent_or_guardian": "이 링크를 부모님이나 후견인에게 보내거나 아래 페이지에 그분들 서명을 요청할 수 있습니다.",
    "consent_guardian_name_signature": "이름",
    "consent_for_minor": "미성년자를 대신하여 동의 서명 ",
    "guardian_signature": "부모님, 후견인, 공인된 의사 결정자의 서명",
    "after_you_schedule": "Participants under the age of {{age}}세 이하 참가자는 꼭 부모님이나 후견인의 동의를 받아야 합니다. 귀하의 후견인에게 보내거나 본인의 피부양자를 위한 서명을 하기 위해서는 꼭 등록 마지막 페이지까지 확인 바랍니다.",
    "consent_guardian_last_name_signature": "성",
    "consent_first_and_last_name": "이름 & 성",
    "phone_number": "보호자 전화번호",
    "email": "보호자 이메일",
    "consent_registrar_with_guardian_name_signature": "보호자 이름",
    "consent_registrar_with_guardian_last_name_signature": "보호자 성",
    "consent_registrar_first_name_signature": "등록자 이름",
    "consent_registrar_last_name_signature": "등록자 성",
    "consent_on_behalf": "{{first_name}} {{last_name}} 을 대신하여 서명합니다.",
    "consent_on_behalf_registrar": "{{first_name}} {{last_name}}에게 구두로 대리 서명할 권한을 받았습니다",
    "consent_on_behalf_registrar_with_guardian": "{{first_name}} {{last_name}}의 보호자에게 구두로 대리 서명할 권한을 받았습니다.",
    "registrar_phone": "보호자 전화 번호",
    "registrar_email": "보호자 이메일",
    "consented_by_parent_guardian": "부모/보호자",
    "consented_by_decision_maker": "공인된 의료 의사 결정자",
    "consented_by_guardian_verbal": "보호자 등록 사무관 (구두 동의 획득)",
    "consented_by_registrar": "등록 사무관 (구두 동의 획득)",
    "full_name": "성명",
    "print": "인쇄"
  },
  "registration": {
    "contact_information": "이름 & 주소",
    "contact_information_additional": "예약 및 결과 확인을 위한 부모님 혹은 후견인 연락처",
    "personal_information": "인구통계학적 정보",
    "address_placeholder": "귀하의 집주소를 입력 바랍니다.",
    "first_name_label": "이름",
    "last_name_label": "성",
    "errors": {
      "state": "주는 두 자리 주 코드를 사용해야 합니다.",
      "phone_number_invalid": "유효하지 않은 번호입니다.",
      "required": "입력해야 합니다.",
      "email_domain": "입력값이 유효하지 않습니다. 다시 한번 확인 바랍니다. 등록은 후원 기관에 의해 선택된 참가자만 가능합니다. 회사나 커뮤니티 이메일을 사용하여 주시길 바랍니다. 등록 자격이 있다고 생각되는데도 오류가 표시되는 경우 서포트 데스크에 연락 바랍니다.",
      "age_requirement": "생년월일이 나이 제한을 충족하지 않습니다.",
      "signature_required": "서명이 요구됩니다.",
      "regex_invalid": "유효하지 않은 입력값입니다.",
      "date_invalid": "존재하지 않는 날짜입니다.",
      "invalid_entry": "등록은 계속하지 마시고 스크린의 지시 사항에 따르거나 귀하의 담당자에게 연락해 주세요.",
      "city": "유효한 도시를 입력하세요.",
      "survey_unanswered": "*표시된 칸을 다 입력하여 주시길 바랍니다.",
      "postal_code": "유효한 다섯 자리 우편 번호를 입력하세요.",
      "field_required": "{{field}} 값을 입력해야 합니다",
      "option_required": "항목 중 하나를 선택해야 합니다",
      "year_length_invalid": "년도 4자리 (YYYY)를 포함한 생년월일을 기입하세요",
      "invalid_day": "일자는 1 - 31 사이여야 합니다",
      "reached_max_chars": "입력 가능한 글자 수를 초과하였습니다.",
      "chars_max": "최대 입력 글자 수",
      "minimum_length": "입력 길이는 {{length}}자 이상이어야 합니다."
    },
    "insurance_status": {
      "question": "건강 보험을 가지고 있습니까?",
      "have_health_insurance": "네, 건강 보험을 가지고 있습니다.",
      "do_not_have_health_insurance": "아니요, 건강 보험을 가지고 있지 않습니다."
    },
    "insurance_policy_holder": {
      "question": "보험 가입자는 누구입니까?",
      "i_am": "본인",
      "my_spouse": "배우자 혹은 파트너",
      "my_parents": "부모님",
      "someone_else": "기타",
      "policy_first_name": "보험 가입자 이름",
      "policy_last_name": "보험 가입자 성",
      "policy_dob": "보험 가입자 생년월일",
      "name": "보험 계약자 이름"
    },
    "insurance_information": {
      "title": "보험 정보",
      "company_name": "보험 회사명",
      "id_number": "회원 식별 번호",
      "group_number": "회원 그룹 번호",
      "secondary_insurance_label": "2차 보헙을 가지고 있습니다.",
      "take_photo": "보험증 사진을 찍어주세요. 사진을 찍으면 일부 정보가 자동으로 채워집니다.",
      "front_of_card": "보험증 앞면",
      "card_information": "보험증 정보",
      "back_of_card": "보험증 뒷면"
    },
    "employment_information": {
      "label": "직장",
      "address_1": "회사 주소",
      "address_2": "예: Suite 200",
      "phone_number": "회사 전화 번호",
      "company": "회사명",
      "postal_code": "고용주 우편 번호",
      "employed": "취업중",
      "sole_prorietor": "자영업",
      "not_employed": "무직",
      "reporting_supervisor": "보고 담당 감독관",
      "reporting_department": "보고 담당 부서",
      "supervisor_name": "관리자 이름",
      "supervisor_email": "관리자 이메일"
    },
    "location_availability": "테스트는 현재 이 지역들의 커뮤니티 멤버에게 우선 적용됩니다. ",
    "custom_survey": "설문",
    "confirmation": "검토",
    "waitlisted": "대기자",
    "schedule_your_appointment": "날짜 예약",
    "information": "정보",
    "consent": "동의",
    "location": "장소",
    "symptoms": "증상",
    "address_required": "귀하의 주소를 입력해 주세요.",
    "consent_required": "계속하기 위해서는 동의하세요.",
    "required_field": "입력해야 하는 항목을 표시합니다.",
    "phone_number": "휴대폰 번호",
    "email": "이메일",
    "date_of_birth": "생년월일",
    "minimum_age": "참가를 위한 최소 연령은 {{year}} 세입니다.",
    "no_minimum_age": "테스트를 하기 위한 최소 연령은 없습니다.",
    "continue_button": "계속",
    "email_required": "유효한 이메일값을 입력해 주세요.",
    "done": "완료",
    "signature": "서명",
    "clear_button": "전체 삭제",
    "back_button": "뒤로 가기",
    "choose_location": "장소 선택",
    "no_slots": "{{location}}에는 예약 가능한 자리가 없습니다.",
    "choose_appointment": "{{location}}의 예약 시간을 선택하십시오.",
    "appointment_required": "예약 시간를 선택하십시오.",
    "phone_number_required": "유효하지 않은 번호입니다.",
    "phone_number_label": "신속한 테스트 결과를 텍스트 메시지로 받을 수 있는 번호를 입력하세요.  ",
    "symptoms_experiencing": "경험하고 있는 증상을 입력하세요. 증상이 없다면, 계속하세요.",
    "household": "가구원 수",
    "household_p1": "선택적으로 테스트가 핑요한 가구원의 예약을 하세요.",
    "add_dependent": "가구원을 추가하세요.",
    "remove_dependent": "피부양자 삭제",
    "dependent_consents": "동의",
    "submit": "완료",
    "add_waitlist": "대기자 명단에 추가",
    "address": "집주소",
    "address_1": "주소 첫 번째 줄",
    "address_2": "아파트/스위트 번호",
    "address_city": "도시",
    "address_state": "주",
    "postal_code": "우편 번호",
    "race_ethnicity": "인종",
    "gender": "성별",
    "self_described_gender": "본인이 정한 성별",
    "interpreter": "통역사가 필요하십니까? 그렇다면, 어떤 언어입니까?",
    "consent_to_terms": "이 약관들에 동의합니다.",
    "reg_not_open": "등록이 열리지 않았습니다.",
    "no_more_avail_spots": "귀하가 선택한 시간은 예약 가능한 자리가 더 이상 없습니다. 다시 시도해 주세요.",
    "consent_helper": "마우스를 클릭한 후 드레그하거나 손으로 아래 박스를 가로질러 서명을 하세요.",
    "phone_number_unreachable_label": "일반 전화?",
    "select": "선택",
    "test_surveys": "예약 질문",
    "edit": "수정",
    "continue_to_registration": "등록 계속",
    "accounts": {
      "already_have_an_account": "이미 회원가입이 되어 있습니까?",
      "log_in": "로그인",
      "sign_up": "회원가입",
      "sign_up_description": "정보를 입력하고 회원 가입을 하시면 다음 등록 시 시간을 절약할 수 있습니다.",
      "log_in_description": "이메일과 비밀번호를 입력하시거나 아래의 구글 혹은 아웃룩을 통해 가입하세요.",
      "sign_in_with_label": "{{provider}}으로 가입",
      "password_must_match": "비밀번호가 일치하지 않습니다.",
      "password_uppercase": "비밀번호는 최소 (%s)개의 대문자를 포함해야 합니다.",
      "password_lowercase": "비밀번호는 최소 (%s) 개의 소문자를 포함해야 합니다.",
      "password_number": "비밀번호는 최소 (%s) 개의 번호를 포함해야 합니다.",
      "password_special": "비밀번호는 최소 (%s) 개의 특수문자를 포함해야 합니다."
    },
    "password": "비밀번호",
    "password_confirmation": "비밀번호 확인",
    "consent_for": "{{name}} 동의",
    "book_one_time_appointment": "1회 예약",
    "duplicate_users": {
      "exists": "이미 등록되었습니다.",
      "overlapping_email_and_phone_p1": "귀하의 전화 번호와 이메일로 메시지를 전송할 것입니다.",
      "p2": "귀하의 메시지를 사용하여 예약을 변경하거나 테스트 결과를 입력할 수 있습니다.",
      "overlapping_phone_and_email_p3": "귀하의 이메일이나 휴대폰에서 상세 사항을 확인하여 주시길 바랍니다.",
      "overlapping_email_p3": "귀하의 이메일에서 상세 사항을 확인하여 주시길 바랍니다.",
      "overlapping_phone_p3": "귀하의 휴대폰에서 상세 사항을 확인하여 주시길 바랍니다.",
      "overlapping_phone_p1": "귀하의 전화 번호로 메시지를 전송하였습니다.",
      "overlapping_email_p1": "귀하의 이메일로 메시지를 전송하였습니다.",
      "p4": "이것이 오류라고 생각되시면 support@primary.health 으로 연락 바랍니다.",
      "overlapping_email_and_phone_p3": "귀하의 이메일이나 휴대폰에서 상세 사항을 확인하여 주시길 바랍니다."
    },
    "duplicate_waitlist": {
      "exists": "귀하는 이미 대기자 명단에 있습니다.",
      "overlapping_email_and_phone_p1": "귀하의 휴대폰과 이메일로 확인 메시지를 다시 보냈습니다.",
      "p2": "확인 메시지를 통해 추가 정보를 입력하거나 대기자 명단에서 본인의 이름을 삭제할 수 있습니다.",
      "overlapping_phone_and_email_p3": "귀하의 이메일이나 휴대폰에서 상세 사항을 확인하여 주시길 바랍니다.",
      "overlapping_email_p3": "귀하의 이메일에서 상세 사항을 확인하여 주시길 바랍니다.",
      "overlapping_phone_p3": "귀하의 휴대폰에서 상세 사항을 확인하여 주시길 바랍니다.",
      "overlapping_email_p1": "귀하의 이메일로 확인 메시지를 다시 보냈습니다."
    },
    "insist_guardian_consent": "(name)를 대신하여 동의합니다.",
    "confirmation_section": {
      "title": "검토 및 확인",
      "p1": "등록을 마치기 전에 귀하의 정보를 검토하세요.",
      "edit_information": "뒤로 가기 및 수정"
    },
    "iemodal": {
      "title": "지원되지 않는 브라우저입니다.",
      "body": "현재 이 브라우저들에서 등록이 가능합니다."
    },
    "show_other_locations": "다른 장소를 보기 위해서 클릭하십시오.",
    "non_us_address": "이것은 국제 통용 주소입니다.",
    "test_group_user_survey": "참가자 심사",
    "self_consent": "본인 동의합니다.",
    "address_country": "국가",
    "appointment": "예약",
    "employee_id": "직원 아이디",
    "appointment_time": "예약 시간",
    "appointment_location": "장소",
    "phone_or_email": "전화번호 또는 이메일 주소",
    "no_self_consent": "나는 이 사항에 동의하지 않습니다",
    "no_insist_guardian_consent": "나는 {{full_name}}를 대신하여 이 사항에 동의하지 않습니다",
    "minimum_age_with_months": "참여 가능한 최소 연령은 {{year}}세 {{month}}개월입니다.",
    "additional_consents_helpertext": "추가 동의는 나중에 요청될 수 있습니다",
    "assistive_technology": "보조공학",
    "covid_vaccine_y_n": "예약하신 때에 코로나 19 백신을 맞기 원하시나요?",
    "received_covid_vaccine_y_n": "코로나 19 백신을 맞으신 적이 있으신가요?",
    "covid_vaccinations": "코로나 19 백신",
    "select_vaccine_dose": "원하시는 백신 복용량을 선택해주세요",
    "demographic_info": "인구통계 정보",
    "additional_info": "추가 정보",
    "self_described_race": "자기 정의 인종",
    "verify_contact_information": "확인",
    "verify_contact": {
      "we_sent_you_a_code": "{{contact}}으로 저희가 보낸 코드를 입력하십시오",
      "confirmation_code": "확인코드",
      "code_resent": {
        "email": "귀하의 이메일로 다른 코드가 전송되었습니다",
        "phone_number": "귀하의 전화기로 다른 코드가 전송되었습니다"
      },
      "did_not_receive_code": "확인코드를 받지 못하셨습니까?",
      "verify_by": {
        "email": "대신 이메일로 확인해 주십시오",
        "phone_number": "대신 전화번호로 확인해 주십시오"
      }
    },
    "preferred_method_of_communication": {
      "question": "선호하는 의사소통 방법",
      "helper_text": "약속 업데이트에 대해 연락드리겠습니다.",
      "phone_number": "전화(SMS 메시지만 가능하며 데이터 요금이 부과될 수 있음)",
      "landline": "현재 유선전화는 지원되지 않습니다. 중요한 커뮤니케이션을 위해 이메일을 제공해 주세요.",
      "verification_method": "인증코드를 어떻게 받고 싶으신가요?",
      "verification_helper_text": "로그인 전 본인 확인을 위한 코드가 전송됩니다."
    },
    "skip_for_now": "일단은 스킵",
    "default_information": {
      "title": "알겠습니다. 이 주소를 사용하겠습니다.",
      "title_v2": "좋아요, 이 연락처 정보를 사용할게요"
    },
    "middle_name_label": "중간 이름",
    "confirm_appointment": "약속 확인",
    "dob": "생일",
    "different_appointment": "다른 약속",
    "select_appointment_time": "약속 시간 선택",
    "decline_address": "주소 제공을 거부합니다. 대신 프로그램 주소를 사용해 주세요.",
    "patient_information": "환자 정보",
    "scan_license": "운전면허증 스캔",
    "how_to_contact": "어떻게 연락해야 하나요?",
    "decline_email_or_phone": "이메일이나 전화번호 제공을 거부합니다. 나는 프로그램 관리자가 내 결과를 받을 수 있도록 승인합니다.",
    "medical_screening": "건강검진",
    "show_service_at_this_location": "이 위치에 서비스 표시",
    "verbal_consent_provided": "미국 장애인법에 따라 참가자 또는 법적으로 승인된 결정권자가 구두 동의를 제공했습니다. 서면 동의는 24시간 이내에 참가자 또는 법적으로 승인된 결정권자로부터 받아야 합니다.",
    "unexpired_written_consent_present": "검사 장소에서 만료되지 않은 서면 동의서가 파일에 기록되어있습니다",
    "select_to_proceed": "계속 진행하려면 위의 옵션을 선택하세요.",
    "guardians_information": "보호자 정보",
    "registrars_information": "사무원 정보",
    "optional": "선택 가능",
    "vfc_eligibility": "어린이 백신 (Vaccine for Children VFC) 자격",
    "vfc_eligibility_subtext": "자녀는 VFC 프로그램에 등록된 의료 사무실, 약국 및 보건 클리닉에서 CDC의 어린이용 백신(VFC) 프로그램을 통해 무료로 백신을 접종받을 수 있습니다."
  },
  "gender_key": {
    "male": "남성",
    "female": "여성",
    "cisgender": "시스젠더",
    "non_binary": "젠더퀴어 혹은 논바이너리",
    "other": "기타",
    "prefer_to_self_describe": "목록에 없음",
    "prefer_not_to_disclose": "대답을 원치 않음",
    "transgender_male": "여성에서 남성으로 성을 전환한 자 ",
    "transgender_female": "남성에서 여성으로 성을 전환한 자 ",
    "unknown": "알 수 없음",
    "non_binary_only": "논바이너리 (어느 쪽에도 속하지 않음)",
    "intersex": "인터섹스 (간성)",
    "transgender": "트랜스젠더",
    "gender_fluid": "젠더 플루이드",
    "not_applicable": "해당 없음"
  },
  "ethnicity": {
    "american_indian_alaska_native": "아메리카 또는 알래스카 원주민",
    "american_indian_central_america": "남미 또는 중남미 원주민",
    "asian": "아시아인",
    "black": "흑인 또는 아프리카계 미국인",
    "latinx": "라틴계 또는 히스페닉",
    "middle_eastern_north_african": "중동인 또는 북아프리카인",
    "native_hawaiian_pacific_islander": "하와이 원주민 또는 태평양 섬 주민",
    "white_european": "백인 또는 코카서스인",
    "unknown": "알 수 없음",
    "prefer_not_to_disclose": "대답을 원치 않음",
    "asian_indian": "인도인",
    "filipino": "필리핀인",
    "japanese": "일본인",
    "korean": "한국인",
    "vietnamese": "베트남인",
    "other_asian": "기타 아시아인",
    "native_hawaiian": "하와이 원주민",
    "guamanian_or_chamorro": "괌 주민 또는 차모르인",
    "samoan": "사모아인",
    "other_pacific_islander": "기타 태평양 섬 주민",
    "chinese": "중국인",
    "help": "인종은 다양한 집단의 사람들을 구분하는 신체적인 특징의 집합을 말합니다. 이는 민족 또는 국적과는 다를 수 있습니다. ",
    "subtitle": "법령에 따라 다음의 정보를 수집해야 합니다.",
    "laotian": "라오스어",
    "cambodian": "캄보디아어",
    "other": "기타",
    "bangladeshi": "방글라데시 사람",
    "hmong": "몽",
    "indonesian": "인도네시아 사람",
    "malaysian": "말레이시아 사람",
    "pakistani": "파키스탄 사람",
    "sri_lankan": "스리랑카 사람",
    "thai": "태국 사람",
    "taiwanese": "대만 사람",
    "fijian": "피지 사람",
    "guamanian": "괌 사람",
    "tongan": "통가 사람"
  },
  "languages": {
    "en": "영어",
    "fr": "프랑스어",
    "es": "스페인어",
    "so": "소말리아어",
    "hmn": "몽족어",
    "asl": "미국식 수화",
    "kar": "카렌어",
    "am": "암하라어",
    "ru": "러시아어",
    "om": "오로모어"
  },
  "symptoms": {
    "fever": "발열 또는 오한",
    "cough": "기침",
    "muscle_aches": "근육통",
    "severe_fatigue": "심한 피로감 (평소보다 심함)",
    "trouble_breathing": "호흡 곤란",
    "diarrhea": "설사",
    "loss_of_smell": "후각 상실",
    "loss_of_taste": "미각 상실",
    "shortness_of_breath": "숨 가쁨 또는 호흡 곤란",
    "headache": "두통",
    "sore_throat": "인후염",
    "congestion": "코막힘 또는 콧물",
    "nausea": "메스꺼움 또는 쿠토",
    "close_contact": "감염자*와 밀접 접촉",
    "helper_text": {
      "close_contact": "*코로나19 바이러스에 감염된 사람과 밀접 접촉(6피트 이내에서 15분 이상)을 한 적이 있습니까?"
    },
    "suspected_exposure": "감염 환경에 노출 의심",
    "none": "증상 없음"
  },
  "instructions": {
    "title": "등록이 완료되었습니다. 페이지에서 나가시기 전에...",
    "p1": "이 페이지에는 귀하의 예약 정보와 예약 바코드가 있습니다.",
    "appointment_scheduled": "예약일이 확정되었습니다.",
    "verify_info": {
      "title": "귀하의 정보를 확인하십시오.",
      "p1": "귀하의 환자 포털에 쉽게 접속하기 위해서 귀하의 연락처 정보를 인증해야 합니다.",
      "p2": "확인 메일이 전송되었습니다. 전송된 링크를 클릭하세요.",
      "p3": "본인의 연락처를 변경해야 할 경우 Help 버튼을 클릭하십시오."
    },
    "verified": "확인됨",
    "not_verified": "확인되지 않음",
    "resend_text": "링크를 받지 않으셨습니까?",
    "resend": "다시 보내기",
    "skip": "인증 건너뛰기 & 확인 정보 보기",
    "name": "이름",
    "appointment_details": "예약",
    "date_and_time": "날짜 및 시간",
    "add_to_calendar": "달력에 추가하기",
    "instructions": "일반 설명문",
    "successfully_flash": "성공적으로 예약이 되었습니다.",
    "success_flash_email": "성공적으로 이메일이 확인되었습니다.",
    "success_flash_phone": "성공적으로 전화 번호가 확인되었습니다.",
    "mail_order": "테스트 키트를 우편으로 일주일 이내에 받으실 수 있습니다.",
    "at_home": "의료 제공자가 귀하의 집에 다음 시간에 방문할 예정입니다.",
    "at_home_instructions": "자택 설명문",
    "mail_order_instructions": "우편 주문 설명문",
    "request_additional_appointment": "후속 예약 하기",
    "book_additional_appointment": "또 다른 예약 하기",
    "confirmation_code": "확인 코드",
    "completed": "완료",
    "appointment_barcode": "예약 바코드",
    "dependent_links": "세대주 예약 링크",
    "on_demand_title": "등록되었습니다. 페이지에서 나가시기 전에...",
    "save_link": "이 링크를 저장해 주세요.",
    "verify_phone": "귀하의 전화 번호를 인증해 주세요",
    "verify_phone_description": "귀하의 기록에 쉽게 접속하기 위해서 귀하의 연락처 정보를 인증해야 합니다. 인증 메시지가 귀하의 휴대폰 번호로 전송되었습니다.",
    "verify_email_description": "귀하의 기록에 쉽게 접속하기 위해서 귀하의 연락처 정보를 인증해야 합니다. 인증 메일가 귀하의 이메일로 전송되었습니다.",
    "information": "등록 정보",
    "follow_up_appointment": "후속 예약",
    "get_directions": "안내문",
    "cancel": "예약 취소",
    "reschedule_appointment": "예약일 변경",
    "reschedule_linked_appointments": "예약일 변경",
    "no_slots": "더 이상 예약 가능한 시간이 없습니다.",
    "check_results": "환자 포털 확인하기",
    "follow_up_modal_header": "2차 접종을 에약하세요.",
    "are_you_sure_you_want_to_cancel": "정말로 예약을 취소하시겠습니까?",
    "please_choose_cancellation_reason": "취소 이유를 아래에서 선택하세요.",
    "additional_details": "추가 사항을 아래에 입력하세요..",
    "errors": {
      "missing_cancellation_reason": "취소 이유를 선택하세요."
    },
    "verify_email": "이메일을 인증하세요.",
    "redcap_url": "클릭하여 설문조사를 완료하세요.",
    "verify_contact_information": "연락처 정보를 확인하세요.",
    "please_also": "또한",
    "new_title": "{{name}}에 대한 확인",
    "contact_method": "연락 방법",
    "next_steps": {
      "title": "다음 단계",
      "p1": "활성화할 테스트 키트가 있습니다. 테스트를 받을 준비가 되면 이메일로 전송된 링크를 따라 이 페이지로 돌아와 아래 키트를 활성화하세요.",
      "administered": "키트가 실험실에 도착하고 결과가 준비되면 연락드리겠습니다. 결과는 일반적으로 실험실에 도착한 후 3~5일이 소요됩니다. 결과가 준비되면 [의료 기록]({{link}}) 페이지에서 결과를 볼 수 있습니다.",
      "resulted": "결과는 [의료 기록]({{link}}) 페이지에서 확인할 수 있습니다.",
      "shipping_information": "배송 정보"
    },
    "save_this_page": {
      "title": "이 페이지를 저장하세요",
      "p1": "이 페이지를 사용하여 자가 테스트를 보고하거나 의료 기록을 통해 결과를 확인하세요.",
      "p2": "나중에 이 페이지에 액세스하려면 확인 이메일이나 SMS 문자에 있는 링크를 클릭하세요.",
      "p3": "이 페이지를 북마크에 추가하거나 홈 화면에 추가하거나 링크를 안전한 장소에 복사하여 저장할 수 있습니다."
    },
    "show_my_barcode": "내 바코드 보여줘",
    "my_account": "내 계정",
    "register_another": "다른 참가자 등록",
    "update_vaccine": "백신 정보 업데이트",
    "medical_history": "병력",
    "upcoming_appointments": "예정된 예약",
    "reschedule": "예약 변경",
    "resend_confirmation": "확인 다시 보내기",
    "appointment_details_v2": "예약 세부 사항",
    "confirm_cancellation": "취소 확인",
    "confirm_cancellation_question": "이 예약을 취소하시겠습니까?",
    "select_new_appointment_time_below": "아래에서 새 예약 시간을 선택하세요. 서비스 또는 예약 장소를 수정해야 하는 경우 이 예약을 취소하고 새 예약을하세요.",
    "no_take_me_back": "아니요, 돌아가기",
    "yes_cancel_appointment": "예, 예약 취소",
    "update_appointment": "예약 갱신하기",
    "select_new_appointment_time": "새 예약 시간 선택",
    "clinic": "진료소",
    "services": "서비스들",
    "appointment_missed": "예약 놓침",
    "appointment_canceled": "예약 취소"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "유효하지 않은 접속 코드입니다."
    },
    "landing": {
      "p1": "결과를 보기 위해서 다음을 입력하세요:",
      "access_code_label": "접속 코드",
      "submit_button": "입력",
      "recent_results": "귀하의 최근 결과"
    },
    "view_results": {
      "headline": "{{name}} 님의 테스트 결과",
      "reregister": "재등록",
      "status": {
        "likely_positive": "결과 미확정",
        "test_not_performed": "재테스트 필요함",
        "results_ready": "결과 확인 가능",
        "processing": "진행중",
        "test_status": "테스트 현황",
        "test_result": "테스트 결과",
        "administered": "관리",
        "results_pending": "결과 보류 중",
        "test_results": "시험 결과"
      },
      "at": "{{address}}"
    },
    "result_label": "결과",
    "result": {
      "flu_a": {
        "result_label": "독감(플루) A 결과"
      },
      "flu_b": {
        "result_label": "독감(플루) \bB 결과"
      },
      "covid19": {
        "result_label": "코로나바이러스감염증-19 결과"
      },
      "covid": {
        "result_label": "코로나바이러스감염증-19 결과"
      },
      "sars": {
        "result_label": "SARS(중증급성호흡기증후군) 결과"
      },
      "control": {
        "result_label": "통제"
      },
      "rsv": {
        "result_label": "RSV 결과"
      },
      "result": {
        "result_label": "결과"
      },
      "hba1c": {
        "normal": "정상",
        "warning": "당뇨병 전증",
        "danger": "당뇨병"
      },
      "lead_venous": {
        "danger": "이상",
        "normal": "정상"
      },
      "tc": {
        "result_label": "TC 결과"
      },
      "hdl": {
        "result_label": "HDL 결과"
      },
      "trg": {
        "result_label": "TRG 결과"
      },
      "ldl": {
        "result_label": "LDL 결과"
      },
      "non_hdl": {
        "result_label": "비 LDL 결과"
      },
      "tc_hdl_ratio": {
        "result_label": "TC/HDL 비율"
      },
      "glu": {
        "result_label": "GLU 결과"
      },
      "alere_cholestech_ldx": {
        "danger": "위험",
        "warning": "이상",
        "normal": "정상"
      },
      "lead": {
        "result_label": "선두"
      },
      "zinc": {
        "result_label": "아연"
      },
      "lead_standard_profile": {
        "danger": "이상",
        "normal": "정상"
      },
      "creatinine": {
        "danger": "이상",
        "normal": "정상"
      },
      "igg": {
        "result_label": "IgG 결과"
      },
      "igm": {
        "result_label": "IgM 결과"
      },
      "blood_glucose_fasted": {
        "normal": "정상",
        "warning": "고등",
        "danger": "고등",
        "hypoglycemia": "저혈당",
        "prediabetes": "당뇨 전단계",
        "diabetes": "당뇨병"
      },
      "total_cholesterol_fasted": {
        "normal": "정상",
        "elevated": "고등",
        "high": "고등",
        "low": "저등",
        "abnormal": "비정상"
      },
      "total_cholesterol_unfasted": {
        "normal": "정상",
        "elevated": "고등",
        "high": "고등"
      },
      "a1c_now": {
        "normal": "정상",
        "warning": "전당뇨",
        "danger": "당뇨병"
      },
      "blood_glucose": {
        "warning": "낮은",
        "normal": "정상",
        "prediabetes": "전당뇨병",
        "danger": "당뇨병",
        "hypoglycemia": "저혈당",
        "medical_emergency": "의료 비상 상황",
        "inconclusive_1": "결론이 나지 않음 1",
        "inconclusive_2": "결론이 나지 않음 2",
        "possible_diabetes": "당뇨병 가능성"
      },
      "triglycerides": {
        "result_label": "트리글리세리드"
      },
      "blood_glucose_ucsf": {
        "low": "낮음",
        "normal": "정상",
        "high": "높음"
      },
      "syphilis": {
        "result_label": "매독 결과"
      },
      "hepatitis_c": {
        "result_label": "C형 간염 결과"
      },
      "hiv": {
        "result_label": "HIV 결과"
      }
    },
    "documents": "자료",
    "self_administered": "자체 관리 테스트 {{location}} 선택",
    "patient": "인내심 있는",
    "medical_history": "병력",
    "overview_title": "자세한 내용과 필요한 추가 작업 항목을 보려면 테스트 또는 서비스를 선택하세요. 일부 테스트 결과는 아직 제공되지 않거나 제공자와 상담한 후에만 제공될 수 있습니다.",
    "insurance_information": "보험 정보를 작성하세요",
    "contact_support": "도움이 필요한 경우 지원팀에 문의하세요",
    "show": "보여주다",
    "hide": "숨다",
    "lab_report": "연구 보고서",
    "contact_provider": {
      "header": "긍정적인 결과가 나왔습니다",
      "description": "귀하의 결과와 치료에 대해 논의하기 위해 의료 제공자와 상담하고 싶으십니까?",
      "yes_option_text": "예, 서비스 제공자와 통화하고 싶습니다",
      "no_option_text": "아니요, 나는 내 결과를 읽고 이해했으며 서비스 제공자와 상담하고 싶지 않습니다.",
      "confirm_phone_number_header": "전화번호를 확인하세요",
      "confirm_phone_number_description": "상담에 가장 적합한 전화번호를 확인해 주세요.",
      "confirm_button_text": "확인하다",
      "consultation_confirmed_header": "상담 확정",
      "consultation_confirmed_description": "서비스 제공업체에서 영업일 기준 2~3일 이내에 {{phone_number}}번으로 전화할 것입니다.",
      "acknowledgement_option_helper_text": "치료의 중요성을 설명하고 사용자가 마음이 바뀔 경우 통화 일정을 잡는 방법을 알려주는 것입니다.",
      "acknowledgement_confirmed_header": "승인이 확인되었습니다.",
      "acknowledgement_confirmed_description": "연결된 자원으로 치료를 받는 것의 중요성에 관한 것입니다. 상담은 완전 무료이며 의료 제공자는 감염 해결에 도움이 되는 기타 처방을 할 수 있다는 점을 기억하세요.",
      "acknowledgement_confirmed_change_mind_text": "마음이 바뀌시면 아래의 \"상담하고 싶습니다\"를 클릭하시면 됩니다.",
      "request_consultation_button_text": "상담을 받고 싶습니다"
    },
    "phone": "전화",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "귀하의 주소는 이 프로그램 제공 장소의 자격 조건와 일치하지 않습니다."
    }
  },
  "member": {
    "medical_history": "기록",
    "view_instructions": "확인서 보기",
    "next_appointment": "다음 예약",
    "over_18": "저는 18세 이상임을 증명합니다.",
    "choose_test_configurations_title": "이 예약에서 받고 싶으신 서비스를 선택하십시오.",
    "member_taken_error": "이미 사용자 계정이 등록되어 있습니다.",
    "choose_test_configurations_subtitle": "서비스는 모든 세대 구성원들에게 적용될 것입니다.",
    "service": "서비스",
    "group_or_location": "그룹/위치",
    "details": "세부사항",
    "see_all": "모두 보기",
    "no_history": "기록을 찾을 수 없습니다.",
    "medical_history_title_with_name": "{{name}}님의 기록",
    "no_dashboard": "현재 이 기능은 사용할 수 없습니다.",
    "product": "제품",
    "price": "가격",
    "quantity": "수량",
    "total_services_selected": "총 선택 서비스",
    "total_price": "총 가격"
  },
  "or": "또는",
  "account": {
    "errors": {
      "must_be_13": "계정을 등록하기 위해서는 13세 이상이어야 합니다."
    }
  },
  "self_administration": {
    "title": "자가 테스트 진행",
    "self_administer_action": "자가 테스트 진행",
    "skip_to_link": "자가 테스트 진행으로 넘어가기",
    "select_person": "참가자 선택",
    "adult": "성인",
    "child": "아동",
    "checked_out": "확인 진행",
    "go_back": "뒤로가기",
    "switch_to_scanner": "스캐너로 변경",
    "enter_barcode": "바코드 입력",
    "scan_barcode": "바코드 스캔",
    "cancel": "취소",
    "barcode_for": "_____ 바코드",
    "enter_barcode_manually": "바코드 수동 입력",
    "instructions": "아래 검사 튜브에 위치한 바코드를 스캔 또는 입력",
    "regex_prefix": "바코드는 _________를 포함하여야 합니다",
    "all_completed": "완료됨: 모든 가족 구성원이 이 단계를 완료하였습니다.",
    "errors": {
      "no_appointment": "예약을 찾을 수 없습니다.",
      "barcode_in_use": "이미 사용된 바코드입니다. 본인이 이전에 이 테스트 키트를 직접 등록한 적이 없다면, 현장에서 이 키트를 귀하에게 제공한 스텝에게 문의하십시오.",
      "test_error_general": "테스트를 불러올 수 없습니다.",
      "different_barcodes": "귀하가 입력하신 바코드가 일치하지 않습니다."
    },
    "confirm_barcode_input": "확인을 위해 바코드를 다시 한 번 입력하십시오.",
    "click_to_self_test": "자가 테스트를 위해 여기를 클릭하십시오.",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "대기자 명단",
      "unavailable": "유효한 예약이 없습니다."
    },
    "labels": {
      "address": "주사",
      "next_appointment": "다음 유효한 예약",
      "services": "제공 가능한 서비스"
    }
  },
  "waiting_room": {
    "header": "귀하는 현재 대기방에 있습니다; 예약일 설정 시스템에 연결될 때까지 기다리십시오.",
    "p1": "기다려주셔서 감사합니다. 저희는 함께 이 팬데믹 상황을 이겨나갈 것입니다.",
    "signature": "진심으로 기원합니다.",
    "p2": "코로나19의 경험이 아주 힘드실 것이라는 걸 압니다. 잠시만 기다려 주십시오. 최대한 빨리 귀하를 예약 사이트로 안내하겠습니다."
  },
  "users": {
    "send_appointment_confirmation_message": "안녕하세요, {{full_name}} 님. {{name}}으로 예약된 귀하의 예약 알림입니다. 귀하의 예약 일시는 {{date}}이고 예약 장소는 {{address}}입니다.",
    "send_registration_link": "안녕하세요, {{full_name}} 님. 이 링크를 통해 {{name}}를 등록을 하십시오. {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "1차 접종",
    "previous_appointment": "과거 예약",
    "booked_appointment": "귀하의 예약",
    "second_dose": "2차 접종",
    "choose_second_location": "2차 예약 장소를 선택하십시오."
  },
  "cancellation_types": {
    "cant_get_to_location": "시간 상에 문제가 있습니다.",
    "timing_conflict": "코로나19에 걸리게 되었습니다.",
    "became_ill_with_covid19": "다른 곳에서 이 서비스를 받은 적이 있습니다.",
    "received_service_elsewhere": "기타",
    "other": "이름을 대신 입력하십시오.",
    "duplicate_appointment": "중복된 예약입니다"
  },
  "translation": {
    "consent": {
      "type_name_instead": "이름 입력",
      "type_name": "대신 서명을 입력하세요."
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "귀하의 접속 코드는 ",
      "please_confirm_your_email": "아래 버튼을 클릭하여 귀하의 이메일을 확인하십시오.",
      "hello": "안녕하세요.",
      "click_to_reschedule": "안내 사항 보기/재예약 클릭",
      "click_to_reschedule_on_demand": "안내 사항 보기 클릭"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "{{location}}에서 {{time}}에 약속이 취소되었습니다. 실수인 경우 support@primary.health로 이메일을 보내주세요."
    }
  },
  "signature_lines": {
    "name": "참가자 성함",
    "date": "날짜",
    "signature": "참가자 서명",
    "and_or": "그리고/또는",
    "guardian_name": "부모/후견인 성함",
    "guardian_signature": "부모/후견인 서명"
  },
  "employer_testing": {
    "hello_name": "안녕하세요 {{name}} 님.",
    "results": {
      "negative": "음성",
      "positive": "양성",
      "did_not_result": "알 수 없음",
      "unknown": "알 수 없음",
      "invalid": "무효함",
      "pending": "처리중"
    },
    "code_reader": {
      "scan_your_code": "귀하의 테스트 카드에 있는 코드를 스캔하십시오.",
      "find_in_top_right_hand_corner": "스캔 코드는 귀화의 테스트만을 위해 만들어진 것이고 테스트 카드의 오른쪽 코너에서 찾으실 수 있습니다.",
      "center_code": "여기에 코드를 입력하십시오.",
      "already_used_error": "이 테스트 바코드는 이미 사용되었습니다. 테스트 키트 제공자에게 연락하십시오.",
      "click_to_scan": "QR 코드 스캔을 위해 클릭하십시오.",
      "scan_new_test_card": "새로운 테스트 카드를 위해 여기를 클릭하십시오.",
      "not_working": "되지 않습니까?",
      "try_again": "여기를 다시 클릭하십시오."
    },
    "continue": "계속하기",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "계속하기 위해서는 참가자의 생년월일을 확인하십시오.",
      "contact_administrator": "화면에 보이는 정보가 맞지 않은 경우, 관리자에게 연락 바랍니다.",
      "error": "입력된 생년월일이 맞지 않습니다. - 다시 시도하시거나 업데이트를 위해 관리자에게 연락 바랍니다."
    },
    "result_entry": {
      "code_is_registered": "귀하의 코드가 등록되었습니다.",
      "take_the_test": "테스트 받기",
      "follow_the_instructions": "테스트 키트와 함께 온 지시문에 따라 검사용 면봉을 유리병에 넣고 나서 15분 간의 타이머를 시작하십시오.",
      "cant_find_instructions": "지시문을 찾을 수 없습니까?",
      "view_instructions": "Binax Now 지시문을 확인하십시오.",
      "start_timer": "15분 타이머 시작 (선택 가능)",
      "submit_within_15": "팁: 15분 이내에 결과를 입력하십시오.",
      "enter_test_results": "테스트 결과 입력",
      "choose_a_result": "테스트 카드 결과를 나타내는 선택지를 선택하십시오.",
      "submit": "입력",
      "must_log_result_and_photo": "계속하기 위해서는 테스트 결과를 입력하고 테스트의 사진을 찍어서 올려야 합니다.",
      "submit_within_15_v2": "팁: 테스트가 15분 경과 된 후의 결과를 입력하십시오.",
      "click_to_take_photo": "사진을 찍기 위해 여기를 클릭하십시오.",
      "results_may_be_invalid": "유효하지 않은 테스트 결과입니다."
    },
    "summary": {
      "title": "결과 요약",
      "negative_subtitle": "코로나19에 걸리지 않았습니다.",
      "negative_message": "귀하의 고용주에게 귀하가 코로나19에 걸리지 않았고 회사에서 다시 근무할 수 있다는 것을 알리는 통지서를 전송하였습니다. ",
      "positive_subtitle": "알려드리기 죄송합니다만 귀하는 코로나19에 걸렸습니다.",
      "positive_message": "귀하의 고용주에게 귀하가 코로나19에 걸렸다는 것을 알리는 통지서를 전송하였습니다. ",
      "what_should_you_do": "무엇을 해야 하는가:",
      "employer_name_resources": "고용주 이름 리소스:",
      "follow_cdc_guidelines": "CDC 권장 지침 따르기"
    },
    "clarifying_results": {
      "title": "결과 확인",
      "which_did_your_result_most_look_like": "귀하의 결과는 어느 것과 가장 비슷합니까?",
      "no_lines": "선이 보이지 않음",
      "blue_control_line_only": "파란색 통제선만 있음",
      "pink_sample_line_only": "분홍색 샘플선만 있음",
      "blue_control_line_and_pink_sample_line": "파란색 통제선과 분홍생/보라색 샘플선",
      "still_not_sure": "정확히 알 수 없음",
      "submit_results": "결과 입력",
      "thank_you": "감사합니다!",
      "clarification_received_message": "귀화의 결과 확인이 처리되었습니다.",
      "only_pink_line": "분홍색 선만 있음",
      "no_lines_v2": "선이 없음"
    },
    "return_to_confirmation": "확인 페이지로 돌아가십시오."
  },
  "preferred_language": {
    "title": "선호 언어",
    "subtitle": "어떤 언어를 사용하시나요?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "첫 백신 종류",
    "one_dose": "특정 차수 백신 예약하기. 특정하자면, 이는 저의",
    "title": "차수 선택",
    "first_dose": "1차 접종",
    "second_dose": "2차 접종",
    "single_dose_title": "1회 접종",
    "additional": "추가",
    "booster": "부스터",
    "supplemental": "보충",
    "third_dose": "3번째 G13만 해당",
    "no_vaccine": "해당사항 없음"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "{{datetime_format}}이 있어야 함"
            },
            "results_at": {
              "invalid_datetime": "{{datetime_format}}이 있어야 함"
            },
            "uid": {
              "unknown_test": "테스트를 찾을 수 없습니다."
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "사용자가 존재하고 업데이트가 허용되지 않습니다."
            },
            "date_of_birth": {
              "invalid_date": "{{datetime_format}}이 있어야 함"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}}는 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{ethnicities}}",
                "other": "{{unknown_ethnicities}}는 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "성이 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{genders}}"
            },
            "phone_number": {
              "invalid": "유효하지 않은 번호입니다."
            },
            "population_name": {
              "unknown_population": "{{unknown_population}}는 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "성이 \"본인이_직접_설명_원함\"인 경우에는 입력할 수 없습니다."
            },
            "sex": {
              "unknown_sex": "성별이 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "성적 지향이 존재하기 않습니다. 다음에서 다른 선택지를 고르십시오: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "상태가 유효하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}}가 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{races}}",
                "other": "{{unknown_races}}가 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "역할을 정하기에 충분한 권한이 없음"
            },
            "user_id": {
              "unknown_user": "사용자를 찾을 수 없음"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "유효하지 않은 JSON"
            },
            "user_id": {
              "unknown_user": "사용자를 찾을 수 없음"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "예약 장소를 찾을 수 없음"
            },
            "date_of_birth": {
              "invalid_date": "{{date_format}}이 있어야 함"
            },
            "email": {
              "duplicated": "___ 이미 사용되고 있습니다."
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}}가 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{ethnicities}}",
                "other": "{{unknown_ethnicities}}가 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "성이 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{genders}}"
            },
            "phone_number": {
              "invalid": "유효하지 않은 번호"
            },
            "population": {
              "unknown_population": "{{unknown_population}}는 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "성이 \"본인이_직접_설명_원함\"인 경우에는 입력할 수 없습니다."
            },
            "sex": {
              "unknown_sex": "성별이 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "성적 지향이 존재하기 않습니다. 다음에서 다른 선택지를 고르십시오: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}}가 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{races}}",
                "other": "{{unknown_races}}가 존재하지 않습니다. 다음에서 다른 선택지를 고르십시오: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "그리고 주소는 다른 장소에서 이미 사용되고 있습니다."
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "히스페닉계 또는 라틴계",
    "not_hispanic": "히스페닉계 또는 라틴계 아님",
    "hispanic_expanded": {
      "other": "다른 히스페닉계, 라틴계 또는 스페인 본토 출신",
      "mexican": "멕시코인, 멕시코계 미국인, 치카노/치카나",
      "puerto_rican": "푸에르토리코인",
      "cuban": "쿠바인"
    },
    "subtitle": "법령에 따라 다음의 정보를 수집해야 합니다.",
    "help": "민족은 다양한 집단의 사람들을 구분하는 언어, 문화 관습, 종교 그 외 기타 특징 등 보편적 특징의 집합으로 말합니다. 이는 인종과 다를 수 있습니다. ",
    "unknown": "알 수 없음",
    "title": "민족"
  },
  "sex_at_birth": {
    "question": "성별",
    "female": "여성",
    "male": "남성",
    "help": "성별은 출생 증명서에 기재되어 있는 것을 말합니다.",
    "non_binary": "논바이너리(여성도 남성도 아님)",
    "subtitle": "법령에 따라 다음의 정보를 수집해야 합니다.",
    "decline": "답변 거부",
    "unknown": "알 수 없음"
  },
  "gender": {
    "help": "성 정체성은 본인의 자아 의식을 말합니다. 이는 출생시의 성별과 일치하지 않을 수 있습니다.",
    "subtitle": "성 정체성에 관련하여 추가하고 싶은 정보가 있는 경우에는 입력 바랍니다."
  },
  "sexual_orientation": {
    "title": "성적 지향",
    "subtitle": "성적 지향에 관련하여 추가하고 싶은 정보가 있는 경우에는 입력 바랍니다.",
    "gay": "게이, 레즈비언 또는 동성애자",
    "heterosexual": "이성애자",
    "bisexual": "양성애자",
    "questioning": "의문을 갖음/확실하지 않음/알지 못함",
    "prefer_not_to_disclose": "공개하지 않음",
    "unknown": "알 수 없음",
    "orientation_not_listed": "목록에 없음",
    "not_applicable": "해당 없음",
    "pansexual": "범성애자",
    "queer": "퀴어"
  },
  "pronouns": {
    "title": "선호 대명사",
    "he": "그",
    "she": "그녀",
    "they": "그들",
    "choose_not_to_disclose": "공개하지 않음",
    "prefer_to_self_describe": "직접 설명 원함"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "운전면허 또는 주 신분증번호",
    "driver_license": "운전면허",
    "use_ssn_instead": "SSN(사회 보장 번호) 대신 사용",
    "social_security_number": "사회 보장 번호",
    "state": "주",
    "upload_front_of_driver_license": "운전 면허증 또는 주에서 발행한 신분증의 앞면을 업로드하세요",
    "choose_file": "파일을 고르세요",
    "no_file_chosen": "파일을 고르지 않으셨습니다",
    "no_identification": "신분증이 없습니다",
    "insurance_card_has_a_back": "보험카드에 뒷면이 있습니다",
    "upload_front_of_insurance_card": "보험카드의 앞면을 업로드하세요",
    "front_of_insurance_card_uploaded": "\b보험카드의 앞면이 업로드 되었습니다",
    "insurance_card": "보험카드",
    "insurance_card_back": "보험카드 뒷면",
    "back_of_insurance_card_uploaded": "보험카드의 뒷면이 업로드 되었습니다"
  },
  "quidel": {
    "certify_text": "이 테스트를 위해서 한 번 결과를 기록하는 것임을 증명합니다. ",
    "entering_results": "결과 입력",
    "review_instructions_1": "지시 사항을 충분히 검토하십시오.",
    "review_instructions_2": "테스트 키트와 함께 도착한 지시 사항을 검토하고 단계별 가이드를 읽거나 아래의 안내 비디오를 시청하실 수 있습니다:",
    "read_instructions": "지시 사항 읽기",
    "watch_video": "비디오 시청",
    "timer": "검사용 면봉을 유리병에 넣고 저은 후 타이머를 시작하십시오. 타이머는 1분 후에 멈추면 검사용 면봉을 유리병에서 빼내십시오. 중요: 결과를 보기 위해서는 타이머를 10분에 맞춰 시작하셔야 합니다.",
    "start_timer": "타이머 시작 (선택 가능)",
    "restart_timer": "멈춘 후 다시 시작",
    "resume": "다시 시작",
    "one_minute_countdown": "1분이 지났습니다. 검사용 면봉을 유리병에서 빼고 타이머를 다시 시작하십시오.",
    "take_photo": "테스트 스트립 사진을 찍으십시오.",
    "photo_warning": "계속하기 위해서는 사진을 제공해야 합니다.",
    "enter_results": "결과를 입력하세요.",
    "choose_result": "테스트 스트립에 나타난 것과 가장 가까운 선택지를 고르십시오. 도움이 필요하십니까? 여기를 클릭하여 결과 샘플을 연람하십시오.",
    "positive": "양성",
    "negative": "음성",
    "unknown": "알 수 없음",
    "clarify": "결과 확인하기",
    "strip_question": "귀하의 테스트 스트립은 어느 것과 가장 비슷합니까?",
    "no_lines": "선이 없음",
    "pink_line": "분홍색 선만 있음",
    "not_sure": "정확히 알 수 없음"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "최종 사진",
    "retake_photo": "다시 촬영",
    "capture_photo": "사진 캡쳐",
    "confirm_information_correct": "위 내용은 사실과 틀림이 없습니다.",
    "submit": "제출",
    "title": "{{name}} 의 결과 입력",
    "subtitle": "iHealth COVID-19 애플리케이션을 다운로드 받거나 사용할 필요가 없습니다.",
    "instruction_title": "iHealth 안내문 보기",
    "instruction_guide": "안내문 읽기",
    "video_guide": "비디오 시청",
    "add_photo": "사진 추가",
    "confirm_result": "결과 확인",
    "result_warning": "제출을 위해 결과 선택",
    "confirm_warning": "제출을 위해 결과 확인",
    "administered_at_label": "언제 검사를 받으셨습니까?",
    "instruction_subtitle": "검사기와 동봉되어 있었던 안내문을 확인하거나 여기에서 안내문을 재확인 바랍니다. "
  },
  "public_test_groups": {
    "title": "코로나바이러스감염증19 검사 키트",
    "appointment_recoveries_title": "돌아가시겠습니까?",
    "appointment_recoveries_button_text": "링크 찾기",
    "search_test_group_title": "처음입니까?",
    "search_test_group_button_text": "지금 등록하기",
    "title_2": "간단 등록 및 보기",
    "step_one": "계정 등록",
    "step_two": "반송을 위한 타액(침) 수집",
    "step_three": "온라인으로 결과 확인하기",
    "title_3": "검사 키트 활성화 등록",
    "population_title": "등록",
    "population_button": "활성화",
    "faq_subtitle": "더 알아보기",
    "faq_title": "자주 물어보는 질문",
    "faq_1": "검사 키트를 어떻게 반송하나요?",
    "faq_1_footer": "Saliva Direct Funnel (본 검사용 키트) 안내서 보기",
    "faq_2": "이미 등록을 하였으나 계정 정보를 잊어버림",
    "faq_3": "검사 키트를 어떻게 활성화하나요?",
    "faq_2_subtitle": "재사용자의 경우, **링크 찾기** 버튼을 클릭하여 당신의 결과를 확인할 수 있습니다.",
    "faq_3_subtitle": "아래의 선택지를 이용하여 Primary.Health 계정 정보를 확인하십시오.",
    "footer_text_1": "이 제품은 FDA 승인이 완료되지 않았지만, FDA 긴급사용승인(EUA)을 받았습니다;",
    "footer_text_2": "이 제품은 SARS-CoV-2 핵산 검측을 돕는 목적으로 타액 견본의 수집과 유지만을 할 수 있게 승인되었습니다. 기타 바이러스나 병원균 용이 아닙니다.；",
    "footer_text_3": "이 제품의 비상 사용은 선언이 무효화되거나 승인이 철회되지 않는 한, 연방 식품, 의약품 및 화장품 관련 법안, 21 U.S.C. § 360bbb-3(b)(1)의 제564(b)(1)조에 따라 의료 기기의 비상 사용 승인을 정당화하는 상황이 존재한다는 선언 기간 동안에만 승인됩니다.",
    "description": "당신의 편의를 위하여, 아래 버튼을 사용하여 검사 키트를 활성화시킬 수 있습니다:",
    "subtitle": "Primary(본 테스트 키트)가 처음이신가요?",
    "subtitle_1": "코로나19 테스트 키트에 대한 안내",
    "faq_1_1": "생체 표본 주머니에 봉합된 샘플 튜브를 넣어 주세요.",
    "faq_1_2": "합께 배송된 SalivaDirect 라벨이 붙은 박스에 생체 표본 주머니를 넣어 주세요. SalivaDirect 박스를 FedEx UN3373 팩에 넣어 주세요.",
    "faq_1_3": "FedEx UN3373 팩의 접착제 보호필름을 떼어내서 팩이 열리지 않도록 입구를 봉합하십시오.",
    "faq_1_4": "타액 샘플을 수집한 같은 날의 마지막 택배 픽업 시간 전까지 샘플을 제출하십시오. 주말에 페덱스 드롭박스에 제출하는 일이 없도록 하십시오.",
    "faq_1_5": "페덱스 웹사이트 주소 **[fedex.com/labreturns](https://www.fedex.com/labreturns)**를 통해 페덱스 드롭박스의 위치와 픽업 일정을 확인할 수 있습니다.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "결과를 보고하세요",
    "report_result_subtitle": "귀하의 기관에서 자가검사를 제공할 경우, 검사 결과를 보고할 준비가 되셨다면 하단의 버튼을 클릭하세요",
    "need_help": "도움이 필요하세요?",
    "assistance": "연락처 정보를 수정해야하거나 다른 도움이 필요하시면 써포트 센터에 연락해주세요",
    "contact_support": "써포트에 연락하기",
    "save_link": "결과를 나중에 보고하기 원하시면 이 링크를 저장하세요",
    "send_via_email": "이메일로 링크 보내기",
    "send_via_text": "문자 메세지로 링크 보내기",
    "copy_link": "링크 복사하기",
    "resend_email": "확인 이메일 다시 보내기",
    "phone_verified": "전화가 확인되었습니다",
    "add_vaccination_status": "코로나바이러스감염증-19 백신 여부 추가/업데이트 하기",
    "terms_of_service": "서비스 조건",
    "verify_contact_information": "결과와 업데이트되는 사항을 수신하기위한 연락처를 확인해주세요. 연락처 정보를 수정하셔야 한다면 써포트 센터에 연락해주세요.",
    "resend_text": "확인 문자 메세지 다시 보내기",
    "loading": "로딩",
    "add_to_account": "계정에 추가하기",
    "hi": "안녕하세요",
    "email_verified": "이메일이 확인되었습니다",
    "go_to_my_account": "내 계정으로 가기",
    "activate_kit": "테스트 키트를 작동시키세요",
    "report_custom_result": "결과를 보고하세요: {{test}}",
    "activate_custom_test": "테스트를 작동시키세요: {{test}}",
    "update_vaccination_status": "백신접종상태 업데이트",
    "order_a_test_kit": "검사 키트 주문하기"
  },
  "enter_information": {
    "contact_info": "연락처를 기입하세요.",
    "label": "전화번호 또는 이메일 주소",
    "invalid": "이메일 주소 또는 전화번호가 유효하지 않습니다",
    "send": "코드를 전화번호 또는 이메일로 발송하겠습니다",
    "legal": "전화번호나 이메일을 입력하심으로써 프라이머리 헬스의 **[서비스 조건](https://primary.health/terms-of-service/)** 과 **[개인정보보호정책](https://primary.health/privacy-policy/)**에 동의합니다.",
    "subtitle": "기록을 찾을 수 있도록 문자나 이메일을 보내거나 검사 결과를 보고할 수 있는 링크를 보내드리겠습니다!",
    "find_link": "등록 되었습니까? 본인의 링크 찾기",
    "not_registered": "등록 안됨?",
    "search_by_keyword": "이름, 키워드, 도시, 우편번호 또는 단체 코드로 검색",
    "search": "검색",
    "register_here": "여기에서 등록하세요"
  },
  "contact_support": {
    "invalid": "\b정보가 유효하지 않습니다",
    "invalid_explanation": "최근에 프라이머리 헬스에 로그인하기 위한 링크를 요청하셨지만, ",
    "never_registered": "등록한 적이 없습니까? 검사 제공자에게 등록 링크를 문의하거나 아래에서 본인의 사이트를 찾으세요.",
    "get_help": "귀하의 기록이 귀하의 연락처와 매치하도록 도움 받기 원하시면",
    "get_help_calling": "또는 전화통화로",
    "invalid_explanation_bold": "제공하신 정보가 유효하지 않습니다",
    "get_help_label": "도움 요청",
    "mobile": {
      "invalid_explanation": "계정에 추가할 수 있는 사용자를 찾을 수 없습니다.",
      "get_help": "추가 궁금한 사항이 있으신 경우 문의해주십시오",
      "reason": "모든 일차보건의료(Primary.Health) 서비스 사용자가 새계정 기능을 이용할 수 있는 것은 아닙니다. 일단 자격이 주어지면 보건기관 또는 그룹 관리자로부터 초대를 받게 됩니다."
    },
    "zen_name": "이름",
    "ticket_type": "이것은 무엇에 관한 것입니까?",
    "appointment_access_code": "확인 코드 (가능한 경우에만)",
    "zen_desc": "메세지",
    "contact_us": "문의",
    "need_assistance": "도움이 필요하거나 연락처 정보를 변경하려면 지원팀에 문의하세요."
  },
  "show_appointments": {
    "welcome": "프로필 찾기",
    "welcome_subtitle_1": "아래에 결과 표시",
    "added_to_account": "계정 추가",
    "error": "해당 참여자가 이미 계정이 있을 수 있습니다",
    "welcome_subtitle_2": "아래 이름이 보이지 않나요?",
    "welcome_not_logged_in": "사이트 링크 찾기",
    "welcome_logged_in": "그룹 선택",
    "welcome_subtitle_1_logged_in": "회원 추가를 완료하려면 사용자 및 그룹을 선택하세요. 회원은 하나의 계정에 할당됩니다.",
    "welcome_subtitle_2_logged_in": "위에 회원이 보이지 않는 경우",
    "welcome_subtitle_1_not_logged_in": "검사 장소를 찾아야하는 회원이나 새로 추가해야 할 회원을 선택하세요. 회원을 찾을 수 없나요?",
    "welcome_subtitle_3_logged_in": "다른 연락처 방법 시도하세요",
    "welcome_subtitle_4_logged_in": "또는 지원팀에 문의하세요",
    "welcome_subtitle_2_not_logged_in": "검색 업데이트",
    "welcome_subtitle_3": "검색 업데이트하기"
  },
  "enter_code": {
    "code": "코드가 무엇인가요?",
    "enter_code": "전송된 코드를 입력해주세요",
    "incorrect_code": "잘못된 코드를 입력하셨습니다",
    "verify": "계정을 확인해주세요",
    "verified": "확인되었습니다",
    "verify_account_information": "계정 확인"
  },
  "general_self_checkout": {
    "choose_test": "어떤 검사를 받으셨나요?",
    "photo_required": "결과 사진이 필요합니다",
    "find_your_test": "귀하의 검사를 찾으세요"
  },
  "login": {
    "create_account": "계정을 만드세요",
    "create_account_help": "계정을 만드는 것은 검사나 백신 등록과 다릅니다. 등록을 하면",
    "create_account_help_schedule": "예약 요청",
    "create_account_help_record": "자가 검사 기록",
    "create_account_help_match": "백신 및 검사 기록 관리",
    "continue": "게스트로서 서비스 사용하기",
    "already": "계정이 이미 있으시다면 클릭하세요",
    "login": "로그인하기",
    "or_sign_in": "다른 방법으로 로그인하기:",
    "no_account": "계정이 없으시다면 클릭하세요",
    "signup": "가입하기",
    "here": "\b여기",
    "email_address": "이메일 주소:",
    "password": "\b비밀번호:",
    "complete_registration": "가입 완료하기",
    "last_covid_test": "코로나바이러스감염증-19 마지막 검사 결과",
    "no_record": "기록 없음",
    "viewing_information": "{{first_name}}님의 정보를 보고계십니다",
    "download": "다운로드",
    "not_registered": "어떤 그룹에도 등록되어있지 않습니다",
    "please_follow_link": "예약을 요청하거나 프라이머리 헬스에 기록된 정보를 보기 원하시면, 이메일로 전송된 초대 링크를 열어주세요",
    "log_results": "검사 결과 기록하기",
    "book_appointment": "예약하기",
    "no_new_appointments": "현재 새로운 예약을 받고 있지 않습니다",
    "upload_vaccinations": "백신 자료를 업로드 해주세요",
    "vaccination": "백신",
    "new_appointment": "새로운 예약",
    "log_otc_results": "처방 없이 받을 수 있는 검사 결과 기록하기",
    "no_household_members": "기록된 가족 구성원이 없습니다",
    "no_upcoming": "\b예정된 예약이 없습니다",
    "update_vaccination": "\b백신 기록 업데이트",
    "upload_vaccination": "백신 기록 업로드",
    "title": "프라이머리 헬스에 오신 것을 환영합니다",
    "sub_title": "Before registering please create an account.",
    "p1": "계정을 만드시면:",
    "p2": "예약 미리 요청",
    "p3": "코로나바이러스감염증-19 자가검사 기록",
    "p4": "종속관계 백신 및 검사 기록 관리",
    "p5": "프라이머리 헬스 계정에 쉽게 로그인",
    "no_vaccination_record": "아직 백신을 추가하지 않았습니다.",
    "vaccination_title_card": "코로나 바이러스 백신 접종 상태",
    "account_and_settings": "계정 & 설정",
    "delete_account": "계정 삭제",
    "language": "언어",
    "language_current": "현재 언어: {{language}}",
    "log_out": "로그아웃",
    "delete_account_title": "계정을 삭제하시겠습니까?",
    "new_encounter": "{{first_name}}님의 새로운 만남",
    "new_encounter_subtitle": "어떤 그룹이 새로운 만남을 위한 것입니까?",
    "no_programs": "프로그램에 등록되어 있지 않습니다.",
    "edit_members": "구성원 수정",
    "edit_members_subtitle": "대시보드를 선택하거나 다른 구성원을 계정에 추가하세요.",
    "add_members": "구성원 추가",
    "delete_member": "{{full_name}}님의 계정을 삭제하시겠습니까?",
    "delete_member_subtitle": "계정 삭제시, 계정의 개인정보는 모두 삭제됩니다.",
    "select_member": "구성원 선택",
    "edit_appointment": "일정 수정",
    "route": {
      "me": "대시보드",
      "history": "기록",
      "programs": "테스트 추가",
      "contact_us": "도움",
      "choose_member": "구성원",
      "settings": "설정",
      "help": "도움"
    },
    "user_dashboard": "{{first_name}}님의 대시보드",
    "view_full_results": "결과 포털 보기",
    "required": "Primary 앱을 사용한다면 요구됩니다.",
    "no_email": "이메일 주소가 없습니다."
  },
  "vaccination_status": {
    "not_vaccinated": "백신 미접종",
    "fully_vaccinated": "백신 접종 완료",
    "partially_vaccinated": "백신 접종 미완료",
    "vaccination_records": "백신접종 기록",
    "title": "COVID-19 백신 접종 상황은 어떻게 됩니까?",
    "definition_title": "백신 상황 정의",
    "definition_cdc": "CDC 백신 상황 정의",
    "definition_osha": "OSHA 백신 상황 정의",
    "definition": {
      "fully_vaccinated": "2 weeks after either a final dose in 2 dose series of 화이자(Pfizer-BioNTech) 혹은 모더나(Moderna)의 2회 접종 중 마지막 접종 후 2주 경과, 또는 존슨앤존슨의 얀샌 백신(Johnson & Johnson's Janssen vaccine) 1회 접종.",
      "partially_vaccinated": "2회 접종 중 1차만 접종 완료, 또는 2회 접종 중 2차 접종 완료 혹은 존슨앤존슨 얀샌 백신 접종 후 2주 미만 경과",
      "not_vaccinated": "COVID-19 백신을 접종하지 않았음.",
      "fully_vaccinated_with_one_booster": "화이자(Pfizer-BioNTech) 혹은 모더나(Moderna) 백신 2차 접종 또는 존슨앤존슨의 얀샌 백식(Johnson & Johnson's Janssen vaccine) 접종 후 부스터 샷 접종 완료.",
      "fully_vaccinated_with_two_boosters": "화이자(Pfizer-BioNTech) 혹은 모더나(Moderna) 백신 2차 접종 또는 존슨앤존슨의 얀샌 백식(Johnson & Johnson's Janssen vaccine) 접종 후 2차 부스터 샷 접종 완료."
    },
    "label": {
      "fully_vaccinated": "백신 완전 접종",
      "partially_vaccinated": "백신 부분 접종",
      "not_vaccinated": "백신 미접종",
      "fully_vaccinated_with_one_booster": "백신 완전 접종 + 부스터",
      "fully_vaccinated_with_two_boosters": "백신 완전 접종 + 부스터 2회",
      "prefer_not_to_answer": "답변을 원하지 않음"
    },
    "progress_bar_title": "COVID-19 백신 접종 내역",
    "upload_record_card": "본인의 COVID-19 백신 접종 기록 카드를 업로드하세요.",
    "show_example": "예 보기",
    "hide_example": "예 숨기기",
    "take_photo": "사진 찍기",
    "or": "혹은",
    "upload_image_or_pdf_instead": "이미지/PDF 업로드",
    "select_doses_received": "COVID-19 백신 접종 내역을 선택하세요.",
    "first_dose": "1차 접종",
    "second_dose": "2차 접종",
    "first_booster_dose": "1차 부스터 샷",
    "second_booster_dose": "2차 부스터 샷",
    "additional_dose": "추가 접종",
    "first_dose_manufacturer": "COVID-19 1차 접종 백신 제조사가 어디인가요?",
    "first_dose_date": "1차 접종 일자는 언제인가요?",
    "second_dose_manufacturer": "COVID-19 2차 접종한 백신 제조사가 어디인가요?",
    "second_dose_date": "2차 접종 일자는 언제인가요?",
    "first_booster_dose_manufacturer": "COVID-19 1차 부스터 샷 백신 제조사가 어디인가요?",
    "first_booster_dose_date": "1차 부스터 샷 접종 일자는 언제인가요?",
    "second_booster_dose_manufacturer": "COVID-19 2차 부스터 샷 백신 제조사가 어디인가요?",
    "second_booster_dose_date": "2차 부스터 샷 접종 일자는 언제인가요?",
    "additional_dose_manufacturer": "COVID-19 추가 접종 백신 제조사가 어디인가요?",
    "additional_dose_date": "추가 접종 일자는 언제인가요?",
    "completed_one": "완료됨",
    "completed_two": "백신 내역 업데이트 감사합니다.",
    "progress_bar_complete": "완료",
    "upload_image": "이미지 업로드",
    "retake_photo": "사진 다시 찍기",
    "other": "기타",
    "remove_first_dose": "1차 접종 내역 삭제",
    "remove_second_dose": "2차 접종 내역 삭제",
    "remove_first_booster_dose": "1차 부스터 샷 접종 내역 삭제",
    "remove_second_booster_dose": "2차 부스터 샷 접종 내역 삭제",
    "remove_additional_dose": "추가 접종 내역 삭제",
    "exemption": "백신 면제 사유가 있습니까?",
    "exempt": "백신 면제자입니다",
    "not_exempt": "백신 면제자가 아닙니다.",
    "enter_exemption": "면제 사유를 입력하세요.",
    "upload_exemption_documentation": "면제 사유를 증명하는 서류를 업로드하세요.",
    "remove_dose": "접종 삭제",
    "continue": "계속하기",
    "enter_credentials": "정보를 입력하세요.",
    "incorrect_credentials": "잘못된 정보입니다.",
    "add_photo": "사진 추가"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "안녕하십니까 {{name}}님, 귀하의 {{org_name}}와의 {{group_name}} 예약이 아직 완료되지 않았습니다. 예약을 유지하기 위해서 귀하께서는 결제 절차를 마치셔야 합니다.  이 링크를 클릭하셔서 결제를 완료해주세요 {{complete_url}}. 예약을 취소하기 원하신다면 이 링크를 클릭해주세요 {{cancel_url}}. 감사합니다."
    }
  },
  "otc": {
    "record_results_for_abbott": "BinaxNOW 테스트 결과를 기록하세요",
    "record_results": "테스트 결과를 기록하세요",
    "activate_a_kit": "키트를 작동시키세요",
    "activate_a_kit_for_test": "{{test}}를 위하여 키트를 작동시키세요",
    "todays_test_process": "금일 테스트 진행과정:",
    "date_of_birth_question": "{{first_name}}님의 생년월일이 언제인가요?",
    "confirm_dob_to_continue": "참가자의 생년월일을 확인해주세요:",
    "incorrect_dob": "생년월일이 맞지 않습니다",
    "file_too_large": "업로드된 파일의 용량이 {{size}}미만이여야 합니다",
    "enter_valid_date_in_last_x_days": "지난 {{n}}일 안에 해당되는 날짜를 입력하세요",
    "barcode_format_not_valid": "이 바코드 형식은 유효하지 않습니다.",
    "complete_button": "완료",
    "next_button": "다음",
    "back_button": "뒤로",
    "report_a_test": "테스트를 보고하세요:",
    "x_%_completed": "{{n}}% 완료",
    "answer_survey": "설문지 질문에 답해주세요",
    "steps": {
      "answer_survey": "설문에 응답해주세요",
      "review_instructions": "설명란을 참조하세요",
      "scan_code": "코드를 스캔하세요",
      "enter_time": "시간을 입력하세요",
      "enter_result": "결과를 입력하세요",
      "take_photo": "사진 찍기",
      "confirm_result": "결과가 맞는지 확인해주세요",
      "selest_test_kit": "테스트 방식 선택",
      "enter_date": "날짜를 입력하세요",
      "enter_date_and_time": "날짜와 시간을 입력하세요"
    },
    "review_instructions": "테스트 설명란을 참조하세요",
    "follow_instructions_or_below": "귀하의 테스트에 동봉된 설명서 또는 하기에 기재된 설명서를 참고하세요.",
    "watch_video": "비디오 보기",
    "view_instructions": "설명서 보기",
    "follow_instructions": "귀하의 테스트에 동봉된 설명서를 따라주세요.",
    "scan_code": "테스트 QR 코드를 스캔하세요",
    "qr_code_description": "이 QR 코드는 귀하의 테스트에만 적용되며, 귀하의 테스트의 우측상단에 입력되어 있습니다.",
    "show_example": "예시 보기",
    "hide_example": "예시 숨기기",
    "barcode_scanned": "바코드가 성공적으로 스캔되었습니다",
    "enter_barcode": "바코드를 입력하세요",
    "scan_qr_code": "QR 코드를 스캔하세요",
    "which_test_did_you_take": "어떤 종류의 테스트를 받으셨나요?",
    "when_did_you_take_test": "언제 이 테스트를 받으셨나요?",
    "what_day_did_you_take_test": "테스트 받으신 날짜가 언제인가요?",
    "what_time_did_you_take_test": "몇시에 이 테스트를 받으셨나요?",
    "time": {
      "today": "오늘",
      "yesterday": "어제",
      "two_days_ago": "이틀 전"
    },
    "enter_current_time": "현재 시간을 입력하세요",
    "enter_result": "테스트 결과를 입력하세요",
    "choose_result_option": "당신의 테스트 결과와 일치하는 사항을 선택하세요",
    "clarify_results": "결과를 분명히 해주세요",
    "take_result_photo": "결과사항 사진 찍기",
    "take_result_photo_description": "결과의 사진을 찍어 기록으로 남기세요.",
    "my_result": "나의 결과",
    "switch_camera": "카메라를 전환하기",
    "confirm_result": "결과가 맞는지 확인해주세요",
    "name": "이름:",
    "date": "날짜:",
    "result": "결과:",
    "results": "결과",
    "test_submitted": "{{first_name}}님 감사합니다! 귀하의 {{test}}가 성공적으로 전송되었습니다.",
    "dob": "생년월일:",
    "administered": "실시하였습니다:",
    "or": "또는",
    "upload": "업로드",
    "change_uploaded_file": "업로드된 파일 변경",
    "take_photo": "사진 찍기",
    "confirm_information": "정보가 맞는지 확인해주세요",
    "barcode": "바코드:",
    "scan_test_barcode": "테스트 바코드를 스캔하세",
    "barcode_description": "이 바코드는 귀하에게만 적용되며, 귀하의 테스트에 입력되어 있습니다.",
    "enter_date_in_last_30_days": "지난 30일 이내에 검사 받은 날짜와 시간을 (최대한 짐작하여) 입력해주세요",
    "add_image": "이미지를 삽입하세요",
    "change_image": "이미지 변경",
    "skip_barcode_reader": "코드를 스캔하는데에 어려움이 있으신가요? \"다음\" 버튼을 클릭하여 테스트 결과를 보고해주세요",
    "enter_date_and_time": "테스트 날짜와 시간을 입력하세요",
    "enter_date": "테스트 날짜를 입력하세요",
    "did_you_take_test_today": "오늘 테스트를 받으셨습니까?",
    "record_results_for_generic": "{{name}} 테스트 결과를 테스트하고 기록하세요.",
    "choose_result_option_custom": "{{test_name}} 결과를 설명하는 옵션을 선택하세요."
  },
  "yes": "네",
  "no": "아니오",
  "event_token": {
    "title": "하기에 제시된 코드를 자판기에 입력하시면 테스트 키트가 출력됩니다.",
    "loading_text": "로딩중입니다. 잠시 후 다시 확인 부탁드립니다."
  },
  "appointment_recovery": {
    "complete_title": "완료되었습니다!",
    "subtitle_1": "아이폰을 사용하고 계신다면, 화면 왼쪽 위에 있는 '완료'를 눌러 본인 대시보드로 돌아가세요.",
    "subtitle_2": "안드로이드폰을 사용하고 계시다면, 화면 왼쪽 위에 있는 'X'표를 눌러주세요.",
    "subtitle_0": "계속하려면 브라우저를 종료하세요."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "동의된 관계",
  "finish_registration": {
    "title": "등록 완료하기",
    "subtitle": "예약을 하기 전에 아래 정보란을 완성하세요.",
    "button": "등록 완료"
  },
  "arab_ethnicity": {
    "arab": "아랍계",
    "non_arab": "아랍계 외",
    "prefer_not_to_answer": "답변을 원하지 않음",
    "question": "아랍 민족",
    "subtitle": "규정에 따라 아래 정보 수집이 요구됩니다.",
    "help": "민족은 언어, 문화 풍습, 종료 그 외 사람들을 분류하기 위한 특징을 공유하는 것을 말합니다. 이는 인종과 일치할 수도 있고 일치하지 않을 수도 있습니다."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "기기에 따라 아래 링크를 클릭하여 Primary Health Pass를 다운로드하세요."
    }
  },
  "stepper": {
    "next": "다음",
    "back": "뒤로가기",
    "complete": "완료하기"
  },
  "registration_direcory": {
    "keyword_search_label": "키워드, 도시, 우편번호, 조직 이름",
    "keyword_search_label_mobile": "도시, 우편 번호, 조직 이름",
    "zero_results": {
      "title": "해당 검색어를 가진 의료인 찾지 못했습니다.",
      "sub_title": "다음을 통해 의료인 검색해 보세요:",
      "p1": "우편번호",
      "p2": "도시 또는 마을 이름",
      "p3": "카운티 이름",
      "p4": "의료인 또는 조직 이름"
    }
  },
  "registration_flyer": {
    "open_camera": "기기에서 카메라 앱을 여십시오",
    "point_to_qr_code": "카메라 큐알 코드를 가리키고 링크를 누르십시오",
    "complete_registration": "등록을 완료하십시오",
    "need_help": "여기로 저희에게 연락하십시오"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "백신 정보 증명",
    "select_checkbox": "계속하기 전에 다음 사항을 검토하고 아래에서 적절한 확인란을 선택하십시오.",
    "opportunity_to_read": "나는 접종할 백신에 대해 제공된 백신 정보 설명서(“VIS”) 또는 긴급 사용 승인(“EUA”) 사실 자료를 읽었거나 읽을 기회가 주어졌거나 나에게 읽어 주었거나 읽었습니다. :",
    "vaccine_info_sheet": "백신 정보 시트",
    "vaccine_fact_sheet": "백신 사실 자료",
    "vaccine_info_statement": "백신 정보 설명서"
  },
  "exemption": {
    "dtap_tdap": "파상풍, 디프테리아, 백일해 백신 (DTap/TDap)",
    "mmr": "홍역, 유행성 이하선염, 풍진 백신 (MMR)",
    "ipv_opv": "소아마비",
    "hib": "인플루엔자균 B형",
    "hep_b": "B형 간염",
    "var": "수두",
    "hep_a": "A형 간염",
    "pcv": "폐렴구균 접합 백신",
    "mcv": "수막구균 백신"
  },
  "test_strip": {
    "A": "이 키트에는 클라미디아와 임질에 대한 테스트가 포함되어 있습니다.",
    "B": "이 키트에는 클라미디아와 임질에 대한 테스트가 포함되어 있습니다.",
    "C": "이 키트에는 매독 테스트가 포함되어 있습니다.",
    "D": "이 키트에는 HIV, 매독, 클라미디아 및 임질에 대한 테스트가 포함되어 있습니다.",
    "E": "이 키트에는 HIV 및 매독 검사가 포함되어 있습니다.",
    "box_type": "상자 {{type}}",
    "administered_text": "키트가 실험실에 도착하고 결과가 준비되면 연락드리겠습니다.",
    "activate_kit": "내 테스트 키트 활성화",
    "register_another_test": "다른 테스트 등록",
    "credentials": {
      "title": "시작하자",
      "label": "연락하다",
      "p1": "귀하에 대한 몇 가지 정보부터 시작하겠습니다.",
      "location_step": {
        "p1": "테스트 키트를 제공한 사람은 누구입니까?",
        "load": "더 로드하기"
      }
    },
    "checklist": {
      "not_urinated": "지난 한 시간 동안 소변을 보지 않았습니다.",
      "bathroom": "소변 수집을 위해 화장실을 이용하실 수 있습니다.",
      "anal_swab": "귀하는 항문 면봉 채취를 수행할 수 있는 개인 장소에 접근할 수 있습니다.",
      "blood_extraction": "혈액 추출을 위한 깨끗한 환경을 이용할 수 있습니다.",
      "washed_hands": "비누와 따뜻한 물로 30초 동안 손을 씻었습니다.",
      "title": "샘플 수집을 시작할 준비가 되셨나요?",
      "no_alcohol": "채취 당일에는 술을 마시지 마십시오.",
      "menstruation": "월경 중이나 성교 후 24시간 이내에는 질 면봉 표본을 수집하지 마십시오."
    },
    "confirm": {
      "title": "정보 확인",
      "p1": "이 정보가 맞나요?"
    },
    "display_name": {
      "A": "클라미디아 및 임질(단일 사이트)",
      "B": "클라미디아 및 임질(3개 사이트)",
      "C": "매독",
      "shortened": {
        "A": "클라미디아 및 임질",
        "B": "클라미디아 및 임질",
        "C": "매독"
      },
      "CC": "COVID-19",
      "CE": "체강 질병 검사",
      "CR": "크레아티닌 테스트",
      "D": "4패널 테스트 - HIV, 매독, 클라미디아, 임질(단일 사이트)",
      "DD": "당뇨병 검사(HbA1c)",
      "E": "HIV와 매독",
      "H": "HPV",
      "P": "C 형 간염",
      "HS": "단순 포진",
      "I": "HIV",
      "TT": "테스토스테론 수치",
      "VD": "비타민 D",
      "VS": "바이러스 감시",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV"
    },
    "rescan": "다시 스캔",
    "scanned": "상자 {{type}}을(를) 스캔했습니다.",
    "not_yet": "아직 아님",
    "success_registration": "등록 성공!",
    "no_box": "상자가 없나요?",
    "faq_link": "FAQ 방문",
    "welcome": "환영!",
    "scan": {
      "title": "당신의 건강한 모습에 한걸음 더 가까워졌습니다.",
      "p1": "상자 측면에 있는 **키트 바코드**를 스캔하거나 입력하세요."
    },
    "appointments": {
      "title": "환자 및 제공자 선택",
      "p1": "귀하의 연락처 정보와 관련된 프로필을 두 개 이상 찾았습니다. 올바른 프로필을 선택하세요.",
      "new": "새로운 환자 또는 제공자"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "귀하의 환자 기록을 찾았습니다."
    },
    "section_1": "키트 내용물 확인",
    "section_2": "테스트 컬렉션",
    "section_3": "포장",
    "section_4": "배송",
    "box_a": "박스 A",
    "box_b": "박스 B",
    "box_c": "박스 C",
    "kit_flow": {
      "button_1": "포장을 계속하세요",
      "button_2": "난 준비됐어",
      "button_3": "내 키트는 완전하고 포장되어 있습니다.",
      "button_4": "난 끝났어",
      "button_5": "다음 테스트",
      "button_6": "지침 건너뛰기"
    },
    "contents": {
      "header": "엄청난! 키트가 완전한지 확인해 봅시다",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "혈액 수집 카드",
      "blood_collection_card_subtitle": "(테스트 키트에 따라 1 또는 2)",
      "alchohol_pads": "알코올 패드 2개",
      "lancets": "일회용 란셋",
      "up_to_other": "(테스트 키트에 따라 최대 4개)",
      "adhesive_bandages": "접착 붕대",
      "pipette": "피펫",
      "urine_tube": "소변 수집 관",
      "urine_cup": "소변 수집 컵",
      "oral_swab": "구강 면봉",
      "oral_tube": "구강 수집 관",
      "anal_swab": "항문 면봉",
      "anal_tube": "항문 수집 관",
      "vaginal_tube": "질 수집 튜브 1개",
      "vaginal_swab": "질 면봉 1개",
      "biodegradable_paper": "생분해성 수집 종이",
      "bottle_and_probe": "샘플링 병 및 프로브",
      "prepaid_envelope": "선불 배송 봉투",
      "biohazard_subtitle": "(흡수패드 포함)",
      "biohazard_bag": "검체봉지 1개",
      "biohazard_bag_plural": "({{count}}) 생물학적 위험물 가방",
      "alcohol_pads_plural": "{{count}} 알코올 패드",
      "sterile_gauze_pad": "멸균 거즈 패드 1개",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "포장 체크리스트",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "모든 테스트 키트 내용물을 포장했습니다.",
      "step_2": "필요한 표본을 수집하기 위해 키트에 포함된 모든 수집 재료를 사용했습니다.",
      "step_3": "귀하의 **생년월일**은 MM/DD/YYYY 형식을 사용하여 모든 검체 수집 튜브에 기록되어 있습니다.",
      "step_4": "**수집일**은 모든 검체 채취 튜브에 MM/DD/YYYY 형식으로 표기됩니다.",
      "step_5": "검체 수집 튜브는 생물학적 위험 봉투에 밀봉되어 있습니다.",
      "step_6": "모든 표본이 상자에 들어있습니다.",
      "step_7": "상자가 선불 배송 봉투에 담겨 있습니다.",
      "step_8": "반품 배송 봉투는 완전히 밀봉되어 있습니다."
    },
    "packaging_box_b": {
      "step_1": "테스트를 건너뛴 경우에도 모든 테스트 키트 내용물을 포장했습니다.",
      "step_2": "필요한 표본을 수집하기 위해 키트에 포함된 모든 수집 재료를 사용했습니다.",
      "step_3": "귀하의 **생년월일**은 MM/DD/YYYY 형식을 사용하여 모든 검체 수집 튜브에 기록되어 있습니다.",
      "step_4": "**수집일**은 모든 검체 채취 튜브에 MM/DD/YYYY 형식으로 표기됩니다.",
      "step_5": "각 검체 수집 튜브는 자체 생물학적 위험 봉투에 밀봉되어 있습니다(가방당 **1개의** 검체만 가능)",
      "step_6": "모든 표본이 상자에 들어있습니다.",
      "step_7": "상자가 선불 배송 봉투에 담겨 있습니다.",
      "step_8": "반품 배송 봉투는 완전히 밀봉되어 있습니다."
    },
    "packaging_box_c": {
      "step_1": "사용한 란셋을 포함하여 모든 테스트 키트 내용물을 포장했습니다.",
      "step_2": "**생년월일**은 MM/DD/YYYY 형식으로 혈액 채취 카드에 기록되어 있습니다.",
      "step_3": "**채혈 날짜**는 MM/DD/YYYY 형식으로 혈액 수집 카드에 기록됩니다.",
      "step_4": "혈액 수집 카드와 사용한 모든 란셋은 생물학적 위험 봉투에 넣습니다.",
      "step_5": "생물학적 위험 봉투가 상자에 들어있습니다.",
      "step_6": "상자가 선불 배송 봉투에 담겨 있습니다.",
      "step_7": "반품 배송 봉투는 완전히 밀봉되어 있습니다."
    },
    "success": {
      "header_1": "엄청난!",
      "text_1": "첫 번째 샘플을 수집할 준비가 되었습니다.",
      "header_2": "훌륭해!",
      "text_2": "**다음:** 키트를 포장하여 반송하세요.",
      "header_3": "잘 했어!",
      "text_3": "당신은 더 나은 건강에 한 걸음 더 가까워졌습니다.",
      "header_4": "좋은 작업!",
      "text_4": "하나만 더 하면 끝!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "지침을 보려면 테스트를 선택하세요.",
      "subtitle": "시험",
      "button": "지침",
      "pdf": "PDF 지침"
    },
    "instructions_box_a": {
      "title": "소변 수집",
      "subtitle": "이 테스트에는 다음 자료가 필요합니다.",
      "step_1": "채취 전 최소 1시간 동안 소변을 보지 마십시오. 아침 첫 소변을 사용하는 것이 가장 좋습니다.",
      "step_2": "PeeCanter 또는 수집 컵에 직접 소변을 보시고 1/3에서 1/2 사이를 채우십시오.",
      "step_3": "PeeCanter에서 따르거나 피펫을 사용하여 소변을 수집 컵에서 주황색 <span style=\"color: #FF5000\">**\"URINE\"**</span> 라벨이 표시된 수집 튜브로 옮깁니다.",
      "step_4": "결합된 액체가 지정된 채우기 선 사이에 올 때까지 수집 튜브를 채웁니다.",
      "step_4_sublabel": "최대 충전선을 초과하여 튜브를 채우지 마십시오. 그렇지 않으면 표본이 거부됩니다.",
      "step_5": "수집 튜브에 뚜껑을 놓고 균일하고 단단히 닫혀 있는지 확인하십시오.",
      "step_6": "생년월일과 검체채취일(오늘일)을 MM/DD/YYYY 형식으로 튜브의 지정된 위치에 기재합니다.",
      "step_7": "흡수 패드가 들어 있는 빈 생물학적 위험 봉투에 표본을 넣고 밀봉이 완전히 닫혔는지 확인합니다."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "소변 수집",
        "subtitle": "이 테스트에는 다음 자료가 필요합니다.",
        "step_1": "채취 전 최소 1시간 동안 소변을 보지 마십시오. 아침 첫 소변을 사용하는 것이 가장 좋습니다.",
        "step_2": "PeeCanter 또는 수집 컵에 직접 소변을 보시고 1/3에서 1/2 사이를 채우십시오.",
        "step_3": "PeeCanter에서 따르거나 피펫을 사용하여 소변을 수집 컵에서 주황색 <span style=\"color: #FF5000\">**\"URINE\"**</span> 라벨이 표시된 수집 튜브로 옮깁니다.",
        "step_4": "결합된 액체가 지정된 채우기 선 사이에 올 때까지 수집 튜브를 채웁니다.",
        "step_4_subtitle": "최대 충전선을 초과하여 튜브를 채우지 마십시오. 그렇지 않으면 표본이 거부됩니다.",
        "step_5": "수집 튜브에 뚜껑을 놓고 균일하고 단단히 닫혀 있는지 확인하십시오.",
        "step_6": "생년월일과 검체채취일(오늘일)을 MM/DD/YYYY 형식으로 튜브의 지정된 위치에 기재합니다.",
        "step_7": "흡수 패드가 들어 있는 빈 생물학적 위험 봉투에 표본을 넣고 밀봉이 완전히 닫혔는지 확인합니다."
      },
      "test_2": {
        "title": "구강 면봉",
        "subtitle": "이 테스트에는 다음 자료가 필요합니다.",
        "step_1": "희미한 점수선(있는 경우)과 면봉의 면봉 부분 사이에 면봉을 잡습니다.",
        "step_2": "면봉을 입에 넣고 면봉 끝을 목 뒤쪽에 10초간 문지릅니다.",
        "step_3": "면봉을 조심스럽게 빼내고 파란색 <span style=\"color: #001F70\">**\"ORAL\"**</span> 라벨이 표시된 수집 튜브에 넣습니다. 수집 튜브를 구부려서 점수선에서 면봉을 부러뜨립니다.",
        "step_4": "수집 튜브에 뚜껑을 놓고 균일하고 단단히 닫혀 있는지 확인하십시오.",
        "step_5": "생년월일과 검체채취일(오늘일)을 MM/DD/YYYY 형식으로 튜브의 지정된 위치에 기재합니다.",
        "step_6": "흡수 패드가 들어 있는 빈 생물학적 위험 봉투에 표본을 넣고 밀봉이 완전히 닫혔는지 확인합니다."
      },
      "test_3": {
        "title": "항문 면봉",
        "subtitle": "이 테스트에는 다음 자료가 필요합니다.",
        "step_1": "희미한 점수선(있는 경우)과 면봉의 면봉 부분 사이에 면봉을 잡습니다.",
        "step_2": "면봉을 항문관에 3~5cm(1~2인치) 삽입합니다. 면봉을 5~10초 동안 가볍게 돌려 잠재적인 유기체를 수집합니다.",
        "step_3": "면봉을 조심스럽게 빼내고 녹색 <span style=\"color: #00C08C\">**“ANAL”**</span> 라벨이 표시된 수집 튜브에 넣습니다. 수집 튜브를 구부려서 점수선에서 면봉을 부러뜨립니다.",
        "step_4": "수집 튜브에 뚜껑을 놓고 균일하고 단단히 닫혀 있는지 확인하십시오.",
        "step_5": "생년월일과 검체채취일(오늘일)을 MM/DD/YYYY 형식으로 튜브의 지정된 위치에 기재합니다.",
        "step_6": "흡수 패드가 들어 있는 빈 생물학적 위험 봉투에 표본을 넣고 밀봉이 완전히 닫혔는지 확인합니다."
      }
    },
    "instructions_box_c": {
      "title": "혈액 수집",
      "subtitle": "이 테스트에는 다음 자료가 필요합니다.",
      "step_1": "해당란에 이름, 생년월일, 수집일을 기재합니다. MM/DD/YYYY 형식을 사용하세요.",
      "step_2": "혈액 카드 플랩을 열어 혈액 수집 용지의 원을 노출시킵니다. 채혈지를 만지지 마십시오.",
      "step_3": "따뜻한 물로 30초 이상 손을 씻은 후 15초 동안 세게 악수하여 손가락의 혈액 순환을 촉진합니다.",
      "step_4": "알코올 패드로 손가락 끝을 닦아냅니다. 주로 사용하지 않는 손의 중지나 약지를 사용하는 것이 가장 좋습니다.",
      "step_5": "란셋을 잡고 캡을 비틀어 엽니다. 바늘이 찰칵 소리를 내며 나올 때까지 작은 끝을 손가락 끝으로 단단히 누르십시오. 채취하는 동안 손을 심장 아래에 두고 손가락 밑부분부터 끝까지 마사지하여 혈류를 촉진합니다.",
      "step_5_subtitle": "랜싯은 일회용입니다. 모든 란셋은 폐기를 위해 샘플과 함께 실험실로 반환되어야 합니다.",
      "step_6": "중앙부터 시작하여 3~6방울을 떨어뜨려 원을 가득 채우고 컬렉션 페이퍼에 스며들게 합니다. 혈류를 제한할 수 있으므로 손가락으로 종이를 만지지 마십시오. 서클이 가득 차면 다음 서클로 이동합니다. 피가 선을 넘어 뻗어나가는 것은 괜찮으나, 핏자국이 서로 번지지 않도록 하십시오.",
      "step_6_subtitle": "원이 완성되거나 건조된 후에는 추가 혈액을 추가하지 마십시오. 혈액을 \"층화\"하면 수집이 무효화됩니다.",
      "step_7": "혈액 카드를 닫지 않은 채 평평한 표면에 놓고 혈액 수집 종이를 실온에서 최소 30분 동안 자연 건조시킵니다. 채혈지를 가열하거나 바람으로 말리거나 직사광선에 노출시키지 마십시오. 열은 표본을 손상시킵니다.",
      "step_7_subtitle": "채혈지 뒷면을 확인하세요. 혈액은 완전히 포화되어 수집 용지의 각 원을 채워야 합니다.",
      "step_8": "채혈 종이가 마르면 혈액 카드 덮개를 밀어서 닫습니다. 혈액 카드와 채혈침을 건조제 팩과 함께 생물학적 위험 봉투에 넣습니다. 생물학적 위험 봉투가 제대로 밀봉되었는지 확인하십시오."
    },
    "test_tips": {
      "title": "적절한 혈액 수집을 위한 팁",
      "subtitle": "최상의 결과를 얻으려면:",
      "step_1": "수집을 수행하는 동안 탈수되지 않도록 하십시오. 수분 공급은 혈류를 촉진합니다.",
      "step_2": "흡연 후 즉시 수집을 수행하지 마십시오.",
      "step_3": "따뜻한 물로 손을 씻고 따뜻하게 하면 손의 혈액 순환을 촉진하는 데 도움이 됩니다.",
      "step_4": "바닥을 향해 힘차게 악수하여 손가락의 혈액 순환을 촉진합니다.",
      "step_5": "최상의 혈액 흐름을 위해 수집하는 동안 수집 장치와 손을 심장 아래에 두십시오.",
      "step_6": "손가락 찌르기가 두 개 이상 필요할 수도 있습니다. 각 손가락 찌르기 사이에 이러한 팁을 반복하십시오."
    },
    "shipping": {
      "header": "키트 배송 준비가 완료되었습니다!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "현장 직원으로부터 추천 테스트 키트를 수령하고 아래에서 등록하세요.",
    "short_display_name": {
      "A": "클라미디아 및 임질",
      "B": "클라미디아 및 임질",
      "C": "매독",
      "CC": "대장암",
      "CE": "복강",
      "CR": "크레아티닌",
      "D": "HIV, 매독, 클라미디아 및 임질",
      "DD": "당뇨병",
      "E": "HIV와 매독",
      "H": "HPV",
      "P": "C 형 간염",
      "HS": "단순 포진",
      "I": "HIV",
      "TT": "테스토스테론",
      "VD": "비타민 D",
      "VS": "바이러스 감시"
    },
    "test_kit": "테스트 키트",
    "view_results": "결과 보기",
    "recommended_kit_title": "권장 테스트 키트",
    "instructions_box_h": {
      "title": "질 면봉 수집",
      "subtitle": "수집 튜브에는 액체 방부제가 포함될 수 있습니다. <span class=\"text-danger\">**수집 튜브에 액체 방부제가 있는 경우 이를 비우지 마십시오.**</span>",
      "step_1": "희미한 점수선(있는 경우)과 면봉 끝 부분 사이에 면봉을 한 손으로 잡고 음순(질 입구 주위의 피부 주름)을 분리합니다.",
      "step_2": "면봉을 질 입구에 5cm(2인치) 정도 삽입합니다. 면봉을 질 벽에 문지르면서 면봉을 30초 동안 부드럽게 돌리십시오.",
      "step_3": "면봉을 조심스럽게 빼내고 <span class=\"text-danger\">**빨간색 “VAGINAL” 라벨**</div>이 표시된 수집 튜브에 넣습니다. 수집 튜브에 대고 구부려서 면봉을 부러뜨립니다.",
      "step_4": "수집 튜브에 뚜껑을 놓고 균일하고 단단히 닫혀 있는지 확인하십시오.",
      "step_5": "생년월일을 MM/DD/YYYY 형식으로 튜브의 지정된 영역(DOB)에 쓰세요.",
      "step_6": "튜브의 지정된 부분(Coll. Date)에 검체 채취 날짜(오늘 날짜)를 MM/DD/YYYY 형식으로 기재합니다.",
      "step_7": "흡수 패드가 있는 빈 생물학적 위험 봉투에 검체를 넣고 밀봉이 완전히 닫혔는지 확인합니다."
    },
    "packaging_box_h": {
      "step_3": "**생년월일**은 검체 채취 튜브에 MM/DD/YYYY 형식으로 기록되어 있습니다.",
      "step_4": "귀하의 **수집 날짜**는 검체 채취 튜브에 MM/DD/YYYY 형식으로 기록되어 있습니다.",
      "step_6": "생물학적 위험 봉투가 상자에 들어있습니다."
    },
    "instructions_box_cc": {
      "title": "대변 수집",
      "step_1": "생년월일과 검체채취일(오늘일)을 MM/DD/YYYY 형식으로 튜브의 지정된 위치에 기재합니다. 녹색 캡을 비틀고 들어올려 엽니다.",
      "step_2": "제공된 수거지를 변기의 물 위에 올려 놓습니다.",
      "step_3": "수집 종이 위에 대변 샘플을 올려 놓습니다.",
      "step_4": "종이 싱크대 및 대변 샘플이 물에 닿기 전에 대변에서 샘플을 수집하십시오.",
      "step_5": "샘플 프로브를 사용하여 대변 샘플의 표면을 긁습니다.",
      "step_6": "대변 검체로 검체 탐침의 홈이 있는 부분을 완전히 덮습니다.",
      "step_7": "샘플 프로브를 삽입하고 녹색 캡을 단단히 닫아 샘플링 병을 닫습니다. 다시 열지 마십시오.",
      "step_8": "플러시. 수거지는 생분해성이고 정화조 시스템에 해를 끼치지 않습니다.",
      "step_9": "샘플링 병을 흡수성 패드에 싸서 생물학적 위험물 봉투에 넣습니다."
    },
    "contact_support": {
      "title": "이 문제를 해결하자",
      "text_1": "문제가 발생했다는 소식을 들으니 안타깝습니다!",
      "text_2": "당사에 연락하여 무엇이 문제인지 알려주시면 키트 교체를 도와드리겠습니다."
    },
    "contact_support_success": {
      "title": "귀하의 메시지를 받았습니다",
      "text_1": "연락 해주셔서 감사합니다.",
      "text_2": "지원팀 담당자가 곧 연락을 드릴 것입니다.",
      "button_text": "귀국"
    },
    "kit_in_transit_to_patient": "주문하신 상품이 배송 중입니다! 대부분의 주문은 영업일 기준 2~5일 이내에 배송됩니다. 키트를 받으면 활성화 방법에 대한 추가 정보를 보내드리고 다시 보내드립니다.\n\n추적 링크: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "연구실에서 귀하의 키트를 받았습니다! 결과가 준비되면 환자 포털을 확인하라는 알림을 받게 됩니다.",
    "registration_confirmation": "이제 등록이 완료되었습니다! 테스트 키트를 활성화하려면 등록 확인 페이지를 방문하세요.",
    "kit_ordered_online": "귀하의 키트 주문이 접수되었으며 배송되면 업데이트를 보내드리겠습니다! 주문 번호: {{order_number}} 주문 날짜: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "귀하의 주문이 접수되었습니다!",
      "p1": "귀하의 주문이 접수되었습니다! 주문이 배송되면 또 다른 업데이트를 보내드리겠습니다.",
      "order_number": "주문하다 #",
      "order_date": "주문일"
    },
    "completed": "완전한",
    "I": "이 키트에는 HIV 테스트가 포함되어 있습니다.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "이 키트에는 체강 질병에 대한 테스트가 포함되어 있습니다.",
    "CR": "이 키트에는 크레아티닌 테스트가 포함되어 있습니다.",
    "DD": "이 키트에는 당뇨병 검사가 포함되어 있습니다.",
    "H": "이 키트에는 HPV 테스트가 포함되어 있습니다.",
    "P": "이 키트에는 C형 간염 검사가 포함되어 있습니다.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "이 키트에는 HSV-2에 대한 테스트가 포함되어 있습니다.",
    "TT": "이 키트에는 테스토스테론 테스트가 포함되어 있습니다.",
    "VD": "이 키트에는 비타민 D 테스트가 포함되어 있습니다.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B."
  },
  "copy_link": "페이지 링크 복사",
  "copied_link": "링크를 복사했습니다!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "일반적인 독감 바이러스로부터 보호",
      "vaccine_covid": "코로나19 바이러스로부터 보호",
      "tdap": "Tdap 백신으로 파상풍 예방 가능",
      "polio": "소아마비 바이러스로부터 보호. 학교를 시작하기 전 어린이에게 필요합니다.",
      "varicella": "수두를 예방하는 백신",
      "shingles": "대상포진을 예방하는 백신. 최소 연령은 18세입니다.",
      "human_papillomavirus": "HPV로부터 보호하는 백신. 11세 어린이에게 권장됩니다.",
      "meningococcal": "백신은 네 가지 유형의 수막구균 박테리아로부터 보호합니다."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "진료소 찾기",
    "select_pin": "세부정보를 보려면 핀을 선택하세요.",
    "miles_shorten": "{{miles}} 마일",
    "next_available": "다음 사용 가능",
    "appointment_type": "약속 유형",
    "individual_appointment": "개별 약속",
    "family_appointment": "가족 약속",
    "vaccines": "백신",
    "what_kind_appointment": "어떤 종류의 약속을 예약하고 싶으신가요?",
    "select_family_or_individual": "여러 가족 구성원을 동시에 예약하려면 가족 약속을 선택하세요.",
    "vaccines_selected": "선택한 백신",
    "where_care": "어디서 진료를 받고 싶으신가요?",
    "select_vaccines_for_individual": "예약에 맞는 백신을 선택하세요(선택 사항).",
    "select_vaccines_for_family": "가족 약속에 맞는 백신을 선택하세요(선택 사항).",
    "schedule_services": "서비스 예약",
    "add_family": "가족 일원을 추가하고 아래에서 서비스를 선택하세요",
    "family_member": "가족 구성원 {{number}}",
    "morning": "아침",
    "afternoon": "오후",
    "slot_available": "{{number}} 사용 가능",
    "within_x_miles": "{{miles}}마일 이내",
    "any_distance": "어떤 거리에서든",
    "partial_results": "부분적인 결과",
    "partial_matches": "다음 진료소에서는 귀하가 찾고 있는 백신의 전부는 아니지만 일부를 제공합니다.",
    "no_matches": "검색 결과 일치하는 항목이 없습니다. 더 나은 결과를 얻으려면 필터를 변경해 보세요.",
    "no_clinics_found": "검색하신 내용과 일치하는 진료과가 없습니다.",
    "broaden_filters": "더 나은 결과를 얻으려면 필터를 확장해 보세요.",
    "unavailable_vaccines": "사용할 수 없는 백신:",
    "available_vaccines": "사용 가능한 백신:",
    "select_date": "날짜를 선택하세요",
    "available_appointments": "이용 가능한 약속",
    "appointment_scheduling_info": "다음 단계에서는 특정 가족 구성원에게 약속 시간을 할당할 수 있습니다. 원하는 경우 다른 날짜에 약속을 예약할 수 있습니다.",
    "hold_selected_appointments": "귀하가 선택한 약속을 15분 동안 유지합니다.",
    "appointments_selected": "선택된 약속",
    "no_appointments_selected": "선택한 약속이 없습니다.",
    "vaccines_needed": "필요한 백신",
    "select_x_appointments": "{{number}}개의 약속을 선택하세요.",
    "more": "더 많이",
    "less": "더 적게",
    "register_next_family_member": "다음 가족 일원 등록",
    "successfully_registered_x_of_y_family_members": "가족일원 {{y}}의 {{x}}을 성공적으로 등록했습니다",
    "next_family_member": "다음 가족 일원",
    "appointments_abbreviated": "예약",
    "register_for_clinic": "클리닉 등록",
    "select_services": "서비스 선택",
    "person": "사람 {{number}}",
    "add_person": "다른 사람 추가",
    "registration_confirmation": "등록 확인"
  },
  "user_mailer": {
    "verify_email": "이메일을 확인해 보세요!",
    "hi_full_name": "안녕하세요 {{full_name}}님,",
    "verify_email_button": "이메일 확인",
    "please_verify_email": "아래 버튼을 이용해 이메일을 확인해 주세요."
  },
  "services": {
    "dptap_dt": "파상풍, 디프테리아, 백일해 백신 (DTap/TDap/Td)",
    "hepatitis_a": "A형 간염",
    "hepatitis_b": "B형 간염",
    "hepatitis_a_b": "A형 및 B형 간염",
    "hib": "B형 헤모필루스 균 인플루엔자",
    "mmr": "홍역 · 유행성이하선염 · 풍진",
    "meningococcal": "수막구균수막염",
    "mpox": "엠폭스",
    "pneumococcal": "폐렴구균",
    "polio": "소아마비",
    "rsv": "호흡기세포융합바이러스",
    "rotovirus": "로타바이러스",
    "zoster": "대상포진",
    "tetanus_diptheria": "파상풍 및 디프테리아",
    "tdap": "Trap",
    "typhoid": "장티푸스",
    "varicella": "수두",
    "covid-19": "코로나-19",
    "covid-19_adult": "코로나-19 (성인)",
    "covid-19_adult_description": "12세 이상용",
    "covid-19_children": "코로나-19 (아동)",
    "covid-19_children_description": "4-11세 아동용",
    "covid-19_infants": "코로나-19 (유아)",
    "covid-19_infants_description": "6개월-3세 아동용",
    "influenza": "독감",
    "mmrv": "홍역, 유행성 이하선염, 풍진, 수두"
  },
  "deep_archived": {
    "admin": "데이터 보안을 위해 {{test_group_name}} 그룹({{slug}})의 데이터는 아카이브되며 이 페이지에서 더 이상 보거나 변경할 수 없습니다. 도움이 필요한 경우 계정 관리자 또는 support@primary.health에 문의하시기 바랍니다.",
    "participant": "이 페이지는 더 이상 사용할 수 없습니다. 건강 캠페인의 연락처로 연락해 주세요: {{test_group_name}}. 이전 기록을 보는 데 문제가 있는 경우 support@primary.health에 문의하여 도움을 요청하세요."
  }
}