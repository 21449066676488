import React from "react";

const ChosenServices = ({ chosenServices }) => {
  if (chosenServices.length == 0) return <span></span>;
  return (
    <>
      <div className="h4-20-semi-bold poppins semibold my-3">
        Chosen test services
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}
      >
        {chosenServices.map((testConfiguration) => (
          <div
            className="body-small-semibold"
            key={testConfiguration.id}
            style={{
              borderRadius: "40px",
              padding: "0px 24px",
              backgroundColor: "#F7F7F7",
              height: "32px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {testConfiguration.display_name}
          </div>
        ))}
      </div>
    </>
  );
};

export default ChosenServices;