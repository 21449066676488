import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown/with-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContentItems } from './ContentsStep';
import {
  ReactMarkdownCustomClass,
  Section,
  SectionItem,
} from '../../components/styled';
import ArrowUpRight from '../../../common/components/Icons/ArrowUpRight';

const Step = ({ step, index, t }) => {
  return (
    <>
      <Card
        body
        style={{
          borderRadius: '16px',
          height: 'auto',
          boxShadow: 'none',
          backgroundColor: '#F7F9FC',
          border: 'none',
          padding: '0px',
        }}
      >
        <Row key={index}>
          <Col xs="auto" className="pe-0">
            <span className="fa-stack" style={{ verticalAlign: 'middle' }}>
              <i>
                <FontAwesomeIcon
                  icon={'circle'}
                  style={{ color: '314677' }}
                  className="fa-stack-2x"
                />
              </i>
              <span className="fa-layers-text" style={{ color: '#FFFFFF' }}>
                {index + 1}
              </span>
            </span>
          </Col>
          <Col>
            <ReactMarkdown
              source={t(step.label)}
              escapeHtml={false}
              renderers={{
                paragraph: ({ children }) => (
                  <ReactMarkdownCustomClass customClass="body big">
                    {children}
                  </ReactMarkdownCustomClass>
                ),
              }}
            />
          </Col>
        </Row>
      </Card>
      {step.label && (
        <div
          style={{
            margin: '10px 38px',
            color: '#D14110',
          }}
        >
          <h4 className="body big">{t(step.sublabel)}</h4>
        </div>
      )}
      <div className="text-center p-3 m-6">
        <img src={step.image} style={{ maxHeight: 140 }} />
      </div>
    </>
  );
};

export default function InstructionsStep({
  instructions,
  t,
  currentInstruction,
  isMobile,
}) {
  const { title, subtitle, test_steps, items, link } =
    instructions[currentInstruction];
  return (
    <Section data-test-hook={`instruction-step-${currentInstruction}`}>
      <SectionItem>
        <Row>
          <Col>
            <Row>
              <h3
                className="body big"
                data-test-hook={`instruction-step-header`}
              >
                {`${t('test_strip.instructions.subtitle')}${
                  instructions.length > 1
                    ? ` ${parseInt(currentInstruction) + 1}: `
                    : ': '
                }`}
                {t(title)}
              </h3>
            </Row>
            <Row>
              <ReactMarkdown
                source={t(subtitle)}
                escapeHtml={false}
                renderers={{
                  paragraph: ({ children }) => (
                    <ReactMarkdownCustomClass customClass="body big">
                      {children}
                    </ReactMarkdownCustomClass>
                  ),
                }}
              />
            </Row>
          </Col>
          {!isMobile && link && (
            <Col className="text-end" xs="auto">
              <a
                className="poppins regular"
                style={{ fontSize: '18px', fontWeight: '600' }}
                href={link}
                target="_blank"
              >
                {t('test_strip.instructions.pdf')}
                <ArrowUpRight className="ms-2" />
              </a>
            </Col>
          )}
        </Row>
      </SectionItem>
      {items && (
        <SectionItem>
          <Row className={`${isMobile ? 'mx-6 px-3' : ''}`}>
            <ContentItems items={items} t={t} />
          </Row>
        </SectionItem>
      )}
      {isMobile && link && (
        <Col xs="auto" className="mb-3">
          <a
            className="poppins regular"
            style={{ fontSize: '18px', fontWeight: '600' }}
            href={link}
            target="_blank"
          >
            {t('test_strip.instructions.pdf')}
            <ArrowUpRight className="ms-2" />
          </a>
        </Col>
      )}
      {test_steps.map((step, index) => (
        <SectionItem key={index}>
          <Step {...{ step, index, t }} />
        </SectionItem>
      ))}
    </Section>
  );
}
