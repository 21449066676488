import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { throttle } from 'lodash';

import {
  CheckInInputs,
  SECTIONS_IN_ORDER,
} from '../../Participants/components/participants/NewCheckInModalV2';
import { checkoutShape } from './shapes';
import { onlyUnique } from '../../common/utils';
import ServicesSection from './ServicesSection';
import StickyHeader from '../../common/components/StickyHeader';
import CheckoutSideBar from './CheckoutSideBar';

const PreCheckinSections = ({
  testGroup,
  appointment,
  currentUser,
  checkinOptions,
  testConfiguarations = [],
  ...props
}) => {
  const { user } = appointment;

  return (
    <React.Fragment>
      {checkinOptions.length > 0 && (
        <form
          encType="multipart/form-data"
          action={`/test_groups/${testGroup.id}/appointments/${appointment.id}`}
          acceptCharset="UTF-8"
          method="post"
          id="checkInForm"
        >
          <CheckInInputs
            currentUserIsHipaaTrained={currentUser.is_hipaa_trained}
            testGroup={testGroup}
            testConfigurations={testConfiguarations}
            appointment={appointment}
            user={user}
            activeSections={checkinOptions}
            disableAdvanced={true}
            checkOut={true}
            {...props}
          />
        </form>
      )}
    </React.Fragment>
  );
};

const sortTests = (tests) =>
  tests.sort((ta, tb) => {
    if (!ta.result && tb.result && tb.administered_at) return -1;
    if (ta.result && ta.administered_at && !tb.result) return 1;
    return 0;
  });

const CheckoutHeader = ({
  appointment,
  redirectToLink,
  setSubmitCheckoutAttempted,
  testGroup,
  user,
}) => {

  const validateCheckout = () => {
    const pendingSections = document.getElementsByClassName("pending");

    if (pendingSections.length > 0) {
      toastr.error("Please complete all required fields");

      // scroll to first pending section
      const headerHeight = document.getElementById('header-container').offsetHeight;
      const firstPendingSection = pendingSections[0];
      const firstPendingSectionPosition = firstPendingSection.getBoundingClientRect().top + window.pageYOffset;
      const offSet = 60
      const offsetPosition = firstPendingSectionPosition - headerHeight - offSet;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });

      return false;
    }
    return true;
  };

  const submitCheckout = () => {
    axios.post(
      `/test_groups/${testGroup.slug}/appointments/${appointment.id}/checkout/complete_checkout`,
    ).then((response) => {
      if (response.data.success) {
        location.href = redirectToLink();
      } else {
        toastr.error("Something went wrong.")
      }
    }).catch((error) => {
      console.log('error', error);
      toastr.error(error.response.data.message)
    });
  }

  const handleCompleteCheckout = () => {
    setSubmitCheckoutAttempted(true);
    if (validateCheckout()) submitCheckout();
  };

  const makeHeaderSticky = () => {
    const headerContainer = document.getElementById('header-container');
    const checkoutHeaderContainer = document.getElementById('checkout-header');
    if (!!headerContainer && !!checkoutHeaderContainer) {
      const headerHeight = headerContainer.offsetHeight;
      const checkoutHeaderHeight = checkoutHeaderContainer.offsetHeight;
      const offSet = 15;
      // setting the checkout header to a fix position right under the main header
      checkoutHeaderContainer.style.top = `${headerHeight + offSet}px`;
      const checkoutContainer = document.getElementById('checkout-container');
      if (checkoutContainer) {
        // adding enough padding to the checkout container so it's not covered be the header
        checkoutContainer.style.paddingTop = `${checkoutHeaderHeight}px`;
      }
    }
  };

  const headerContainerObserver = new MutationObserver(() => {
    makeHeaderSticky();
  });

  headerContainerObserver.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
  });

  makeHeaderSticky();

  return (
    <Row
      className="py-2 fixed-top"
      id="checkout-header"
      style={{
        backgroundColor: "#FFFFFF",
        borderBottom: '1px solid #DEE2E6',
        padding: "0 40px",
        marginTop: "-16px",
        zIndex: 100,
      }}
    >
      <Col>
        <div className="my-3 body-large-16-regular text-muted">
          {testGroup.name}
        </div>
        <div className="my-3 h3-24-medium poppins medium">
          {user.full_name}
          {!!user.pronoun && <span className="ms-2 body-medium-regular">({user.pronoun})</span>}
        </div>
        <div className="my-3 body-large-18-regular">
          {user.date_of_birth} ({user.age} yrs)
        </div>
      </Col>
      <Col className="d-flex justify-content-end">
        <div className="d-flex flex-column justify-content-center">
          <Button onClick={handleCompleteCheckout}>
            Complete checkout
          </Button>
        </div>
      </Col>
    </Row>
  );
};

const CheckoutPage = ({
  test_group,
  test_configurations,
  appointment,
  current_user,
  symptoms,
  vaccine_service_specifications,
  permission_kinds,
  editable_test_types,
}) => {
  const [tests, _setTests] = useState(sortTests(appointment.tests));
  const setTests = (tests) => _setTests(sortTests(tests));
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [unsavedUidByTestConfigurationId, setUnsavedUidByTestConfigurationId] =
    useState({});
  const [surveyMissingAnswer, setSurveyMissingAnswer] = useState(false);

  const specificTestConfigurations = test_configurations.filter(
    (tc) => tc.service_specification !== 'rapid_general',
  );
  const useServicesModalView = specificTestConfigurations.length >= 9;

  const [currentAdministratorName, setCurrentAdministratorName] = useState(null);
  const [currentAdministratorDegree, setCurrentAdministratorDegree] = useState(null);
  const [currentAdministratorInitials, setCurrentAdministratorInitials] = useState(null);

  const { user } = appointment;
  const [showAddTests, setShowAddTests] = useState(
    tests.length === 0 || useServicesModalView,
  );

  const isVaccine = (specification) => {
    return vaccine_service_specifications.includes(specification);
  };

  const updateTest = (test) => {
    setUnsavedChanges(true);
    let index = tests.map((t) => t.id).indexOf(test.id);

    // Concat the front, with the new test with the back
    setTests(
      tests
        .slice(0, index)
        .concat([test])
        .concat(tests.slice(index + 1, tests.length)),
    );
  };

  const redirectToLink = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectTo = urlParams.get('redirect_to');

    if (redirectTo) {
      return redirectTo;
    }
    return `/test_groups/${test_group.slug}/participants`;
  };

  const saveDataToLocalStorage = () => {
    // sticky message that is displayed after saving and redirecting to participants page
    const orderAdministeredMessage = `<a href="appointments/${appointment.id}/checkout">
      Order administered for ${user.first_name} ${user.last_name}.
      Need to make a change? Click here to return to ${user.first_name}
      ${user.last_name}'s checkout.</a>`;

    localStorage.setItem('orderAdministeredMessage', orderAdministeredMessage);
  };

  const submitTests = (
    tests,
    followUpAppointmentReschedulingParams = {},
    bypassInitials = null,
  ) => {
    const body = {
      appointment_id: appointment.id,
      follow_up_appointment: followUpAppointmentReschedulingParams,
      bypassed_consent: !!bypassInitials,
      tests,
    };
    return axios.post(
      `/test_groups/${test_group.slug}/tests_batch_create`,
      body,
    );
  };

  const handleCheckinSubmit = () => {
    const form = document.querySelector('#checkInForm');
    const formData = new FormData(form);

    axios({
        url: `/test_groups/${test_group.id}/appointments/${appointment.id}`,
        method: "post",
        data: formData,
        headers: { 'content-type': 'multipart/form-data' },
      });
  };

  const checkinInCheckoutOptions = test_group.checkout_options
    .concat(test_group.on_demand_only ? ['consent'] : [])
    .filter(onlyUnique)
    .sort((a, b) => SECTIONS_IN_ORDER.indexOf(a) - SECTIONS_IN_ORDER.indexOf(b));

  const saveTests = (
    force,
    redirectAfterSubmit = true,
    bypassInitials = null,
  ) => {
    // Build the tests that are not saved yet.
    const vaccinesWithoutAdministered = tests
      .filter((t) => isVaccine(t.test_configuration.service_specification))
      .filter((t) => t.administered === null);
    if (vaccinesWithoutAdministered.length > 0 && !force) {
      // Show modal
      setShowErrorModal(true);
      return;
    }

    // Update tests that do not show the administer button
    tests
      .filter((t) => t.test_configuration.hide_administered_button)
      .map((t) => updateTest({ ...t, administered: true }));

    let unsavedTests = [];
    for (const [testConfigurationId, uid] of Object.entries(
      unsavedUidByTestConfigurationId,
    )) {
      if (uid && uid.length > 4) {
        unsavedTests.push({
          test_configuration_id: testConfigurationId,
          appointment_id: appointment.id,
          uid: uid,
        });
      }
    }
    setUnsavedChanges(false);

    const appointmentId =
      appointment.follow_up_appointment_id || appointment.id;

    if (checkinInCheckoutOptions.length > 0) handleCheckinSubmit();

    const anyTestGotAdministered = tests.some((test) => test['administered']);

    submitTests(
      tests.concat(unsavedTests),
      {
        new_date: rescheduleDate,
        id: appointmentId,
        one_dose_only: !appointment.follow_up_appointment_id,
      },
      bypassInitials,
    )
      .then((_response) => {
        if (redirectAfterSubmit) {
          if (anyTestGotAdministered) saveDataToLocalStorage();
          location.href = redirectToLink();
        }
      })
      .catch((error) => {
        alert('Something went wrong: ' + error.response.data.message);
      });
  };

  const [redirectCallbackPath, setRedirectCallbackPath] = useState(null);

  useEffect(() => {
    if (redirectCallbackPath) {
      location.href = redirectCallbackPath;
    }
  }, [redirectCallbackPath]);

  const [showScheduleFollowUp, setShowScheduleFollowUp] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState(null);

  useEffect(() => {
    if (showScheduleFollowUp) {
      const value = Cookies.get('recommended_follow_up_reschedule_date');
      setRescheduleDate(value);
    } else {
      // If they hide the panel, this value should be removed
      setRescheduleDate(null);
    }
  }, [showScheduleFollowUp]);

  useEffect(() => {
    const bodyTag = document.querySelector('body');
    bodyTag.style.backgroundColor = '#FBFBFB';
  }, []);

  const [submitCheckoutAttempted, setSubmitCheckoutAttempted] = useState(false);
  const { contentRef } = StickyHeader();

  const [sidebarKeys, setSidebarKeys] = useState([]);
  const [activeSection, setActiveSection] = useState(sidebarKeys[0]);

  useEffect(() => {
    function setSidebarHeight() {
      // to dynamically resize the sticky sidebar height
      const checkoutHeader = document.getElementById('checkout-header');
      const mainHeader = document.getElementById('header-container');
      const sidebar = document.querySelector('.sidebar-nav');
      const viewContainer = document.getElementById('checkout-view-container');

      if (mainHeader && checkoutHeader && sidebar && viewContainer) {
        const totalHeaderHeight = mainHeader.offsetHeight + checkoutHeader.offsetHeight;
        const viewContainerRect = viewContainer.getBoundingClientRect();
        const sidebarVisibleHeight = Math.max(0, Math.min(viewContainerRect.bottom, window.innerHeight) - totalHeaderHeight);

        sidebar.style.top = `${totalHeaderHeight}px`;
        sidebar.style.height = `${sidebarVisibleHeight}px`;
        viewContainer.style.width = `calc(100% - ${sidebar.offsetWidth}px)`;
      }
    }

    const throttledSetSidebarHeight = throttle(setSidebarHeight, 200);

    setSidebarHeight();
    window.addEventListener('resize', throttledSetSidebarHeight);
    window.addEventListener('scroll', throttledSetSidebarHeight);

    return () => {
      window.removeEventListener('resize', throttledSetSidebarHeight);
      window.removeEventListener('scroll', throttledSetSidebarHeight);
      throttledSetSidebarHeight.cancel(); // Cancel any pending execution
    };
  }, []);

  useEffect(() => {
    function setActiveSectionOnMouseEnter(event) {
      // to set the active section based on mouse enter
      const sectionId = event.currentTarget.id;
      const sectionKey = sectionId?.replace('_section_container', '');
      setActiveSection(sectionKey);
    }

    const sections = document.querySelectorAll('.checkout-section');
    sections.forEach((section) => {
      section.addEventListener('mouseenter', setActiveSectionOnMouseEnter);
    });

    return () => {
      sections.forEach((section) => {
        section.removeEventListener('mouseenter', setActiveSectionOnMouseEnter);
      });
    };
  }, []);

  return (
    <div ref={contentRef}>
      <CheckoutHeader
        appointment={appointment}
        redirectToLink={redirectToLink}
        setSubmitCheckoutAttempted={setSubmitCheckoutAttempted}
        testGroup={test_group}
        user={user}
      />
      <div className="d-flex" id="checkout-container">
        <CheckoutSideBar
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          sidebarKeys={sidebarKeys}
        />
        <div id="checkout-view-container">
          <PreCheckinSections
            testGroup={test_group}
            appointment={appointment}
            symptoms={symptoms}
            tests={tests}
            currentUser={current_user}
            checkinOptions={checkinInCheckoutOptions}
            submitCheckoutAttempted={submitCheckoutAttempted}
            testConfiguarations={test_configurations}
            setSurveyMissingAnswer={setSurveyMissingAnswer}
            sidebarKeys={sidebarKeys}
            setSidebarKeys={setSidebarKeys}
          />
          <ServicesSection
            appointment={appointment}
            currentAdministratorDegree={currentAdministratorDegree}
            currentAdministratorInitials={currentAdministratorInitials}
            currentAdministratorName={currentAdministratorName}
            displayChosenServices={true}
            editableTestTypes={editable_test_types}
            permissionKinds={permission_kinds}
            saveTests={saveTests}
            setCurrentAdministratorDegree={setCurrentAdministratorDegree}
            setCurrentAdministratorInitials={setCurrentAdministratorInitials}
            setCurrentAdministratorName={setCurrentAdministratorName}
            setRedirectCallbackPath={setRedirectCallbackPath}
            setTests={setTests}
            submitCheckoutAttempted={submitCheckoutAttempted}
            submitTests={submitTests}
            testConfigurations={test_configurations}
            testGroup={test_group}
            tests={tests}
            updateTest={updateTest}
          />
        </div>
      </div>
    </div>
  );
};

CheckoutPage.propTypes = checkoutShape;

export default CheckoutPage;
