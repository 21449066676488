import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import ContactSupport from './ContactSupport';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import MainMedicalHistory from './MainMedicalHistory';
import UserDetailsSection, { BackButton } from './UserDetailsSection';
import { vaccineName } from '../../common/utils/vaccine';

const MedicalHistory = ({ data }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const {
    appointment: _appointment,
    user,
    test_group_user,
    test_group,
    flipper_flags,
    vaccine_service_specifications,
  } = data;
  const [showInsuranceModal, setShowInsuranceModal] = useState(
    user.admin_requests_change_on_insurance,
  );

  const [appointment, setAppointment] = useState(_appointment);

  const params = {
    last_name: user.last_name,
    date_of_birth: user.date_of_birth,
    access_code: test_group_user.access_code || '',
    locale: i18n.language,
    key: appointment.registration_access_key || '',
    registration_type: appointment.population_name || '',
  };
  Object.keys(params).forEach(
    (key) => (params[key] === null || params[key] === '') && delete params[key],
  );

  const accessCodeRegistrationUrl = `/a/${test_group.slug}/new?${jQuery.param(
    params,
  )}`;

  const isVaccine = (specification) => {
    return vaccine_service_specifications.includes(specification);
  };

  const wellnessTests = appointment.tests.filter((t) => ["vitals_screening", "hypertension_screening"].includes(t.test_configuration.service_specification));
  const vaccines = appointment.tests.filter((t) => isVaccine(t.test_configuration.service_specification));
  const tests = appointment.tests.filter((t) => !wellnessTests.includes(t) && !vaccines.includes(t) && !t.part_of_test_strip);
  const iisVaccines = appointment?.iis_vaccines?.map((v) => ({ ...v, displayName: vaccineName(v.display_name) })) || [];

  const allServices = tests.concat(appointment.test_strips, vaccines, wellnessTests, iisVaccines).sort((a, b) => {
    const dateA = new Date(a.administered_at);
    const dateB = new Date(b.administered_at);
    return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
  }).reverse();

  const [selectedTestStrip, setSelectedTestStrip] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [selectedWellnessTest, setSelectedWellnessTest] = useState(null);
  const [selectedIISVaccine, setselectedIISVaccine] = useState(null);

  const selections = {
    selectedTestStrip,
    selectedTest,
    selectedVaccine,
    selectedWellnessTest,
    selectedIISVaccine,
  };
  const noTestSelected = Object.values(selections).every((v) => !v);

  const noOtherTestTypeSelected = (key) => {
    const otherSelections = Object.keys(selections)
      .filter((selectionKey) => selectionKey !== key)
      .map((selectionKey) => selections[selectionKey]);
    return otherSelections.every((v) => !v);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [
    selectedTestStrip,
    selectedTest,
    selectedVaccine,
    selectedWellnessTest,
    selectedIISVaccine,
  ]);

  const overviewMessage = `${t('result_page.overview_title')}`;
  const overviewTitle = `${t('result_page.medical_history')}`;

  const recordAcknowledgement = async (testStrip, status, user = undefined) =>
    axios
      .post(appointment.record_result_acknowledgement_path, {
        test_strip_id: testStrip.id,
        acknowledgement_status: status,
        user: { phone_number: user?.phone_number },
      })
      .then((_) => {
        const idx = appointment.test_strips.findIndex(
          (ts) => ts.id === testStrip.id,
        );
        const testStrips = [...appointment.test_strips];
        testStrips[idx] = {
          ...testStrips[idx],
          acknowledgement_status: status,
        };

        setAppointment({
          ...appointment,
          test_strips: testStrips,
        });
      });

  const onBackClick = () => {
    const acknowledgementContainer = document.getElementsByClassName(
      'has-pending-acknowledgement',
    );
    if (acknowledgementContainer.length > 0) {
      acknowledgementContainer[0].classList.add('focus');
      acknowledgementContainer[0].scrollIntoView();
    } else {
      setSelectedTestStrip(null);
      setSelectedTest(null);
      setSelectedVaccine(null);
      setSelectedWellnessTest(null);
      setselectedIISVaccine(null);
    }
  };

  return (
    <Row
      className={`responsive-background ${isMobile ? '' : 'mt-4'}`}
      id="results_page"
      gap={3}
    >
      <Col sm={12} md={4} lg={3}>
        <UserDetailsSection
          user={user}
          test_group={test_group}
          accessCodeRegistrationUrl={accessCodeRegistrationUrl}
          setShowInsuranceModal={setShowInsuranceModal}
          showInsuranceModal={showInsuranceModal}
          noTestSelected={noTestSelected}
          onBackClick={onBackClick}
          isMobile={isMobile}
        />
        {!isMobile && <ContactSupport isMobile={isMobile} />}
      </Col>
      <Col>
        <MainMedicalHistory
          noTestSelected={noTestSelected}
          overviewTitle={overviewTitle}
          overviewMessage={overviewMessage}
          selectedTestStrip={selectedTestStrip}
          isMobile={isMobile}
          selectedWellnessTest={selectedWellnessTest}
          noOtherTestTypeSelected={noOtherTestTypeSelected}
          selectedTest={selectedTest}
          wellnessTests={wellnessTests}
          vaccines={vaccines}
          selectedVaccine={selectedVaccine}
          setSelectedVaccine={setSelectedVaccine}
          testStrips={appointment.test_strips}
          tests={tests}
          test_group={test_group}
          setSelectedTest={setSelectedTest}
          user={user}
          setSelectedTestStrip={setSelectedTestStrip}
          recordAcknowledgement={recordAcknowledgement}
          flipperFlags={flipper_flags}
          allServices={allServices}
          setSelectedWellnessTest={setSelectedWellnessTest}
          selectedIISVaccine={selectedIISVaccine}
          setselectedIISVaccine={setselectedIISVaccine}
          iisVaccines={iisVaccines}
          appointment={appointment}
        />
        {isMobile && (
          <div className="d-print-none">
            <ContactSupport />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default MedicalHistory;
