import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpRightFromSquare,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faFlag,
  faArrowDownToLine,
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import RequestConsultationFlow from './ContactProvider';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import ShowEyeball from '../../common/components/Icons/ShowEyeball';
import HideEyeball from '../../common/components/Icons/HideEyeball';
import { humanize } from '../../common/utils';
import { MultiplexResults } from '../../shared_labs/multiplex_results';
import Flipper from '../../common/components/Flipper';
import { buildLocalizedString } from '../../common/locales/langs';
import { shortDisplayDate } from './utils';
import { kitTypeDisplayLetters } from '../../KitFlow/components/utils';

const IconArea = styled.div`
  width: 43px;
  height: 43px;
  background-color: ${({ color }) => color};
  border-radius: 11px;
`;

export const StatusBadge = ({ status }) => {
  const { t } = useTranslation();
  const resultsReady = status === 'resulted' || status === true;
  const badgeClass = resultsReady ? 'bg-success-shade-100' : 'bg-dark-gray';
  const badgeStyle = {
    color: resultsReady ? '#359975' : '#2D3648',
    padding: '5px 12px',
  };

  return (
    <span className={`badge rounded-pill ${badgeClass}`} style={badgeStyle}>
      {resultsReady
        ? t('result_page.view_results.status.results_ready')
        : t('result_page.view_results.status.results_pending')}
    </span>
  );
};

export const Header = ({
  icon,
  color,
  kitType,
  displayTime,
  displayName,
  status,
  source = 'Primary.Health'
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const headerDisplayName =
    displayName ||
    `${
      isMobile
        ? t([`test_strip.short_display_name.${kitType}`, `test_strip.display_name.${kitType}`])
        : t('test_strip.box_type', { type: kitTypeDisplayLetters(kitType) }) +
          ' - ' +
          t(`test_strip.display_name.${kitType}`)
    }`;

  return (
    <Row style={{ cursor: 'pointer' }}>
      <Col xs="auto">
        <IconArea className="d-flex" color="#F7F9FC">
          <FontAwesomeIcon
            icon={['fa-regular', icon]}
            className="m-auto"
            size={'2x'}
            style={{
              color: color,
              maxHeight: 18,
            }}
          />
        </IconArea>
      </Col>
      <Col className="pe-0">
        <Row className="inter bold">
          <Col>{t(headerDisplayName)}</Col>
        </Row>
        <Row>
          {status && (
            <Col xs={12} md={{ span: 'auto', order: 'last' }}>
              <div className="mt-2">
                <StatusBadge status={status} />
              </div>
            </Col>
          )}
          <Col xs={12} md={{ span: 'auto', order: 'first' }}>
            <div className="mt-2" style={{ color: '#2D3648' }}>
              {shortDisplayDate(displayTime)}
            </div>
          </Col>
        </Row>
        <Row className="mt-2 body-medium-regular" style={{ color: '#717D96' }}>
          <Col>
            {t('result_page.source')}: {source}
          </Col>
        </Row>
      </Col>
      <Col
        xs="auto"
        className="d-flex justify-content-center align-items-center"
      >
        <span className="p-2">
          <FontAwesomeIcon icon={faChevronRight} style={{ color: '#193154' }} />
        </span>
      </Col>
    </Row>
  );
};

const TestRowDisplay = ({ test, resultsVisible, t }) => {
  const [showTestResultText, toggleResultTextDisplay] = useState(false);
  const color =
    test.result === 'negative'
      ? 'dark'
      : test.result === 'positive'
      ? 'primary'
      : 'muted';
  const isMultiplex = !!(
    test.test_configuration.results_schema && test.generic_test_results.length
  );
  const multiplexResults =
    isMultiplex &&
    new MultiplexResults(test, {
      renderers: {
        ungrouped: (results) => {
          return (
            <Col>
              <dl className="row mt-2">
                {results.map(({ subTestKey, testName, resultLabel }) => (
                  <React.Fragment key={subTestKey}>
                    <dd className="col-6">{testName}</dd>
                    <dt className="col-6 text-end">{resultLabel}</dt>
                  </React.Fragment>
                ))}
              </dl>
            </Col>
          );
        },
      },
    });

  const resultText = (
    <Col className="mx-1 my-3 result-text-area">
      <LocalizedMarkdown
        container={test.test_configuration}
        stringKey={`${test.result || 'processing'}_result_text`}
      />
    </Col>
  );

  const resultTextPresent = buildLocalizedString(
    test.test_configuration,
    `${test.result}_result_text`,
  );

  const iconChevron = showTestResultText ? faChevronUp : faChevronDown;

  const testResultDisplayButton = (
    <div
      onClick={() => toggleResultTextDisplay(!showTestResultText)}
      className={`text-${color}`}
    >
      {test.result === 'positive' && (
        <FontAwesomeIcon className="me-2" icon={faFlag} />
      )}
      {isMultiplex &&
        (multiplexResults?.fixedResults()
          ? ''
          : t('employer_testing.results.pending'))}
      {!isMultiplex &&
        (humanize(test.result) || t('employer_testing.results.pending'))}
      {(resultTextPresent ||
        (isMultiplex && multiplexResults?.fixedResults())) && (
        <FontAwesomeIcon className="ms-2" icon={iconChevron} />
      )}
    </div>
  );

  const resultDisplay = (
    <Col role="cell" className="text-end pt-2">
      {isMultiplex && testResultDisplayButton}
      {!isMultiplex &&
        (test.result == null || resultsVisible
          ? testResultDisplayButton
          : '*********')}
    </Col>
  );

  return (
    <div className="body-medium-normal">
      <Col>
        <Row role="row" className="d-flex justify-content-md-between">
          <Col xs="auto" role="cell">
            <div className="pt-2">{test.test_configuration.display_name}</div>
          </Col>
          {resultDisplay}
        </Row>
      </Col>
      {showTestResultText && isMultiplex && multiplexResults?.fixedResults()}
      {showTestResultText &&
        (resultsVisible || test.result == null) &&
        resultText}
      {showTestResultText && resultText}
      <hr />
    </div>
  );
};

const TestStripDetails = ({
  user,
  testStrip,
  tests,
  recordAcknowledgement,
  flipperFlags,
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const updateResultsViewed = async (testStrip) => {
    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');
    const response = await axios.post(
      `/test_strips/${testStrip.id}/results_viewed`,
      {
        token: token,
      },
    );
    if (response.data.redirect) {
      window.location.href = response.data.redirect;
    }
  };

  const testStripTests = testStrip
    ? tests.filter((test) => testStrip.test_ids.includes(test.id))
    : null;

  const sortedTests = testStrip.has_positive_test
    ? [
        ...testStripTests.filter((test) => test.result == 'positive'),
        ...testStripTests.filter((test) => !(test.result == 'positive')),
      ]
    : testStripTests;

  const customTemplates = testStripTests
    ? testStripTests[0].custom_templates
    : null;

  const [resultsVisible, setResultsVisible] = useState(false);
  const checkResultsDisplay = (testStrip) => {
    if (testStrip.results_viewed_at == null) {
      updateResultsViewed(testStrip);
    }
    setTimeout(() => setResultsVisible(true), 200);
  };

  const printTestStripDetails = () => {
    // adjust style for printing ? should result text always print?
    window.print();
  };

  const resultsReady = testStrip.status == 'resulted';

  return (
    <div>
      {resultsReady && isMobile && (
        <div className="text-end mb-3">
          <Button
            variant="link"
            aria-label="Download test details"
            onClick={() => printTestStripDetails()}
          >
            <FontAwesomeIcon icon={faArrowDownToLine} />
          </Button>
        </div>
      )}
      <div
        style={
          isMobile
            ? {}
            : {
                borderRadius: 16,
                padding: '24px',
              }
        }
      >
        <Row>
          <Col>
            <Row className="d-flex my-2 pb-3" style={{ maxWidth: '560px' }}>
              <Col>
                <h4>{t('result_page.view_results.status.test_results')}</h4>
              </Col>
              {resultsReady && (
                <Col className="text-end">
                  {resultsVisible ? (
                    <a
                      tabIndex="0"
                      className="primary-500 font-weight-bold"
                      onClick={() => setResultsVisible(false)}
                      aria_label="Hide test results"
                    >
                      {t('result_page.hide')}
                      <HideEyeball
                        style={{ height: 18, width: 18 }}
                        className="ms-2"
                      />
                    </a>
                  ) : (
                    <a
                      tabIndex="0"
                      className="primary-500 font-weight-bold"
                      onClick={() => checkResultsDisplay(testStrip)}
                      aria_label="Display test results" // not overriding "show" on button
                    >
                      {t('result_page.show')}
                      <ShowEyeball
                        style={{ height: 18, width: 18 }}
                        className="ms-2"
                      />
                    </a>
                  )}
                </Col>
              )}
              <div role="table" aria-label="Test results">
                {sortedTests.map((test) => (
                  <TestRowDisplay
                    test={test}
                    resultsVisible={resultsVisible}
                    key={test.id}
                    resultsReady={resultsReady}
                    t={t}
                  />
                ))}
              </div>
              <Flipper
                record={{ flipper_flags: flipperFlags }}
                flag="request_consultation"
              >
                <Flipper.Enabled>
                  {testStrip.has_positive_test && (
                    <div className="mt-4">
                      <RequestConsultationFlow
                        recordAcknowledgement={recordAcknowledgement}
                        testStripId={testStrip.id}
                        acknowledgementStatus={testStrip.acknowledgement_status}
                        user={user}
                      />
                    </div>
                  )}
                </Flipper.Enabled>
              </Flipper>
            </Row>
          </Col>
          {!isMobile && (
            <Col className="text-end" xs="auto">
              <Button
                variant="link"
                aria-label="Download test details"
                oonClick={() => printTestStripDetails()}
              >
                {!isMobile && <div className="me-2">{t('login.download')}</div>}
                <FontAwesomeIcon icon={faArrowDownToLine} />
              </Button>
            </Col>
          )}
        </Row>
        <Col className="py-3">
          {testStrip.lab_report_url && (
            <Button
              className="btn-outline-secondary me-3"
              href={testStrip.lab_report_url}
            >
              {t('result_page.lab_report')}
              <FontAwesomeIcon className="ms-2" icon={faUpRightFromSquare} />
            </Button>
          )}
          {customTemplates &&
            customTemplates
              .filter(
                (custom_template) =>
                  (custom_template.name
                    .toUpperCase()
                    .includes('AFTER VISIT SUMMARY') &&
                    testStrip.has_admin_survey) ||
                  !custom_template.name
                    .toUpperCase()
                    .includes('AFTER VISIT SUMMARY'),
              )
              .map((custom_template, index) => (
                <Button
                  key={index}
                  variant="outline-tertiary"
                  href={custom_template.url}
                  target="_blank"
                >
                  {custom_template.name}
                </Button>
              ))}
        </Col>
      </div>
    </div>
  );
};

const TestStripDisplay = ({
  user,
  testStrip,
  selectedTestStrip,
  setSelectedTestStrip,
  tests,
  recordAcknowledgement,
  flipperFlags,
}) => {
  return (
    <React.Fragment>
      {testStrip && !selectedTestStrip && (
        <div
          className="medical_history__item test-card my-3"
          key={testStrip.id}
          onClick={() => setSelectedTestStrip(testStrip)}
        >
          <Header
            icon={testStrip.font_awesome_icon}
            color={testStrip.color_code}
            kitType={testStrip.kit_type}
            displayTime={testStrip.display_time}
            status={testStrip.status}
            testStrip={testStrip}
            key={'o' + testStrip.id}
          />
        </div>
      )}
      {selectedTestStrip && selectedTestStrip.id == testStrip.id && (
        <>
          <TestStripDetails
            user={user}
            key={'d' + selectedTestStrip.id}
            testStrip={selectedTestStrip}
            tests={tests}
            setSelectedTestStrip={setSelectedTestStrip}
            recordAcknowledgement={recordAcknowledgement}
            flipperFlags={flipperFlags}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default TestStripDisplay;
