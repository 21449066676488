import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const formatDateInCurrentLocale = (dateInput) => {
  const { i18n } = useTranslation();

  moment.locale(i18n.language);

  const date = moment.parseZone(dateInput);

  return date.format('MMMM D, YYYY [@] h:mm A');
};

export const shortDisplayDate = (dateInput) => {
  const date = moment.parseZone(dateInput);

  return date.format('MM/DD/YYYY [@] h:mm A');
};
