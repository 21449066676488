import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Button, FormControl } from "react-bootstrap";
import { capitalize } from 'lodash';
import { faArrowsRotate, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GenericTestCardBody from "./CheckoutServiceCardBodies/GenericTestCardBody";
import GenericVaccineCardBody from "./CheckoutServiceCardBodies/GenericVaccineCardBody";
import { hexToRgba} from "../../common/components/ServiceCard";
import HyperextensionScreeningCardBody from "./CheckoutServiceCardBodies/HyperextensionScreeningCardBody";
import ScanOrGenerateCardBody from "./CheckoutServiceCardBodies/ScanOrGenerateCardBody";
import { SERVICE_SPECIFICATION_TYPES } from '../../TestGroupTestConfigurations/components/TestGroupTestConfigurationsIndex';
import { StatusDot } from "./ServicesSection";
import VitalsScreeningCardBody from './CheckoutServiceCardBodies/VitalsScreeningCardBody';


export const handleRemove = async (slug, testId) => {
  const isConfirmed = window.confirm("Are you sure you want to delete this test?");
  if (!isConfirmed) return;
  const response = await axios.delete(`/test_groups/${slug}/tests/${testId}`);
  if (response.data.success) {
    window.location.reload()
  } else {
    toastr.error("Failed to delete test")
  }
};

const CUSTOM_CARD_BODIES = {
  vitals_screening: VitalsScreeningCardBody,
  hypertension_screening: HyperextensionScreeningCardBody,
};

export const SaveButton = ({
  disabled,
  displayRemove = false,
  label = "Save",
  onClick,
  slug,
  testId
}) => {
  return (
    <div className="d-flex justify-content-end mt-4">
      {displayRemove && (
        <Button
          onClick={async () => handleRemove(slug, testId)}
          variant="link"
        >
          Remove service
        </Button>
      )}
      <Button
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </Button>
    </div>
  );
};

export const ResultStatus = ({
  className,
  color,
  icon,
  status,
}) => {
  return (
    <div
      className={`d-flex ${className}`}
      style={{ color: color }}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{ height: "24px", width: "24px" }}
      />
      <div className="ms-2">{capitalize(status)}</div>
    </div>
  );
};

const ServiceCardHeader = ({
  color,
  handleToggle,
  isOpen,
  saved,
  tag,
  tagBackgroundColor,
  tagColor,
  title,
}) => {
  const lighterColor = hexToRgba(color, 0.07);
  return (
    <div
      className="service-card-header-container"
      style={{
        backgroundColor: lighterColor,
        borderRadius: isOpen ? "16px 16px 0 0" : "16px"
      }}
      onClick={handleToggle}
    >
      <div>
        <span className="service-card-header">
          {title}
        </span>
        <CardTag
          backgroundColor={tagBackgroundColor}
          color={tagColor}
          text={tag}
        />
        <StatusDot
          className={`ms-3 my-auto ${saved ? "" : "unsaved-card-tag"}`}
          status={saved ? "ready" : "pending"}
        />
      </div>
      <span
        className="service-card-open-indicator"
        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
      >
        <FontAwesomeIcon
          icon={faChevronUp}
          style={{ height: "24px", width: "24px" }}
        />
      </span>
    </div>
  );
};

export const ServiceCardSubHeader = ({
  containerClassName,
  handleReset,
  title,
}) => {
  return (
    <div className={`d-flex justify-content-between my-3 ${containerClassName}`}>
      <span className="service-card-header">{title}</span>
      <div>
        <span
          className="service-card-action-button"
          onClick={handleReset}
        >
          <FontAwesomeIcon
            className="service-card-action-button-icon"
            icon={faArrowsRotate}
          />
          Reset
        </span>
      </div>
    </div>
  );
};

const ServiceCardBody = ({
  children,
  isOpen,
  notesRef,
}) => {
  const contentRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (isOpen) {
        contentRef.current.style.height = `inherit`;
      } else {
        contentRef.current.style.height = '0px';
      }
    };

    const handleMouseDown = (e) => {
      if (e.target === notesRef.current) {
        window.addEventListener("mousemove", updateHeight);
        window.addEventListener("mouseup", handleMouseUp);
      }
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", updateHeight);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    updateHeight(); // Initial height update

    // Set up mutation observer for when elements change within the content
    const observer = new MutationObserver(updateHeight);
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    // Set up mouse down event listener for resizing notes textarea
    const notes = notesRef.current;
    if (notes) {
      notes.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      // Clean up on unmount
      if (observer && contentRef.current) observer.disconnect();
      if (notes) notes.removeEventListener("mousedown", handleMouseDown);
    };
  }, [isOpen, children, notesRef]);

  return (
    <div className="service-card-body" ref={contentRef}>
      <div className="service-card-body-content">
        {children}
      </div>
    </div>
  );
};

export const CardTag = ({
  backgroundColor = "white",
  color = "black",
  text,
}) => {
  return (
    <span
      className="service-card-tag"
      style={{
        backgroundColor: backgroundColor,
        color: color
      }}
    >
      {text}
    </span>
  );
};

export const LabeledField = ({
  children,
  containerClassName,
  label,
  ...props
}) => {
  return (
    <div className={containerClassName}>
      <div className="body-large-16-regular inter semibold mb-2">
        {label}
      </div>
      {children ? (
        children
      ) : (
        <InputWithUnits {...props} />
      )}
    </div>
  );
};

export const InputWithUnits = ({
  as,
  disabled,
  error,
  inputStyle,
  inputContainerClassName,
  name,
  onBlur,
  onChange,
  type,
  unit,
  value,
}) => {
  return (
    <div>
      <div className={`input-with-unit ${inputContainerClassName}`}
        style={{ ...inputStyle}}
      >
        <FormControl
          as={as}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          style={{ ...inputStyle, border: error ? "1px solid var(--danger, #CB2531)" : "" }}
          type={type}
          value={value}
        />
        {unit && (
          <span className="unit">{unit}</span>
        )}
      </div>
      {error && (
        <div className="text-danger my-2">
          <FontAwesomeIcon
            className="me-2"
            icon={faExclamationCircle}
          />
          {error}
        </div>
      )}
    </div>
  );
};

const ServiceCard = ({
  containerClassName,
  key,
  serviceSpecification,
  serviceType,
  test,
  tests,
  ...props
}) => {

  const determineCardBody = () => {
    if (!test.id) return ScanOrGenerateCardBody;
    if (!!CUSTOM_CARD_BODIES[serviceSpecification]) return CUSTOM_CARD_BODIES[serviceSpecification];
    return serviceType === "immunization" ? GenericVaccineCardBody : GenericTestCardBody;
  };

  const [isOpen, setIsOpen] = useState(true);
  const CardBody = determineCardBody();
  const notesRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <div className={`${containerClassName} service-card-container`} key={key}>
      <ServiceCardHeader
        isOpen={isOpen}
        tag={test.service_type == "other" ? test.service_kind_label : SERVICE_SPECIFICATION_TYPES[test.service_type]['label']}
        tagBackgroundColor={SERVICE_SPECIFICATION_TYPES[test.service_type]['backgroundColor']}
        tagColor={SERVICE_SPECIFICATION_TYPES[test.service_type]['color']}
        handleToggle={handleToggle}
        saved={test.saved && (test.service_type === "immunization" || !!test.result)}
        {...props}
      />
      <ServiceCardBody
        isOpen={isOpen}
        notesRef={notesRef}
        {...props}
      >
        <div>
          <CardBody
            key={test.id || serviceSpecification}
            notesRef={notesRef}
            setIsOpen={setIsOpen}
            test={test}
            tests={tests}
            {...props}
          />
        </div>
      </ServiceCardBody>
    </div>
  );
};

export default ServiceCard;