export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Ամիս",
    "day": "Օր",
    "year": "Տարի",
    "january": "Հունվար",
    "february": "Փետրվար",
    "march": "Մարտ",
    "april": "Ապրիլ",
    "may": "Մայիս",
    "june": "Հունիս",
    "july": "Հուլիս",
    "august": "Օգոստոս",
    "september": "Սեպտեմբեր",
    "october": "Հոկտեմբեր",
    "november": "Նոյեմբեր",
    "december": "Դեկտեմբեր"
  },
  "user": {
    "send_appointment_confirmation_message": "Ձեր ժամադրութիւնը հաստատուած է հետեւեալ անունով՝ {{name}}։",
    "landline_appointment_reminder_message": "Ողջույն, {{full_name}}: Սա հիշեցում է, որ դուք ժամադրություն ունեք {{name}} - ում: Ձեր ժամադրությունը տեղի կունենա {{date}} - ին {{time}} - ին {{address}} հասցեում:",
    "send_at_home_visit_confirmation_message": "Ձեր ժամադրութիւնը հաստատուած է {{date}} թուականին, ժամը {{time}}ին։",
    "send_mail_order_confirmation_message": "Ձեր փոստի պատուէրը հաստատուած է։",
    "send_waitlist_message": "Դուք հայտնվել եք {{name}} - ի սպասման ցուցակում: Խնդրում ենք չերևալ, քանի դեռ չենք հաստատել ժամադրության ժամը:",
    "verify_contact": {
      "phone": "Խնդրում ենք հաստատել ձեր հեռախոսահամարը `կտտացնելով ՝ {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Շարունակեք գրանցվել ժամադրության համար այստեղ ՝ {{url}}",
    "on_demand_confirmation_message": "Յաջողութեամբ արձանագրուեցաք {{name}}ին համար։",
    "appointment_reminder": "Նախքան ձեր ժամադրությունը ժամը {{start_time}}, խնդրում ենք սեղմել հղումը ՝ անհրաժեշտ բոլոր տեղեկությունները լրացնելու և ձեր հաստատումը դիտելու համար: Խնդրում ենք չժամանեք նշանակման ժամից ավելի քան 15 րոպե առաջ:",
    "appointment_reminder_base": "{{text}} Սեղմեք Այստեղ {{link}}",
    "test_result_notifier": {
      "text_message": "{{first_name_with_last_initial}}-ի համար հիվանդի պորտալը թարմացվել է: Գնացեք {{url}} և օգտագործեք կոդը՝ {{access_code}}",
      "email_subject": "Նոր թարմացումներ ձեր հիվանդների պորտալում",
      "email_intro": "Բարև {{first_name_with_last_initial}}",
      "email_p1": "Ձեզ համար հասանելի է նոր հաղորդագրություն կամ հիվանդի թարմացում:",
      "email_p2": "Սեղմեք ստորև բերված կոճակը կամ օգտագործեք հղումը՝ ձեր հիվանդի պորտալը դիտելու և կոդը օգտագործելու համար",
      "email_p3": "«Դիտեք հիվանդի պորտալը հետևյալ հասցեով՝",
      "email_button": "Դիտել պորտալը"
    },
    "landline_appointment_reminder_message_without_time": "Ողջույն, {{full_name}}: Սա հիշեցում է, որ դուք ժամադրություն ունեք {{name}} - ում: Ձեր ժամադրությունը տեղի կունենա {date} - ին {{address}} հասցեում:",
    "appointment_reminder_on_demand": "Նախքան ձեր ժամադրությունը {{date}} - ին խնդրում ենք սեղմել հղումը ՝ անհրաժեշտ բոլոր տեղեկությունները լրացնելու և ձեր հաստատումը դիտելու համար: ",
    "new_test_result_notifier": {
      "email_intro": "Ձեր հիվանդի պորտալը թարմացվել է:",
      "email_text_1": "Այցելեք ձեր Բժշկական պատմության պորտալը՝ ստորև՝ տեսնելու հիվանդների ցանկացած նոր գրառում կամ լաբորատոր հաշվետվություն:",
      "email_button": "Անցեալի առողջութեան խնդիրներ",
      "email_text_2": "Կրնաք նաեւ ընդօրինակել հետեւեալ կայքի «URL»ը ձեր համացանցի «browser»ին վրայ"
    },
    "mailers": {
      "email_contact_us": "Հարցում ունի՞ք։ Կրնաք մեզի կապուիլ "
    }
  },
  "errors": {
    "generic": {
      "message": "Սխալ առաջացավ, կրկին փորձեք"
    },
    "messages": {
      "blank": "չի կարող դատարկ լինել"
    },
    "incorrect_credentials": "Ձեր տուեալները սխալ են։ Կը խնդրենք որ կրկին փորձէք։",
    "error_activating_test_kit": "Քննութեան սնտուկը միացնելու խնդիր կար։"
  },
  "type": "Տեսակ",
  "payment": {
    "continue_label": "Շարունակեք Վճարման",
    "policy_text": "Եթե դուք չեղարկեք կամ չներկայանաք ձեր ժամադրությանը, մենք կվերադարձնենք ձեր վճարման 80% -ը ՝ ձեր ժամադրության օրվանից 7 օր հետո: Դուք կարող եք դիտել ամբողջական առայության պայմանները] [Terms of Service]({{link}})",
    "card_number": "Քարտի Թիվը",
    "expiry_date": "Վավերականության ժամկետը",
    "pay_button": "Վճարել",
    "no_credit_card": "Կվճարեմ տեղում",
    "cash_payment_helper": "Խնդրում ենք բերել **{{payment}}**  ձեր ժամադրությանը",
    "invoice": "Հաշիվ -ապրանքագիր",
    "pay_by_card": "Վճարեք քարտով",
    "cost": "Արժեք",
    "total": "Ընդհանուր",
    "pay_cash_at_appointment": "Իմ ժամադրության ժամանակ կանխիկ կվճարեմ {{appointment}}",
    "view_invoice": "Դիտել/տպել հաշիվ -ապրանքագիր",
    "refund_policy": "Եթե չեղարկեք ձեր ժամադրությունը, մենք կվերադարձնենք ձեր վճարման {{amount}} գումարը՝ ձեր ժամադրության ամսաթվից 7 օր հետո: Դուք կարող եք դիտել ամբողջական [Ծառայության պայմանները] ({{link}})",
    "refund_window_passed": "Ձեր ժամադրության համար հնարավոր չէ վերադարձնել գումարը, քանի որ ժամադրության ժամանակն անցել է: Դիմեք ուղղակիորեն կայքի համակարգողներին ցանկացած լուծումների համար:",
    "amount_paid": "Վճարված գումարը",
    "balance": "Մնացորդը"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Վերբեռնեք համաձայնությունից հրաժարվելու փաստաթուղթը կամ ստորագրեք ստորագրության վանդակը",
    "stop": "Գրանցումն ավարտելու համար անհրաժեշտ է համաձայնություն",
    "parent_or_guardian_required_html": "Ձեր գրանցումն ավարտելու համար ձեր ծնողը, խնամակալը կամ լիազորված որոշում կայացնողը պետք է համաձայնություն տա ստորև բերված ձևին և ստորագրի նրա անունը: Դուք  <b>ՉԵՔ ԿԱՐՈՂ</b> համաձայնեք այս ծառայությանը ինքնուրույն:",
    "send_parent_or_guardian": "Դուք կարող եք ձեր ծնողին կամ խնամակալին ուղարկել այս հղումը կամ խնդրել նրանց ստորագրել այս էջում:",
    "consent_guardian_name_signature": "Անուն",
    "consent_for_minor": "Անչափահասի փոխարեն ստորագրեք համաձայնությունը",
    "guardian_signature": "ծնողի, խնամակալի կամ լիազորված բժշկական որոշում կայացնողի ստորագրությունը",
    "after_you_schedule": "Մինչև {{age}} տարեկան մասնակիցները պետք է ունենան իրենց անունից ստորագրված համաձայնություն ծնողի կամ խնամակալի կողմից: Շարունակեք գրանցման ավարտը `ձեր խնամակալին ուղարկելու կամ ձեր կախվածության համար ստորագրելու համար:",
    "consent_guardian_last_name_signature": "Ազգանուն",
    "consent_first_and_last_name": "Անունը և Ազգանունը",
    "phone_number": "Խնամակալի հեռախոսահամարը",
    "email": "Խնամակալի էլ․ փոստը",
    "consent_registrar_with_guardian_name_signature": "Խնամակալի անունը",
    "consent_registrar_with_guardian_last_name_signature": "Խնամակալի ազգանունը",
    "consent_registrar_first_name_signature": "Գրանցողի անունը",
    "consent_registrar_last_name_signature": "Գրանցողի ազգանունը",
    "consent_on_behalf": "Ես ստորագրում եմ {{first_name}} {{last_name}}-ի անունից:",
    "consent_on_behalf_registrar": "{{first_name}} {{last_name}}-ն ինձ բանավոր թույլտվություն է տվել ստորագրելու իրենց անունից:",
    "consent_on_behalf_registrar_with_guardian": "Ես բանավոր թույլտվություն եմ ստացել {{first_name}} {{last_name}} խնամակալի կողմից՝ ստորագրելու նրանց անունից:",
    "registrar_phone": "Գրանցողի հեռախօսի համար",
    "registrar_email": "Գրանցողի էլեկտրոնային փոստ (email)",
    "consented_by_parent_guardian": "Ծնողք կամ խնամակալ",
    "consented_by_decision_maker": "Լիազորուած բժշկական որոշումներ կայացնող ",
    "consented_by_guardian_verbal": "Գրանցող՝ խնամակալի հետ (բանաւոր համաձայնութիւն ստացուած է)",
    "consented_by_registrar": "Գրանցող (բանաւոր համաձայնութիւն ստացուած է)",
    "full_name": "Անուն ու ազգանուն",
    "print": "Տպեցէք"
  },
  "registration": {
    "contact_information": "Անուն եւ տան հասցէ",
    "contact_information_additional": "Ծնողի կամ խնամակալի կոնտակտային տվյալներ նշանակման հաղորդակցության և արդյունքների համար",
    "personal_information": "Անձնական ընդհանուր տուեալներ",
    "address_placeholder": "Խնդրում ենք մուտքագրել ձեր տան հասցեն",
    "first_name_label": "Անուն",
    "last_name_label": "Ազգանուն",
    "errors": {
      "state": "Նահանգը պետք է լինի վավեր 2 նիշ ունեցող պետական ծածկագիր",
      "phone_number_invalid": "Հեռախոսահամարը սխալ է ",
      "required": "Այս արժեքը պարտադիր է",
      "email_domain": "Ձեր մուտքն անվավեր է. խնդրում եմ կրկնակի ստուգել: Գրանցումը սահմանափակվում է հովանավոր կազմակերպության կողմից ընտրված մասնակիցներով: Փորձեք օգտագործել ձեր աշխատանքի կամ համայնքի էլեկտրոնային փոստը: Եթե կարծում եք, որ իրավասու եք և կա սխալ, դիմեք աջակցության ծառայությանը:",
      "age_requirement": "ԾՆնդյան ամսաթիվը չի համապատասխանում տարիքային պահանջներին",
      "signature_required": "Ստորագրությունը պարտադիր է",
      "regex_invalid": "Այս արժեքը կարծես անվավեր է:",
      "date_invalid": "Այս ամսաթիվը գոյություն չունի",
      "invalid_entry": "Խնդրում ենք չշարունակել գրանցումը: Հետևեք էկրանի ցուցումներին կամ դիմեք ձեր շփման կետին:",
      "city": "Պետք է վավեր քաղաք լինի",
      "survey_unanswered": "Խնդրում ենք ստուգել, որ * -ով նշված բոլոր պահանջվող հարցերին տրվել է պատասխան:",
      "postal_code": "Պետք է լինի վավեր 5 նիշանոց փոստային կոդ",
      "field_required": "{{field}} պետք է լցված լինի",
      "option_required": "Դուք պետք է ընտրեք տարբերակներից մեկը",
      "year_length_invalid": "Մուտքագրեք ձեր ծննդյան ամսաթիվը քառանիշ թվով (տտտտ)",
      "invalid_day": "Օրը պետք է լինի 1D1-ից մինչև 31-ը",
      "reached_max_chars": "Դուք հասել եք նիշերի առավելագույն քանակին:",
      "chars_max": "Առավելագույն նիշ",
      "minimum_length": "Պատասխանիդ պէտք է նուազագոյն {{length}} գիր կամ թիւ ունենայ։ "
    },
    "insurance_status": {
      "question": "Ունե՞ք առողջության ապահովագրություն:",
      "have_health_insurance": "Այո, ես առողջության ապահովագրություն ունեմ",
      "do_not_have_health_insurance": "Ոչ, ես առողջության ապահովագրություն չունեմ"
    },
    "insurance_policy_holder": {
      "question": "Ո՞վ է առողջության ապահովագրության հիմնական անդամը:",
      "i_am": "ես եմ",
      "my_spouse": "Իմ կինը կամ ամուսինը",
      "my_parents": "Իմ ծնողները",
      "someone_else": "Ուրիշ մեկը",
      "policy_first_name": "Առողջության ապահովագրության սեփականատիրոջ անունը",
      "policy_last_name": "Առողջության ապահովագրության սեփականատիրոջ ազգանունը",
      "policy_dob": "Առողջության ապահովագրության սեփականատիրոջ ծննդյան ամսաթիվը",
      "name": "Ապահովագրութեան տիրոջ անուն"
    },
    "insurance_information": {
      "title": "Ապահովագրական տեղեկատվություն",
      "company_name": "Ապահովագրական ընկերության անվանումը",
      "id_number": "Անդամի նույնականացման համարը",
      "group_number": "Անդամի խմբի համարը",
      "secondary_insurance_label": "Ես ունեմ երկրորդական ապահովագրություն",
      "take_photo": "Կը խնդրենք որ ձեր ապահովագրութեան քարտը նկարէք։ Նկարելով՝ աւելի արագ կ՛ընթանայ գրանձման ընթացքը։",
      "front_of_card": "Քարտին առջեւի կողմը",
      "card_information": "Ապահովագրութեան քարտի տեղեկութիւններ",
      "back_of_card": "Քարտին ետեւի կողմը"
    },
    "employment_information": {
      "label": "Աշխատանք",
      "address_1": "Աշխատավայրի հասցեն",
      "address_2": "Օրինակ ՝ Suite 200",
      "phone_number": "Ընկերության հեռախոսահամարը",
      "company": "Ընկերության Անվանումը",
      "postal_code": "Գործատուի փոստային ինդեքս",
      "employed": "Աշխատանքի տեղավորված",
      "sole_prorietor": "Անհատ ձեռնարկատեր",
      "not_employed": "Չաշխատող",
      "reporting_supervisor": "Հաշվետվության վերահսկիչ",
      "reporting_department": "Հաշվետու բաժին",
      "supervisor_name": "Ղեկավարի անունը",
      "supervisor_email": "Ղեկավարի էլեկտրոնային փոստը"
    },
    "location_availability": "Այս պահին թեստավորումն առաջնահերթություն ունի այս տարածքներում բնակվող համայնքի անդամների համար",
    "custom_survey": "Հարցաթերթիկ",
    "confirmation": "Վերանայում",
    "waitlisted": "Սպասողների ցուցակում",
    "schedule_your_appointment": "Գրանցվել նշանակման",
    "information": "Տեղեկատվություն",
    "consent": "Համաձայնություն",
    "location": "Գտնվելու վայրը",
    "symptoms": "Ախտանիշներ",
    "address_required": "Խնդրում ենք մուտքագրել ձեր հասցեն",
    "consent_required": "Խնդրում ենք համաձայնվել շարունակել",
    "required_field": "ցույց է տալիս պահանջվող դաշտը",
    "phone_number": "Բջջային հեռախոսի համարը",
    "email": "էլեկտրոնային փոստ",
    "date_of_birth": "Ծննդյան ամսաթիվ",
    "minimum_age": "Մասնակցության նվազագույն տարիքը {{year}} տարեկան է:",
    "no_minimum_age": "Թեստավորման համար նվազագույն տարիք չկա",
    "continue_button": "Շարունակել",
    "email_required": "Այս արժեքը պետք է լինի վավեր էլեկտրոնային փոստի հասցե:",
    "done": "Կատարված է",
    "signature": "Ստորագրություն",
    "clear_button": "Մաքրել",
    "back_button": "Հետ",
    "choose_location": "Ընտրեք Վայրը",
    "no_slots": "{{location}} հասցեում այլ տեղեր չկան",
    "choose_appointment": "Ընտրեք ժամադրության ժամանակը {{location}} - ում",
    "appointment_required": "Խնդրում ենք ընտրել նշանակման ժամանակը",
    "phone_number_required": "Հեռախոսահամարն անվավեր է",
    "phone_number_label": "Խնդրում ենք մուտքագրել հեռախոսահամար, որը կարող է տեքստային հաղորդագրություններ ստանալ ՝ փորձարկման ավելի արագ արդյունքների համար",
    "symptoms_experiencing": "Կտտացրեք այն ախտանիշներին, որոնք դուք այժմ զգում եք: Եթե ախտանիշներ չկան, շարունակեք:",
    "household": "Տան անդամներ",
    "household_p1": "Եթե ցանկանում եք, նշանակեք նաեվ տան այն անդամների համար, ովքեր նույնպես պետք է անալիզներ անցնեն:",
    "add_dependent": "Ավելացնել տան անդամ",
    "remove_dependent": "Հեռացնել տան անդամին",
    "dependent_consents": "համաձայնվել",
    "submit": "Ամբողջական",
    "add_waitlist": "Ավելացնել սպասման ցուցակին",
    "address": "Տան հասցե",
    "address_1": "Հասցե Մաս 1",
    "address_2": "Բնակարան/Սյուիտ #",
    "address_city": "Քաղաք",
    "address_state": "Նահանգ",
    "postal_code": "փոստային ինդեքս",
    "race_ethnicity": "Ազգ",
    "gender": "Գենդերային ինքնություն",
    "self_described_gender": "Ինքնագրված սեռ",
    "interpreter": "Թարգմանիչ է պետք? Եթե այո, ապա ո՞ր լեզուն:",
    "consent_to_terms": "Ես համաձայն եմ այս պայմաններին:",
    "reg_not_open": "Գրանցումները չեն բացվել",
    "no_more_avail_spots": "Ձեր ընտրած ժամանակային հատվածն այլևս հասանելի չէ: Խնդրում եմ կրկին փորձեք.",
    "consent_helper": "Սեղմեք և քաշեք ձեր մկնիկը կամ մատը ներքևի վանդակում ՝ ստորագրելու համար",
    "phone_number_unreachable_label": "Ֆիքսված",
    "select": "Ընտրել",
    "test_surveys": "Նշանակման հարցեր",
    "edit": "փոխել",
    "continue_to_registration": "Շարունակեք գրանցվել ժամադրության համար այստեղ ՝ {{url}}",
    "accounts": {
      "already_have_an_account": "Արդեն ունեք հաշիվ?",
      "log_in": "“Login” (Մուտքային անուն)",
      "sign_up": "Գրանցվել",
      "sign_up_description": "Խնդրում ենք մուտքագրել ձեր տվյալները `ձեր հաշիվը ստեղծելու և հետագա գրանցումների համար ժամանակ խնայելու համար:",
      "log_in_description": "Խնդրում ենք մուտքագրել ձեր էլ.փոստը և գաղտնաբառը կամ մուտք գործել ստորև ՝ Google- ի կամ Outlook- ի միջոցով",
      "sign_in_with_label": "Մուտք գործեք {{provider}} - ով",
      "password_must_match": "Գաղտնաբառերը պետք է համընկնեն",
      "password_uppercase": "Ձեր գաղտնաբառը պետք է պարունակի առնվազն (%s) մեծատառ:",
      "password_lowercase": "Ձեր գաղտնաբառը պետք է պարունակի առնվազն (%s) փոքրատառ:",
      "password_number": "Ձեր գաղտնաբառը պետք է պարունակի առնվազն (%s) համար:",
      "password_special": "Ձեր գաղտնաբառը պետք է պարունակի առնվազն (%s) հատուկ նիշ:"
    },
    "password": "Գաղտնաբառ",
    "password_confirmation": "Գաղտնաբառի հաստատում",
    "consent_for": "Համաձայնություն {{name}} - ի համար",
    "book_one_time_appointment": "Ամրագրեք մեկանգամյա նշանակում",
    "duplicate_users": {
      "exists": "Դուք արդեն գրանցված եք",
      "overlapping_email_and_phone_p1": "Մենք հաղորդագրություն ենք ուղարկել ձեր հեռախոսահամարին և էլ. Հասցեին:",
      "p2": "Օգտագործելով ձեր հաղորդագրությունը, կարող եք փոխել ձեր նշանակումը կամ մուտքագրել թեստի արդյունքները:",
      "overlapping_phone_and_email_p3": "Մանրամասների համար խնդրում ենք ստուգել ձեր էլ. Հասցեն կամ հեռախոսը:",
      "overlapping_email_p3": "Մանրամասների համար խնդրում ենք ստուգել ձեր էլեկտրոնային փոստը:",
      "overlapping_phone_p3": "Մանրամասների համար խնդրում ենք ստուգել ձեր հեռախոսը:",
      "overlapping_phone_p1": "Մենք հաղորդագրություն ենք ուղարկել ձեր հեռախոսահամարին:",
      "overlapping_email_p1": "Մենք հաղորդագրություն ուղարկեցինք ձեր էլեկտրոնային փոստի հասցեին:",
      "p4": "Եթե կարծում եք, որ սա սխալ է, դիմեք մեզ support@primary.health հասցեով",
      "overlapping_email_and_phone_p3": "Մանրամասների համար խնդրում ենք ստուգել ձեր էլ. Հասցեն կամ հեռախոսը:"
    },
    "duplicate_waitlist": {
      "exists": "Դուք արդեն սպասման ցուցակում եք",
      "overlapping_email_and_phone_p1": "Մենք մեկ այլ հաստատում ուղարկեցինք ձեր հեռախոսահամարին և էլ. Հասցեին",
      "p2": "Օգտագործելով ձեր հաստատումը, կարող եք տրամադրել լրացուցիչ տեղեկություններ կամ հեռացնել ձեզ սպասման ցուցակից:",
      "overlapping_phone_and_email_p3": "Մանրամասների համար խնդրում ենք ստուգել ձեր էլ. Հասցեն կամ հեռախոսը:",
      "overlapping_email_p3": "Մանրամասների համար խնդրում ենք ստուգել ձեր էլեկտրոնային փոստը:",
      "overlapping_phone_p3": "Մանրամասների համար խնդրում ենք ստուգել ձեր հեռախոսը:",
      "overlapping_email_p1": "Մենք մեկ այլ հաստատում ուղարկեցինք ձեր էլեկտրոնային փոստին:"
    },
    "insist_guardian_consent": "Ես նրանց անունից համաձայնություն եմ տալիս",
    "confirmation_section": {
      "title": "Վերանայեք և հաստատեք",
      "p1": "Խնդրում ենք վերանայել ձեր տվյալները գրանցումն ավարտելուց առաջ:",
      "edit_information": "Վերադարձեք և շտկեք"
    },
    "iemodal": {
      "title": "Չաջակցվող դիտարկիչ",
      "body": "Այս դիտարկիչներում գրանցումը ներկայումս հնարավոր է"
    },
    "show_other_locations": "Կտտացրեք ՝ այլ վայրեր ցուցադրելու համար",
    "non_us_address": "Սա միջազգային հասցե է",
    "test_group_user_survey": "Մասնակիցների ցուցադրում",
    "self_consent": "Ես համաձայն եմ ինձ համար",
    "address_country": "Երկիրը",
    "appointment": "ժամադրություն",
    "employee_id": "Աշխատողի համարը ",
    "appointment_time": "ժամադրությոան ժամը",
    "appointment_location": "Գտնվելու վայրը",
    "phone_or_email": "Հեռախոսահամար կամ էլ. փոստ",
    "no_self_consent": "Ես հրաժարվում եմ իմ համաձայնությունից",
    "no_insist_guardian_consent": "Ես մերժում եմ համաձայնությունը {{full_name}}-ի անունից",
    "additional_consents_helpertext": "Ավելի ուշ կարող են պահանջվել լրացուցիչ համաձայնություն",
    "minimum_age_with_months": "Մասնակցության նվազագույն տարիքը {{year}} տարեկան {{month}} ամսականն է։",
    "assistive_technology": "Օժանդակ տեխնոլոգիա",
    "covid_vaccine_y_n": "Ցանկանու՞մ եք այս ամրագրմամբ պատվաստել COVID-19-ի դեմ:",
    "received_covid_vaccine_y_n": "Դուք նախկինում ստացե՞լ եք COVID-19-ի դեմ պատվաստանյութ:",
    "covid_vaccinations": "Covid-19-ի պատվաստումներ",
    "select_vaccine_dose": "Խնդրում ենք ընտրել պատվաստանյութի այն չափաբաժինը, որը ցանկանում եք ստանալ",
    "demographic_info": "Ժողովրդագրական տեղեկություններ",
    "additional_info": "Հավելյալ տեղեկություններ",
    "self_described_race": "Ինքնուրույն նկարագրված ռասա",
    "verify_contact_information": "Ստուգում",
    "verify_contact": {
      "we_sent_you_a_code": "Խնդրում ենք մուտքագրել կոդը, որը մենք ուղարկել ենք {{contact}}",
      "confirmation_code": "Հաստատման կոդ",
      "code_resent": {
        "email": "Մեկ այլ կոդ է ուղարկվել ձեր էլ",
        "phone_number": "Մեկ այլ կոդ է ուղարկվել ձեր հեռախոսին"
      },
      "did_not_receive_code": "Չե՞ք ստացել հաստատման կոդը:",
      "verify_by": {
        "email": "Փոխարենը հաստատեք էլփոստով",
        "phone_number": "Փոխարենը հաստատեք հեռախոսահամարով"
      }
    },
    "preferred_method_of_communication": {
      "question": "Կապ պահելու նախընտրած միջոց",
      "helper_text": "Ձեզի հետ կը կապուինք ժամադրութեան տեղեկութիւններու մասին",
      "phone_number": "Հեռախօսի համար (միայն SMSի հաղորդագրութիւններու համար՝ որուն պարագային կարելի է ինտերնետի վճարումնիդ ազդուի)",
      "landline": "Այժմ չենք կրնար տունի հեռախօսներու հետ կապ հաստատել։ Կը խնդրենք էլեկտրոնային հասցէ տրամադրել, որպէսզի կարեւոր հաղորդագրութիւններ ստանաք։",
      "verification_method": "Ինչպէ՞ս կը նախընտրէք ստանալ ձեր վաւերացման թիւը։",
      "verification_helper_text": "Վաւերացման թիւ կը ստանաք վաւերացնելու ձեր ընքնութիւնը շարունակելէ առաջ։"
    },
    "skip_for_now": "Այժմ բաց թողել",
    "default_information": {
      "title": "Շատ լաւ՝ այս հասցէն կ՛օգտագործենք",
      "title_v2": "Լավ, մենք կօգտագործենք այս կոնտակտային տեղեկատվությունը"
    },
    "middle_name_label": "Հայրանուն",
    "confirm_appointment": "Հաստատել ժամադրութիւն",
    "dob": "Ծննդեան թուական",
    "different_appointment": "Ուրիշ ժամադրութիւն",
    "select_appointment_time": "Ընտրեցէք ժամադրութեան ժամը",
    "decline_address": "Կը մերժեմ հասցէս տրամադրել։ Կը խնդրեմ որ անոր փոխարէն՝ ծրագրի հասցէն գործածէք։",
    "patient_information": "Հիւանդի տեղեկութիւն",
    "scan_license": "Վարորդական քարտ նկարել (scan)",
    "how_to_contact": "Ինչպէ՞ս կապ հաստատենք ձեր հետ։",
    "decline_email_or_phone": "Կը մերժեմ էլեկտրոնային հասցէ կամ հեռախօսի համար տրամադրել։ Հրաման կու տամ ծրագրի ղեկավարներուն, որպէսզի ստանան արդիւնքներս։",
    "medical_screening": "Բժշկական զննում",
    "show_service_at_this_location": "Ցոյց տուէք այս կեդրոնին ծառայութիւները",
    "verbal_consent_provided": "«Americans with Disabilities Act» (Հաշմանդամութիւն ունեցող ամերիկացներու օրէնքին) համեմատ, գրանցողը կամ իր օրինականօրէն լիազորուած որոշումներ կայացնողը բանաւոր համաձայնութիւն տուած են։ 24 ժամուայ ընթացքին գրանցողի կամ իր օրինականօրէն լիազորուած որոշումներ կայացնողի կողմէ պէտք է գրաւոր համաձայնութիւն ստացուի։ ",
    "unexpired_written_consent_present": "Քննութեան կեդրոնը իր ցանկերուն մէջ տակաւին ունի ժամկետը չանցած գրաւոր համաձայնութիւն։",
    "select_to_proceed": "Կը խնդրենք որ վերի տարբերակներէն մէկը ընտրէք շարունակելու համար։",
    "guardians_information": "Խնամակալի տեղեկութիւն",
    "registrars_information": "Գրանձողի տեղեկութիւն",
    "optional": "(ըստ ցանկության",
    "vfc_eligibility": "Երեխաններու Պատուաստութեան ծրագիրի (Vaccine for Children (VFC)) իրաւասութիւն",
    "vfc_eligibility_subtext": "Կարելի է որ ձեր երեխան ստանայ անվճար պատուաստանիւթ CDCի Երեխաններու Պատուաստութեան ծրագիրի (Vaccines for Children (VFC)) միջոցով բժշկական գրասենեակներու, դեղարաններու եւ կլինիկաններու մէջ որոնք արձանագրուած են VFC ծրագիրին։"
  },
  "gender_key": {
    "male": "Արական",
    "female": "իգական",
    "cisgender": "Սիսջենդեր",
    "non_binary": "Գենդերային քուեր կամ ոչ երկուական",
    "other": "Այլ",
    "prefer_to_self_describe": "Ինքնությունը նշված չէ",
    "prefer_not_to_disclose": "Պատասխանելուց հրաժարվել",
    "transgender_male": "Տրանսգենդեր տղամարդ ",
    "transgender_female": "Տրանսգենդեր կին",
    "unknown": "Անհայտ",
    "non_binary_only": "Ոչ բինար",
    "intersex": "Ինտերսեքս",
    "transgender": "Տրանսգենդեր",
    "gender_fluid": "Սեռային հեղուկ",
    "not_applicable": "Չի կիրառվում"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Ամերիկյան հնդիկ կամ Ալյասկայի բնիկ",
    "american_indian_central_america": "Ամերիկյան հնդիկ Հարավային կամ Կենտրոնական Ամերիկայից",
    "asian": "Ասիական",
    "black": "Սևամորթ կամ աֆրոամերիկացի",
    "latinx": "Լատինո, Լատինx կամ իսպանախոս",
    "middle_eastern_north_african": "Միջին Արեւելյան կամ Հյուսիսաֆրիկյան",
    "native_hawaiian_pacific_islander": "Բնիկ Հավայան կղզիներ կամ Խաղաղօվկիանոսյան կղզիներ",
    "white_european": "Սպիտակ կամ կովկասյան",
    "unknown": "Անհայտ",
    "prefer_not_to_disclose": "Ես նախընտրում եմ չբացահայտել",
    "asian_indian": "Ասիական հնդիկ",
    "filipino": "Ֆիլիպինցի",
    "japanese": "Ճապոնացի",
    "korean": "Կորեացի",
    "vietnamese": "Վիետնամցի",
    "other_asian": "Այլ ասիացի",
    "native_hawaiian": "Բնիկ հավայցի",
    "guamanian_or_chamorro": "Գուամայցաի կամ Չամորոյացի",
    "samoan": "Սամոացի",
    "other_pacific_islander": "Խաղաղօվկիանոսյան կղզու այլ բնակիչ",
    "chinese": "Չինացի",
    "help": "Ազգությունը վերաբերում է մի շարք ֆիզիկական բնութագրերի, որոնք օգտագործվում են մարդկանց խմբերը տարբերելու համար: Ազգությունը կարող է համընկնել կամ չհամապատասխանել քաղաքացիությանը, կամ այն երկրին, որտեղ դուք ունեք քաղաքացիություն:",
    "subtitle": "Կանոնակարգերը պահանջում են, որ մենք հավաքենք հետևյալ բոլոր տեղեկությունները:",
    "laotian": "Լաոսի անձ",
    "cambodian": "Կամբոջացի",
    "other": "Այլ",
    "bangladeshi": "Բանգլադեշցի",
    "hmong": "Հմոնգ",
    "indonesian": "Ինդոնեզիացի",
    "malaysian": "Մալայզիացի",
    "pakistani": "Պակիստանցի",
    "sri_lankan": "Շրի Լանկանցի",
    "thai": "Թաիլանդացի",
    "taiwanese": "Թայվանացի",
    "fijian": "Ֆիջիացի",
    "guamanian": "Գուամացի",
    "tongan": "Տոնգացի"
  },
  "languages": {
    "en": "Անգլերեն",
    "fr": "Ֆրանսերեն",
    "es": "Իսպաներեն",
    "so": "Սուոմաալի",
    "hmn": "Հմոնգ",
    "asl": "Ամերիկյան ժեստերի լեզու (ASL)",
    "kar": "Կարեն",
    "am": "Ամհարերեն",
    "ru": "Ռուսերեն",
    "om": "Օրոմո"
  },
  "symptoms": {
    "fever": "Բարձր ջերմություն կամ սարսուռ",
    "cough": "Հազ",
    "muscle_aches": "Մկանային ցավեր",
    "severe_fatigue": "Սուր հոգնածություն (սովորականից ավելի)",
    "trouble_breathing": "Շնչառության դժվարություն",
    "diarrhea": "Փորլուծություն",
    "loss_of_smell": "Հոտի կորուստ",
    "loss_of_taste": "Համի զգացողության կորուստ",
    "shortness_of_breath": "Շնչառության դժվարություն",
    "headache": "Գլխացավ",
    "sore_throat": "Կոկորդի ցավ",
    "congestion": "Գերբնակվածություն կամ քթի հոսք",
    "nausea": "Սրտխառնոց կամ փսխում",
    "close_contact": "Սերտ շփում վարակված անձի հետ*",
    "helper_text": {
      "close_contact": "Դուք սերտ շփում ունեցե՞լ եք (6 ոտնաչափ հեռավորության վրա առնվազն 15 րոպե) մեկի հետ, ով հաստատել է COVID-19- ը:"
    },
    "suspected_exposure": "Կասկածելի բացահայտում",
    "none": "Ախտանիշներ չկան"
  },
  "instructions": {
    "title": "Դուք գրանցված եք, բայց մինչ գնալը ...",
    "p1": "Այս էջը պարունակում է տեղեկություններ ձեր նշանակման, ինչպես նաև ձեր նշանակման շտրիխի մասին:",
    "appointment_scheduled": "Ձեր նշանակումը նշանակված է",
    "verify_info": {
      "title": "Հաստատեք ձեր տեղեկությունները",
      "p1": "Կարևոր է հաստատել ձեր կոնտակտային տվյալները, որպեսզի կարողանաք արագ մուտք գործել ձեր հիվանդների պորտալ:",
      "p2": "եր էլ. Փոստին ուղարկվել է հաստատում: Խնդրում ենք սեղմել տրված հղումը:",
      "p3": "Եթե Ձեզ անհրաժեշտ է փոխել ձեր կոնտակտային տվյալները, սեղմեք օգնության կոճակը:"
    },
    "verified": "հաստատված է",
    "not_verified": "հաստատված Չի ",
    "resend_text": "Չե՞ք ստացել հղումը:",
    "resend": "Նորից ուղարկել",
    "skip": "Բաց թողնել վավերացումը և ցուցադրել հաստատումը",
    "name": "Անուն",
    "appointment_details": "Նշանակումը",
    "date_and_time": "Նշանակումը և ժամանակը",
    "add_to_calendar": "Ավելացնել օրացույցին",
    "instructions": "Ընդհանուր ցուցումներ",
    "successfully_flash": "Հաջողությամբ ստեղծվեց նշանակումը:",
    "success_flash_email": "Էլեկտրոնային փոստի հասցեն հաջողությամբ հաստատված է",
    "success_flash_phone": "Հեռախոսահամարը հաջողությամբ հաստատված է",
    "mail_order": "Մեկ շաբաթվա ընթացքում փոստով կստանաք ձեր թեստային փաթեթը:",
    "at_home": "Բժշկական ծառայություն մատուցողը մոտավորապես այս ժամին կգա ձեր տուն",
    "at_home_instructions": "Տնային ցուցումներ",
    "mail_order_instructions": "Փոստի պատվերների ցուցումներ",
    "request_additional_appointment": "Ամրագրեք հետագա նշանակումը",
    "book_additional_appointment": "Ամրագրեք մեկ այլ հանդիպում",
    "confirmation_code": "Հաստատման կոդ",
    "completed": "Ավարտված",
    "appointment_barcode": "Նշանակման շտրիխ",
    "dependent_links": "Տնային նշանակումների հղումներ",
    "on_demand_title": "ուք գրանցված եք, բայց մինչ գնալը ...",
    "save_link": "Պահպանեք այս հղումը",
    "verify_phone": "Հաստատեք ձեր հեռախոսահամարը",
    "verify_phone_description": "Կարևոր է հաստատել ձեր կոնտակտային տվյալները, որպեսզի կարողանաք արագ մուտք գործել ձեր գրառումները: Ձեր համարին ուղարկվել է հաստատման տեքստային հաղորդագրություն:",
    "verify_email_description": "Կարևոր է հաստատել ձեր կոնտակտային տվյալները, որպեսզի կարողանաք արագ մուտք գործել ձեր գրառումները: Ձեր էլ. Փոստի հասցեին ուղարկվել է հաստատման էլ.",
    "information": "Գրանցման տեղեկատվություն",
    "follow_up_appointment": "Լրացուցիչ նշանակում",
    "get_directions": "Ստացեք ուղղություններ",
    "cancel": "Չեղարկել նշանակումը",
    "reschedule_appointment": "Փոխել նշանակումը",
    "reschedule_linked_appointments": "Փոխել նշանակումները",
    "no_slots": "Այլևս մատչելի ժամանակներ չկան",
    "check_results": "Ստուգեք հիվանդների պորտալը",
    "follow_up_modal_header": "Խնդրում ենք պլանավորել ձեր 2 -րդ դոզան",
    "are_you_sure_you_want_to_cancel": "Վստա՞հ եք, որ ցանկանում եք չեղարկել այս նշանակումը:",
    "please_choose_cancellation_reason": "Խնդրում ենք ընտրել չեղարկման պատճառը ստորև",
    "additional_details": "Խնդրում ենք ավելացնել լրացուցիչ մանրամասներ ստորև",
    "errors": {
      "missing_cancellation_reason": "Խնդրում ենք ընտրել չեղարկման պատճառ"
    },
    "verify_email": "Ստուգեք ձեր էլեկտրոնային փոստի հասցեն",
    "redcap_url": "Կտտացրեք ՝ ամբողջական հետազոտություն կատարելու համար",
    "verify_contact_information": "Հաստատեք ձեր կոնտակտային տվյալները",
    "please_also": "Խնդրում ենք նաև",
    "new_title": "Հաստատում ի {{name}} համար",
    "contact_method": "Կապ հաստատելու միջոց",
    "next_steps": {
      "title": "Յաջորդ քայլեր",
      "p1": "Քննութեան սնտուկնիդ պատրաստ է։ Երբ պատրաստ ըլլաք քննութիւնիդ առնելու, հետեւեցէք ձեր էլեկրոնային հասցէին ղրկուած «link»ին, որպէսզի վերադառնաք այս էջը եւ աշխատցնենք քննութեան սնտուկը։",
      "administered": "Ձեր հետ կը կապուինք երբ սնտուկնիդ լաբորատորիա հասնի։ Լաբորատորիան հասնելէ ետք, արդիւնքները ընդհանրապէս 3-5 օրէն պատրաստ կ՛ըլլան։ Երբ որ արդիւնքները պատրաստ են կրնաք տեսնել «Անցեալի առողջութեան հարցեր» ({{link}} էջին վրայ։",
      "resulted": "Արդիւնքներ կը գտնուին ձեր անցեալի առողջութեան խնդիրներու «Medical history» էջին վրայ",
      "shipping_information": "Առաքումի տեղեկութիւն"
    },
    "save_this_page": {
      "title": "Պահել այս էջը",
      "p1": "Այս էջը գործածեցէք լուր տալու անձնական քննութեան մասին կամ տեսնելու քննութեան արդիւնքներ անցեալի առողջութեան խնդիրներու էջի միջոցով։",
      "p2": "Ապագային այս էջը հասնելու համար ձեր հաստատման էլեկտրոնային փոստի կամ հեռախօսի SMS հաղորդագրման մէջի կայքի «link»ը սխմեցէք",
      "p3": "Կրնաք պահել այս էջը «bookmark» ընելով, ձեր կեդրոնական էջին աւելցնելով կամ «link»ը ապահով տեղ մը արձանագրելով։"
    },
    "show_my_barcode": "Ցոյց տուէք իմ «barcode»ս",
    "my_account": "Իմ հաշիւս",
    "register_another": "Ուրիշ մասնակից արձանագրել",
    "update_vaccine": "Սրսկումի տեղեկութիւններ աւելցնել",
    "medical_history": "Անցեալի առողջութեան խնդիրներ",
    "upcoming_appointments": "Եկող ժամադրութիւններ",
    "reschedule": "Թուական փոխել",
    "resend_confirmation": "Կրկին ուղղարկել հաստատում",
    "appointment_details_v2": "Ժամադրութեան մանրամասնութիւններ",
    "confirm_cancellation": "Հաստատել չեղարկում",
    "confirm_cancellation_question": "Վստահ ե՞ք որ կուզէք ձեր ժամադրութիւննը չեղարկել",
    "select_new_appointment_time_below": "Ներքեւը ընտրեցէք նոր ժամադրութիւն մը։ Եթէ կը փափաքիք ծառայութիւններ կամ ժամադրութեան վայրը փոխել, կը խնդրենք որ չեղարեալ համարէք ժամադրութիւնը եւ նոր ժամադրութիւն մը ընտրէք։",
    "no_take_me_back": "Ո՛չ, ինծի նախկին էջը վերադարձուցէք",
    "yes_cancel_appointment": "Այո՛, չեղեալ նկատեցէք ժամադրութիւն",
    "update_appointment": "Փոխեցէք ժամադրութիւնս",
    "select_new_appointment_time": "Ընտրել նոր ժամադրութեան ժամ",
    "clinic": "Կլինիկա",
    "services": "Ծառայութիւններ",
    "appointment_missed": "Ժամադրութիւնէն բացակայ",
    "appointment_canceled": "Չեղարկուած ժամադրութիւն"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Անվավեր մուտքի կոդ"
    },
    "landing": {
      "p1": "Ձեր արդյունքները դիտելու համար մուտքագրեք ՝",
      "access_code_label": "Մուտքի կոդ",
      "submit_button": "Ներկայացնել",
      "recent_results": "Ձեր վերջին արդյունքները"
    },
    "view_results": {
      "headline": "Փորձարկման արդյունքներ {{name}} - ի համար",
      "reregister": "Վերագրանցվել",
      "status": {
        "likely_positive": "Արդյունքներ Անորոշ",
        "test_not_performed": "Անհրաժեշտ է կրկնել թեստը",
        "results_ready": "Արդյունքները պատրաստ են",
        "processing": "Վերամշակում",
        "test_status": "Թեստի կարգավիճակ",
        "test_result": "Թեստի արդյունքներ",
        "administered": "Ղեկավարվում է",
        "results_pending": "Արդիւնքները կը պատրաստուին",
        "test_results": "Քննութեան արդիւնքներ"
      },
      "at": "{{address}}-ում"
    },
    "result_label": "Արդյունք",
    "result": {
      "flu_a": {
        "result_label": "Գրիպի A արդյունք"
      },
      "flu_b": {
        "result_label": "Գրիպի B արդյունք"
      },
      "covid19": {
        "result_label": "COVID-19-ի արդյունք"
      },
      "covid": {
        "result_label": "COVID-19-ի արդյունք"
      },
      "sars": {
        "result_label": "SARS-ի արդյունքը"
      },
      "control": {
        "result_label": "Վերահսկողություն"
      },
      "rsv": {
        "result_label": "RSV արդյունքը"
      },
      "result": {
        "result_label": "Արդյունք"
      },
      "hba1c": {
        "normal": "Նորմալ",
        "warning": "Նախադիաբետ / նախաշաքարախտ",
        "danger": "Շաքարային դիաբետ / շաքարախտ"
      },
      "lead_venous": {
        "danger": "Աննորմալ",
        "normal": "Նորմալ"
      },
      "tc": {
        "result_label": "«TC» արդիւնք (ընդհանուր խոլեսթերին)"
      },
      "hdl": {
        "result_label": "«HDL» արդիւնք (բարձր խտության լիպոպրոտիեն կամ «լաւ» խոլեսթերին)"
      },
      "trg": {
        "result_label": "«TRG» արդիւնք (տրիգլիցերիդ)"
      },
      "ldl": {
        "result_label": "«LDL» արդիւնք (ցած խտության լիպոպրոտիեն կամ «վատ» խոլեսթերին)"
      },
      "non_hdl": {
        "result_label": "«Non-HDL» արդիւնք (ոչ բարձր խտության լիպոպրոտիեն)"
      },
      "tc_hdl_ratio": {
        "result_label": "«TC/HDL» համեմատութիւն (ընդհանուր խոլեսթեր եւ բարձր խտության լիպոպրոտիեն)"
      },
      "glu": {
        "result_label": "«GLU» արդիւնք (քաղցրուց)"
      },
      "alere_cholestech_ldx": {
        "danger": "Վտանգի տակ",
        "warning": "Աննորմալ",
        "normal": "Նորմալ"
      },
      "lead": {
        "result_label": "Կապար"
      },
      "zinc": {
        "result_label": "Զինք"
      },
      "lead_standard_profile": {
        "danger": "Աննորմալ",
        "normal": "Նորմալ"
      },
      "creatinine": {
        "danger": "Աննորմալ",
        "normal": "Նորմալ"
      },
      "igg": {
        "result_label": "«IgG» արդիւնք"
      },
      "igm": {
        "result_label": "«IgM» արդիւնք"
      },
      "blood_glucose_fasted": {
        "normal": "Նորմալ",
        "warning": "Բարձրացված",
        "danger": "Բարձր",
        "hypoglycemia": "Հիպոգլիկեմիա (գլյուկոզի ցած մակարդակ)",
        "prediabetes": "Նախաշաքարախտ",
        "diabetes": "Շաքարախտ"
      },
      "total_cholesterol_fasted": {
        "normal": "Նորմալ",
        "elevated": "Բարձրացված",
        "high": "Բարձր",
        "low": "Ցած",
        "abnormal": "անսովոր"
      },
      "total_cholesterol_unfasted": {
        "normal": "Նորմալ",
        "elevated": "Բարձրացված",
        "high": "Բարձր"
      },
      "a1c_now": {
        "normal": "Նորմալ",
        "warning": "Նախաշաքարեգի վիճակ",
        "danger": "Շաքարեգ"
      },
      "blood_glucose": {
        "warning": "Ցածր",
        "normal": "Սովորական",
        "prediabetes": "Նախադիաբետ",
        "danger": "Շաքարախտ",
        "hypoglycemia": "Հիպոգլիկեմիա (գլյուկոզի ցած մակարդակ)",
        "medical_emergency": "Բժշկական արտակարգ իրավիճակ",
        "inconclusive_1": "Անվճռորոշ 1",
        "inconclusive_2": "Անվճռորոշ 2",
        "possible_diabetes": "Հնարավոր շաքարախտ"
      },
      "triglycerides": {
        "result_label": "Տրիգլիցերիդներ"
      },
      "blood_glucose_ucsf": {
        "low": "ցածր",
        "normal": "նորմալ",
        "high": "բարձր"
      },
      "syphilis": {
        "result_label": "Սիֆիլիս արդյունք"
      },
      "hepatitis_c": {
        "result_label": "Հեպատիտ C արդյունք"
      },
      "hiv": {
        "result_label": "HIV արդյունք"
      }
    },
    "documents": "Փաստաթղթեր",
    "self_administered": "Ինքնաղեկավարվող թեստը ստացված {{location}}-ից",
    "patient": "Հիւանդ",
    "medical_history": "Անցեալի առողջութեան խնդիրներ",
    "overview_title": "Ընտրեցէք քննութիւն կամ ծառայութիւն մը, որպէսզի տեսնէք անոր մանրամասնութիւնները եւ ոեւէ աւելորդ քայլեր որ պէտք է առնէք։ Յիշեցէք որ կարգ մը քննութեան արդիւնքներ կրնան տակաւին պատրաստ չըլլալ կամ միայն պատրաստ կ՛ըլլան բժիշկի հետ խօսելէ ետք։",
    "insurance_information": "Լրացուցէք ձեր ապահովագրութեան տեղեկութիւնները։",
    "contact_support": "Եթէ օգնութիւն պէտք ունիք, կը խնդրենք որ մեր օգնութեան խումբին հետ կապուիք։",
    "show": "Ցոյց տալ",
    "hide": "Ծածկել",
    "lab_report": "Լապարատորիայի տեղեկագրութիւն",
    "contact_provider": {
      "header": "Դրական արդիւնքներ ունիք։",
      "description": "Կուզե՞ք բժիշկի հետ խօսիլ ձեր արդիւնքներուն եւ բուժումին մասին։",
      "yes_option_text": "Այո՛, կը փափաքիմ բժիշկի հետ խօսիլ։",
      "no_option_text": "Ո՛չ, արդիւնքներս կարդացի եւ հասկցայ ու չեմ ուզեր բժիշկի հետ խօսիլ։ ",
      "confirm_phone_number_header": "Հաստատէ հեռախօսի համարդ",
      "confirm_phone_number_description": "Կը խնդրենք որ հաստատէք ամենայ լաւ հեռախօսի համարը ձեր խորհրդակցութեան ժամադրութեան համար։",
      "confirm_button_text": "Հաստատէ՛",
      "consultation_confirmed_header": "խորհրդակցութեան ժամադրութիւնը հաստատուեցաւ",
      "consultation_confirmed_description": "Բժիշկ մը ձեր {{phone_number}} թիւը կը հեռաձայնէ 2-3 օրէն։",
      "acknowledgement_option_helper_text": "Շատ կարեւոր է բժում ստանալ։ Եթէ միտքերնիդ փոխէք՝ կրնաք հեռաձայնել ժամադրութիւն առնելու համար։",
      "acknowledgement_confirmed_header": "Ձեր համաձայնութիւնը հաստատուեցաւ։",
      "acknowledgement_confirmed_description": "Շատ կարեւոր է բժում ստանալ նշուած միջոցներով։ Խորհրդակցութեան ժամադրութիւնը ամբողջովին անվճար է եւ բժիշկը կրնայ պատուիրել դեղ ու դարման վարակումը բուժելու։",
      "acknowledgement_confirmed_change_mind_text": "Եթէ միտքներնիդ փոխէք, պարզապէս սխմեցցէք «Խորհրդակցութեան ժամադրութիւն կ՛ուզեմ» կոճակը",
      "request_consultation_button_text": "Խորհրդակցութեան ժամադրութիւն կ՛ուզեմ"
    },
    "phone": "Հեռախօս",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Ձեր հասցեն չի համապատասխանում այս ծրագրում տեղ գտած կայքերի համապատասխանության պահանջներին:"
    }
  },
  "member": {
    "medical_history": "Պատմություն",
    "view_instructions": "Դիտել հաստատումը",
    "next_appointment": "Հաջորդ նշանակում",
    "over_18": "Ես հաստատում եմ, որ 18 տարեկան կամ ավելի մեծ տարիք եմ",
    "choose_test_configurations_title": "Խնդրում ենք ընտրել այն ծառայությունը (ծառայությունները), որոնք կցանկանայիք այս նշանակման համար",
    "member_taken_error": "Օգտվողն արդեն հաշիվ ունի",
    "choose_test_configurations_subtitle": "Ծառայությունը (երը) կկիրառվեն տան բոլոր անդամների վրա",
    "service": "Ծառայություն",
    "group_or_location": "Խումբ/Գտնվելու վայր",
    "details": "Մանրամասներ",
    "see_all": "Տեսնել բոլորը",
    "no_history": "Այս պահին, պատմություն չկա հասանելի",
    "medical_history_title_with_name": "{{name}}-ի պատմություն",
    "no_dashboard": "Ներկայումս հասանելի ոչինչ չկա",
    "product": "Ապրանք",
    "price": "Գին",
    "quantity": "Քանակ",
    "total_services_selected": "Ընտրված ծառայությունների ընդհանուր թիվը",
    "total_price": "Ընդհանուր գինը"
  },
  "or": "կամ",
  "account": {
    "errors": {
      "must_be_13": "Հաշվի գրանցվելու համար պետք է լինի 13 տարեկան"
    }
  },
  "self_administration": {
    "title": "Ինքնակառավարում",
    "self_administer_action": "Ինքնակառավարվող",
    "skip_to_link": "Անցեք ինքնակառավարմանը",
    "select_person": "Շարունակելու համար ընտրեք անձի",
    "adult": "չափահաս",
    "child": "երեխա",
    "checked_out": "Ավարտված է",
    "go_back": "Վերադառնալ",
    "switch_to_scanner": "Անցնել սկաների",
    "enter_barcode": "Մուտքագրեք շտրիխ կոդը",
    "scan_barcode": "Սկանավորեք շտրիխ կոդը",
    "cancel": "Չեղարկել",
    "barcode_for": "Շտրիխ կոդ",
    "enter_barcode_manually": "Մուտքագրեք շտրիխ կոդը ձեռքով",
    "instructions": "Ստորև սկանավորեք կամ մուտքագրեք ձեր փորձանոթի վրա գտնվող շտրիխ կոդը:",
    "regex_prefix": "Շտրիխ կոդը պետք է բաղկացած լինի",
    "all_completed": "Ավարտված. ընտանիքի բոլոր անդամներն ավարտել են այս քայլը",
    "errors": {
      "no_appointment": "Ժամադրությունը գտնելու խնդիր կա",
      "barcode_in_use": "Այս շտրիխ կոդը նախկինում արդեն օգտագործվել է: Եթե դուք նախկինում ինքնուրույն չեք կառավարել այս փաթեթը, ապա խոսեք այն կայքի անձնակազմի հետ, որը ձեզ տրամադրել է փաթեթը և խնդրեք նոր փաթեթ: Հակառակ դեպքում սեղմեք ստորև նշված օգնության կոճակը:",
      "test_error_general": "Թեստ ստեղծելու խնդիր կա",
      "different_barcodes": "Ձեր մուտքագրած շտրիխ կոդերը չեն համընկնում:"
    },
    "confirm_barcode_input": "Հաստատելու համար կրկին մուտքագրեք շտրիխ կոդը",
    "click_to_self_test": "Սեղմեք այստեղ՝ ինքնափորձարկման համար",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Սպասման ցուցակ",
      "unavailable": "Հանդիպումներ չկան"
    },
    "labels": {
      "address": "Հասցե",
      "next_appointment": "Հաջորդ հասանելի նշանակումը",
      "services": "Հասանելի ծառայություններ"
    }
  },
  "waiting_room": {
    "header": "Դուք սպասասրահում եք; խնդրում ենք սպասել, մինչև մենք ձեզ կապենք պլանավորման համակարգի հետ:",
    "p1": "Խնդրում ենք լինել համբերատար. Մենք միասին կավարտենք այս համաճարակը:",
    "signature": "Հարգանքներով",
    "p2": "Մենք գիտենք, որ COVID-19- ի փորձը կարող է հիասթափեցնել: Խնդրում ենք սպասել այստեղ, քանի դեռ մենք աշխատում ենք հնարավորինս արագ, որպեսզի ձեզ հասցնենք կայք ՝ ձեր նշանակումը պատվիրելու համար:"
  },
  "users": {
    "send_appointment_confirmation_message": "Ողջույն {{full_name}}: Սա հիշեցում է ձեր նշանակման {{name}} հասցեով: Ձեր նշանակումը {{date}} - ին {{time}} - ին {{address}} - ում է:",
    "send_registration_link": "Ողջույն, {{full_name}}: Հետևեք այս հղմանը ՝ ձեր {{name}} {{registration_link}} - ի համար գրանցվելու համար"
  },
  "follow_up": {
    "first_dose": "Առաջին դոզան",
    "previous_appointment": "Անցյալ նշանակում",
    "booked_appointment": "Ձեր նշանակումը",
    "second_dose": "Երկրորդ դոզան",
    "choose_second_location": "Ընտրեք երկրորդ նշանակման վայրը"
  },
  "cancellation_types": {
    "cant_get_to_location": "Ես ունեի ժամանակի կոնֆլիկտ",
    "timing_conflict": "Ես հիվանդացա COVID-19- ով",
    "became_ill_with_covid19": "Ես այս ծառայությունը ստացել եմ այլուր",
    "received_service_elsewhere": "Այլ",
    "other": "Փոխարենը մուտքագրեք անունը",
    "duplicate_appointment": "Սա կրկնակի ժամադրություն էր"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Մուտքագրեք Անունը",
      "type_name": "Ստորագրութեան փոխարէն՝ տպէք։"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Ձեր մուտքի կոդն է",
      "please_confirm_your_email": "Խնդրում ենք հաստատել ձեր էլփոստը `կտտացնելով ներքևի կոճակին",
      "hello": "Ողջույն",
      "click_to_reschedule": "Կտտացրեք ՝ ցուցումները ցուցադրելու/վերադասավորելու համար",
      "click_to_reschedule_on_demand": "Կտտացրեք ՝ ցուցումները ցուցադրելու համար"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Ձեր ժամադրութիւնը {{time}} ժամուն {{location}} չեղեալ (cancel) համարուած է։ Եթէ սխալ է, կը խնդրենք կապ հաստատէք support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Մասնակցի անունը",
    "date": "Ամսաթիվ",
    "signature": "Մասնակցի ստորագրությունը",
    "and_or": "ԵՎ/ԿԱՄ",
    "guardian_name": "Ծնողի/խնամակալի անունը",
    "guardian_signature": "Ծնողի/խնամակալի տորագրությունը"
  },
  "employer_testing": {
    "hello_name": "Բարև {{name}}",
    "results": {
      "negative": "Բացասական",
      "positive": "Դրական",
      "did_not_result": "Անհայտ",
      "unknown": "Անհայտ",
      "invalid": "Անվավեր",
      "pending": "Սպասվում է"
    },
    "code_reader": {
      "scan_your_code": "Սկանավորեք ձեր թեստային քարտի կոդը",
      "find_in_top_right_hand_corner": "Սկան կոդը եզակի է ձեր թեստին և կարելի է գտնել ձեր թեստի վերևի աջ անկյունում Սկանավորել ծածկագիրը ձեր թեստային քարտի վրա",
      "center_code": "Կենտրոնացրեք կոդը այստեղ:",
      "already_used_error": "Այս փորձարկման շտրիխ կոդն արդեն օգտագործվել է: Աջակցության համար դիմեք ձեր փորձարկման հավաքածուի մատակարարին:",
      "click_to_scan": "Սեղմեք ՝ QR կոդը սկանավորելու համար",
      "scan_new_test_card": "Կտտացրեք այստեղ ՝ նոր թեստային քարտ սկանավորելու համար:",
      "not_working": "Չի աշխատում?",
      "try_again": "Սեղմեք այստեղ նորից փորձելու համար:"
    },
    "continue": "Շարունակել",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Շարունակելու համար խնդրում ենք հաստատել մասնակցի ծննդյան ամսաթիվը",
      "contact_administrator": "Եթե ցուցադրված տեղեկատվությունը սխալ է, օգնության համար դիմեք ադմինիստրատորին",
      "error": "Մուտքագրված ծննդյան ամսաթիվը սխալ է. Կրկին փորձեք կամ դիմեք ձեր կայքի ադմինիստրատորին `դա թարմացնելու համար:"
    },
    "result_entry": {
      "code_is_registered": "Ձեր կոդը գրանցված է:",
      "take_the_test": "Անցեք թեստը",
      "follow_the_instructions": "Հետևեք ձեր թեստին տրված հրահանգներին և գործարկեք ձեր 15 րոպեանոց ժամաչափը, երբ քթի շվաբրը թեստավորման քարտի սրվակի մեջ մտցրեք:",
      "cant_find_instructions": "Չե՞ք կարողանում գտնել հրահանգները:",
      "view_instructions": "Դիտեք Binax Now հրահանգները",
      "start_timer": "Սկսել 15 րոպեանոց ժամաչափ (ըստ ցանկության)",
      "submit_within_15": "Հուշում. Ներկայացրեք ձեր արդյունքները 15 րոպեի ընթացքում",
      "enter_test_results": "Մուտքագրեք թեստի արդյունքները",
      "choose_a_result": "Ընտրեք այն տարբերակը, որը նկարագրում է ձեր փորձարկման քարտի արդյունքը",
      "submit": "Ներկայացնել",
      "must_log_result_and_photo": "Շարունակելու համար դուք պետք է գրանցեք արդյունքը և լուսանկարեք ձեր թեստը",
      "submit_within_15_v2": "Հուշում. Ներկայացրեք ձեր արդյունքները թեստի 15 րոպե տևելուց հետո",
      "click_to_take_photo": "Կտտացրեք լուսանկարելու համար",
      "results_may_be_invalid": "Թեստի արդյունքները կարող են անվավեր լինել"
    },
    "summary": {
      "title": "Արդյունքների ամփոփում",
      "negative_subtitle": "Դուք չունեք COVID-19",
      "negative_message": "Մենք ծանուցում ենք ուղարկել ձեր գործատուին, որ դուք իրավունք ունեք վերադառնալ աշխատանքի:",
      "positive_subtitle": "Մենք ցավում ենք, բայց դուք ունեք COVID-19",
      "positive_message": "Մենք ծանուցում ենք ուղարկել ձեր գործատուին, որ դուք ունեք COVID-19:",
      "what_should_you_do": "Ինչ պետք է անեք.",
      "employer_name_resources": "Գործատուի անվան աղբյուրներ.",
      "follow_cdc_guidelines": "Հետևեք CDC- ի առաջարկվող ուղեցույցներին"
    },
    "clarifying_results": {
      "title": "Ձեր արդյունքների հստակեցում",
      "which_did_your_result_most_look_like": "Ո՞րն էր ձեր արդյունքն ամենից շատ նման:",
      "no_lines": "Ոչ մի տող չի երեւում",
      "blue_control_line_only": "Միայն կապույտ կառավարման գիծ",
      "pink_sample_line_only": "Միայն վարդագույն նմուշի տող",
      "blue_control_line_and_pink_sample_line": "Կապույտ կառավարման գիծ ԵՎ վարդագույն/մանուշակագույն ընտրանքային գիծ",
      "still_not_sure": "Դեռ վստահ չէ",
      "submit_results": "Ներկայացրեք արդյունքները",
      "thank_you": "Շնորհակալություն!",
      "clarification_received_message": "Ձեր արդյունքի պարզաբանումը ստացվել է:",
      "only_pink_line": "Միայն վարդագույն գիծ",
      "no_lines_v2": "Ոչ մի տող"
    },
    "return_to_confirmation": "Վերադառնալ հաստատման էջ"
  },
  "preferred_language": {
    "title": "նախընտրելի լեզու",
    "subtitle": "Ո՞ր լեզուն կնախընտրեք"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Սկզբնական պատվաստման շարքը",
    "one_dose": "Պատվիրեք հատուկ դոզայի համար: Մասնավորապես, սա իմն է",
    "title": "Դոզայի ընտրություն",
    "first_dose": "Առաջին դոզան",
    "second_dose": "Երկրորդ դոզան",
    "single_dose_title": "Մեկ դոզան",
    "additional": "Լրացուցիչ",
    "booster": "երրորդ/լրացուցիչ դոզա",
    "supplemental": "հավելյալ",
    "third_dose": "Միայն երրորդը",
    "no_vaccine": "Ոչ մեկը"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "պետք է ունենա {{datetime_format}} ձևաչափ"
            },
            "results_at": {
              "invalid_datetime": "պետք է ունենա {{datetime_format}} ձևաչափ"
            },
            "uid": {
              "unknown_test": "թեստը չի գտնվել"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "օգտվողը գոյություն ունի և իրավունք չունի փոխել այն"
            },
            "date_of_birth": {
              "invalid_date": "պետք է ունենա {{date_format}} ձևաչափ"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից ՝ {{ethnicities}}",
                "other": "{{unknown_ethnicities}} գոյություն չունեն, ընտրեք հետևյալ ընդունելի արժեքներից. {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "սեռը գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից ՝ {{genders}}"
            },
            "phone_number": {
              "invalid": "անվավեր թիվ է"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից. {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "չի կարող լրացնել այն, եթե սեռը \"prefer_to_self_describe\" չէ"
            },
            "sex": {
              "unknown_sex": "սեռը գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից ՝ {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "սեռական_կողմնորոշում գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից ՝ {{sex_orientations}}"
            },
            "status": {
              "unknown_status": "կարգավիճակը անվավեր է, ընտրեք հետևյալ ընդունելի արժեքներից ՝ {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից ՝ {{races}}",
                "other": "{{unknown_races}} գոյություն չունեն, ընտրեք հետևյալ ընդունելի արժեքներից ՝ {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "անբավարար արտոնություններ ՝ դերը սահմանելու համար"
            },
            "user_id": {
              "unknown_user": "օգտատերը չի գտնվել"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "անվավեր JSON"
            },
            "user_id": {
              "unknown_user": "օգտատերը չի գտնվել"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "նշանակման վայրը չի գտնվել"
            },
            "date_of_birth": {
              "invalid_date": "պետք է ունենա {{date_format}} ձևաչափ"
            },
            "email": {
              "duplicated": "արդեն օգտագործված է"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից ՝ {{ethnicities}}",
                "other": "{{unknown_ethnicities}} գոյություն չունեն, ընտրեք հետևյալ ընդունելի արժեքներից.{{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "սեռը գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից. {{genders}}"
            },
            "phone_number": {
              "invalid": "անվավեր թիվ է"
            },
            "population": {
              "unknown_population": "{{unknown_population}} գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից ՝ {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "չի կարող լրացնել այն, եթե սեռը «նախընտրել_ոչ_ նկարագրել» չէ"
            },
            "sex": {
              "unknown_sex": "սեռը գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից. {{sexes}} "
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "եռական_կողմնորոշում գոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից. {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} ոյություն չունի, ընտրեք հետևյալ ընդունելի արժեքներից. {{races}}",
                "other": "{{unknown_races}} գոյություն չունեն, ընտրեք հետևյալ ընդունելի արժեքներից. {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "և հասցեն արդեն օգտագործվում են մեկ այլ գոյություն ունեցող վայրում"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Իսպանախոս կամ լատինո/ա",
    "not_hispanic": "Ոչ իսպանախոս կամ լատինո/ա",
    "hispanic_expanded": {
      "other": "Մեկ այլ իսպանախոս, լատինո/ա կամ իսպանական ծագում",
      "mexican": "Մեքսիկացի, մեքսիկացի ամերիկացի, Չիկանո/ա",
      "puerto_rican": "Պուերտոռիկացի",
      "cuban": "Կուբացի"
    },
    "subtitle": "Կանոնակարգերը պահանջում են, որ մենք հավաքենք հետևյալ տեղեկությունները",
    "help": "Ազգությունը վերաբերում է մի շարք ընդհանուր հատկանիշների, ինչպիսիք են լեզուն, մշակութային սովորույթները, կրոնը և այլ հատկություններ, որոնք օգտագործվում են մարդկանց խմբերը տարբերելու համար: Այն կարող է համընկնել նաև ռասայական ինքնության հետ:",
    "unknown": "Անհայտ",
    "title": "Ազգություն"
  },
  "sex_at_birth": {
    "question": "Սեռ",
    "female": "իգական",
    "male": "Արական",
    "help": "Սեռը վերաբերում է այն, ինչ կա ձեր ծննդյան վկայականում:",
    "non_binary": "Ոչ երկուական",
    "subtitle": "Կանոնակարգերը պահանջում են, որ մենք հավաքենք հետևյալ բոլոր տեղեկությունները:",
    "decline": "հրաժարվում եմ պատասխանել",
    "unknown": "Անհայտ"
  },
  "gender": {
    "help": "Գենդերային ինքնությունը վերաբերում է նրան, թե ինչպես եք անձամբ նույնականանում: Այն կարող է համընկնել կամ չհամընկնել այն սեռի հետ, որը ձեզ նշանակվել է ծննդյան ժամանակ:",
    "subtitle": "Եթե ցանկանում եք ձեր սեռական ինքնության մասին տեղեկություններ ավելացնել, խնդրում ենք դա անել:"
  },
  "sexual_orientation": {
    "title": "Սեռական կողմնորոշում",
    "subtitle": "Եթե ցանկանում եք ձեր սեռական կողմնորոշման մասին տեղեկատվություն ավելացնել, խնդրում ենք դա անել:",
    "gay": "Գեյ, լեսբուհի կամ նույնասեռական",
    "heterosexual": "Հետերոսեքսուալ ",
    "bisexual": "Երկսեռ",
    "questioning": "Հարցաքննություն/անորոշ/չգիտեմ",
    "prefer_not_to_disclose": "Ընտրում եմ չբացահայտել",
    "unknown": "Անհայտ",
    "orientation_not_listed": "Կողմնորոշումը նշված չէ",
    "not_applicable": "Չի կիրառվում",
    "pansexual": "Պանսեքսուալ",
    "queer": "Քվիր"
  },
  "pronouns": {
    "title": "Նախընտրելի դերանուններ",
    "he": "Նա/նրան",
    "she": "Նա/նրան",
    "they": "Նրանք",
    "choose_not_to_disclose": "Ես ընտրում եմ չբացահայտելը",
    "prefer_to_self_describe": "Ես նախընտրում եմ ինքս նկարագրել"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Վարորդի լիցենզիա կամ պետական ID համար",
    "driver_license": "Վարորդական իրավունք",
    "use_ssn_instead": "Փոխարենը օգտագործեք SSN",
    "social_security_number": "Սոցիալական քարտի համար",
    "state": "Նահանգ",
    "upload_front_of_driver_license": "Վերբեռնեք ձեր վարորդական իրավունքի կամ անձը հաստատող պետական փաստաւղթի առջևի մասը",
    "choose_file": "Ընտրել ֆայլը",
    "no_file_chosen": "Ոչ մի ֆայլ ընտրված չէ",
    "no_identification": "Ես անձը հաստատող փաստաթուղթ չունեմ",
    "insurance_card_has_a_back": "Իմ ապահովագրական քարտը հետին մաս ունի",
    "upload_front_of_insurance_card": "Վերբեռնեք ձեր ապահովագրական քարտի առջևի մասը:",
    "front_of_insurance_card_uploaded": "Ձեր ապահովագրական քարտի առջևի մասը վերբեռնված է:",
    "insurance_card": "Ապահովագրական քարտ",
    "insurance_card_back": "Ապահովագրական քարտի հետին մաս",
    "back_of_insurance_card_uploaded": "Ձեր ապահովագրական քարտի հետին մասը վերբեռնված է:"
  },
  "quidel": {
    "certify_text": "Ես հաստատում եմ, որ այս թեստի արդյունքները գրանցում եմ միայն մեկ անգամ:",
    "entering_results": "Մուտքագրեք ձեր արդյունքները",
    "review_instructions_1": "Ուշադիր վերանայեք հրահանգները",
    "review_instructions_2": "Կարող եք վերանայել ձեր թեստին տրված հրահանգները, կարդալ քայլ առ քայլ ուղեցույց կամ դիտել ստորև տրված ուսուցողական տեսանյութը.",
    "read_instructions": "Կարդացեք Հրահանգները",
    "watch_video": "Դիտեք Տեսանյութը",
    "timer": "Սկսեք ժամաչափը խողովակի մեջ շվաբրը խառնելուց հետո: ժամաչափը կդադարի 1 րոպե անց (ձեզ համար շվաբրը խողովակից հանելու համար): Խնդրում ենք նկատի ունենալ. Դուք պետք է վերսկսեք ժամաչափը `ձեր թեստի արդյունքի համար 10 րոպե սկսելու համար:",
    "start_timer": "Գործարկել ժամաչափը (ըստ ցանկության)",
    "restart_timer": "Կանգնեք և վերագործարկեք",
    "resume": "վերսկսել",
    "one_minute_countdown": "Անցավ 1 րոպե! Հեռացրեք ձեր շվաբրը խողովակից և շարունակեք ժամաչափը:",
    "take_photo": "Լուսանկարեք ձեր փորձարկման գոտին",
    "photo_warning": "Շարունակելու համար անհրաժեշտ է լուսանկար տրամադրել:",
    "enter_results": "Մուտքագրեք թեստի արդյունքները",
    "choose_result": "Ընտրեք այն տարբերակը, որը լավագույնս արտացոլում է ձեր փորձարկման շերտը: Օգնության կարիք ունեմ? Կտտացրեք այստեղ ՝ արդյունքների օրինակ տեսնելու համար:",
    "positive": "Դրական",
    "negative": "Բացասական",
    "unknown": "Անհայտ",
    "clarify": "Ձեր արդյունքների հստակեցում",
    "strip_question": "Ո՞րն էր ձեր տեստերի ժապավենին ամենից շատ նման:",
    "no_lines": "Տողեր չկան",
    "pink_line": "Միայն վարդագույն գիծ",
    "not_sure": "Դեռ վստահ չեմ"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Արդյունքի լուսանկար",
    "retake_photo": "Վերականգնել լուսանկարը",
    "capture_photo": "Լուսանկարել",
    "confirm_information_correct": "Ես հաստատում եմ, որ վերը նշված տեղեկատվությունը ճիշտ է:",
    "submit": "Ներկայացնել",
    "title": "Մուտքագրեք արդյունքները {{name}} -ի համար",
    "subtitle": "Ձեզ հարկավոր չէ ներբեռնել կամ օգտագործել iHealth COVID-19 հավելվածը:",
    "instruction_title": "Դիտեք iHealth-ի հրահանգները",
    "instruction_guide": "Կարդացեք հրահանգները",
    "video_guide": "Դիտեք տեսանյութը",
    "add_photo": "Ավելացնել լուսանկար",
    "confirm_result": "Հաստատեք արդյունքը",
    "result_warning": "Ընտրեք արդյունքը ներկայացնելու համար",
    "confirm_warning": "Հաստատեք արդյունքը՝ ներկայացնելու համար",
    "administered_at_label": "Ե՞րբ եք հանձնել այս թեստը:",
    "instruction_subtitle": "Դիտեք ձեր թեստի հետ բերված հրահանգները կամ վերանայեք հրահանգները այստեղ:"
  },
  "public_test_groups": {
    "title": "COVID-19 թեստ հավաքածուներ",
    "appointment_recoveries_title": "Արդեն գրանցվա՞ծ եք։",
    "appointment_recoveries_button_text": "Գտնել իմ հղումը",
    "search_test_group_title": "Առաջին անգա՞մ եք։",
    "search_test_group_button_text": "Գրանցվել հիմա",
    "title_2": "Պարզ գրանցում և հաշվետվություն",
    "step_one": "Հաշվի գրանցում",
    "step_two": "Վերցրեք թքի նմուշը՝ հետ ուղարկելու համար",
    "step_three": "Տեսնել արդյունքները առցանց",
    "title_3": "Գրանցվեք ձեր հավաքածուն ակտիվացնելու համար",
    "population_title": "գրանցում",
    "population_button": "Ակտիվացնել",
    "faq_subtitle": "Իմանալ ավելին",
    "faq_title": "Հաճախակի տրվող հարցեր",
    "faq_1": "Ինչպե՞ս վերադարձնեմ իմ թեստ հավաքածուն:",
    "faq_1_footer": "Դիտեք SalivaDirect ձագարների հավաքման հրահանգները",
    "faq_2": "Ես արդեն գրանցված եմ, բայց մոռացել եմ իմ հաշիվը:",
    "faq_3": "Ինչպե՞ս կարող եմ ակտիվացնել իմ հավաքածուն:",
    "faq_2_subtitle": "Որպես հաշիվ ունեցող օգտատեր՝ դուք կարող եք հաստատել ձեր հաշվետվությունը՝ օգտագործելով վերևում գտնվող '**Գտնել իմ հղումը**' կոճակը։",
    "faq_3_subtitle": "Անցեք Primary.Health հաշիվ ստեղծելու գործընթացը՝ օգտագործելով հետևյալ տարբերակները.",
    "footer_text_1": "Այս արտադրանքը չի թույլատրվել կամ հաստատվել FDA-ի կողմից, բայց այն լիազորված է FDA-ի կողմից արտակարգ իրավիճակներում օգտագործման համար՝ համաձայն EUA-ի:",
    "footer_text_2": "Այս արտադրանքը թույլատրված է միայն թքի նմուշների հավաքման և պահպանման համար՝ որպես SARS-CoV-2-ից նուկլեինաթթվի հայտնաբերման օժանդակ միջոց, այլ ոչ թե որևէ այլ վիրուսի կամ պաթոգենի:",
    "footer_text_3": "Այս արտադրանքի շտապ օգտագործումը թույլատրվում է միայն պաշտոնական հայտարարության ուժի մեջ լինելու ժամանակահատվածում, երբ կան հանգամանքներ, որոնք հիմնավորում են բժշկական սարքերի շտապ օգտագործման թույլտվությունը՝ համաձայն Սննդի, դեղերի և կոսմետիկայի մասին դաշնային օրենքի 564 (բ) (1) բաժնի, 21 USC § 360bbb-3(b)(1), բացառությամբ այն դեպքերի, եթե պաշտոնական հայտարարությունը չեղարկվի կամ հետ կանչվի:",
    "description": "Ձեր հարմարության համար կարող եք ակտիվացնել ձեր թեստ հավաքածուն՝ օգտագործելով ստորև նշված կոճակները.",
    "subtitle": "Առաջին անգա՞մ եք Primary-ում։",
    "subtitle_1": "Ձեր COVID-19 թեստ հավաքածուների մասին",
    "faq_1_1": "Տեղադրեք փակ նմուշի խողովակը կենսանմուշի տոպրակի մեջ",
    "faq_1_2": "Տեղադրեք կենսանմուշի տոպրակը SalivaDirect պիտակավորված տուփի մեջ, ինչում այն առաքվել է: Տեղադրեք SalivaDirect պիտակով տուփը FedEx UN 3373 Pak-ի մեջ:",
    "faq_1_3": "Հեռացրեք FedEx UN 3373 Pak-ի կպչուն շերտը և ամուր սեղմեք ներքև՝ Pak-ը լավ փակելու համար։",
    "faq_1_4": "Խնդրում ենք վերադարձնել նմուշը՝ այն վերցնելու նույն օրը և մինչև վերջին հավաքման ժամանակը: Հանգստյան օրերին նմուշը մի հանձնեք նմուշի հավաքման տուփ:",
    "faq_1_5": "Այցելեք FedEx-ի վեբկայքը **[fedex.com/labreturns](https://www.fedex.com/labreturns)** տուփերի տեղակայման և վերցնելու ժամանակացույցի համար:",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "Հաղորդել արդյունքի մասին",
    "report_result_subtitle": "Եթե ձեր կազմակերպությունն առաջարկում է ինքնաթեստավորում, և դուք պատրաստ եք զեկուցել արդյունքի մասին, խնդրում ենք սեղմել ստորև նշված կոճակը:",
    "need_help": "Օգնության կարիք ունե՞ք։",
    "assistance": "Եթե օգնության կարիք ունեք կամ ցանկանում եք փոխել ձեր կոնտակտային տվյալները, դիմեք Աջակցության կենտրոն:",
    "contact_support": "Կապվեք աջակցության կենտրոնի հետ",
    "save_link": "Պահպանել այս հղումը՝ արդյունքները ավելի ուշ հաղորդելու համար:",
    "send_via_email": "Ուղարկել հղումն էլեկտրոնային փոստով",
    "send_via_text": "Ուղարկել հղումը SMS տեքստի միջոցով",
    "copy_link": "Պատճենել հղումը",
    "resend_email": "Նորից ուղարկել էլփոստի հաստատումը",
    "phone_verified": "Հեռախոսը ստուգված է",
    "add_vaccination_status": "Ավելացնել/թարմացնել COVID-19-ի դեմ պատվաստման կարգավիճակը",
    "terms_of_service": "Ծառայությունների մատուցման պայմաններ",
    "verify_contact_information": "Ստուգեք ձեր կոնտակտային տվյալներն՝ արդյունքներ և հաղորդագրություններ ստանալու համար: Եթե ​​Ձեզ անհրաժեշտ է փոխել ձեր կոնտակտային տվյալները, խնդրում ենք կապվել մեր Աջակցության կենտրոնի հետ:",
    "resend_text": "Կրկին ուղարկել SMS տեքստային հաստատում",
    "loading": "Բեռնվում է",
    "add_to_account": "Ավելացնել հաշվին",
    "hi": "Ողջույն",
    "email_verified": "էլ․փոստը հաստատված է",
    "go_to_my_account": "Անցնել իմ հաշիվը",
    "activate_kit": "Ակտիվացրեք թեստի հավաքածուն",
    "report_custom_result": "Հաղորդել արդյունքի մասին՝ {{test}}",
    "activate_custom_test": "Ակտիվացրեք թեստը՝ {{test}}",
    "update_vaccination_status": "Պատվաստումների կարգավիճակի թարմացում",
    "order_a_test_kit": "Պատվիրեք թեստի հավաքածու"
  },
  "enter_information": {
    "contact_info": "Նշե՛ք ձեր կոնտակտային տվյալները",
    "label": "Հեռախոս կամ էլ․ փոստ",
    "invalid": "Սա անվավեր էլ․ փոստի հասցե կամ հեռախոսահամար է։",
    "send": "Մենք կուղարկենք ծածկագիրը ձեր հեռախոսահամարին կամ էլ.փոստին",
    "legal": "Մուտքագրելով ձեր հեռախոսահամարը կամ էլփոստը՝ դուք համաձայնում եք Primary Health-ի **[Ծառայության մատուցման պայմանների](https://primary.health/terms-of-service/)** և **[Գաղտնիության քաղաքականությանը](https://primary.health/privacy-policy/)**։",
    "subtitle": "Ձեզ հեռախօսի կամ էլեկտրոնային հաղորդագրութիւն կը ղրկենք՝ որպէսզի օգնենք ձեր արդիւնքները կամ",
    "find_link": "Արձանագրուա՞ծ էք։ Գտէք ձեր հղումը։",
    "not_registered": "Գրանցված չե՞ք։ Որոնեք ձեր կայքը",
    "search_by_keyword": "Որոնեք անունով, բանալի բառով, քաղաքով, փոստային դասիչով կամ կազմակերպության կոդով:",
    "search": "Որոնում",
    "register_here": "Արձանագրուեցէք այստեղ"
  },
  "contact_support": {
    "invalid": "Անվավեր տեղեկատվություն",
    "invalid_explanation": "Դուք վերջերս խնդրել եք Primary.Health մուտք գործելու հղում:\r\nԱյնուամենայնիվ,",
    "never_registered": "Երբևէ չե՞ք գրանցվել: Խնդրում ենք դիմել ձեր թեստավորման մատակարարին գրանցման հղման համար կամ որոնեք ձեր կայքը ստորև:",
    "get_help": "Դուք կարող եք օգնություն ստանալ՝ ձեր գրառումը ձեր կոնտակտային տվյալների հետ համապատասխանեցնելու համար",
    "get_help_calling": "կամ զանգելով",
    "invalid_explanation_bold": "Ձեր տրամադրած տեղեկատվությունը անվավեր էր:",
    "get_help_label": "Օգնություն ստանալ",
    "mobile": {
      "invalid_explanation": "Չի գտնվել օգտվող, ով կարող է ավելացվել ձեր հաշվին։",
      "get_help": "Եթե դուք ունեք հավելյալ հարցեր, խնդրում ենք կապնվել մեզ հետ",
      "reason": "«Առաջնային առողջության»/Primary․Health/ ոչ բոլոր օգտվողներն իրավունք ունեն օգտագործել մեր նոր հաշվի հնարավորությունը. Երբ դուք ստանում եք մասնակցելու իրավունք, դուք կստանաք հրավեր «Առաջնային առողջության»/Primary․Health/ կամ ձեր խմբի ադմինիստրատորի կողմից:"
    },
    "zen_name": "Կոնտանկտի անունը",
    "ticket_type": "Ի՞նչ խնդիր ունեք։",
    "appointment_access_code": "Հաստատման կոդը (եթե գիդեք)",
    "zen_desc": "Նամակ գրել",
    "contact_us": "Հետադարձ կապ",
    "need_assistance": "Եթէ օգնութեան պէտք ունիք, կամ եթէ կ՛ուզէք փոխել ձեր կապ հաստատելու տեղեկութիւնները, մեր հետ կապուեցցէք"
  },
  "show_appointments": {
    "welcome": "Գտնալ ձեր էջը",
    "welcome_subtitle_1": "Հետեւեալ արդիւնքները [for xyz] համար",
    "added_to_account": "Հաշիվն ավելացված է",
    "error": "Հնարավոր է, որ այս մասնակիցը արդեն հաշիվ ունի",
    "welcome_subtitle_2": "Անունը ներքեւը չե՞ք տեսներ",
    "welcome_not_logged_in": "Ընտրէք կայքի «link»ը",
    "welcome_logged_in": "Ընտրէք ձեր խումբը",
    "welcome_subtitle_1_logged_in": "Նոր անդամ աւելցնելու համար, ընտրեցէք անցը եւ խումբը։ Անդամները մէկ հաշիւը կ՛արձանագրուին։",
    "welcome_subtitle_2_logged_in": "Եթէ ձեր անդամը վերեւում չի կայ, կրնաք",
    "welcome_subtitle_1_not_logged_in": "Նշէք ով պէտք է իր վայրը գտնայ կամ նոր անդամ աւելցնէ։ Անդամը չի՞ գտնուեցաւ։",
    "welcome_subtitle_3_logged_in": "ուրիշ կապի միջոց փորձէք",
    "welcome_subtitle_4_logged_in": "կամ մեր հետ կապ հաստատեցցէք",
    "welcome_subtitle_2_not_logged_in": "Փոխել ձեր բնտրածը",
    "welcome_subtitle_3": "Փոխել ձեր որոնումը"
  },
  "enter_code": {
    "code": "Ո՞րն է ծածկագիրը:",
    "enter_code": "Մուտքագրեք ծածկագիրը, որն ուղարկվել է",
    "incorrect_code": "Դուք սխալ ծածկագիր եք մուտքագրել։",
    "verify": "Ստուգեք հաշիվը",
    "verified": "Ստուգված է",
    "verify_account_information": "Հաստատէք ձեր հաշիւը"
  },
  "general_self_checkout": {
    "choose_test": "Ի՞նչ թեստ եք անցել:",
    "photo_required": "Պահանջվում է արդյունքի լուսանկար",
    "find_your_test": "Գտնել թեստը"
  },
  "login": {
    "create_account": "Ստեղծել հաշիվ",
    "create_account_help": "Հաշիվ ստեղծելը նույնը չէ, ինչ թեստավորման կամ պատվաստումների համար գրանցվելը: Երբ դուք գրանցվեք",
    "create_account_help_schedule": "Պլանավորել ժամադրություններ",
    "create_account_help_record": "Գրանցվել թեստի համար տանը",
    "create_account_help_match": "Կառավարել պատվաստումների և թեստավորման գրառումները:",
    "continue": "Շարունակել որպես հյուր",
    "already": "Արդեն հաշիվ ունե՞ք։ Սեղմեք",
    "login": "մուտք գործելու համար",
    "or_sign_in": "Կամ մուտք գործեք հետևյալով՝",
    "no_account": "Չունե՞ք հաշիվ: Սեղմեք",
    "signup": "գրանցվելու համար:",
    "here": "այստեղ",
    "email_address": "էլ․ փոստ՝",
    "password": "Գաղտնաբառ՝",
    "complete_registration": "Ավարտել գրանցումը",
    "last_covid_test": "Վերջին COVID-19-ի թեստի արդյունքը՝",
    "no_record": "Գրառումներ չկան",
    "viewing_information": "Դիտվում են {{first_name}}-ի տվյալները",
    "download": "Ներբեռնել",
    "not_registered": "Կարծես թե գրանցված չեք որևէ խմբում",
    "please_follow_link": "Խնդրում ենք սեղմել ձեր էլ. փոստի հրավերի հղումը՝ պլանավորելու և մուտք գործելու ձեր տվյալները Primary.Health-ում:",
    "log_results": "Գրանցել իմ թեստի արդյունքները",
    "book_appointment": "Ամրագրել ժամադրություն",
    "no_new_appointments": "Ներկայումս հնարավոր չէ նշանակել նոր ժամադրություններ",
    "upload_vaccinations": "Վերբեռնել պատվաստումները",
    "vaccination": "պատվաստումը",
    "new_appointment": "Նոր ժամադրություն",
    "log_otc_results": "Գրանցել արդյունքները OTC թեստի համար",
    "no_household_members": "Դուք դեռ չեք ավելացրել ընտանիքի անդամ:",
    "no_upcoming": "Առաջիկա ժամադրություն չկա",
    "update_vaccination": "Թարմացնել պատվաստումը",
    "upload_vaccination": "Վերբեռնել պատվաստումը",
    "title": "Բարի գալուստ Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "Հաշիվ ստեղծելը թույլ է տալիս.",
    "p2": "Պլանավորել ապագա ժամադրություններ",
    "p3": "Գրանցել COVID-19-ի տանը անցկացված թեստերը",
    "p4": "Կառավաել կախված պատվաստումները և թեստավորման գրառումները",
    "p5": "Պարզ մուտք գործելու գործընթաց my.primary.health-ից",
    "no_vaccination_record": "Պատվաստներ դեռ չկան ավելացրած",
    "vaccination_title_card": "COVID-19 պատվաստման մասին տվյալները",
    "account_and_settings": "Հաշիվ և կարգավորումուներ",
    "delete_account": "Ջնջել հաշիվը",
    "language": "Ընտրեք լեզու",
    "language_current": "Լեզու. {{language}}",
    "log_out": "Դուրս գալ",
    "delete_account_title": "Հաստա՞տ ուզում եգ ջնջել ձեր հաշիվը։",
    "new_encounter": "{{first_name}}-ի նոր հանդիպումը",
    "new_encounter_subtitle": "Ո՞ր խմբի համար է այս նոր հանդիպումը։",
    "no_programs": "Ոնց որ թե՝ դուք չեք գրանցված ոչ մի ծրագրերում",
    "edit_members": "Փոփոխել անդամներին",
    "edit_members_subtitle": "Ընտրե՛ք որ էջն եք ուզում կամ ավելացրեք ուրիշ անդամ ձեր հաշիվին։",
    "add_members": "Ավելացնել անդամ",
    "delete_member": "Ջնջել {{full_name}}-ի հաշիվը",
    "delete_member_subtitle": "Հաշիվը ջնջելուց նաև կջնջվեն բոլոր նրա հետ կապված տվյալները։",
    "select_member": "Անդամի ընտրություն",
    "edit_appointment": "Փոփոխել նշանակումը",
    "route": {
      "me": "Հիմնական էջը",
      "history": "Պատմություն",
      "programs": "Ավելացնել թեսթ",
      "contact_us": "Օգնություն",
      "choose_member": "Անդամ",
      "settings": "Կարգավորումներ",
      "help": "Օգնություն"
    },
    "user_dashboard": "{{first_name}}-ի հիմնական էջը",
    "view_full_results": "Նայել արդյունքները պորտալի մեջ",
    "required": "Պահանջվում է, եթե դուք օգտագործում եք Primary հավելվածը։",
    "no_email": "Ես էլփոստի հասցե չունեմ"
  },
  "vaccination_status": {
    "not_vaccinated": "Պատվաստված չեմ",
    "fully_vaccinated": "Լիովին պատվաստված",
    "partially_vaccinated": "Մասամբ պատվաստված",
    "vaccination_records": "Պատվաստումների մասին գրառումները",
    "title": "Ո՞րն է ձեր COVID-19-ի պատվաստման կարգավիճակը:",
    "definition_title": "Պատվաստման կարգավիճակի սահմանումներ",
    "definition_cdc": "CDC պատվաստումների կարգավիճակի սահմանում",
    "definition_osha": "OSHA պատվաստումների կարգավիճակի սահմանում",
    "definition": {
      "fully_vaccinated": "Pfizer-BioNTech կամ Moderna պատվաստանյութերի 2 դոզայից կամ Johnson & Johnson Janssen պատվաստանյութի 1 դոզայից բաղկացած վերջին դեղաչափից 2 շաբաթ անց:",
      "partially_vaccinated": "Ստացել եմ հիմնական սերիայի 2 դոզաններից միայն 1-ը կամ 2 դոզային սերիայի վերջին դեղաչափից 2 շաբաթ անց կամ Johnson & Johnson's Janssen-ի 1 դոզան:",
      "not_vaccinated": "COVID-19-ի դեմ պատվաստանյութ չեմ ստացել։",
      "fully_vaccinated_with_one_booster": "Ստացել եմ Pfizer-BioNTech կամ Moderna պատվաստանյութերի խթանիչ դոզան՝ ամբողջական սերիայից հետո կամ Johnson & Johnson's Janssen պատվաստանյութի 1 դոզա:",
      "fully_vaccinated_with_two_boosters": "Ստացել եմ Pfizer-BioNTech կամ Moderna պատվաստանյութերի երկրորդ խթանիչ չափաբաժինը Johnson & Johnson's Janssen պատվաստանյութի ամբողջական սերիայից կամ չափաբաժնից հետո:"
    },
    "label": {
      "fully_vaccinated": "Լիովին պատվաստված եմ",
      "partially_vaccinated": "Մասամբ պատվաստված եմ",
      "not_vaccinated": "Պատվաստված չեմ",
      "fully_vaccinated_with_one_booster": "Լիովին պատվաստված + խթանիչ դեղաչափ",
      "fully_vaccinated_with_two_boosters": "Լիովին պատվաստված + 2 խթանիչ դեղաչափ",
      "prefer_not_to_answer": "Նախընտրում եմ չպատասխանել"
    },
    "progress_bar_title": "COVID-19 պատվաստումների պատմություն",
    "upload_record_card": "Վերբեռնեք ձեր COVID-19 պատվաստումների գրանցման քարտը",
    "show_example": "Ցույց տալ օրինակը",
    "hide_example": "Թաքցնել օրինակը",
    "take_photo": "Սեղմեք՝ լուսանկարելու համար",
    "or": "ԿԱՄ",
    "upload_image_or_pdf_instead": "Փոխարենը վերբեռնեք պատկեր/PDF",
    "select_doses_received": "Ընտրեք ձեր ստացած բոլոր COVID-19 պատվաստումների դեղաչափերը։",
    "first_dose": "Առաջին դեղաչափ",
    "second_dose": "Երկրորդ դեղաչափ",
    "first_booster_dose": "Առաջին խթանիչ դեղաչափ",
    "second_booster_dose": "Երկրորդ խթանիչ դեղաչափ",
    "additional_dose": "Լրացուցիչ դեղաչափ",
    "first_dose_manufacturer": "Ո՞վ էր COVID-19-ի ձեր առաջին դեղաչափի արտադրողը:",
    "first_dose_date": "Ո՞րն էր ձեր առաջին դեղաչափի ամսաթիվը:",
    "second_dose_manufacturer": "Ո՞վ էր COVID-19-ի ձեր երկրորդ դեղաչափի արտադրողը:",
    "second_dose_date": "Ո՞րն էր ձեր երկրորդ դեղաչափի ամսաթիվը:",
    "first_booster_dose_manufacturer": "Ո՞վ է եղել COVID-19-ի ձեր առաջին խթանիչ դեղաչափի արտադրողը:",
    "first_booster_dose_date": "Ո՞րն էր ձեր առաջին խթանիչ դեղաչափի ամսաթիվը:",
    "second_booster_dose_manufacturer": "Ո՞վ էր COVID-19-ի արտադրողը ձեր երկրորդ խթանիչ դեղաչափի համար:",
    "second_booster_dose_date": "Ո՞րն էր ձեր երկրորդ խթանիչ դեղաչափի ամսաթիվը:",
    "additional_dose_manufacturer": "Ո՞վ է եղել COVID-19-ի ձեր լրացուցիչ դեղաչափի արտադրողը:",
    "additional_dose_date": "Ո՞րն էր ձեր լրացուցիչ դեղաչափի ամսաթիվը:",
    "completed_one": "Լրացվել է",
    "completed_two": "Շնորհակալություն ձեր պատվաստման կարգավիճակը թարմացնելու համար:",
    "progress_bar_complete": "Լրացնել",
    "upload_image": "Վերբեռնել պատկեր",
    "retake_photo": "Կրկին լուսանկարել",
    "other": "Այլ",
    "remove_first_dose": "Հեռացնել առաջին դեղաչափի պատմությունը",
    "remove_second_dose": "Հեռացնել երկրորդ դեղաչափի պատմությունը",
    "remove_first_booster_dose": "Հեռացնել առաջին խթանիչ դեղաչափի պատմությունը",
    "remove_second_booster_dose": "Հեռացնել երկրորդ խթանիչ դեղաչափի պատմությունը",
    "remove_additional_dose": "Հեռացնել լրացուցիչ դեղաչափի պատմությունը",
    "exemption": "Դուք ազատում ունե՞ք:",
    "exempt": "Ես ազատված եմ",
    "not_exempt": "Ես ազատված չեմ",
    "enter_exemption": "Մուտքագրել ազատումը",
    "upload_exemption_documentation": "Վերբեռնեք փաստաթղթեր ձեր ազատման համար",
    "remove_dose": "Հեռացնել դեղաչափը",
    "continue": "Շարունակել",
    "enter_credentials": "Մուտքագրեք ձեր մուտքագրման տվյալները",
    "incorrect_credentials": "Սխալ մուտքագրման տվյալներ, կրկին փորձեք:",
    "add_photo": "Ավելացնել լուսանկար"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Բարև {{name}}, Մենք նկատեցինք, որ դուք չեք ավարտել ձեր {{group_name}} ժամադրությունը {{org_name}}-ի հետ: Ձեր ժամադրությունը պահպանելու համար դուք պետք է ավարտեք վճարման քայլը: Սեղմեք այս հղումը՝ {{complete_url}} գործընթացը ավարտելու համար: Եթե ​​ցանկանում եք չեղարկել ձեր ժամադրությունը, սեղմեք այս {{cancel_url}} հղման վրա: Շնորհակալություն։"
    }
  },
  "otc": {
    "record_results_for_abbott": "Փորձարկեք և գրանցեք ձեր արդյունքները BinaxNOW թեստի համար",
    "record_results": "Փորձարկեք և գրանցեք ձեր արդյունքները",
    "activate_a_kit": "Ակտիվացրեք հավաքածուն",
    "activate_a_kit_for_test": "Ակտիվացրեք հավաքածուն {{test}}-ի համար",
    "todays_test_process": "Այսօրվա թեստավորման գործընթացը՝",
    "date_of_birth_question": "Ո՞րն է {{first_name}}-ի ծննդյան ամսաթիվը:",
    "confirm_dob_to_continue": "Շարունակելու համար խնդրում ենք հաստատել մասնակցի ծննդյան ամսաթիվը՝",
    "incorrect_dob": "Սխալ ծննդյան ամսաթիվ",
    "file_too_large": "Խնդրում ենք համոզվել, որ վերբեռնված ֆայլի չափը փոքր է, քան {{size}}",
    "enter_valid_date_in_last_x_days": "Մուտքագրեք վավեր ամսաթիվ վերջին {{n}} օրվա ընթացքում",
    "barcode_format_not_valid": "Շտրիխ կոդի ձևաչափը վավեր չէ",
    "complete_button": "Ավարտել",
    "next_button": "Հաջորդ",
    "back_button": "Վերադառնալ",
    "report_a_test": "Հաղորդել թեստի մասին՝",
    "x_%_completed": "{{n}}% ավարտված է",
    "answer_survey": "Պատասխանել հարցման հարցերին",
    "steps": {
      "answer_survey": "Պատասխանել հարցմանը",
      "review_instructions": "Վերանայել հրահանգները",
      "scan_code": "Սկանավորեք կոդը",
      "enter_time": "Մուտքագրեք ժամը",
      "enter_result": "Մուտքագրեք արդյունքը",
      "take_photo": "Լուսանկարել",
      "confirm_result": "Հաստատել արդյունքը",
      "selest_test_kit": "Ընտրեք թեստի տեսակը",
      "enter_date": "Մուտքագրեք ամսաթիվը",
      "enter_date_and_time": "Մուտքագրեք ամսաթիվը և ժամը"
    },
    "review_instructions": "Վերանայել թեստի հրահանգները",
    "follow_instructions_or_below": "Հետևեք ձեր թեստի հրահանգներին կամ տե՛ս ստորև ներկայացված հրահանգները:",
    "watch_video": "Դիտել տեսանյութը",
    "view_instructions": "Դիտել հրահանգները",
    "follow_instructions": "Հետևեք ձեր թեստի հրահանգներին:",
    "scan_code": "Սկանավորեք թեստի QR կոդը",
    "qr_code_description": "QR կոդը եզակի է ձեր թեստի համար/ այն կարող եք գտնել ձեր թեստի վերևի աջ անկյունում",
    "show_example": "Ցույց տալ օրինակ",
    "hide_example": "Թաքցնել օրինակը",
    "barcode_scanned": "Շտրիխ կոդը հաջողությամբ սկանավորվեց",
    "enter_barcode": "Մուտքագրեք շտրիխ կոդը",
    "scan_qr_code": "Սկանավորեք QR կոդը",
    "which_test_did_you_take": "Ո՞ր թեստն եք հանձնել:",
    "when_did_you_take_test": "Ե՞րբ եք հանձնել այս թեստը:",
    "what_day_did_you_take_test": "Ո՞ր օրն եք հանձնել այս թեստը:",
    "what_time_did_you_take_test": "Ո՞ր ժամին եք հանձնել այս թեստը:",
    "time": {
      "today": "Այսօր",
      "yesterday": "Երեկ",
      "two_days_ago": "2 օր առաջ"
    },
    "enter_current_time": "Մուտքագրեք ընթացիկ ժամանակը",
    "enter_result": "Մուտքագրեք թեստի արդյունքը",
    "choose_result_option": "Ընտրեք այն տարբերակը, որը նկարագրում է ձեր թեստավորման քարտի արդյունքը.",
    "clarify_results": "Հստակեցրեք ձեր արդյունքները",
    "take_result_photo": "Լուսանկարեք արդյունքները",
    "take_result_photo_description": "Լուսանկարեք լրացված արդյունքների քարտը՝ ձեր արդյունքները գրանցելու համար",
    "my_result": "Իմ արդյունքը",
    "switch_camera": "Միացնել տեսախցիկը",
    "confirm_result": "Հաստատել արդյունքը",
    "name": "Անուն՝",
    "date": "Ամսաթիվ՝",
    "result": "Արդյունք՝",
    "results": "Արդյունքներ",
    "test_submitted": "Շնորհակալություն, {{first_name}}: Ձեր {{test}}-ը հաջողությամբ ներկայացվել է:",
    "dob": "Ծննդյան ամսաթիվ՝",
    "administered": "Նշանակվել է՝:",
    "or": "ԿԱՄ",
    "upload": "Վերբեռնել",
    "change_uploaded_file": "Փոխել բեռնված ֆայլը",
    "take_photo": "Լուսանկարեք",
    "confirm_information": "Հաստատել տեղեկատվությունը",
    "barcode": "Շտրիխ կոդ՝",
    "scan_test_barcode": "Սկանավորեք թեստի շտրիխ կոդը",
    "barcode_description": "Շտրիխ կոդը եզակի է ձեր թեստի համար․ այն կարող եք գտնել ձեր թեստի վրա",
    "enter_date_in_last_30_days": "Մուտքագրեք վերջին 30 օրվա ամսաթիվը և թեստի անցկացման մոտավոր ժամը",
    "add_image": "Ավելացնել պատկեր",
    "change_image": "Փոխել պատկերը",
    "skip_barcode_reader": "Չե՞ք կարող սկանավորել կոդը: Սեղմեք «Հաջորդ»՝ թեստի արդյունքը հաղորդելու համար",
    "enter_date_and_time": "Մուտքագրեք թեստի ամսաթիվը և ժամը",
    "enter_date": "Մուտքագրեք թեստի ամսաթիվը",
    "did_you_take_test_today": "Դուք այսօր հանձնե՞լ եք քննությունը.",
    "record_results_for_generic": "Փորձէք եւ նշէք ձեր արդիւնքները {{name}}  քննութեան համար",
    "choose_result_option_custom": "Ամբողջացնել գրանձում"
  },
  "yes": "Այո",
  "no": "Ոչ",
  "event_token": {
    "title": "Խնդրում ենք մուտքագրել ստորև նշված կոդը վաճառող մեքենայի մեջ՝ թեստի հավաքածուն ստանալու համար",
    "loading_text": "Բեռնվում է, խնդրում ենք նորից ստուգել մեկ րոպեից"
  },
  "appointment_recovery": {
    "complete_title": "Պատրաստ էք",
    "subtitle_1": "Եթե օգտագործում եք iPhone, խնդրում ենք սեղմել **Done** այս էջի վերևի ձախ կողմին որ վերադարնալ ձեր հիմնական էջը։",
    "subtitle_2": "Եթե օգտագործում եք Android, խնդրում ենք սեղմել **X** այս էջի վերևի ձախ կողմին։",
    "subtitle_0": "Խնդրում ենգ դուրս գալ բրաուզերից որ շարունակել։"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Համաձայնեցված հարաբերություններ",
  "finish_registration": {
    "title": "Ավարտեք ձեր գրանցումը",
    "subtitle": "Լրացնեք հետևյալ տեղեկությունները նախքան ձեր նշանակումը.",
    "button": "Լրացնել գրանձումը"
  },
  "arab_ethnicity": {
    "arab": "Արաբ",
    "non_arab": "Ոչ արաբ",
    "prefer_not_to_answer": "Նախընտրում եմ չպատասխանել",
    "question": "Արաբական պատկանելություն",
    "subtitle": "Կանոնակարգերը պահանջում են, որ մենք հավաքենք հետևյալ տեղեկատվությունը։",
    "help": "Էթնիկ պատկանելությունը վերաբերում է ընդհանուր հատկանիշների մի շարքին, ինչպիսիք են լեզուն, մշակութային սովորույթները, կրոնը և այլ հատկանիշներ, որոնք օգտագործվում են մարդկանց խմբերը տարբերելու համար: Այն կարող է համընկնել կամ չհամընկնել նաև ռասայական ինքնության հետ:"
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Ձեր համակարգիչին կամ հեռախօսին համեմատ, ընտրեցցէք ներքեւի հղումը ստանալու ձեր Առաջնային Առողջապահութեան Տոմսը։"
    }
  },
  "stepper": {
    "next": "Հաջորդը",
    "back": "Վերադառնալ",
    "complete": "Լրացնել"
  },
  "registration_direcory": {
    "keyword_search_label": "Հիմնաբառ, քաղաք, փոստային թիւ. կազմակերպութեան անուն",
    "keyword_search_label_mobile": "քաղաք, փոստային թիւ. կազմակերպութեան անուն",
    "zero_results": {
      "title": "Չի գտանք ոեւէ բժիշկ այդ անունով",
      "sub_title": "Փորձէք ձեր բժիշկը փնտրել հետեւեալով՝",
      "p1": "Փոստային թիւ",
      "p2": "Քաղաքի անուն",
      "p3": "Մարզի (county) անուն",
      "p4": "Բժիշկի կամ կազմակերպութեան անուն"
    }
  },
  "registration_flyer": {
    "open_camera": "Բացեք տեսախցիկի հավելվածը ձեր սարքում",
    "point_to_qr_code": "Ողղեք QR կոդի վրա և հպեք հղմանը",
    "complete_registration": "Ավարտեք գրանցումը",
    "need_help": "Օգնության կարիք ունե՞ք։ Կապվեք մեզ հետ՝"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Պատուաստանիւթի տեղեկութեան երդում",
    "select_checkbox": "Կը խնդրենք որ շարունակելէ առաժ հետեւեալը կարդաք եւ ընտրէք յարմար տաբերակը։",
    "opportunity_to_read": "Կարդացած եմ կամ առիթը ունեցած եմ կարդալու, կամ մէկը ինծի համար կարդաց, «Պատուաստանիւթի Տեղեկութեան Յայտարարութիւնը» կամ «Տագնապի Օգտագործման Արտօնութեան» փաստաթերթը, որոնք ինծ տրամադրուեցան որպէսզի պատուաստանիւթը ինծի տրուի։",
    "vaccine_info_sheet": "Պատուաստանիւթի տեղեկութեան թերթ",
    "vaccine_fact_sheet": "Պատուաստանիւթի փաստաթերթ",
    "vaccine_info_statement": "Պատուաստանիւթի տեղեկութեան յայտարարութիւն"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap պատուաստանիւթեր (Դիֆթերիա, Փայտացում եւ Կապոյտ հազի համար)",
    "mmr": "Կարմրուկ, կարմրախտ և խոզուկ (ԿԿԽ)",
    "ipv_opv": "Պոլիովիրուս",
    "hib": "Հեմոֆիլուս ինֆլուենզա b տիպի (Hib)",
    "hep_b": "Հեպատիտ B",
    "var": "Վարիսելլա (Սուտ ծաղկախտ)",
    "hep_a": "Հեպատիտ Ա",
    "pcv": "Խոզի ցիրկովիրուս",
    "mcv": "MCV վիրուս"
  },
  "test_strip": {
    "A": "this kit includes tests for chlamydia and gonorrhea.",
    "B": "this kit includes tests for chlamydia and gonorrhea.",
    "C": "this kit includes tests for syphilis.",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "this kit includes tests for HIV and syphilis.",
    "box_type": "Box {{type}}",
    "administered_text": "We will contact you when your kit reaches the lab and when your results are ready.",
    "activate_kit": "Activate my test kit",
    "register_another_test": "Register another test",
    "credentials": {
      "title": "Let's get started",
      "label": "Contact",
      "p1": "We'll start with some info about you.",
      "location_step": {
        "p1": "Who provided your test kit?",
        "load": "Load more"
      }
    },
    "checklist": {
      "not_urinated": "You have not urinated in the last hour.",
      "bathroom": "You have access to a bathroom for urine collection.",
      "anal_swab": "You have access to a private place to perform an anal swab.",
      "blood_extraction": "You have access to a clean environment for blood extraction.",
      "washed_hands": "You have washed your hands with soap and warm water for 30 seconds.",
      "title": "Are you ready to begin sample collection?",
      "no_alcohol": "Do not drink alcohol on the day of collection.",
      "menstruation": "Do not collect vaginal swab specimen during menstruation or within 24 hours of intercourse."
    },
    "confirm": {
      "title": "Confirm your information",
      "p1": "Is this information correct?"
    },
    "display_name": {
      "A": "Chlamydia & Gonorrhea (Single Site)",
      "B": "Chlamydia & Gonorrhea (3 Site)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia & Gonorrhea",
        "B": "Chlamydia & Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "COVID-19",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV"
    },
    "rescan": "Rescan",
    "scanned": "You've scanned Box {{type}}",
    "not_yet": "Not Yet",
    "success_registration": "Registration success!",
    "no_box": "Don't have a box?",
    "faq_link": "Visit FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "You're one step closer to a healthier you.",
      "p1": "Scan or enter the **kit bar code** on the side of your box."
    },
    "appointments": {
      "title": "Select the patient & provider",
      "p1": "We found more than one profile associated with your contact information. Select the correct profile:",
      "new": "New patient or provider"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "We found your patient record."
    },
    "section_1": "Confirm kit contents",
    "section_2": "Test collection",
    "section_3": "Packaging",
    "section_4": "Shipping",
    "box_a": "Box A",
    "box_b": "Box B",
    "box_c": "Box C",
    "kit_flow": {
      "button_1": "Continue to packaging",
      "button_2": "I'm ready",
      "button_3": "My kit is complete and packed",
      "button_4": "I'm finished",
      "button_5": "Next test",
      "button_6": "Skip instructions"
    },
    "contents": {
      "header": "Great! Let's make sure your kit is complete",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Blood collection card",
      "blood_collection_card_subtitle": "(1 or 2 depending on test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Single use lancets",
      "up_to_other": "(Up to 4 depending on test kit)",
      "adhesive_bandages": "Adhesive bandages",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Biodegradable collection paper",
      "bottle_and_probe": "Sampling bottle and probe",
      "prepaid_envelope": "Pre-paid shipping envelope",
      "biohazard_subtitle": "(with absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Biohazard bags",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 sterile gauze pad",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "Packaging checklist",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "The specimen collection tube is sealed in its biohazard bag",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_b": {
      "step_1": "You have packed all test kit contents, even if you skipped the test(s)",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "Each specimen collection tube is sealed in its own biohazard bag (only **one** specimen per bag)",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_c": {
      "step_1": "You have packed all test kit contents, including used lancets.",
      "step_2": "Your **date-of-birth** is written on the blood collection card using MM/DD/YYYY format",
      "step_3": "The **collection date** is written on the blood collection card using MM/DD/YYYY format",
      "step_4": "The blood collection card and all used lancets are placed in the biohazard bag",
      "step_5": "The biohazard bag is placed into the box",
      "step_6": "The box is placed into the pre-paid shipping envelope",
      "step_7": "The return shipping envelope is completely sealed"
    },
    "success": {
      "header_1": "Great!",
      "text_1": "You're ready to collect your first sample.",
      "header_2": "You did it!",
      "text_2": "**Next up:** package your kit to send back.",
      "header_3": "Great job!",
      "text_3": "You’re one step closer to better health.",
      "header_4": "Nice job!",
      "text_4": "Just one more and you’re done!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "Select a test to view instructions:",
      "subtitle": "Test",
      "button": "Instructions",
      "pdf": "PDF instructions"
    },
    "instructions_box_a": {
      "title": "Urine collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
      "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
      "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
      "step_4_sublabel": "Do not fill tube past the maximum fill line or specimen will be rejected.",
      "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
      "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Urine collection",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
        "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
        "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
        "step_4_subtitle": "Do not fill tube past the maximum fill line or specimen will be rejected.",
        "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab into your mouth and rub the swab tip against the back of your throat for 10 seconds.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a blue <span style=\"color: #001F70\">**\"ORAL\"**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab 3 - 5 cm (1 - 2”) into the anal canal. Gently turn the swab for 5 - 10 seconds to collect any potential organisms.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a green <span style=\"color: #00C08C\">**“ANAL”**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      }
    },
    "instructions_box_c": {
      "title": "Blood collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your name, date of birth, and the date of collection in the designated fields. Use MM/DD/YYYY format.",
      "step_2": "Open blood card flap to expose the circles on the blood collection paper. Do not touch the blood collection paper.",
      "step_3": "Wash hands with warm water for at least 30 seconds, then shake hands vigorously for 15 seconds to encourage blood flow to your fingers.",
      "step_4": "Clean fingertip with alcohol pad. It is best to use the middle or ring finger of your non-dominant hand.",
      "step_5": "Take the lancet and twist off the cap. Press the small tip firmly into the tip of your finger, until the needle ejects with a click. Keeping your hand below your heart during collection, massage finger from base to tip to encourage blood flow.",
      "step_5_subtitle": "Lancets are single use. All lancets need to be returned with your sample to the laboratory for disposal.",
      "step_6": "Starting at the center, apply 3-6 drops to fill the circle and soak through the collection paper. Do not touch the paper with your finger as this will restrict blood flow. Once circle is full, move on to next circle. It is okay for blood to extend beyond lines, but do not let blood spots spread into each other.",
      "step_6_subtitle": "Do not add additional blood to a circle once completed or dry. The “layering” of blood will invalidate the collection.",
      "step_7": "Without closing the blood card, lay it on a flat surface and allow the blood collection paper to air dry at room temperature, for at least 30 minutes. Do not heat, blow dry, or expose the blood collection paper to direct sunlight. Heat will damage the specimen.",
      "step_7_subtitle": "Check the back side of blood collection paper. Blood should saturate all the way through and fill each circle of the collection paper.",
      "step_8": "When blood collection paper is dry, close blood card by tucking flap. Place the blood card and lancets into biohazard bag with the desiccant pack. Ensure biohazard bag is properly sealed."
    },
    "test_tips": {
      "title": "Tips for Proper Blood Collection",
      "subtitle": "For best results:",
      "step_1": "Be sure you are not dehydrated while performing collection. Hydration promotes blood flow.",
      "step_2": "Do not perform collection immediately after smoking.",
      "step_3": "Washing and warming your hands under warm water will help promote blood flow in your hands.",
      "step_4": "Shake hands vigorously towards the floor to encourage blood flow to your fingers.",
      "step_5": "Keep collection device and hands below your heart during collection for best blood flow.",
      "step_6": "You may need more than one finger prick. Repeat these tips between each finger prick."
    },
    "shipping": {
      "header": "Your kit is ready to ship!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "Pick up your recommended test kit from the site staff and register below",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "View results",
    "recommended_kit_title": "Recommended Test Kits",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection",
      "subtitle": "Collection tubes may contain a liquid preservative. <span class=\"text-danger\">**Do not empty liquid preservative from the collection tubes if present.**</span>",
      "step_1": "Hold the swab between the faint score line (if present) and the cotton-tipped portion of the swab in one hand and separate the labia (folds of skin around the vaginal opening).",
      "step_2": "Insert the swab 5cm (2in) into the vaginal opening. Gently turn the swab for 30 seconds while rubbing the swab against the walls of the vagina.",
      "step_3": "Carefully withdraw the swab and place into the collection tube marked with a <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Break the swab by bending against the collection tube.",
      "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_5": "Write your date of birth in MM/DD/YYYY format onto the tube in the designated area (DOB).",
      "step_6": "Write the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area (Coll. Date).",
      "step_7": "Place specimen into the empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "packaging_box_h": {
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "Your **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_6": "The biohazard bag is placed into the box"
    },
    "instructions_box_cc": {
      "title": "Stool collection",
      "step_1": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area. Open green cap by twisting and lifting.",
      "step_2": "Place supplied collection paper into toilet bowl on top of water.",
      "step_3": "Deposit stool sample on top of collection paper.",
      "step_4": "Collect sample from stool before paper sinks and stool sample touches water.",
      "step_5": "Scrape the surface of the fecal sample with the sample probe.",
      "step_6": "Cover the grooved portion of the sample probe completely with stool sample.",
      "step_7": "Close sampling bottle by inserting sample probe and snapping green cap on tightly. Do not reopen.",
      "step_8": "Flush. Collection paper is biodegradable and will not harm septic systems.",
      "step_9": "Wrap sampling bottle in absorbent pad and insert in to  the biohazard bag."
    },
    "contact_support": {
      "title": "Let's fix this",
      "text_1": "We are sorry to hear that something is wrong!",
      "text_2": "Please contact us to let us know what’s wrong and we will help to replace your kit."
    },
    "contact_support_success": {
      "title": "We received your message",
      "text_1": "Thanks for contacting us.",
      "text_2": "A member of our support team will be in touch soon.",
      "button_text": "Return home"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B."
  },
  "copy_link": "Ընդօրինակալ էջի «link»ը",
  "copied_link": "«link»ը ընդօրինակուեցաւ",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Պաշտպանութիւն հասարակ հարբուխ/գրիպի վիրուսներէ ",
      "vaccine_covid": "«COVID-19» Կորոնավիրուս համաճարակէն պաշտպանութիւն",
      "tdap": "Դիֆթերիա, տետանոս եւ կապոյտ հազի պատուաստանիւթը կը կանխէ տետանոսը",
      "polio": "Պաշտպանութիւն պոլիովիրուսէ։ Պարտադիր է երեխաներուն համար նախկան դպրոց սկսիլը։",
      "varicella": "Պատուաստանիւթ մը որ կը պաշտպանէ սուտ ծաղկախտէ",
      "shingles": "Պատուաստանիւթ մը որ կը պաշտպանէ սողնախտէ (shingles)։ Նուազագոյն տարիք՝ 18 տարեկան: ",
      "human_papillomavirus": "Պատուաստանիւթ մը որ կը պաշտպանէ Մարդու պապիլոմավիրուս (HPV)է։ Կը թելադրենք որ երեխաները 11 տարեկանին ստանան։ ",
      "meningococcal": "Պատուաստանիւթը կը պաշտպանէ չորս տեսակի մենինգոկոկային բակտերիաներէ։"
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Գտնել կլինիկա ",
    "select_pin": "Ընտրեցցէք գնդասեղ մը մանրամասնութիւններ տեսնելու",
    "miles_shorten": "{{miles}} մղոն",
    "next_available": "Յաջորդ",
    "appointment_type": "Ժամադրութեան տեսակ",
    "individual_appointment": "Անհատական ժամադրութիւն",
    "family_appointment": "Ընտանեկան ժամադրութիւն",
    "vaccines": "Պատուաստանիւթեր",
    "what_kind_appointment": "Ի՞նչ տեսակի ժամադրութիւն կ՛ուզէք։",
    "select_family_or_individual": "Եթէ մէկէն աւելի ընտանիքի անդամի համար կը փորձէք ժամադրութիւն առնել, ընտրեցէք «ընտանիքի ժամադրութիւն»",
    "vaccines_selected": "Ընտրուած պատուաստանիւթեր",
    "where_care": "Ու՞ր կ՛ուզէք բուժում ստանալ։",
    "select_vaccines_for_individual": "Ընտրեցէք պատուաստանիւթեր ձեր ժամադրութեան համար (պարտադիր չէ)",
    "select_vaccines_for_family": "Ընտրեցէք պատուաստանիւթեր ձեր ընտանեկան ժամադրութեան համար (պարտադիր չէ)",
    "schedule_services": "Ծառայութիւններու ժամադրութիւն",
    "add_family": "Ներքեւը աւելցուցէք ընտանիքի անդամներ եւ կարգ մը ծառայութիւններ",
    "family_member": "Ընտանիքի անդամ {{number}}",
    "morning": "Առտու",
    "afternoon": "Կէսօր",
    "slot_available": "{{number}} առկայ են",
    "within_x_miles": "{{miles}} մղոն հեռաւորութեամբ",
    "any_distance": "Որեւէ հեռաւորութեան",
    "partial_results": "Արդիւնքներու մէկ մասը",
    "partial_matches": "Հետեւեալ կլինիկանները ունին ձեր փնտռած պատուաստումներէն մի քանի հատը",
    "no_matches": "Ձեր փնտռածը չի գտանք։ Փորձէք փոխել ձեր փնտռածը աւելի լաւ արդիւնքներ ստանալու",
    "no_clinics_found": "Որեւէ կլինիկա չի կայ ձեր փնտռածին համեմատ",
    "broaden_filters": "Փորձէք ընդարձակել ձեր փնտռածը աւելի լաւ արդիւնքներ ստանալու համար",
    "unavailable_vaccines": "Անհասանելի պատուաստանիւթեր՝",
    "available_vaccines": "Հասանելի պատուաստանիւթեր՝",
    "select_date": "Ընտրեցէք թուական մը",
    "available_appointments": "ՀԱՍԱՆԵԼԻ ԺԱՄԱԴՐՈՒԹԻՒՆՆԵՐ",
    "appointment_scheduling_info": "Առիթը կ՛ունենաք ժամադրութեան ժամեր արձանագրել իւրաքանչիւր ընտանիքի անդամի համար յաջորդ քայլին մէջ։ Եթէ փափաքիք՝ կրնաք ժամադրութիւններ առնել տարբեր օրերով։",
    "hold_selected_appointments": "Ձեր ընտրած ժամադրութիւնը 15 րոպէ կը պահենք։",
    "appointments_selected": "ԸՆՏՐԱԾ ԺԱՄԱԴՐՈՒԹԻՒՆՆԵՐ",
    "no_appointments_selected": "Ժամադրութիւններ չեն ընտրուած",
    "vaccines_needed": "Պէտք ունեցած պատուաստանիւթեր ",
    "select_x_appointments": "Ընտրէք {{number}} ժամադրութիւններ",
    "more": "Աւելի",
    "less": "Նուազ",
    "register_next_family_member": "Արձանագրէ յաջորդ ընտանիքի անդամը",
    "successfully_registered_x_of_y_family_members": "Յաջողութեամբ արձանագրեցիք {{y}} ընտանիքի անդամներէ {{x}} հատը",
    "next_family_member": "Յաջորդ ընտանիքի անդամը",
    "appointments_abbreviated": "ժամադրութիւններ",
    "register_for_clinic": "Արձանագրուիր կլինիկայի համար",
    "select_services": "Ընտրէ ծառայութիւններ",
    "person": "Անձի {{թիւ}}",
    "add_person": "Ուրիշ անձ աւելցնել ",
    "registration_confirmation": "Գրանձման հաստատում"
  },
  "user_mailer": {
    "verify_email": "Հաստատենք ձեր էլեկտրոնային հասցէն",
    "hi_full_name": "Բարեւ {{full_name}},",
    "verify_email_button": "Հաստատէք ձեր էլեկտրոնային հասցէն",
    "please_verify_email": "Կը խնդրենք որ ներքեւի կոճակը սխմէք ձեր էլեկտրոնային հասցէն Հաստատելու"
  },
  "services": {
    "dptap_dt": "DTaP/TDap/Td պատուաստանիւթեր (Դիֆթերիա, Փայտացում եւ Կապոյտ հազի համար)",
    "hepatitis_a": "Հեպատիտ A",
    "hepatitis_b": "Հեպատիտ B",
    "hepatitis_a_b": "Հեպատիտ A եւ B",
    "hib": "Հեմոֆիլուս ինֆլուենզա b տիպի (Hib)",
    "mmr": "Կարմրուկ, կարմրախտ և խոզուկ (ԿԿԽ)",
    "meningococcal": "մենինգոկոկային",
    "mpox": "Կապկի ծաղիկ (Mpox) հիւանդութիւն",
    "pneumococcal": "Պնևմակոկ",
    "polio": "Պոլիովիրուս",
    "rsv": "Ռեսպիրատոր սինցիտիալ վիրուս",
    "rotovirus": "Ռոտավիրուս",
    "zoster": "Սողնախտ (shingles)",
    "tetanus_diptheria": "Փայտացում եւ Դիֆթերիա",
    "tdap": "TDap պատուաստանիւթ (Դիֆթերիա, Փայտացում եւ Կապոյտ հազի համար)",
    "typhoid": "Տիֆային",
    "varicella": "Վարիսելլա (Սուտ ծաղկախտ)",
    "covid-19": "«COVID-19» Կորոնավիրուս համաճարակ ",
    "covid-19_adult": "«COVID-19» Կորոնավիրուս համաճարակ (Չափահաս)",
    "covid-19_adult_description": "12+ տարեկան անձերու համար",
    "covid-19_children": "«COVID-19» Կորոնավիրուս համաճարակ (Երեխայ)",
    "covid-19_children_description": "4 - 11 տարեկան երեխաներու համար",
    "covid-19_infants": "«COVID-19» Կորոնավիրուս համաճարակ (Մանուկ)",
    "covid-19_infants_description": "6 ամսու - 3 տարեկան երեխաներու համար",
    "influenza": "գրիպ,  տենդախառն հարբուխ",
    "mmrv": "Կարմրուկ, կարմրախտ,  խոզուկ (ԿԿԽ) եւ Վարիսելլա (Սուտ ծաղկախտ)"
  },
  "deep_archived": {
    "admin": "Տուեալներու պաշտպանութեան համար, {{test_group_name}} ({{slug}}) խումբի տուեալները արխիւացուեցան եւ այլեւս հասանելի չէ այս էջէն։ Եթէ օգնութեան պէտք էք, կապ հաստատէք ձեր հաշիւի ղեկավարին կամ support@primary.healt ին հետ։ ",
    "participant": "Այս էջը այլեւս հասանելի չէ։ Կը խնդրենք որ կապ հաստատէք {{test_group_name}} առողջապահական խումբի անդամի մը հետ։ Եթէ հին ցուցանիշներ գտնալու խնդիր ունիք եւ օգնութեան պէտք ունիք, կը խնդրենք որ support@primary.health կապ հաստատէք:"
  }
}