import TestStripDisplay, { StatusBadge } from './TestStripDisplay';
import { TestDisplay } from './TestDisplay';
import VaccineDisplay from './VaccineDisplay';
import WellnessTestDisplay, { TestHeader } from './WellnessTestDisplay';
import IISVaccineDisplay from './IISVaccineDisplay';
import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import SearchBar from './SearchBar';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateInCurrentLocale } from './utils';
import { kitTypeDisplayLetters } from '../../KitFlow/components/utils';
const PageHeader = ({ title, message, displayTime, barcode }) => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ padding: '24px 0' }}>
      <h4 className="mb-0">{title}</h4>
      <div>{message}</div>
      {(barcode || displayTime) && (
        <div style={{ color: '#717D96', padding: '16px 0 0 0' }}>
          {barcode && (
            <>
              <span>
                <b>{t('otc.barcode')}</b> {barcode.toUpperCase()}
              </span>
              <br />
            </>
          )}
          {formatDateInCurrentLocale(displayTime)}
        </div>
      )}
    </div>
  );
};

const MainMedicalHistory = ({
  noTestSelected,
  overviewTitle,
  overviewMessage,
  selectedTestStrip,
  isMobile,
  selectedWellnessTest,
  noOtherTestTypeSelected,
  selectedTest,
  wellnessTests,
  vaccines,
  selectedVaccine,
  setSelectedVaccine,
  testStrips,
  tests,
  test_group,
  setSelectedTest,
  user,
  setSelectedTestStrip,
  recordAcknowledgement,
  flipperFlags,
  allServices,
  setSelectedWellnessTest,
  selectedIISVaccine,
  setselectedIISVaccine,
  iisVaccines,
  appointment,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const filteredServices = allServices.filter((test) => {
    if (test.test_strip) {
      const displayName = `${t('test_strip.box_type', {
        type: test.kit_type,
      })} - ${t(`test_strip.display_name.${test.kit_type}`)}`;

      return displayName.toLowerCase().includes(search.toLowerCase());
    }

    if (test.iis_vaccine) {
      return test.displayName.toLowerCase().includes(search.toLowerCase());
    }

    return test.test_configuration.display_name
      .toLowerCase()
      .includes(search.toLowerCase());
  });

  return (
    <div className={isMobile ? 'card-bottom-border' : 'card border-0'}>
      <div className={isMobile ? 'p-3' : 'card-body'}>
        <div>
          {noTestSelected && (
            <>
              <PageHeader title={overviewTitle} message={overviewMessage} />
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 me-1">
                  <SearchBar search={search} setSearch={setSearch} />
                </div>
                <Button variant="link" onClick={() => window.print()}>
                  {!isMobile && (
                    <div className="me-2">{t('login.download')}</div>
                  )}
                  <FontAwesomeIcon icon={faArrowDownToLine} />
                </Button>
              </div>
            </>
          )}
          {selectedTestStrip && (
            <Row>
              <Col>
                <PageHeader
                  title={
                    isMobile ? (
                      <>
                        {t('test_strip.box_type', {
                          type: kitTypeDisplayLetters(selectedTestStrip.kit_type),
                        })}
                        <br />
                        {t(
                          `test_strip.display_name.${selectedTestStrip.kit_type}`,
                        )}
                      </>
                    ) : (
                      `${t('test_strip.box_type', {
                        type: kitTypeDisplayLetters(selectedTestStrip.kit_type),
                      })} - ${t(
                        `test_strip.display_name.${selectedTestStrip.kit_type}`,
                      )}`
                    )
                  }
                  barcode={selectedTestStrip.barcode}
                  displayTime={selectedTestStrip.display_time}
                />
              </Col>
              <Col className="text-end col-12 col-sm-auto order-first order-sm-last">
                <StatusBadge status={selectedTestStrip.status} />
              </Col>
            </Row>
          )}
          {selectedTest && (
            <>
              <Row>
                <Col>
                  <TestHeader test={selectedTest} />
                </Col>
                <Col className="text-end col-12 col-sm-auto order-first order-sm-last">
                  <StatusBadge status={selectedTest.result_complete} />
                </Col>
              </Row>
            </>
          )}
          {filteredServices.map((test) => {
            return (
              <>
                {tests.includes(test) &&
                  noOtherTestTypeSelected('selectedTest') && (
                    <TestDisplay
                      test={test}
                      selectedTest={selectedTest}
                      setSelectedTest={setSelectedTest}
                    />
                  )}
                {vaccines.includes(test) &&
                  noOtherTestTypeSelected('selectedVaccine') && (
                    <VaccineDisplay
                      vaccine={test}
                      selectedVaccine={selectedVaccine}
                      setSelectedVaccine={setSelectedVaccine}
                    />
                  )}
                {wellnessTests.includes(test) &&
                  noOtherTestTypeSelected('selectedWellnessTest') && (
                    <WellnessTestDisplay
                      test={test}
                      selectedWellnessTest={selectedWellnessTest}
                      setSelectedWellnessTest={setSelectedWellnessTest}
                    />
                  )}
                {testStrips.includes(test) &&
                  noOtherTestTypeSelected('selectedTestStrip') && (
                    <TestStripDisplay
                      user={user}
                      testStrip={test}
                      selectedTestStrip={selectedTestStrip}
                      setSelectedTestStrip={setSelectedTestStrip}
                      tests={appointment.tests}
                      recordAcknowledgement={recordAcknowledgement}
                      flipperFlags={flipperFlags}
                    />
                  )}
                {iisVaccines.includes(test) &&
                  noOtherTestTypeSelected('selectedIISVaccine') && (
                    <IISVaccineDisplay
                      iisVaccine={test}
                      selectedIISVaccine={selectedIISVaccine}
                      setselectedIISVaccine={setselectedIISVaccine}
                    />
                  )}
              </>
            );
          })}
          <div className="my-4">
            <LocalizedMarkdown container={test_group} stringKey="result_text" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMedicalHistory;
