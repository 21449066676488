import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare, faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import { humanize } from '../../common/utils';
import { MultiplexResults } from '../../shared_labs/multiplex_results';
import { Header } from './TestStripDisplay';

const TestRowDisplay = ({ test, t }) => {
  const color =
    test.result === 'negative'
      ? 'dark'
      : test.result === 'positive'
      ? 'primary'
      : 'muted';
  const isMultiplex = !!(
    test.test_configuration.results_schema && test.generic_test_results.length
  );
  const multiplexResults =
    isMultiplex &&
    new MultiplexResults(test, {
      renderers: {
        ungrouped: (results) => {
          return (
            <Col>
              <dl className="row mt-2">
                {results.map(({ subTestKey, testName, resultLabel }) => (
                  <React.Fragment key={subTestKey}>
                    <dd className="col-5">{testName}</dd>
                    <dt className="col-7 text-end">{resultLabel}</dt>
                  </React.Fragment>
                ))}
              </dl>
            </Col>
          );
        },
      },
    });

  const testResultDisplayButton = (
    <div className={`text-${color}`}>
      {isMultiplex
        ? multiplexResults?.fixedResults()
          ? ''
          : t('employer_testing.results.pending')
        : humanize(test.result) || t('employer_testing.results.pending')}
    </div>
  );

  return (
    <div className="body-medium-normal">
      <Col>
        <Row role="row" className="d-flex justify-content-md-between">
          <Col xs="auto" role="cell">
            <div>{test.test_configuration.display_name}</div>
          </Col>
          <Col role="cell" className="text-end pt-2">
            {testResultDisplayButton}
          </Col>
        </Row>
      </Col>
      {isMultiplex && multiplexResults?.fixedResults()}
      <Col className="mx-1 my-3 result-text-area">
        <LocalizedMarkdown
          container={test.test_configuration}
          stringKey={`${test.result || 'processing'}_result_text`}
        />
      </Col>
      <hr />
    </div>
  );
};

const TestDetails = ({ test }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const printDetails = () => {
    window.print();
  };

  return (
    <div>
      {isMobile && (
        <div className="text-end mb-3">
          <Button
            variant="link"
            aria-label="Download test details"
            onClick={printDetails}
          >
            {!isMobile && <div className="me-2">{t('login.download')}</div>}
            <FontAwesomeIcon icon={faArrowDownToLine} />
          </Button>
        </div>
      )}
      <div
        style={
          isMobile
            ? {}
            : {
                borderRadius: 16,
                padding: '24px',
              }
        }
      >
        <Row>
          <Col style={{ maxWidth: '560px' }} xs={12} s={10}>
            <Row className="d-flex my-2 pb-3">
              <Col>
                <h4>{t('result_page.view_results.status.test_results')}</h4>
              </Col>
            </Row>
            <div role="table" aria-label="Test results">
              <TestRowDisplay test={test} key={test.id} t={t} />
            </div>
          </Col>
          {!isMobile && (
            <Col className="text-end">
              <Button
                variant="link"
                aria-label="Download test details"
                onClick={printDetails}
              >
                <div className="me-2">{t('login.download')}</div>
                <FontAwesomeIcon icon={faArrowDownToLine} />
              </Button>
            </Col>
          )}
        </Row>
        <Col className="my-3">
          {test.lab_report_attached && (
            <Button
              className="btn-outline-secondary me-3"
              href={test.lab_report_url}
            >
              {t('result_page.lab_report')}
              <FontAwesomeIcon className="ms-2" icon={faUpRightFromSquare} />
            </Button>
          )}
          {test.custom_templates && (
            <Row className="my-3">
              {test.custom_templates.map((custom_template) => (
                <div>
                  <a href={custom_template.url} target="_blank">
                    {custom_template.name}
                  </a>
                </div>
              ))}
            </Row>
          )}
        </Col>
      </div>
    </div>
  );
};

export const TestDisplay = ({
  test,
  selectedTest,
  setSelectedTest,
}) => {
  return (
    <React.Fragment>
      {!selectedTest &&
        !test.part_of_test_strip && (
          <div
            className="medical_history__item test-card my-3"
            onClick={() => setSelectedTest(test)}
            key={test.id}
          >
            <Header
              displayName={test.test_configuration.display_name}
              icon={test.test_configuration.font_awesome_icon}
              color={test.test_configuration.color_code}
              displayTime={test.display_time}
              status={
                test.result_complete ? test.result_complete : 'processing'
              }
              key={'o' + test.id}
            />
          </div>
        )
      }
      {selectedTest && selectedTest.id == test.id && (
        <TestDetails key={'d' + selectedTest.id} test={selectedTest} />
      )}
    </React.Fragment>
  );
};
